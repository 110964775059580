import { ReadOnlyFieldWithValueProps, ReadOnlyField as CoreReadOnlyField } from '@msaf/core-react'
import { Control, Controller, Path } from 'react-hook-form'

export interface ReadOnlyFieldProps<T> extends Omit<ReadOnlyFieldWithValueProps, 'value'> {
  control: Control<T>
  fieldName: Path<T>
}

const convertType = <T,>(value: T) => {
  if (value == null) {
    return undefined
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  } else if (typeof value === 'string') {
    return value
  } else if ('label' in value) {
    return (value as unknown as { label: string }).label
  }
  throw new Error('Field type is not a string or object with a label key.')
}

export function ReadOnlyField<T>({ fieldName, control, ...props }: ReadOnlyFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        const value = convertType(field.value)
        if (value === undefined || typeof value == 'string') {
          return <CoreReadOnlyField value={value} {...props} />
        }
        throw new Error('field.value was not a string or object with a string `label` field.')
      }}
    />
  )
}
