import { useMemo } from 'react'
import { Button } from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { EoiWorkflow } from '../../../../components/workflow-containers/eoi'
import { SAVE_AND_CONTINUE_ID } from '../../../../forms/components/atom/footer'
import { EoiForm, EoiFormFields, FormErrors } from '../../../../forms/eoi'
import { useFormState } from '../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../hooks/use-mutation-with-toast'
import { usePermission } from '../../../../services/permissions'
import { UserPermissions } from '../../../../types/permissions'
import { RouteMode, RouteParams } from '../../../../types/route'

export const PlanEoi = () => {
  const { id, mode } = useParams<RouteParams>()
  // If edit mode, check if the user has permission to edit / process the EOI
  // If view mode, check that the user has permission to view the EOI
  useViewRedirectGatekeeper(UserPermissions.PROCESS_EOI, [UserPermissions.VIEW_EOI], `/plans/view/${id}/eoi`)
  const [isValid, setValid] = useState(true)
  const navigate = useNavigate()

  const hasEditPermission = usePermission(UserPermissions.PROCESS_EOI)

  const { isLoading, errors, data, refetchErrors } = useFormState<EoiFormFields>(([id]) => `/api/plan/${id}/eoi`, {
    workflowId: 'expressionOfInterest',
    sectionId: 'expressionOfInterest',
    formId: 'newRegistration',
    instanceId: `${id}`,
  })

  const initialState = useMemo(
    () => ({
      ...data,
      sectors: data?.sectors.length == 0 ? [{}] : data?.sectors,
      activitiesInterestedIn: data?.activitiesInterestedIn.length !== 0 ? data?.activitiesInterestedIn : [{}],
      isRepresentingGroupOrCollective: data?.isRepresentingGroupOrCollective ?? false,
      groupOrCollective: data?.isRepresentingGroupOrCollective ? data?.groupOrCollective : null,
    }),
    [data],
  )

  const mutation = useMutationWithToast<AxiosResponse<EoiFormFields>, AxiosError<FormErrors>, EoiFormFields, void>({
    method: 'PUT',
    url: `/api/plan/${id}/eoi`,
    success: {
      toastMessage: 'Expression of interest saved.',
      action: () => {
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save expression of interest.',
    },
  })

  return (
    <EoiWorkflow recordId={id}>
      <EoiForm
        mode={mode ?? RouteMode.VIEW}
        initialState={initialState}
        isSkeleton={isLoading}
        submitAction={async (data) => {
          const response = await mutation.mutateAsync(data)
          if (response.status !== 200) {
            throw new Error('Submission unsuccessful')
          }
        }}
        canEdit={hasEditPermission}
        errors={errors}
        onValidate={setValid}
        nextPath={`/plans/${mode}/${id}/eoi/decision`}
        footerActions={
          mode === RouteMode.EDIT ? (
            <>
              <Button
                // Navigation magically happens in form.tsx
                id={SAVE_AND_CONTINUE_ID}
                type='submit'
                buttonStyle='primary'
                label='Save and continue'
                isLoading={mutation.isLoading}
                isDisabled={mutation.isLoading || !isValid}
              />
              <Button
                type='submit'
                buttonStyle='primary'
                label='Save'
                isLoading={mutation.isLoading}
                isDisabled={mutation.isLoading || !isValid}
              />
              <Button
                buttonStyle='text-action'
                label='Cancel'
                onClick={() => navigate('/')}
                isDisabled={mutation.isLoading}
              />
            </>
          ) : undefined
        }
      />
    </EoiWorkflow>
  )
}
