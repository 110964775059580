type CacheData = {
  expiryTimestamp: number
  value: string
}

export class ClientCache {
  private static instance: ClientCache
  private _ttl!: number
  private _data: Map<string, CacheData>
  private _capacity!: number

  private constructor(ttl?: number, capacity?: number) {
    this._data = new Map()
    this._capacity = capacity ?? 10 // Max capacity of the cache
    this._ttl = ttl ?? 3600 // Time to live for the cache
  }

  /**
   * Initialise a ClientCache instance for the app, or
   * return already instantiated ClientCache instance
   *
   * The ttl and capacity values can be updated using the respective setters
   *
   * @param ttl (Optional) Time to live in seconds. Defaults to 3600s.
   * @param capacity (Optional) Maximum cache capacity. Defaults to 10 entries.
   * @returns
   */
  public static getInstance(ttl?: number, capacity?: number): ClientCache {
    if (!ClientCache.instance) {
      ClientCache.instance = new ClientCache(ttl ?? 3600, capacity ?? 10)
    }
    return ClientCache.instance
  }

  /**
   * Update the cache capacity
   */
  set capacity(capacity: number) {
    this._capacity = capacity
  }

  /**
   * Update the cache time to live
   */
  set ttl(ttl: number) {
    this._ttl = ttl
  }

  /**
   * Fetch the value from cache.
   * On cache hit, return the cached value
   * On cache miss, get the value from the `callback` function
   * and update the cache
   * @param key Cache key
   * @param callback Optionally gets the value on cache miss
   * @returns
   */
  fetch<T>(key: string, callback?: () => T): T | undefined {
    if (this._data.has(key)) {
      const cache = this._data.get(key)
      if (cache && cache.expiryTimestamp > this.now()) {
        return JSON.parse(cache.value) as T
      }
    }

    if (callback) {
      // Get the value
      const value: T = callback()

      if (value) {
        // Update cache
        this._data.delete(key)
        this._data.set(key, {
          expiryTimestamp: this.now() + this._ttl,
          value: JSON.stringify(value),
        })
      }

      return value
    }

    return undefined
  }
  /**
   * Create new cache entry
   * @param key Cache key
   * @param value Cache value.This value will eventually be stringified and stored against the key
   */
  create<T>(key: string, value: T) {
    this._data.delete(key)
    // Check if cache is full
    if (this._data.size === this._capacity) {
      const nextVal = this._data.keys().next().value
      this._data.delete(nextVal)
    } else {
      this._data.set(key, {
        expiryTimestamp: this.now() + this._ttl,
        value: JSON.stringify(value),
      })
    }
  }

  /**
   * Clears all cache
   */
  flush() {
    this._data.clear()
  }

  delete(key: string) {
    if (key in this._data) {
      this._data.delete(key)
    }
  }

  now(): number {
    return new Date().getTime() / 1000
  }
}
