import { ActionsMenuItem } from '@msaf/core-react'
import { createContext, ReactNode, useCallback, useContext, useReducer } from 'react'

interface AppContextState {
  pageActions?: ActionsMenuItemWithId[]
}

export type ActionId = string
export type ActionsMenuItemWithId = ActionsMenuItem & { id: ActionId }

interface AppContextActions {
  addPageActions?: (pageActions?: ActionsMenuItemWithId[] | ActionsMenuItemWithId) => void
  removePageActions?: (pageActions?: ActionId[] | ActionId) => void
}

type AppContextAction =
  | { type: 'add-actions'; pageActions?: ActionsMenuItemWithId[] }
  | { type: 'remove-actions'; pageActionIds?: ActionId[] }

const AppContext = createContext<AppContextState & AppContextActions>({})

export function useAppContext() {
  return useContext(AppContext)
}

const appContextReducer = (state: AppContextState, action: AppContextAction): AppContextState => {
  switch (action.type) {
    case 'add-actions':
      return { ...state, pageActions: [...(state.pageActions ?? []), ...(action.pageActions ?? [])] }
    case 'remove-actions': {
      const pageActions = state.pageActions?.filter(
        (existingAction) => !action.pageActionIds?.includes(existingAction.id),
      )
      return {
        ...state,
        pageActions,
      }
    }
    default:
      throw new Error('This action is not supported')
  }
}

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appContextReducer, {})

  const addPageActions = useCallback(
    (pageActions?: ActionsMenuItemWithId[] | ActionsMenuItemWithId) => {
      if (!pageActions) return
      dispatch({
        type: 'add-actions',
        pageActions: !Array.isArray(pageActions) ? [pageActions] : pageActions,
      })
    },
    [dispatch],
  )

  const removePageActions = useCallback(
    (pageActionIds?: ActionId[] | ActionId) => {
      if (!pageActionIds) return
      dispatch({
        type: 'remove-actions',
        pageActionIds: !Array.isArray(pageActionIds) ? [pageActionIds] : pageActionIds,
      })
    },
    [dispatch],
  )

  return (
    <AppContext.Provider
      value={{
        ...state,
        addPageActions,
        removePageActions,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
