import { ClientCache } from '@msaf/core-common'
import { AuthStrategy, RequestOptions, SearchQuery } from '@msaf/generic-search-common'
import { useSearchConfig } from './use-api'

export interface UseAdvancedSearchProps<T extends AuthStrategy = 'token'> {
  searchTypeKey: string
  requestOptions?: RequestOptions<T>
}

export function useAdvancedSearch<T extends AuthStrategy = 'token'>({
  searchTypeKey,
  requestOptions,
}: UseAdvancedSearchProps<T>) {
  const { data, isLoading } = useSearchConfig<T>(searchTypeKey, requestOptions, !!searchTypeKey)

  // Cache the search query for the active search type key
  const cacheSearchQuery = (query?: SearchQuery) => {
    const cacheClient = ClientCache.getInstance()
    const cacheKey = query?.searchTypeKey
    if (cacheKey) {
      const cachedQuery = cacheClient.fetch<SearchQuery>(cacheKey)
      if (!cachedQuery || (cachedQuery && JSON.stringify(query) !== JSON.stringify(cachedQuery))) {
        // Cache search query
        cacheClient.create(cacheKey, query)
      }
    }
  }

  return {
    searchTypeKey,
    searchTemplate: data?.searchTemplate,
    cacheSearchQuery,
    cachedSearchQuery: ClientCache.getInstance().fetch<SearchQuery>(searchTypeKey),
    isLoading,
  }
}
