import classNames from 'classnames'
import React from 'react'
import { Icon } from '../../../common/icon'
import { Link } from '../../link'
import { Icons } from '../../../common/icon/icon-paths'

type Status = 'info' | 'success' | 'warning' | 'error'

export interface NavLinkProps extends React.ComponentPropsWithRef<'a'> {
  label: string
  path: string
  checked?: boolean
  exact?: boolean
  isHidden?: boolean
  status?: Status
}

function getIconAndAriaLabel(status: Status): [Icons, string] {
  switch (status) {
    case 'info':
      return ['information-circle', 'Workflow information']
    case 'success':
      return ['check-circle', 'Workflow step completed']
    case 'warning':
      return ['warning', 'Workflow warning']
    case 'error':
      return ['warning-solid', 'Workflow error']
    default:
      return ['check-circle', 'Workflow step completed']
  }
}

export function NavLink({ label, checked, className, isHidden, status = 'success', ...props }: NavLinkProps) {
  const classes = classNames([
    'c-workflow-nav__link',
    {
      [className ?? '']: !!className,
    },
  ])

  if (isHidden) return null

  const [icon, ariaLabel] = getIconAndAriaLabel(status)

  return (
    <Link className={classes} activeClassName='c-workflow-nav__link--active' {...props}>
      <span className='c-workflow-nav__link-text'>{label}</span>
      <span className='c-workflow-nav__link-icon'>
        {checked ? (
          <Icon role='img' icon={icon} ariaLabel={ariaLabel} height='16px' width='16px' />
        ) : (
          <Icon role='img' icon={icon} ariaHidden={true} height='16px' width='16px' visibility='hidden' />
        )}
      </span>
    </Link>
  )
}
