import * as L from 'leaflet'
import { MapLayerConfig } from '../../types/map'

export type MapboxLayerConfig = MapLayerConfig & {
  styleId?: string
  tilesetId?: string
  options: L.TileLayerOptions
}

export type MapboxConfig = {
  layers: MapboxLayerConfig[]
  token: string
}

export const createMapboxLeafletLayers = (configList: MapboxLayerConfig[], mapboxToken: string) => {
  const defaultOptions = { maxZoom: 22, minZoom: 12, tileSize: 512, zoomOffset: -1 }

  return configList.map((config) => {
    const options = { ...defaultOptions, ...config.options }
    const styleUrl = `https://api.mapbox.com/styles/v1/kaipara-moana-remediation/${config.styleId}/tiles/{z}/{x}/{y}@2x?access_token=${mapboxToken}`
    const tilesetUrl = `https://api.mapbox.com/v4/${config.tilesetId}/{z}/{x}/{y}@2x.png?access_token=${mapboxToken}`
    const url = config.tilesetId ? tilesetUrl : styleUrl
    return { layer: L.tileLayer(url, options), config }
  })
}

export const buildLayersObject = (
  configList: { config: { name: string }; layer: L.Layer }[],
): L.Control.LayersObject => {
  return configList.reduce((layers, { layer, config }) => {
    layers[config.name] = layer
    return layers
  }, {} as L.Control.LayersObject)
}
