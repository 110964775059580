import { useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { DisplayContext } from '../../../../../../../components/gatekeeper'
import { ProgressWorkflow } from '../../../../../../../components/workflow-containers/progress'
import { ErrorCode } from '../../../../../../../constants'
import { RouteParams } from '../../../../../../../types/route'

export const Progress = () => {
  const { id } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  if (!id) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }
  return (
    <ProgressWorkflow recordId={id}>
      <Outlet />
    </ProgressWorkflow>
  )
}
