import { FormField } from '@msaf/core-react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { OrganisationCrudFieldProps } from '../../../forms/types'
import { RouteMode } from '../../../types/route'
import Lookup from '../base-lookup'
import { CrudLabels, CrudLookup, CrudLookupWrapper } from '../crud'
import { EditForm, ViewForm } from './form'
import { Organisation as OrganisationType } from './type'

interface OrganisationProps {
  onOrganisationSelected: (organisation?: OrganisationType) => void
  organisation?: OrganisationType
  label?: string
  isSkeleton?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  labels: CrudLabels
}

const OrganisationLookupWrapper: CrudLookup<OrganisationType> = ({ object, setObject }) => (
  <FormField label='Search organisations' labelId='orgSearchLabel'>
    <Lookup<OrganisationType>
      labelledBy='orgSearchLabel'
      object={object}
      setObject={(org) => setObject(org)}
      requestUrl={(query) => `/api/organisation/?query=${query}`}
      displayFields={['tradingName']}
      idField={'id'}
    />
  </FormField>
)

export const Organisation = ({ organisation, onOrganisationSelected, ...props }: OrganisationProps) => {
  return (
    <CrudLookupWrapper<OrganisationType>
      editForm={EditForm}
      viewForm={ViewForm}
      initialObject={organisation}
      onObjectChange={onOrganisationSelected}
      lookup={OrganisationLookupWrapper}
      {...props}
      urlFunc={(id) => `/api/organisation/${id ?? ''}`}
    />
  )
}

export const OrganisationCrudField = <T extends FieldValues>(props: OrganisationCrudFieldProps<T>) => {
  const { fieldId, mode, isDisabled } = props
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={fieldId}
      render={({ field }) => (
        <Organisation
          isDisabled={isDisabled || mode == RouteMode.VIEW}
          organisation={field.value as OrganisationType}
          onOrganisationSelected={(person) => {
            field.onChange(person)
          }}
          {...props}
        />
      )}
    />
  )
}
