import { ImageWithActions } from '@msaf/core-react'
import { useMemo } from 'react'
import { StaticInlineFeatureMap } from '../../../components/feature-map/static-inline'
import { StaticInlineFeatureMapFieldProps } from '../../types'

export function StaticInlineFeatureMapField<T>({
  actions,
  features,
  isSkeleton,
  ...props
}: StaticInlineFeatureMapFieldProps<T>) {
  const map = useMemo(
    () => <StaticInlineFeatureMap features={features} {...props} isEditable={false} isSkeleton={isSkeleton} />,
    [props, features],
  )

  if (actions) return <ImageWithActions imageElement={map} actions={actions} isSkeleton={isSkeleton} />

  return map
}
