import { CheckableField, CheckableFields, FormField } from '@msaf/core-react'
import { HelpTextType } from '../forms/types'

export interface BooleanRadioProps {
  label: string
  id: string
  isRequired?: boolean
  isSkeleton?: boolean
  isDisabled?: boolean
  validationMessages?: string[] | undefined
  helpText?: HelpTextType
}

type ClassicControlProps = {
  value: boolean
  setValue: (value: boolean) => void
}

export function BooleanRadio({
  label,
  id,
  value,
  setValue,
  isRequired,
  helpText,
  isSkeleton = false,
  validationMessages,
  isDisabled,
}: BooleanRadioProps & ClassicControlProps) {
  const valProps = (state: boolean) => {
    return {
      checked: value === state,
      onChange: () => setValue(state),
      name: id,
    }
  }
  return (
    <FormField
      label={label}
      labelId={`${id}Label`}
      helpText={helpText}
      isRequired={isRequired}
      validationMessages={validationMessages}
    >
      <CheckableFields ariaLabelledBy={`${id}Label`}>
        <CheckableField
          isSkeleton={isSkeleton}
          isDisabled={isDisabled}
          id={`${id}-true`}
          label='Yes'
          describedBy={`${id}Label`}
          {...valProps(true)}
          type='radio'
        />
        <CheckableField
          isSkeleton={isSkeleton}
          isDisabled={isDisabled}
          id={`${id}-false`}
          label='No'
          describedBy={`${id}Label`}
          {...valProps(false)}
          type='radio'
        />
      </CheckableFields>
    </FormField>
  )
}
