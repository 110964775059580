import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import { UploadedFile } from '../../../../../../../../forms/components/file-controller'
import { FormConfig } from '../../../../../../../../forms/types'
import { LookupItem, RepeatingTextItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { ApplicationRouteParams } from '../../../../../../../../types/route'
import { useLocation } from '@msaf/router-react'
import { usePlanSummary } from '../../../../../../../../hooks/usePlanSummary'
import { DEFAULT_CENTER } from '../../../../../../../../config/map'

export interface LongTermMonitoringFields {
  longTermMonitoringType: LookupItem | null
  longTermMonitoringSiteVisitDates: RepeatingTextItem | null
  sites: [{ locationName: string; longitude: number; latitude: number }]
  files?: UploadedFile[]
  notes: Array<AppNoteType>
}

export const useFormConfig = (): FormConfig<LongTermMonitoringFields> => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const url = useLocation()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { location } = usePlanSummary()

  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Long term monitoring selection' },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'longTermMonitoringType',
          lookupId: 'longTermMonitoringType',
          defaultValue: { label: '' },
        },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'longTermMonitoringSiteVisitDates',
          repeating: true,
          defaultValue: { value: '' },
          header: 'Long term monitoring',
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Monitoring sites' },
      },
      {
        type: 'field',
        element: {
          type: 'map',
          center: location ?? DEFAULT_CENTER,
          zoom: 15,
          featuresUrl: `/api/plan/${planId}/project/${applicationId}/features`,
          filterFeatureTypes: ['longTermMonitoringSite'],
          actions: [
            {
              iconAriaLabel: 'map',
              icon: 'map',
              label: 'Map location',
              type: 'button',
              onClick: () => {
                navigate(`/plans/${mode}/${planId}/map?origin=${url.pathname}`)
              },
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'repeating-section',
          sectionName: 'Monitoring site',
          fieldId: 'sites',
          allowEmpty: true,
          fields: [
            {
              type: 'text',
              fieldId: 'locationName',
            },
            {
              type: 'number',
              fieldId: 'latitude',
              formPrefix: 'sites',
              step: '.000001',
              min: -36.9,
              max: -35.3,
            },
            {
              type: 'number',
              fieldId: 'longitude',
              formPrefix: 'sites',
              step: '.000001',
              required: true,
              min: 173.5,
              max: 174.8,
            },
          ],
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Excel sheets and photos' },
      },
      {
        type: 'field',
        element: {
          type: 'files',
          fieldId: 'files',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.REQUEST_FOR_PAYMENT,
        },
      },
    ],
    [user, planId, applicationId, location],
  )
}
