import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js'
import { AuthEventHandler, LoginOptions } from '../types'
import { AuthMethod } from './base'

export const REFRESH_WITHIN_SEC = 30

export interface KeycloakAuthOptions {
  config?: Partial<KeycloakConfig>
  initOptions?: KeycloakInitOptions
  frontendUri?: string
}

const DEFAULT_CONFIG_OPTIONS: KeycloakConfig = Object.freeze({
  realm: 'main',
  clientId: 'frontend',
})

const DEFAULT_INIT_OPTIONS: KeycloakInitOptions = Object.freeze({
  onLoad: 'check-sso',
})

export class KeycloakAuth extends AuthMethod {
  keycloak?: Keycloak
  tokenIdentifier = 'Bearer'
  options?: KeycloakAuthOptions

  constructor(options?: KeycloakAuthOptions, eventHandler?: AuthEventHandler) {
    super(eventHandler)
    this.options = options
  }

  init = () => {
    // We only want one keycloak!
    if (this.keycloak) return

    this.keycloak = new Keycloak({ ...DEFAULT_CONFIG_OPTIONS, ...this.options?.config })
    this.keycloak.onReady = () => this.keycloakEventHandler()
    this.keycloak.onAuthSuccess = () => this.keycloakEventHandler()
    this.keycloak.onAuthRefreshSuccess = () => this.keycloakEventHandler()
    this.keycloak.onAuthRefreshError = () => this.keycloakEventHandler()
    this.keycloak.onAuthLogout = () => this.keycloakEventHandler()
    this.keycloak.onAuthError = () => this.keycloakEventHandler()

    this.keycloak.onTokenExpired = () => this.refresh()
    this.keycloak.init({ ...DEFAULT_INIT_OPTIONS, ...this.options?.initOptions })
  }

  use = () => true

  private keycloakEventHandler = () => {
    this.token = this.keycloak?.token
    this._eventHandler(this.keycloak?.authenticated ? 'authenticated' : 'unauthenticated')
  }

  private uriFromPath = (path: string) => {
    if (this.options?.frontendUri === undefined) return
    const strippedHost = this.options.frontendUri.replace(/\/$/, '')
    const strippedPath = path.replace(/^\//, '')
    return `${strippedHost}/${strippedPath}`
  }

  login = async (options?: LoginOptions) => {
    const redirectUri = options?.redirectPath ? this.uriFromPath(options.redirectPath) : undefined
    this.keycloak?.login({ redirectUri, loginHint: options?.email })
  }

  logout = async (options?: LoginOptions) => {
    const redirectUri = options?.redirectPath ? this.uriFromPath(options.redirectPath) : undefined
    this.keycloak?.logout({ redirectUri })
  }

  refresh = async () => {
    try {
      const result = await this.keycloak?.updateToken(REFRESH_WITHIN_SEC)
      if (!result) {
        throw new Error()
      }
    } catch {
      console.warn('Refresh failed. Login will be required.')
    }
  }
}
