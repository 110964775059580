import { Properties } from '@turf/helpers'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { FEATURE_CATEGORIES, FeatureType, MAP_CONFIG } from '../../../../../../../../config/map'
import { FeatureFormModeKeys } from '../../../../../../../../forms/types'
import { RouteParams } from '../../../../../../../../types/route'
import { getConfigForPlanActionType } from '../../../../../../../../forms/feature/config'

export type ActionFormConfigType = ReturnType<typeof getConfigForPlanActionType>
export interface InitialState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any
}
export const useFormConfig = (
  planId: string | undefined,
  featureType: FeatureType,
  properties: Properties | undefined,
  formMode: FeatureFormModeKeys,
) => {
  const { mode } = useParams<RouteParams>()
  const actionTypeForFeature = useMemo(() => {
    if (featureType in MAP_CONFIG.featureConfig) {
      const categoryKey = MAP_CONFIG.featureConfig[featureType].categoryKey
      return {
        actionType: categoryKey ? FEATURE_CATEGORIES[categoryKey] : undefined,
        actionSubtype: MAP_CONFIG.featureConfig[featureType].label,
      }
    }
    return {}
  }, [featureType])

  const config: {
    formConfig: ActionFormConfigType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState?: InitialState
  } = useMemo(() => {
    if (planId && properties) {
      return {
        formConfig: getConfigForPlanActionType({
          actionType: featureType,
          planId,
          formMode,
          routeMode: mode,
          properties,
        }),
        initialState: {
          ...actionTypeForFeature,
          ...properties,
        },
      }
    }
    return {
      formConfig: [],
    }
  }, [planId, properties, mode])

  return config
}
