import { createContext, PropsWithChildren } from 'react'
import { FeatureMapContextProps } from './types'

export const FeatureMapContext = createContext<Partial<FeatureMapContextProps>>({})

export const FeatureMapContextProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<FeatureMapContextProps>>) => (
  <FeatureMapContext.Provider value={props}>{children}</FeatureMapContext.Provider>
)
