import { Button, createToastMessage, ToastMessage } from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { Feature, Geometry } from '@turf/helpers'
import { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DisplayContext, useGatekeeper, useRedirect } from '../../../../../../../../components/gatekeeper'
import { ErrorCode } from '../../../../../../../../constants'
import { Form } from '../../../../../../../../forms/components/form'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { useRequest } from '../../../../../../../../hooks/useRequest'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ActionRouteParams, RouteMode } from '../../../../../../../../types/route'
import { useFormConfig as useActionFormConfig } from '../../action/[actionId]/action.form'
import { convertElementToView } from '../../../../../../../../forms/utils'
import { FeatureFormMode } from '../../../../../../../../forms/types'

export const TransferAction = () => {
  useGatekeeper(UserPermissions.EDIT_SUPPLY)
  const { setError } = useContext(DisplayContext)
  const { id: planId, mode, applicationId, actionId } = useParams<ActionRouteParams>()
  const redirect = useRedirect({ mode: RouteMode.EDIT })
  const navigate = useNavigate()

  const { client } = useRequest()

  const { data, isLoading } = useQuery<Feature<Geometry>>(['action', planId, actionId], () =>
    client.get(`/api/plan/${planId}/features/${actionId}`).then((response) => response.data),
  )

  const transferAction = usePostAction({
    url: `/api/plan/${planId}/project/${applicationId}/transfer-action`,
    displayServerError: true,
    messages: { success: 'Successfully transferred action', error: 'Unable to transfer action.' },
  })

  const actionConfig = useActionFormConfig(planId, data?.properties?.type, data?.properties, FeatureFormMode.EDIT_FORM)
  const initialState = useMemo(
    () => ({ ...actionConfig.initialState, transferToSubsequentApplication: false }),
    [actionConfig.initialState],
  )

  const validPage = applicationId != null && planId != null

  useEffect(() => {
    if (validPage && mode === RouteMode.VIEW) {
      // Redirect if view, this page is edit only
      redirect()
    }
  }, [validPage, mode, redirect])

  if (!validPage) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }

  const returnPath = `/plans/${mode}/${planId}/supply/project/${applicationId}/nursery`

  return (
    <Form
      mode={RouteMode.EDIT}
      initialState={initialState}
      isSkeleton={isLoading}
      submitAction={async (data) => {
        if (!data.subsequentApplication?.value && data.transferToSubsequentApplication) {
          createToastMessage(
            <ToastMessage
              messageType='error'
              title='Error'
              message='You must select a valid project to transfer to in order to complete the transfer process'
            />,
          )
        } else {
          await transferAction.mutateAsync({
            action: actionId,
            transferToSubsequentApplication: data.transferToSubsequentApplication,
            targetApplication: data.subsequentApplication?.value,
          })
          navigate(returnPath)
        }
      }}
      footerActions={
        <>
          <Button type='submit' buttonStyle='primary' label='Save and return' />
          <Button
            buttonStyle='text-action'
            label='Cancel'
            path={`/plans/${mode}/${planId}/supply/project/${applicationId}/nursery`}
          />
        </>
      }
      config={[
        {
          type: 'action',
          element: {
            type: 'button',
            path: returnPath,
            label: 'Back',
            iconAlignment: 'left',
            icon: 'arrow-left',
            buttonStyle: 'text-action',
            isDisabled: false,
          },
        },
        {
          type: 'atom',
          element: { type: 'heading', content: 'Action transfer', level: 2 },
        },
        {
          type: 'field',
          element: {
            type: 'boolean',
            fieldId: 'transferToSubsequentApplication',
          },
        },
        {
          type: 'field',
          element: {
            type: 'lookup',
            fieldId: 'subsequentApplication',
            lookupId: 'subsequentApplication',
            fieldFilters: { planId, applicationId },
            isDisabled: true,
            watch(props) {
              if (props.info.name === 'transferToSubsequentApplication' && props.info.type === 'change') {
                if (props.data.transferToSubsequentApplication === true) {
                  return { ...props.initialConfig, isDisabled: false }
                }
                // Clear the value if `transferToSubsequentApplication` is false
                props.methods.setValue('subsequentApplication', null)
                return { ...props.initialConfig, isDisabled: true }
              }
              return props.initialConfig
            },
          },
        },
        {
          type: 'atom',
          element: { type: 'divider' },
        },
        {
          type: 'atom',
          element: { type: 'heading', content: 'Proposed action details', level: 2 },
        },
        ...actionConfig.formConfig.map((c) => convertElementToView(c)),
      ]}
    />
  )
}
