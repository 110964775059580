import { useMemo } from 'react'
import { FieldError, FieldValues, Path, useFormContext } from 'react-hook-form'
import { ErrorType } from '../../../validation/constants'

export function useFieldErrors<T extends FieldValues>(fieldId: Path<T>) {
  const {
    formState: { errors },
  } = useFormContext<T>()

  const fieldOrParentId = fieldId.split('.')[0]
  const isComplexField = fieldOrParentId != fieldId

  const fieldErrors = useMemo(() => {
    const fieldErrors: string[] = []

    if (!isComplexField && fieldId in errors) {
      const fieldError: FieldError = errors[fieldId as Path<T>]

      if (fieldError.types) {
        Object.entries(fieldError.types).forEach(([t, message]) => {
          if (t === ErrorType.REQUIRED) {
            fieldErrors.push('This field is required')
          } else if (t === ErrorType.CUSTOM) {
            fieldErrors.push(message as string)
          } else {
            throw new Error('Unable to display unknown error')
          }
        })
      } else if (fieldError.message) {
        fieldErrors.push(fieldError.message)
      }
    }

    if (isComplexField) {
      // for complex fields, we expect the following data structure and assume the field to render is 3 tiers deep in the object:
      // {
      //   sectionKey: [
      //     {fieldKey: {
      //       message: string
      //     }}
      //   ]
      // }
      // example:
      // {
      //   landCovers: [
      //     {
      //       landCoverType: : {
      //         message: 'At least one field required'
      //       }
      //     }
      //   ]
      // }

      const errorObj = fieldId.split('.').reduce(function (a, b) {
        // when nested objects don't exist, return null and prevent the value from being added to the UI.
        try {
          return a[b]
        } catch {
          return
        }
      }, errors)

      !!errorObj && fieldErrors.push(errorObj?.message)
    }

    return fieldErrors
  }, [errors[fieldOrParentId]])

  return fieldErrors
}
