import axios, { AxiosError } from 'axios'
import { useContext, useEffect, useMemo } from 'react'
import { useAuth } from '../auth'
import { DisplayContext } from '../components/gatekeeper'
import { ErrorCode } from '../constants'
import { SERVER_ADDRESS } from '../environment'
import RaygunClient, { ErrorType } from '../logging/raygun'

const AXIOS_CLIENT = axios.create({
  baseURL: SERVER_ADDRESS,
})

AXIOS_CLIENT.interceptors.response.use(
  (resp) => resp,
  (error: AxiosError) => {
    const response = error?.response
    RaygunClient.getInstance().trackError(`Request failed with code: [${response?.status}] ${response?.data}`, {
      type: ErrorType.API_ERROR,
      url: error.config.url,
      method: error.config.method,
    })
    return Promise.reject(error)
  },
)

export const useRequest = () => {
  const displayContext = useContext(DisplayContext)
  const auth = useAuth()
  useEffect(() => {
    const interceptor = AXIOS_CLIENT.interceptors.response.use(
      (resp) => resp,
      (error: AxiosError) => {
        let code: ErrorCode
        const response = error?.response
        switch (response?.status) {
          case 401:
            code = ErrorCode.UNAUTHENTICATED_401
            break
          case 403:
            code = ErrorCode.PERMISSION_DENIED_403
            break
          case 404:
            code = ErrorCode.NOT_FOUND_404
            break
          case 500:
            code = ErrorCode.SERVER_ERROR_500
            break
          default:
            code = ErrorCode.UNKNOWN_ERROR
            break
        }

        if (code != ErrorCode.UNKNOWN_ERROR) {
          displayContext?.setError(code)
        }

        return Promise.reject(error)
      },
    )
    return () => {
      AXIOS_CLIENT.interceptors.request.eject(interceptor)
    }
  }, [displayContext])

  useMemo(() => {
    AXIOS_CLIENT.defaults.headers = { ...AXIOS_CLIENT.defaults.headers, ...auth.authStrategy?.toHeader() }
  }, [auth.authStrategy?.tokenIdentifier, auth.authStrategy?.token])

  return { client: AXIOS_CLIENT }
}
