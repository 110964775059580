import { RenderContentComponentProps, SupportedFeatureType } from '@msaf/maps-common'
import { Feature, Geometry } from '@turf/helpers'
import { useMemo } from 'react'
import { DeepPartial, FieldValues, UnpackNestedValue } from 'react-hook-form'
import { NavigateFunction, useMatch, useParams } from 'react-router-dom'
import { FeatureType } from '../../config/map'
import { PLAN_MAP_PATH } from '../../constants'
import { updateVerificationFields } from '../../routes/plans/[mode]/[id]/application/[applicationId]/progress/action-verification/action-verification.form'
import { getActionVariationInitialState } from '../../routes/plans/[mode]/[id]/application/[applicationId]/progress/action-verification/utils'
import { RouteMode, RouteParams } from '../../types/route'
import { EmptyWrapper } from '../components/empty-wrapper'
import { Form } from '../components/form'
import { FormWatchResultProps } from '../components/hooks/use-form-watch'
import {
  DEFAULT_AVERAGE_PRICE_PER_PLANT,
  DEFAULT_AVERAGE_PRICE_PER_PLANT_SILVOPASTURE,
  DEFAULT_PLANT_SPACING_STEMS_PER_HA,
  DEFAULT_PLANT_SPACING_STEMS_PER_HA_SILVOPASTURE,
  PLANTING_TYPES,
} from '../constants'
import { FeatureFormMode, FeatureFormFields, FeatureFormModeKeys, FormConfig, FormElementConfig } from '../types'
import { getConfigForPlanActionType } from './config'
import { isFormModes } from '../utils'
import { getLongTermMonitoringFormConfig } from './long-term-monitoring/config'

export interface FieldValuesWithActionTypes extends FieldValues {
  actionType: unknown
  actionSubtype: unknown
}

export type GetFeatureFormConfigProps<T extends FieldValues> = {
  pathname: string
  navigate: NavigateFunction
  planId: string
  formMode: FeatureFormModeKeys
  actionType: FeatureType
  location: [number, number]
  data?: T
}

type FeatureProperties = Feature<Geometry> & {
  hasVariation?: boolean
}

export interface FeatureFormProps
  extends RenderContentComponentProps<FeatureProperties, SupportedFeatureType & { isLoading: boolean }> {
  featureType: FeatureType
}

export function FeatureForm<T extends FieldValuesWithActionTypes>({
  context,
  featureType,
  state,
  updateState,
}: FeatureFormProps) {
  const { id: planId, mode } = useParams<RouteParams>()
  const match = useMatch(PLAN_MAP_PATH)

  if (!planId) throw new Error('Cannot display a FeatureForm without a plan.')

  const formMode = match ? FeatureFormMode.MAIN_MAP : FeatureFormMode.VERIFICATION_MAP

  const formConfig = useMemo(() => {
    let config: FormConfig<FeatureFormFields> = getConfigForPlanActionType({
      actionType: featureType,
      planId,
      formMode,
      routeMode: mode,
      properties: state.properties,
    })
    if (formMode === FeatureFormMode.VERIFICATION_MAP) {
      if (featureType === 'longTermMonitoringSite') {
        config = getLongTermMonitoringFormConfig({ formMode }) as FormConfig<FeatureFormFields>
      } else {
        config = updateVerificationFields(state.properties, config)
      }
    }
    return config
  }, [formMode, featureType, planId, state])

  const fieldWatch = useMemo(() => {
    if (!updateState) return undefined
    return ({ data, info }: FormWatchResultProps<T>) => {
      if (info?.type === 'change') {
        // Strip out area and length as they shouldn't be set
        // here and should be pulled down from the geometry changes.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { area, length, ...nonGeometryFields } = data
        state.properties = {
          ...state.properties,
          ...nonGeometryFields,
        }
        updateState(state)
      }
    }
  }, [updateState, state])

  const initialState = useMemo(() => {
    type DefaultDataState = {
      averagePricePerPlant?: string
      plantingSpacingStemsPerHa?: string
      kmrContributionPerHa?: string
    }
    const defaultData: DefaultDataState = {}

    if (PLANTING_TYPES.includes(featureType)) {
      defaultData.averagePricePerPlant =
        featureType === 'hillCountrySilvopasturePlanting'
          ? DEFAULT_AVERAGE_PRICE_PER_PLANT_SILVOPASTURE
          : DEFAULT_AVERAGE_PRICE_PER_PLANT
      defaultData.plantingSpacingStemsPerHa =
        featureType === 'hillCountrySilvopasturePlanting'
          ? DEFAULT_PLANT_SPACING_STEMS_PER_HA_SILVOPASTURE
          : DEFAULT_PLANT_SPACING_STEMS_PER_HA
      defaultData.kmrContributionPerHa = '1500.00'
    }

    type AdditionalDataState = {
      hasVariation?: boolean
    }
    const additionalData: AdditionalDataState = {}
    if (formMode === FeatureFormMode.VERIFICATION_MAP) {
      // Ensure hasVariation defaults to false for the map verification form.
      additionalData.hasVariation = state.properties?.hasVariation ?? false
    }

    const defaults = { ...defaultData, ...state.properties, ...additionalData } as UnpackNestedValue<DeepPartial<T>>
    if (isFormModes(formMode).isVerificationMode) {
      return getActionVariationInitialState(defaults) as UnpackNestedValue<DeepPartial<T>>
    }
    return defaults
  }, [state.id])

  if (context === undefined) throw new Error('Context is required.')

  return (
    <Form<T>
      initialState={initialState}
      fieldWatch={fieldWatch}
      wrapperComponent={EmptyWrapper}
      mode={mode ?? RouteMode.VIEW}
      config={formConfig as FormElementConfig<T>[]}
      displayFooter={false}
      isSkeleton={context.isLoading}
      // Feature forms have unsaved changes transition configured at
      // page level in the FeatureMap component
      hasUnsavedChangesTransition={false}
    />
  )
}
