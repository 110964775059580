import { RecordContent } from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { FormEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { DeepPartial, DefaultValues, FieldValues, FormProvider, UnpackNestedValue, useForm } from 'react-hook-form'
import { useFormConfig } from '../../hooks/use-form-config'
import { RouteMode } from '../../types/route'
import { FormErrors } from '../../validation/constants'
import { BaseForm } from '../base-form'
import { FormElementConfig } from '../types'
import Footer, { SAVE_AND_CONTINUE_ID } from './atom/footer'
import { Transition } from './atom/transition'
import { useFormWatch, UseFormWatchFunc } from './hooks/use-form-watch'
import { useTranslateErrors } from '../../hooks/use-translate-errors'

interface FormProps<T extends FieldValues> {
  submitAction?: (data: T, submitter: HTMLElement | null) => Promise<unknown>
  config: FormElementConfig<T>[]
  mode: RouteMode
  initialState: DefaultValues<T> | undefined | null
  isSkeleton?: boolean
  errors?: FormErrors<T>
  fieldWatch?: UseFormWatchFunc<T>
  wrapperComponent?: React.ElementType
  footerActions?: ReactNode
  displayFooter?: boolean
  nextPath?: string
  canEdit?: boolean
  hasUnsavedChangesTransition?: boolean
}

export function Form<T extends FieldValues>({
  config,
  isSkeleton,
  mode,
  initialState,
  errors,
  footerActions,
  displayFooter = true,
  nextPath,
  canEdit,
  wrapperComponent: Wrapper = RecordContent,
  submitAction,
  fieldWatch,
  hasUnsavedChangesTransition = true,
}: FormProps<T>) {
  const [submitter, setSubmitter] = useState<HTMLElement | null>()
  const navigate = useNavigate()
  const resolveTransitionRef = useRef<() => void>()
  const methods = useForm<T>({ defaultValues: { notes: [], ...(initialState as UnpackNestedValue<DeepPartial<T>>) } })

  useTranslateErrors({
    errors,
    setError: methods.setError,
    clearErrors: methods.clearErrors,
    submitCount: methods.formState.submitCount,
    toMessage: (e) => ({ type: 'custom', message: e.errorMessage }),
  })

  useEffect(() => {
    methods.reset(initialState as UnpackNestedValue<DeepPartial<T>>, { keepErrors: true })
  }, [initialState])

  useEffect(() => {
    // HACK: Reset form on unmount - combats strict mode shenanigans adding
    // an additional field instance to the form w/ remount
    return () => methods.reset(undefined, { keepErrors: true })
  }, [])

  useEffect(() => {
    // Reset form state on submit
    if (methods.formState.isSubmitSuccessful) {
      resolveTransitionRef.current?.()
      methods.reset(methods.getValues(), { keepErrors: true })
    }
    if (!methods.formState.isDirty && submitter?.id === SAVE_AND_CONTINUE_ID && nextPath) {
      // On success
      // Submitter only set on success, and we only want to navigate once the form state is set to dirty
      navigate(nextPath)
    }
  }, [methods.formState, submitter?.id])

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      const handleSubmit = () => {
        const data = methods.getValues()
        const submitter = (e?.nativeEvent as SubmitEvent).submitter
        submitAction?.(data as T, submitter).then(() => {
          setSubmitter(submitter)
        })
      }
      // Submit even if there is an error. We do validation server side.
      methods.handleSubmit(handleSubmit, handleSubmit)(e)
    },
    [methods.handleSubmit, methods.getValues, submitAction],
  )

  useFormWatch(methods, fieldWatch)

  const FormConfig = useFormConfig({ config, mode, isSkeleton })

  const formRef = useRef<HTMLFormElement | null>()

  return (
    <FormProvider {...methods}>
      {mode === RouteMode.EDIT && hasUnsavedChangesTransition && (
        <Transition
          formConfig={config}
          onSave={() => {
            formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
            return new Promise<void>((resolve) => {
              // To be resolved when form has been submitted
              resolveTransitionRef.current = resolve
            })
          }}
        />
      )}
      <BaseForm onSubmit={onSubmit} formRef={formRef}>
        <Wrapper>{FormConfig}</Wrapper>
        {displayFooter && (
          <Footer
            mode={mode}
            footerActions={footerActions}
            nextPath={nextPath}
            canEdit={canEdit}
            isDisabled={methods.formState.isSubmitting}
          />
        )}
      </BaseForm>
    </FormProvider>
  )
}
