import React, { ChangeEvent, useMemo, useState } from 'react'
import { LegendOptionProps } from '../legend-option'
import { CheckableField } from '../../../../../forms/checkable-fields/checkable-field'
// TODO: update this import to '@msaf/core-common' once update released
import { formatSelector } from '../../../../../../../../../common/core-common/src/helpers'
import { LegendIndicator } from '../../../legend-indicator'
import { LegendOptionLabel } from '../legend-option/legend-option-label'

export interface LegendOptionGroupProps {
  groupName: string
  groupOptions: Array<LegendOptionProps>
  name: string
}

export function LegendOptionGroup({ groupName, groupOptions, name }: LegendOptionGroupProps) {
  const [options, setOptions] = useState(groupOptions)

  const allOptionsSelected = groupOptions.every((option) => option.isChecked)

  const groupId = useMemo(() => {
    return 'legend-group-' + formatSelector(groupName)
  }, [groupName])

  const handleGroupCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptions(
      options.map((option) => {
        if (e.target.checked) {
          option.isChecked = true
          option.onChange(true, option.id)
        } else {
          option.isChecked = false
          option.onChange(false, option.id)
        }
        return option
      }),
    )
  }

  const handleOptionCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targetId = e.target.id
    const isChecked = e.target.checked
    setOptions(
      options.map((option) => {
        if (option.id === targetId) {
          option.isChecked = isChecked
          option.onChange(isChecked, option.id)
        }
        return option
      }),
    )
  }

  return (
    <div className='c-legend-menu__option-group'>
      <CheckableField
        type='checkbox'
        id={groupId}
        label={groupName}
        name={name}
        checked={allOptionsSelected}
        onChange={handleGroupCheckboxChange}
      />
      <ul className='c-legend-menu__items c-legend-menu__items--indented'>
        {options.map(({ id, label, name, isChecked, mapFeatureTheme }) => {
          return (
            <li key={id} className='c-legend-option'>
              <CheckableField
                type='checkbox'
                id={id}
                label={<LegendOptionLabel label={label} indicator={<LegendIndicator {...mapFeatureTheme} />} />}
                name={name}
                checked={isChecked}
                onChange={handleOptionCheckboxChange}
                hasNoVerticalSpacing
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
