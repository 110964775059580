import { MapUIConfig, SupportedDrawnLayers, updateFeatureProperties } from '@msaf/maps-common'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { Id, Properties } from '@turf/helpers'
import * as L from 'leaflet'
import { FitBoundsOptions } from 'leaflet'
import { useEffect } from 'react'
import { MAP_CONFIG } from '../../config/map'
import { FeatureFilterFunction, FeatureFilterGroup } from './feature-filter-groups/types'
import { CenterMode, FeatureMapContextProps, FeatureMapFeatureOptions } from './types'
import { useFeatureDrawing } from './utils/drawing'
import { useFeatureLayer } from './utils/feature-management'
import { useFeatureState } from './utils/state-management'
import { Feature, Polygon } from 'geojson'

export const useCenterMode = (
  map: L.Map | undefined,
  layer: L.GeoJSON | undefined,
  centerMode?: CenterMode,
  options?: FitBoundsOptions,
) => {
  const [localMap, setLocalMap] = useState<L.Map | undefined>()

  const recenter = useCallback(() => {
    if (centerMode === 'toFeatures' && map && layer && layer.getLayers().length !== 0 && map != localMap) {
      map.fitBounds(layer.getBounds(), options)
      setLocalMap(map)
    }
  }, [map, localMap, layer, centerMode, Object.values(options ?? {})])

  useEffect(() => {
    recenter()
  }, [recenter])

  useEffect(() => {
    localMap?.on('resize', recenter)

    return () => {
      localMap?.off('resize', recenter)
    }
  }, [localMap])

  useLayoutEffect(() => {
    map?.invalidateSize()
    recenter()
  }, [])

  return recenter
}

export interface UseFeatureMapMethodsReturn extends Partial<FeatureMapContextProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layer: L.GeoJSON<any> | undefined
  isDirty: boolean
  onSave?: () => void
}

export const useFeatureMapMethods = (
  featuresUrl: string,
  options: FeatureMapFeatureOptions,
  map?: L.Map,
  config: MapUIConfig = MAP_CONFIG,
) => {
  const readOnly = useMemo(() => !(options.edit || options.create || options.delete), [options])

  // Initialise feature collection state
  const {
    state: collectionState,
    upsertFeature,
    removeFeature,
    saveFeatures,
    addFeatureFilter,
    removeFeatureFilter,
    isLoading,
  } = useFeatureState({ featuresUrl, config })

  // Set up feature layer with state from collection
  const featureLayerMethods = useFeatureLayer({
    map,
    config,
    features: collectionState.collections?.filtered,
  })

  const { state: layerState, clearFeatureSelection, createFeature, deleteFeature } = featureLayerMethods

  // Enable drawing for the map
  const { newFeature, setDrawOptions, drawOptions, cancelDrawing, setSnappable } = useFeatureDrawing({
    map,
    clearFeatureSelection,
    config,
    featureLayerMethods,
    onGeometryChange: (layer: SupportedDrawnLayers) => {
      if (layer.feature) upsertFeature(layer.toGeoJSON())
    },
    readOnly,
  })

  const drawFeature = useCallback(
    async (featureType: string) => {
      const layer = await newFeature(featureType)
      if (layer) {
        // No layer if the draw action is cancelled.
        createFeature(layer)
      }
      return layer
    },
    [newFeature, createFeature],
  )

  const onSave = useCallback(() => {
    cancelDrawing()
    clearFeatureSelection()
    if (collectionState.collections?.unfiltered) saveFeatures(collectionState.collections.unfiltered)
  }, [collectionState.collections?.unfiltered, cancelDrawing])

  const getLayerForFeature = useCallback(
    (featureId: Id) => {
      return featureLayerMethods.state.layer
        ?.getLayers()
        .find((l) => (l as SupportedDrawnLayers).feature?.id === featureId) as SupportedDrawnLayers
    },
    [featureLayerMethods.state.layer],
  )

  const [filterGroups, setFilterGroups] = useState<FeatureFilterGroup[]>([])
  const [toggleAll, setToggleAll] = useState<boolean | null>(null)

  useEffect(() => {
    const filterFunctions = filterGroups
      .map((filterGroup) => filterGroup.filterFunction)
      .filter((filterFunction) => filterFunction != null) as FeatureFilterFunction[]

    // Register all the filter functions
    filterFunctions.forEach((f) => f && addFeatureFilter(f))
    return () => filterFunctions.forEach((f) => f && removeFeatureFilter(f))
  }, [filterGroups])

  const upsertFilterGroup = useCallback(
    (updateFilterGroup: FeatureFilterGroup) => {
      setFilterGroups((filterGroups) => {
        if (!filterGroups.find((filterGroup) => filterGroup.key === updateFilterGroup.key)) {
          return [...filterGroups, updateFilterGroup]
        }
        return filterGroups.map((filterGroup) =>
          filterGroup.key === updateFilterGroup.key ? updateFilterGroup : filterGroup,
        )
      })
    },
    [setFilterGroups],
  )
  const removeFilterGroup = useCallback(
    (removeFilterGroup: FeatureFilterGroup) => {
      setFilterGroups((filterGroups) => filterGroups.filter((filterGroup) => filterGroup.key !== removeFilterGroup.key))
    },
    [setFilterGroups],
  )

  const methods: UseFeatureMapMethodsReturn = {
    layer: featureLayerMethods.state.layer,
    isDirty: collectionState.isDirty,
    onSave,
    isLoading,

    selectedFeature: layerState.selectedFeature,
    collectionState,
    clearFeatureSelection,
    filterGroups,
    upsertFilterGroup,
    removeFilterGroup,
    toggleAll,
    setToggleAll,
  }

  const duplicateFeature = (feature: Feature<Polygon>, featureType: string, properties?: Properties) => {
    /* Create an ID so the frontend is able to lookup and identify the feature
     * within the leaflet map. The ID is negative so the backend knows to ignore
     * it and generate a new one when the feature is saved.
     */
    const id = 0 - new Date().getTime() / 1000

    const newFeature = {
      ...feature,
      id,
      properties: { type: featureType },
    }

    // Create the leaflet layer.
    const layer = L.geoJSON(newFeature) as unknown as L.Polygon

    // Make the layer editable
    layer.options.pmIgnore = false
    L.PM.reInitLayer(layer)

    // Create the layer / feature and sync the properties.
    layer.feature = newFeature
    createFeature?.(layer)
    updateFeatureProperties(layer, { ...properties, type: featureType })

    return layer
  }

  if (options.create || options.edit) {
    methods.drawOptions = drawOptions
    methods.setDrawOptions = setDrawOptions
    methods.setSnappable = setSnappable
    methods.cancelDrawing = cancelDrawing
    methods.upsertFeature = upsertFeature
    methods.editFeature = (featureId: Id) => {
      const layer = featureLayerMethods.state.layer
        ?.getLayers()
        .find((l) => (l as SupportedDrawnLayers).feature?.id === featureId) as SupportedDrawnLayers
      featureLayerMethods.editFeature(layer)
    }
    methods.createFeature = createFeature
    methods.duplicateFeature = duplicateFeature
  }

  if (options.create) {
    methods.drawFeature = drawFeature
  }

  if (options.delete) {
    methods.removeFeature = (featureId: string | number | undefined) => {
      if (featureId) {
        removeFeature(featureId)
        deleteFeature(featureId)
      }
    }
  }

  if (options.edit || readOnly) {
    // Edit or View mode
    methods.selectFeature = (featureId: Id) => {
      const layer = getLayerForFeature(featureId)
      featureLayerMethods.editFeature(layer)
    }
  }

  return methods
}
