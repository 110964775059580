import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { REVIEW_EDIT_PERMISSIONS, REVIEW_VIEW_PERMISSIONS } from '../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../types/route'
import { ApplicationWrapper } from './application-wrapper'

export const ReviewSectionWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  useViewRedirectGatekeeper(REVIEW_EDIT_PERMISSIONS, REVIEW_VIEW_PERMISSIONS)

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'review', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not submitted</InlineNotification>
      </RecordContent>
    )
  }

  return <ApplicationWrapper />
}
