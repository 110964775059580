import { AnimatePresence, motion } from 'framer-motion'
import { Button, ButtonWithIcon } from '../../common/button'
import { desktopMenuAnimations as menuAnimations } from '../app-shell/toolbar-menu/animations'
import React, { useEffect } from 'react'
import { useOptionalExternalState } from '../../../hooks/use-optional-external-state'
import {
  FloatingPortal,
  autoUpdate,
  useFloating,
  offset,
  FloatingFocusManager,
  useDismiss,
  useInteractions,
  useClick,
} from '@floating-ui/react'

export type ActionsMenuOptions =
  | {
      isOpen: boolean
      setIsOpen: (isOpen: boolean) => void
    }
  | { isOpen?: undefined; setIsOpen?: undefined }

export interface ActionsMenuProps extends ButtonWithIcon {
  label: string
  isLeftAligned?: boolean
  menuOptions?: ActionsMenuOptions
  /**
   * Optional prop to select portal root. Defaults to page content area to avoid drop downs
   * appearing in front of fixed page header element.
   */
  menuPortalTarget?: HTMLElement | null
  collapseOnClick?: boolean
  /**
   * If menu rendered within table, we want to prevent clicks within it bubbling up
   * and triggering a row click.
   */
  preventClickBubbling?: boolean
}

export function ActionsMenu({
  label,
  isLeftAligned = false,
  buttonStyle,
  isDisabled,
  isSkeleton,
  children,
  menuOptions,
  menuPortalTarget,
  collapseOnClick = true,
  preventClickBubbling = false,
}: ActionsMenuProps) {
  const [isOpen, setIsOpen] = useOptionalExternalState<boolean>({
    initialValue: false,
    setExternalState: menuOptions?.setIsOpen,
    externalState: menuOptions?.isOpen,
  })

  // Portal dropdown to allow it to display within elements with overflow hidden
  const { context, refs, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: isLeftAligned ? 'bottom-start' : 'bottom-end',
    middleware: [
      offset({
        mainAxis: 8,
      }),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  useEffect(() => {
    /**
     * The Floating UI library needs an id for the portal but we take in a ref so as to be consistent
     * with other msaf components. If the ref component passed in doesn't have an ID then we assign one.
     */
    if (menuPortalTarget && !menuPortalTarget.id)
      menuPortalTarget.id = `msafActionsMenuPortalTarget-${new Date().getUTCMilliseconds()}`
  }, [menuPortalTarget])

  /**
   * Conditionally prevent click event from bubbling (to avoid triggering row click
   * behaviour if rendered within a table)
   */
  const clickWithin = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (preventClickBubbling) {
      e.stopPropagation()
    }
  }

  return (
    <div className='c-actions-menu' onClick={clickWithin}>
      <Button
        ref={refs.setReference}
        className='c-actions-menu__btn'
        label={label}
        icon='chevron-down'
        iconAriaLabel='Toggle menu'
        buttonStyle={buttonStyle}
        isDisabled={isDisabled}
        isSkeleton={isSkeleton}
        isIconRotated180={isOpen}
        {...getReferenceProps()}
      />
      <AnimatePresence>
        {isOpen && (
          <FloatingPortal id={menuPortalTarget ? menuPortalTarget.id : 'page-content-portal'}>
            <FloatingFocusManager context={context} modal={false}>
              {/* Portal drop-down to avoid overflow properties cutting it off in tables etc */}
              <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                <motion.nav
                  className='c-dropdown-container'
                  aria-label={`${label} actions`}
                  initial={menuAnimations.initial}
                  animate={menuAnimations.animate}
                  exit={menuAnimations.initial}
                  onClick={() => setIsOpen(!collapseOnClick)}
                >
                  {children}
                </motion.nav>
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  )
}
