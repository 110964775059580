import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { useParams } from 'react-router-dom'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { ApplicationRouteParams } from '../../../../types/route'
import { ApplicationWrapper } from './application-wrapper'

export const LongTermMonitoringWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'longTermMonitoring', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not submitted</InlineNotification>
      </RecordContent>
    )
  }

  return <ApplicationWrapper />
}
