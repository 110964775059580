import { MapPageProps } from '@msaf/maps-react'
import { DEFAULT_FEATURE_ZOOM_LEVEL } from '../../../config/map'
import Logo from '../../../../assets/logo.svg'

export const MAP_CONFIG_BASE: Partial<MapPageProps> = {
  appLogo: {
    iconLogoImagePath: Logo,
    fullLogoImagePath: Logo,
  },
  mapOptions: {
    editable: true,
    zoom: DEFAULT_FEATURE_ZOOM_LEVEL,
    zoomControl: false,
  },
}

export const MENU_CONTEXT_WIDTH = 320
