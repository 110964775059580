import React from 'react'
import { useState } from 'react'
import { BoilerplateModalProps, Button, ButtonStyle, DeprecatedModalProps, Modal } from '../../common'
import { usePrompt } from './usePrompt'

export interface ConfirmTransitionProps
  extends Omit<BoilerplateModalProps | DeprecatedModalProps, 'isOpen' | 'onRequestClose' | 'body'> {
  when?: boolean
  confirmText?: string
  cancelText?: string
  modifiedFields?: string[]
  actionConfig: Array<{
    type: 'confirm' | 'cancel'
    label: string
    buttonStyle: ButtonStyle
    onClick?: () => Promise<void>
    key?: string
  }>
}

export function ConfirmTransition({
  when,
  contentLabel = 'Unsaved changes confirmation dialog',
  heading = 'Unsaved changes',
  bodyText,
  modifiedFields = [],
  confirmText = 'Discard changes',
  cancelText = 'Continue editing',
  actionConfig,
  ...props
}: ConfirmTransitionProps) {
  const [open, setOpen] = useState(false)
  const { onClick } = usePrompt(setOpen, when)

  const actions = actionConfig.map((config) => {
    const { type, onClick: callbackFn, ...buttonProps } = config
    return (
      <Button {...buttonProps} key={buttonProps.key ?? buttonProps.label} onClick={() => onClick(type, callbackFn)} />
    )
  })

  const closeModal = () => {
    setOpen(false)
  }

  // If there are no modified fields, we don't need to show the list of modified fields
  const newBodyText =
    modifiedFields.length === 0 ? (
      bodyText ?? 'This page has unsaved changes, are you sure you want to continue?'
    ) : (
      <>
        <p>{bodyText ?? 'This page has unsaved changes to the following fields. Are you sure you want to continue?'}</p>
        <ul>
          {modifiedFields.map((field) => (
            <li key={field}>{field}</li>
          ))}
        </ul>
      </>
    )

  return (
    <Modal
      {...props}
      isOpen={open}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      heading={heading}
      buttons={<>{actions}</>}
      body={newBodyText}
    />
  )
}
