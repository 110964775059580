import { InlineNotification } from '@msaf/core-react'
import { usePlanWarning } from '../../../hooks/use-plan-warnings'

export function PlanWarningsSection() {
  const { warnings } = usePlanWarning()

  if (!warnings) return null

  return (
    <>
      {warnings?.map((w) => (
        <InlineNotification
          key={w.code}
          messageType={w.level.toLowerCase() as 'warning' | 'error'}
          isDismissable={false}
        >
          {w.message}
        </InlineNotification>
      ))}
    </>
  )
}
