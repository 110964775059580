import classNames from 'classnames'
import React from 'react'

export interface LegendIndicatorProps {
  fillColor?: string
  borderColor?: string
}

export function LegendIndicator({ fillColor, borderColor }: LegendIndicatorProps) {
  const classes = classNames('c-legend-indicator', {
    'c-legend-indicator--has-border': !!borderColor,
  })

  return fillColor || borderColor ? (
    <div className={classes} style={{ borderColor: borderColor, backgroundColor: fillColor }} aria-hidden></div>
  ) : null
}
