import { BodyTextContainer, ContentContainer, Heading, Link } from '@msaf/core-react'
import { ReactNode } from 'react'
import { usePageHeading } from '../hooks/usePageHeading'

type WithSupportingText = {
  children?: undefined
  supportingText: string
}

type WithChildren = {
  children: ReactNode
  supportingText?: undefined
}

interface ErrorPageProps {
  headingText: string
  inPage?: boolean
}

export default function ErrorPage({
  inPage = false,
  headingText,
  children,
  supportingText,
}: ErrorPageProps & (WithChildren | WithSupportingText)) {
  if (inPage) {
    usePageHeading(headingText)
  }
  return (
    <ContentContainer>
      <BodyTextContainer>
        {!inPage && <Heading level={1}>{headingText}</Heading>}
        {supportingText && (
          <>
            <p>{supportingText}</p>
            <p>
              <Link path={'/'}>Return to the Dashboard.</Link>
            </p>
          </>
        )}
        {children && children}
      </BodyTextContainer>
    </ContentContainer>
  )
}
