import { FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { ComplexFieldConfig, FieldConfig } from '../../types'
import { BooleanField } from './boolean-field'
import { CheckableGroup } from './checkable-group'
import { LookupField } from './lookup-field'
import { NotesField } from './notes-field'
import { TextField } from './text-field'
import { DateField } from './date-field'
import { ReadOnlyField } from './read-only-field'
import { useMemo } from 'react'
import { useFieldWatch } from '../hooks/use-field-watch'
import { GenericRepeatingSection } from './repeating/section'
import { TextArea } from './text-area'
import { PersonCrudField } from '../../../components/lookups/person'
import { OrganisationCrudField } from '../../../components/lookups/organisation'
import { LookupAddressField } from './lookup-address'
import { FilesField } from './files-field'
import { SummaryTable } from '../../../components/summary-table'
import { InlineFeatureMapField } from './inline-feature-map-field'
import { StaticInlineFeatureMapField } from './static-inline-feature-map-field'

export interface GenericFieldProps<T extends FieldValues> {
  element: FieldConfig<T> | ComplexFieldConfig<T>
  mode: RouteMode
  isSkeleton?: boolean
  isMigrated?: boolean
}

export function GenericField<T extends FieldValues>({ element, mode, isSkeleton, isMigrated }: GenericFieldProps<T>) {
  const methods = useFormContext<T>()
  const initialConfig = useMemo(
    () => ({ mode, isSkeleton, validationDisabled: isMigrated, ...element }),
    [element, mode, isSkeleton, isMigrated],
  )
  const { validationDisabled, ...fieldConfig } = useFieldWatch<T>(methods, initialConfig, element.watch)

  return useMemo(() => {
    if (fieldConfig.isHidden) {
      return <></>
    }
    switch (fieldConfig.type) {
      case 'lookup':
        return <LookupField {...fieldConfig} />
      case 'checkable-group':
        return <CheckableGroup {...fieldConfig} />
      case 'date':
        return <DateField {...fieldConfig} />
      case 'read-only':
        return <ReadOnlyField {...fieldConfig} />
      case 'boolean':
        return <BooleanField {...fieldConfig} />
      case 'text':
      case 'number':
        return <TextField {...fieldConfig} />
      case 'text-area':
        return <TextArea {...fieldConfig} />
      case 'repeating-section':
        return <GenericRepeatingSection {...fieldConfig} />
      case 'notes':
        return <NotesField {...fieldConfig} />
      case 'address-lookup':
        return <LookupAddressField {...fieldConfig} />
      case 'person-crud':
        return <PersonCrudField {...fieldConfig} validationDisabled={validationDisabled} />
      case 'organisation-crud':
        return <OrganisationCrudField {...fieldConfig} validationDisabled={validationDisabled} />
      case 'table':
        return <SummaryTable {...fieldConfig} />
      case 'files':
        return <FilesField {...fieldConfig} />
      case 'map':
        return <InlineFeatureMapField {...fieldConfig} />
      case 'static-map':
        return <StaticInlineFeatureMapField {...fieldConfig} />
      default:
        throw new Error(`type: ${fieldConfig.type} is not able to be displayed using GenericField`)
    }
  }, [fieldConfig])
}
