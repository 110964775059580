import { useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { DisplayContext } from '../../../../components/gatekeeper'
import { ReviewWorkflow } from '../../../../components/workflow-containers/review'
import { ErrorCode } from '../../../../constants'
import { RouteParams } from '../../../../types/route'

export const PlanReview = () => {
  const { id } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  if (!id) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }
  return (
    <ReviewWorkflow recordId={id}>
      <Outlet />
    </ReviewWorkflow>
  )
}
