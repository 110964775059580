import React, { useState, useEffect } from 'react'
import { getSearchTemplateWithDisplayFilters } from '@msaf/generic-search-common'
import { AUTOCOMPLETE_MIN_CHARS, Typeahead, TypeaheadOption } from '@msaf/core-react'
import {
  SearchResultRow,
  createDefaultFilterSet,
  createFilterSet,
  FilterInstance,
  getSearchResult,
  SearchQuery,
  SearchTemplate,
  RequestOptions,
} from '@msaf/generic-search-common'
import { Action, OptionsType, OptionTypeBase } from 'react-select'
import debounce from 'lodash.debounce'

export interface QuickSearchProps {
  maxNumberOfResults?: number // Maximum number of results returned from quick search
  placeholderText?: string // Placeholder string inside search box to tell user what fields can be searched
  onRowClick: (id: string) => void // Redirects user to specific search item details
  getDisplayOptions: (suggestions: Array<SearchResultRow>) => TypeaheadOption[]
  searchTemplate: SearchTemplate | undefined
  requestOptions: RequestOptions
  debounceIntervalMs?: number
}

export function QuickSearch({
  maxNumberOfResults = 10,
  placeholderText = '',
  onRowClick,
  getDisplayOptions,
  searchTemplate,
  requestOptions,
  debounceIntervalMs = 300,
}: QuickSearchProps) {
  const [selectedOption, setSelectedOption] = useState<TypeaheadOption | null>(null)
  const [searchQuery, setSearchQuery] = useState<SearchQuery>()

  useEffect(() => {
    if (searchTemplate) {
      // Filter out the hidden filters so that the rest of the logic need not worry about it
      const template = getSearchTemplateWithDisplayFilters(searchTemplate)
      const currentViewKey = template.views[0].viewKey
      const defaultFilters = createDefaultFilterSet(template.filters)
      const defaultFilterSet = createFilterSet(defaultFilters, 'AND')
      const searchQuery: SearchQuery = {
        viewKey: currentViewKey,
        searchTypeKey: template.searchTypeKey,
        requestedPage: 1,
        requestedPageLength: maxNumberOfResults,
        filterSet: defaultFilterSet.filterSet,
      }
      setSearchQuery(searchQuery)
    }
  }, [searchTemplate, maxNumberOfResults])

  const getSearchFilters = (filterValue: string): Array<FilterInstance> => {
    return [
      {
        filterKey: 'quickSearch',
        filterValue: filterValue,
        filterOperator: '@@',
      },
    ]
  }

  const fetchSuggestions = async (inputValue: string, callback: (options: TypeaheadOption[]) => void) => {
    const filters = getSearchFilters(inputValue)
    const { filterSet } = createFilterSet([createFilterSet(filters, 'OR')], 'AND')
    const appliedSearchQuery: SearchQuery = {
      ...searchQuery,
      filterSet,
    }
    const response = await getSearchResult(appliedSearchQuery, {
      ...requestOptions,
      urlPrefix: `${requestOptions.urlPrefix}`,
    })
    const suggestions: Array<SearchResultRow> = response?.searchResult?.rows
    if (suggestions) {
      const displayOptions: TypeaheadOption[] = getDisplayOptions(suggestions)
      callback(displayOptions)
    }
  }

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < AUTOCOMPLETE_MIN_CHARS) return callback([])
    if (inputValue.length >= AUTOCOMPLETE_MIN_CHARS) {
      fetchSuggestions(inputValue, callback)
    }
  }

  const handleChange = (option: TypeaheadOption, _triggeredAction?: Action) => {
    setSelectedOption(option && option.value ? option : { label: '', value: '' })
    option.value && onRowClick(option.value)
  }

  return (
    <Typeahead
      selectType='async'
      labelledBy={'quickSearch'}
      options={[]}
      loadOptions={debounce(loadOptions, debounceIntervalMs)}
      handleChange={handleChange}
      selectedOption={selectedOption}
      placeholder={placeholderText}
      autoFocus
      blurInputOnSelect
      // Override `react-select` styling to match our design
      styles={{
        container: (styles: any) => ({
          ...styles,
          marginBottom: 'unset',
        }),
        clearIndicator: (styles: any) => ({
          ...styles,
          display: 'none',
        }),
        dropdownIndicator: (styles: any) => ({
          ...styles,
          display: 'none',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (styles: any) => ({
          ...styles,
          display: 'none',
        }),
      }}
    />
  )
}
