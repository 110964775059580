import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { PropsWithChildren, useMemo } from 'react'
import { useWorkflowState } from '../../validation/hooks'
import { buildSectionsForWorkflow } from '../../validation/utils'
import { RouteMode } from '../../types/route'
import { useParams } from 'react-router-dom'

interface WorkflowProps {
  recordId?: string
}

export const EoiWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  const { mode } = useParams<{ mode?: RouteMode }>()
  const stateQuery = useWorkflowState(recordId, 'expressionOfInterest')
  const sections = useMemo(() => {
    if (!recordId || stateQuery.isSuccess) {
      return buildSectionsForWorkflow({
        workflowId: 'expressionOfInterest',
        recordId,
        state: stateQuery.data,
        mode: mode ?? RouteMode.VIEW,
        multiRecord: false,
      })
    }
    return undefined
  }, [stateQuery.data, stateQuery.isSuccess, recordId])

  return <RecordContainer workflowNav={<WorkflowNav sections={sections ?? []} />}>{children}</RecordContainer>
}
