import { createFeatureLayer, getConfigForFeature, MapUIConfig, SupportedDrawnLayers } from '@msaf/maps-common'
import { FeatureCollection, Geometry } from '@turf/helpers'
import * as L from 'leaflet'
import { useCallback, useEffect, useReducer } from 'react'

import { SuperFeature } from '../types'
import { safeGetConfigForFeature } from '.'
import cloneDeep from 'lodash.clonedeep'

export type FeatureTypes = string[]

interface UseFeatureLayerProps {
  map?: L.Map
  features?: FeatureCollection<Geometry>
  config: MapUIConfig
}

type InitialisedFeatureLayer = {
  initialised: true
  layer: L.GeoJSON
  selectedFeature?: SuperFeature
  config: MapUIConfig
}

type UninitialisedFeatureLayer = {
  layer?: undefined
  initialised: false
  selectedFeature?: undefined
}
type FeatureLayerState = InitialisedFeatureLayer | UninitialisedFeatureLayer

type FeatureLayerAction =
  | {
      type: 'collection-update'
      collection: FeatureCollection<Geometry>
      config: MapUIConfig
    }
  | { type: 'edit'; layer: SupportedDrawnLayers }
  | { type: 'select'; layer: SupportedDrawnLayers }
  | { type: 'new'; layer: SupportedDrawnLayers }
  | { type: 'clear' }
  | { type: 'remove'; featureId: string | number }

export type UseFeatureLayerReturn = {
  state: FeatureLayerState
  createFeature: (layer: SupportedDrawnLayers) => void
  deleteFeature: (featureId: string | number) => void
  clearFeatureSelection: () => void
  editFeature: (layer: SupportedDrawnLayers) => void
  selectFeature: (layer: SupportedDrawnLayers) => void
}

const featureLayerReducer = (state: FeatureLayerState, action: FeatureLayerAction): FeatureLayerState => {
  switch (action.type) {
    case 'collection-update': {
      const featureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features: action.collection.features.sort((a, b) => {
          // Migrated records don't match feature configs so we need to exclude them here.
          const aConfig = safeGetConfigForFeature(action.config, a)
          const bConfig = safeGetConfigForFeature(action.config, b)

          if (aConfig?.zIndex == bConfig?.zIndex) {
            return (b.properties?.area ?? 0) - (a.properties?.area ?? 0)
          }
          return (aConfig?.zIndex ?? 0) - (bConfig?.zIndex ?? 0)
        }),
      }

      const layer = createFeatureLayer(action.config, featureCollection)
      // Ensure this layer is at the top.
      layer.bringToFront()

      const newState: FeatureLayerState = { ...state, initialised: true, layer, config: action.config }

      // Only do the selected feature handling if there is a selected feature.
      if (!state.selectedFeature) return newState

      /* If there is a selected feature we need to update it to contain the layer from the new
      feature layer. */
      const selectedFeatureId = state.selectedFeature.feature.id
      const feature = action.collection.features.find((f) => f.id === selectedFeatureId)
      const selectedLayer = layer
        .getLayers()
        .find((l) => (l as SupportedDrawnLayers).feature?.id === selectedFeatureId) as SupportedDrawnLayers

      // If there wasn't one to find or we failed to find it return and unselect the feature.
      if (!selectedFeatureId || !selectedLayer || !feature) {
        return { ...newState, selectedFeature: undefined }
      }

      // Deep clone everything except the leaflet layer to prevent mutations to original state
      const selectedFeature = {
        ...cloneDeep({
          config: getConfigForFeature(state.config, feature),
          feature,
        }),
        layer: selectedLayer,
      }
      return { ...newState, selectedFeature }
    }
    case 'select':
    case 'edit': {
      if (!state.initialised) throw new Error('Unable to edit uninitialised layer')
      const feature = action.layer.feature

      if (!feature) {
        throw new Error('Cannot select a layer with no feature data')
      }

      // "Select" the given feature
      const selectedFeature = {
        config: getConfigForFeature(state.config, action.layer.feature),
        feature,
        layer: action.layer,
      }

      return { ...state, selectedFeature }
    }
    case 'clear': {
      return { ...state, selectedFeature: undefined }
    }
    case 'remove': {
      if (!state.initialised) throw new Error('Unable to edit uninitialised layer')

      // Find layer
      const layer = state.layer
        .getLayers()
        .find((l) => (l as SupportedDrawnLayers).feature?.id === action.featureId) as SupportedDrawnLayers

      if (layer) {
        // Remove layer from feature layer
        // Only if layer is a child
        state.layer.removeLayer(layer)
      }

      // Ensure selectedFeature is cleared.
      return { ...state, selectedFeature: undefined }
    }
    case 'new':
      if (!state.initialised) throw new Error('Unable to edit uninitialised layer')

      state.layer.addLayer(action.layer)

      return { ...state }
    default:
      throw new Error('No default action for this reducer')
  }
}

export function useFeatureLayer(props: UseFeatureLayerProps): UseFeatureLayerReturn {
  const [state, dispatch] = useReducer(featureLayerReducer, {
    initialised: false,
    layer: undefined,
    selectedFeature: undefined,
  })

  useEffect(() => {
    if (props.features && props.config) {
      dispatch({
        type: 'collection-update',
        collection: props.features,
        config: props.config,
      })
    }
  }, [props.config, props.features])

  useEffect(() => {
    if (props.map && state.layer) {
      state.layer.addTo(props.map)

      return () => {
        state.layer.remove()
      }
    }
  }, [props.map, state.layer])

  const editFeature = useCallback((layer: SupportedDrawnLayers) => {
    dispatch({ type: 'edit', layer })
  }, [])

  const selectFeature = useCallback((layer: SupportedDrawnLayers) => {
    dispatch({ type: 'select', layer })
  }, [])

  const createFeature = useCallback((layer: SupportedDrawnLayers) => {
    dispatch({ type: 'new', layer })
    dispatch({ type: 'select', layer })
  }, [])

  const deleteFeature = useCallback((featureId: string | number) => {
    dispatch({ type: 'remove', featureId })
  }, [])

  const clearFeatureSelection = useCallback(() => {
    dispatch({ type: 'clear' })
  }, [])

  return {
    state,
    createFeature,
    deleteFeature,
    clearFeatureSelection,
    editFeature,
    selectFeature,
  }
}
