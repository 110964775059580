export const formatCurrency = (
  value: string | number | undefined | null,
  options?: Omit<Intl.NumberFormatOptions, 'style'> & { locale?: string },
) => {
  const valueAsNumber = typeof value === 'string' ? parseFloat(value) : value ?? 0
  if (isNaN(valueAsNumber)) {
    return value as string
  }
  const formatter = new Intl.NumberFormat(options?.locale ?? 'en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 0,
    ...options,
  })
  return formatter.format(valueAsNumber)
}
