export enum RouteMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export type RouteParams = {
  id: string
  mode: RouteMode.EDIT | RouteMode.VIEW
}

export type ApplicationRouteParams = RouteParams & {
  applicationId: string
}

export type ActionRouteParams = ApplicationRouteParams & {
  actionId: string
}

export type ApplicationFeedbackRouteParams = ApplicationRouteParams & {
  reviewId: string
}

export type RequestForPaymentRouteParams = ApplicationRouteParams & {
  paymentId: string
}
