import React, { useCallback, useEffect, useState } from 'react'
import { Typeahead, TypeaheadOption } from '@msaf/core-react'
import { AuthStrategy, LookupDisplayValue, LookupResult, getLookupResult } from '@msaf/generic-search-common'
import { FilterProps } from '../../../utils'
import { useLookupResult, useLookupDisplayValue } from '../../../../../../../hooks/use-api'
import debounce from 'lodash.debounce'

export function FilterTypeahead<T extends AuthStrategy = 'token'>({
  filterKey,
  value,
  setValue,
  template,
  searchTypeKey,
  requestOptions,
}: FilterProps<T>) {
  if (typeof value !== 'string') throw new Error('Invalid value type for `typeahead`')
  const [selectedOption, setSelectedOption] = useState<TypeaheadOption>({ label: '', value: '' })
  const [options, setOptions] = useState<Array<TypeaheadOption>>([])

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: TypeaheadOption[]) => void) => {
      getLookupResult({ searchTypeKey, optionsKey: template.optionsKey, filter: inputValue }, requestOptions).then(
        (res) => {
          if (res.lookupResult?.rows) {
            const displayOptions: TypeaheadOption[] = res.lookupResult.rows.map((r) => ({
              label: r.display_value,
              value: r.id,
            }))
            callback(displayOptions)
          }
          callback([])
        },
      )
    }, 300),
    [template.optionsKey, searchTypeKey, requestOptions],
  )

  // For an async select the 'asyncLookup' flag will have been included in
  // the filter template
  const { isLoading, isSuccess, data } = useLookupResult<T>(
    {
      searchTypeKey,
      optionsKey: template.optionsKey,
      filter: '', // Fetch all options
    },
    requestOptions,
  )

  const {
    isLoading: isLoadingDisplayValue,
    isSuccess: gotDisplayedValue,
    data: lookupDisplayValueData,
  } = useLookupDisplayValue<T>(
    {
      searchTypeKey,
      optionsKey: template.optionsKey,
      internalValue: value,
    },
    requestOptions,
    !!value && typeof value === 'string',
  )

  useEffect(() => {
    if (gotDisplayedValue && lookupDisplayValueData?.lookupDisplayValueResult?.displayValue) {
      const result = lookupDisplayValueData as LookupDisplayValue
      setSelectedOption({
        label: result.lookupDisplayValueResult.displayValue,
        value,
      })
    }
  }, [gotDisplayedValue, lookupDisplayValueData, value])

  useEffect(() => {
    if (isSuccess && data?.lookupResult.rows) {
      const result = data as LookupResult
      const lookupOptions: Array<TypeaheadOption> = result.lookupResult.rows.map(
        ({ id: value, display_value: label }) => ({ label, value }),
      )
      setOptions(lookupOptions)
    }
  }, [isSuccess, data])

  const handleChange = (option: TypeaheadOption | null) => {
    setSelectedOption(option && Object.keys(option).length ? option : { label: '', value: '' })
    setValue(option?.value != null ? String(option?.value) : '')
  }

  return (
    <div data-test-filter-typeahead-selector>
      <Typeahead
        selectType={template.asyncLookup ? 'async' : 'default'}
        loadOptions={template.asyncLookup ? loadOptions : undefined}
        labelledBy={`search-filter-input-label-${filterKey}`}
        options={options}
        handleChange={handleChange}
        selectedOption={selectedOption}
        isSkeleton={isLoading || isLoadingDisplayValue}
      />
    </div>
  )
}
