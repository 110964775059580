import logo from '../../../../../assets/logo.png'
import { PdfDocument } from '../../../../components/pdf/pdf-document'

export function PdfNotFound() {
  return (
    <PdfDocument>
      <section className='l-pdf-container--page-break-after-always'>
        <div className='c-pdf-page__banner--flex-end'>
          <img src={logo} />
        </div>
        <p>Unable to create a PDF at this time. Please contact your application support team.</p>
      </section>
    </PdfDocument>
  )
}
