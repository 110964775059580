import { usePermission } from '../../../../../../../../services/permissions'
import { LookupItem } from '../../../../../../../../types'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { RouteMode } from '../../../../../../../../types/route'
import { UploadedFile } from '../../../../../../../../forms/components/file-controller'
import { FormElementConfig, ProposedTotals } from '../../../../../../../../forms/types'
import { toDollarCurrencyFormat, toDollarCurrencyFormatter } from '../../../../../../../../forms/utils'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'

export interface ApplicationApprovalFields {
  recommendationFromKmr: string | null
  proposedTotals: ProposedTotals
  coFunderTotal: number | null
  finalDecision: LookupItem
  dateCommitted: string | null
  delegatedAuthority: LookupItem
  files?: UploadedFile[]
  notes: AppNoteType[]
  readyForProcess: boolean
}

export const useApplicationApprovalConfig = (
  mode: RouteMode | undefined,
): FormElementConfig<ApplicationApprovalFields>[] => {
  const hasEditRecommendationPermission = usePermission([UserPermissions.EDIT_APPROVAL_RECOMMENDATION])
  const hasEditDelegatedAuthorityPermission = usePermission([UserPermissions.EDIT_APPROVAL_DELEGATED_AUTHORITY])
  const hasEditPermission = usePermission([UserPermissions.PROCESS_REVIEW])
  const disableField = mode === RouteMode.EDIT && !hasEditPermission

  return [
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Approval' },
    },
    {
      type: 'atom',
      element: {
        type: 'plan-warnings',
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'recommendationFromKmr',
        type: 'text-area',
        helpText: (
          <>
            This should include:
            <ul>
              <li>Justification for any deviations from standard KMR criteria</li>
              <li>Assurance that panel feedback has been satisfactorily addressed (if relevant)</li>
              <li>Any other information relevant to the approver</li>
            </ul>
          </>
        ),
        isRequired: true,
        isDisabled: disableField && !hasEditRecommendationPermission,
      },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'delegatedAuthority',
        isRequired: true,
        lookupId: 'delegated_authorities',
        isDisabled: disableField && !hasEditDelegatedAuthorityPermission,
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'proposedTotals.totalProposedCost',
        isDisabled: disableField,
        conversion: toDollarCurrencyFormatter(2),
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'proposedTotals.landOwnerContribution',
        isDisabled: disableField,
        conversion: toDollarCurrencyFormatter(2),
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'proposedTotals.kmrContribution',
        isDisabled: disableField,
        conversion: toDollarCurrencyFormatter(2),
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'coFunderTotal',
        isDisabled: disableField,
        conversion: toDollarCurrencyFormat,
      },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'finalDecision',
        isRequired: true,
        lookupId: 'application_decision',
        isDisabled: disableField,
      },
    },
    {
      type: 'field',
      element: {
        type: 'date',
        fieldId: 'dateCommitted',
        isRequired: true,
        isDisabled: disableField,
      },
    },
    { type: 'field', element: { type: 'files', fieldId: 'files', isDisabled: disableField } },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.APPROVAL,
      },
    },
  ]
}
