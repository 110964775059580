import { AxiosError } from 'axios'
import { useContext, useEffect } from 'react'
import { DisplayContext } from '../components/gatekeeper'
import { ErrorCode } from '../constants'

export function useErrorHandler<T>(isError: boolean, error: AxiosError<T> | null) {
  const { setError } = useContext(DisplayContext)
  useEffect(() => {
    if (isError) {
      if ([403, 404].includes(error?.response?.status ?? 500)) {
        setError(ErrorCode.NOT_FOUND_404)
      } else {
        setError(ErrorCode.SERVER_ERROR_500)
      }
    }
  }, [isError, error])
}
