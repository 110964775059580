import { Button } from '@msaf/core-react'
import { ReactNode } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import RaygunClient, { ErrorType } from '../logging/raygun'
import { ErrorPage } from './error-full-page'

const logError = (error: Error, { componentStack }: { componentStack: string }) =>
  RaygunClient.getInstance().trackError(ErrorType.RENDER_ERROR, { error, componentStack })

const ErrorBlock = () => {
  return (
    <ErrorPage header='Something went wrong'>
      <p>If the problem persists, please contact your administrator.</p>
      <Button label='Go home' path='/' />
    </ErrorPage>
  )
}

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <ReactErrorBoundary fallbackRender={ErrorBlock} onError={logError}>
      {children}
    </ReactErrorBoundary>
  )
}
