import { ContentContainer, Heading } from '@msaf/core-react'
import { AdvancedSearch } from '@msaf/generic-search-react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../auth'
import { useGatekeeper } from '../../../../components/gatekeeper'
import { DISPLAY_DATE_FORMAT } from '../../../../constants'
import { SEARCH_API_URL } from '../../../../environment'
import { UserPermissions } from '../../../../types/permissions'
import { RouteParams } from '../../../../types/route'

export const PlanHistory = () => {
  useGatekeeper([UserPermissions.VIEW_PLANS])
  const { authStrategy, user } = useAuth()
  const { id } = useParams<RouteParams>()

  if (!authStrategy || !user) {
    throw Error('403 - This page is only able to be reached with authenticated access')
  }

  return (
    <ContentContainer>
      <Heading level={2}>History</Heading>
      <AdvancedSearch<'token'>
        searchTypes={[
          {
            key: 'history',
            label: 'History',
          },
        ]}
        viewKey='auditSearch'
        requestOptions={{
          urlPrefix: SEARCH_API_URL,
          authStrategy: 'token',
          authToken: authStrategy.token,
          authTokenScheme: authStrategy.tokenIdentifier,
        }}
        filterBy={{
          booleanOperator: 'AND',
          filters: [
            {
              filterKey: 'planId',
              filterOperator: '=',
              filterValue: id,
            },
          ],
        }}
        defaultSortBy={{
          orderColumn: 'date',
          orderDirection: 'desc',
        }}
        showResultsInCard
        showResultsCount={false}
        dateFormat={DISPLAY_DATE_FORMAT}
        currentUserPermissions={user.permissions}
      />
    </ContentContainer>
  )
}
