import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import { Organisation } from '../../../../../../../../components/lookups/organisation/type'
import { Person } from '../../../../../../../../components/lookups/person/type'
import { UploadedFile } from '../../../../../../../../forms/components/file-controller'
import { FormConfig } from '../../../../../../../../forms/types'
import { LookupItem, RepeatingLookupItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { RequestForPaymentRouteParams } from '../../../../../../../../types/route'

export interface RequestForPaymentFields {
  applicant: Person
  applicantOrganisation: Organisation
  kmrPaymentIdentifier: string
  paymentReason: RepeatingLookupItem
  paymentComments: string
  invoiceNumber: string
  paymentRegistered: string
  kmrPaymentAmount: number
  authorisedBy: LookupItem
  files?: UploadedFile[]
  notes: Array<AppNoteType>
}

export const useFormConfig = (): FormConfig<RequestForPaymentFields> => {
  const { id: planId, applicationId, mode } = useParams<RequestForPaymentRouteParams>()
  const { user } = useAuth()

  return useMemo(
    () => [
      {
        type: 'action',
        element: {
          type: 'button',
          path: `/plans/${mode}/${planId}/payments/project/${applicationId}/payments`,
          label: 'Back',
          iconAlignment: 'left',
          icon: 'arrow-left',
          buttonStyle: 'text-action',
          enabledInViewMode: true,
        },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Applicant' },
      },
      {
        type: 'field',
        element: {
          type: 'person-crud',
          fieldId: 'applicant',
          labels: { singular: 'person', heading: 'Applicant details', modal: 'Edit applicant details' },
          isDisabled: true,
        },
      },
      {
        type: 'field',
        element: {
          type: 'organisation-crud',
          fieldId: 'applicantOrganisation',
          labels: {
            singular: 'organisation',
            heading: 'Applicant organisation',
            modal: 'Edit applicant organisation',
          },
          isDisabled: true,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Request for payment details' },
      },
      {
        type: 'field',
        element: {
          fieldId: 'kmrPaymentIdentifier',
          type: 'read-only',
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'paymentReason',
          repeating: true,
          lookupId: 'paymentReason',
          defaultValue: { label: '' },
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'paymentComments',
          type: 'text-area',
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'invoiceNumber',
          type: 'text',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'paymentRegistered',
          type: 'date',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'kmrPaymentAmount',
          type: 'text',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'authorisedBy',
          lookupId: 'delegated_authorities',
          isRequired: true,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Invoices',
        },
      },
      {
        type: 'field',
        element: {
          type: 'files',
          fieldId: 'files',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.REQUEST_FOR_PAYMENT,
        },
      },
    ],
    [user, planId, applicationId],
  )
}
