import React, { useEffect, useState } from 'react'
import { Button, Pill, Label, GroupContainer } from '@msaf/core-react'
import {
  FilterStringComponent,
  flattenFiltersToSelected,
  SearchQueryByFilter,
  SearchTemplate,
} from '@msaf/generic-search-common'
import { LabelledAppliedFilters } from './labelled-applied-filters'

export interface AppliedFiltersProps {
  modifyValues: () => void
  query: SearchQueryByFilter
  searchTemplate: SearchTemplate
  lookupResolver?: (value: string, optionsKey?: string, searchTypeKey?: string) => Promise<string>
  dateResolver?: (value: string) => Promise<string>
  saveEnabled?: boolean
  filtersLabel?: string
  showLabelsSeparately?: boolean
  isSkeleton?: boolean
  saveAction?: () => void
}

export function AppliedFilters({
  modifyValues,
  query,
  searchTemplate,
  lookupResolver,
  dateResolver,
  saveEnabled,
  filtersLabel = 'Search criteria',
  showLabelsSeparately,
  isSkeleton,
  saveAction,
}: AppliedFiltersProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [displaySegments, setDisplaySegments] = useState<{
    [filter: string]: FilterStringComponent[][]
  }>({})

  useEffect(() => {
    async function getDisplaySegments() {
      setIsLoading(true)
      try {
        const segments = await flattenFiltersToSelected(query, searchTemplate, { lookupResolver, dateResolver })
        setDisplaySegments(segments)
      } catch {
        // TODO: Error handling
        setDisplaySegments({})
      }
      setIsLoading(false)
    }
    getDisplaySegments()
  }, [query, lookupResolver, searchTemplate])

  if (showLabelsSeparately) {
    return (
      <LabelledAppliedFilters
        modifyValues={modifyValues}
        query={query}
        searchTemplate={searchTemplate}
        lookupResolver={lookupResolver}
        saveEnabled={saveEnabled}
        isSkeleton={isSkeleton}
        saveAction={saveAction}
      />
    )
  }

  const pillValues = searchTemplate.filters.flatMap((f) => {
    if (!(f.filterKey in displaySegments)) {
      return []
    }

    return displaySegments[f.filterKey].map((segments) => {
      const pillContent = segments.map((segment) => Object.values(segment).join(' ')).join(' ')
      return {
        label: f.label,
        value: pillContent,
      }
    })
  })

  return (
    <fieldset>
      <Label
        labelText={filtersLabel}
        labelId={`${filtersLabel.replace(' ', '')}-label`}
        labelTag='legend'
        isSkeleton={isSkeleton || isLoading}
      />
      <GroupContainer verticalAlignment='center'>
        {pillValues.map(({ label, value }) => (
          <Pill
            key={`pill-${label}-${value}`}
            label={label}
            value={value}
            isSelected={true}
            isSelectable={false}
            isSkeleton={isSkeleton || isLoading}
          />
        ))}
        <Button
          label='Modify search'
          data-selenium-search-modify
          onClick={() => modifyValues()}
          buttonStyle='text-link'
          isSkeleton={isSkeleton || isLoading}
        />
        {saveAction && saveEnabled && (
          <Button
            label='Save search'
            onClick={() => saveAction()}
            buttonStyle='text-link'
            isSkeleton={isSkeleton || isLoading}
          />
        )}
      </GroupContainer>
    </fieldset>
  )
}
