import { LookupItem } from '../../../types'
import { AppNoteType, NoteForm } from '../../../types/notes'
import { RouteMode } from '../../../types/route'
import { FieldWatchResultProps } from '../../components/hooks/use-field-watch'
import { ComplexFieldConfig, FieldConfig, FormElementConfig, LabelledFieldConfig } from '../../types'

type ReviewFeatureCategory = 'gate_trough' | 'planting' | 'fencing'

export interface ApplicationPanelistReviewFields {
  dateReviewed: string
  panelist: LookupItem
  generalNotes: string
  areActionsEffective: boolean
  planEffectivenessNotes: string
  hasConcernsWithWetlandProtection: boolean
  wetlandProtectionChanges: string
  arePlantingChangesRecommended: boolean
  areFencingChangesRecommended: boolean
  areGatesTroughsChangesRecommended: boolean
  recommendedVariations: Array<{
    featureCategory: ReviewFeatureCategory
    feature: Partial<LookupItem> | null
    recommendedChange: string
  }>
  plantingRecommendedVariations: Array<{
    feature: Partial<LookupItem> | null
    recommendedChange: string
  }>
  fencingRecommendedVariations: Array<{
    feature: Partial<LookupItem> | null
    recommendedChange: string
  }>
  gateTroughRecommendedVariations: Array<{
    feature: Partial<LookupItem> | null
    recommendedChange: string
  }>
  meetsPanelReviewThreshold: boolean
  notes: Array<AppNoteType>
}

export const useApplicationPanelistReviewConfig = (
  data?: ApplicationPanelistReviewFields,
  mode?: RouteMode,
  planId?: string,
  applicationId = '',
): FormElementConfig<ApplicationPanelistReviewFields>[] => {
  return [
    {
      type: 'action',
      element: {
        type: 'button',
        path: `/plans/${mode}/${planId}/review/project/${applicationId}/review`,
        label: 'Back',
        buttonStyle: 'text-action',
        icon: 'arrow-left',
        iconAlignment: 'left',
        enabledInViewMode: true,
      },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Panelist details' },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'panelist',
        label: 'Panelist name',
        isRequired: true,
        lookupId: 'review_panelist',
      },
    },
    {
      type: 'field',
      element: {
        type: 'date',
        fieldId: 'dateReviewed',
        isRequired: true,
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'General plan feedback' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'generalNotes',
        type: 'text-area',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'areActionsEffective',
        type: 'boolean',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'planEffectivenessNotes',
        type: 'text-area',
        isRequired: data?.areActionsEffective == undefined ? false : !data?.areActionsEffective,
        isDisabled: data?.areActionsEffective == undefined ? true : data?.areActionsEffective,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationPanelistReviewFields>) => {
          if (info.type === 'change' && info.name === 'areActionsEffective') {
            if (data.areActionsEffective) {
              methods.setValue('planEffectivenessNotes', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationPanelistReviewFields>),
              type: 'text-area',
              isRequired: !data?.areActionsEffective,
              isDisabled: !!data?.areActionsEffective,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'hasConcernsWithWetlandProtection',
        type: 'boolean',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'wetlandProtectionChanges',
        type: 'text-area',
        isRequired: !!data?.hasConcernsWithWetlandProtection,
        isDisabled: !data?.hasConcernsWithWetlandProtection,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationPanelistReviewFields>) => {
          if (info.type === 'change' && info.name === 'hasConcernsWithWetlandProtection') {
            if (!data.hasConcernsWithWetlandProtection) {
              methods.setValue('wetlandProtectionChanges', '')
            }
            return {
              ...(initialConfig as LabelledFieldConfig<ApplicationPanelistReviewFields>),
              type: 'text-area',
              isRequired: !!data?.hasConcernsWithWetlandProtection,
              isDisabled: !data?.hasConcernsWithWetlandProtection,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Planting' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'arePlantingChangesRecommended',
        type: 'boolean',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        type: 'repeating-section',
        heading: 'Recommended action changes',
        sectionName: 'Change',
        repeating: true,
        fieldId: 'plantingRecommendedVariations',
        allowEmpty: true,
        isHidden: !data?.arePlantingChangesRecommended,
        fields: [
          {
            type: 'lookup',
            fieldId: 'feature',
            lookupId: 'application_action',
            fieldFilters: { applicationId, featureCategory: 'planting' },
            isRequired: true,
          },
          {
            type: 'text',
            fieldId: 'recommendedChange',
            isRequired: true,
          },
        ],
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationPanelistReviewFields>) => {
          if (info.type === 'change' && info.name === 'arePlantingChangesRecommended') {
            if (!data.arePlantingChangesRecommended) {
              methods.setValue('plantingRecommendedVariations', [])
            } else {
              methods.setValue('plantingRecommendedVariations', [{ feature: null, recommendedChange: '' }])
            }
            return {
              ...(initialConfig as
                | FieldConfig<ApplicationPanelistReviewFields>
                | ComplexFieldConfig<ApplicationPanelistReviewFields>),
              isHidden: !data?.arePlantingChangesRecommended,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Fencing' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'areFencingChangesRecommended',
        type: 'boolean',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        type: 'repeating-section',
        heading: 'Recommended action changes',
        sectionName: 'Change',
        repeating: true,
        fieldId: 'fencingRecommendedVariations',
        isHidden: !data?.areFencingChangesRecommended,
        allowEmpty: true,
        fields: [
          {
            type: 'lookup',
            fieldId: 'feature',
            lookupId: 'application_action',
            fieldFilters: { applicationId, featureCategory: 'fencing' },
            isRequired: true,
          },
          {
            type: 'text',
            fieldId: 'recommendedChange',
            isRequired: true,
          },
        ],
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationPanelistReviewFields>) => {
          if (info.type === 'change' && info.name === 'areFencingChangesRecommended') {
            if (!data.areFencingChangesRecommended) {
              methods.setValue('fencingRecommendedVariations', [])
            } else {
              methods.setValue('fencingRecommendedVariations', [{ feature: null, recommendedChange: '' }])
            }
            return {
              ...(initialConfig as
                | FieldConfig<ApplicationPanelistReviewFields>
                | ComplexFieldConfig<ApplicationPanelistReviewFields>),
              isHidden: !data?.areFencingChangesRecommended,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Gates & troughs' },
    },
    {
      type: 'field',
      element: {
        fieldId: 'areGatesTroughsChangesRecommended',
        type: 'boolean',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        type: 'repeating-section',
        heading: 'Recommended action changes',
        sectionName: 'Change',
        repeating: true,
        fieldId: 'gateTroughRecommendedVariations',
        isHidden: !data?.areGatesTroughsChangesRecommended,
        allowEmpty: true,
        fields: [
          {
            type: 'lookup',
            fieldId: 'feature',
            lookupId: 'application_action',
            fieldFilters: { applicationId, featureCategory: 'gate_trough' },
            isRequired: true,
          },
          {
            type: 'text',
            fieldId: 'recommendedChange',
            isRequired: true,
          },
        ],
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicationPanelistReviewFields>) => {
          if (info.type === 'change' && info.name === 'areGatesTroughsChangesRecommended') {
            if (!data.areGatesTroughsChangesRecommended) {
              methods.setValue('gateTroughRecommendedVariations', [])
            } else {
              methods.setValue('gateTroughRecommendedVariations', [{ feature: null, recommendedChange: '' }])
            }
            return {
              ...(initialConfig as
                | FieldConfig<ApplicationPanelistReviewFields>
                | ComplexFieldConfig<ApplicationPanelistReviewFields>),
              isHidden: !data?.areGatesTroughsChangesRecommended,
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.PANELIST_REVIEW,
      },
    },
  ]
}
