import { useMemo } from 'react'
import { useAuth } from '../../../../../../../auth'
import { TableState } from '../../../../../../../hooks/use-table-state'
import { UserPermissions } from '../../../../../../../types/permissions'
import { RouteMode } from '../../../../../../../types/route'
import { FormElementConfig } from '../../../../../../../forms/types'
import { toDollarCurrencyFormat } from '../../../../../../../forms/utils'
import { ApplicationDetailsFields } from './config'
import { ColumnHeader } from '@msaf/core-react'
import { sqmToHa } from '../../../../../../../utils/units'
import { SITE_PREPARATION_TABLE_COLUMNS } from '../../../../../../../forms/constants'

interface SitePreparationConfigProps extends Pick<TableState, 'refreshTable' | 'deleteAction'> {
  mode?: RouteMode
  isMigrated?: boolean
  applicationId?: string
  planId?: string
}

export const useSitePreparationConfig = ({
  mode,
  refreshTable,
  isMigrated,
  planId,
  applicationId,
  deleteAction,
}: SitePreparationConfigProps): FormElementConfig<ApplicationDetailsFields>[] => {
  const { user } = useAuth()
  return useMemo(() => {
    if (isMigrated)
      return [
        {
          type: 'field',
          element: {
            type: 'repeating-section',
            heading: 'Site preparation',
            sectionName: 'Site preparation action',
            repeating: true,
            fieldId: 'sitePreparationActions',
            fields: [
              {
                type: 'lookup',
                fieldId: 'type',
                lookupId: 'action_subtype',
                fieldFilters: { feature_category: 'site_preparation' },
              },
              {
                type: 'number',
                fieldId: 'area',
              },
              {
                type: 'number',
                fieldId: 'proposedCost',
              },
            ],
          },
        },
      ]

    return [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Site preparation' },
      },
      {
        type: 'field',
        element: {
          type: 'table',
          currentUserPermissions: user?.permissions,
          columnHeaders: [
            ...SITE_PREPARATION_TABLE_COLUMNS,
            {
              elementKey: 'actions',
              columnHeading: '',
              viewColumn: 'actions',
              type: 'actions-no-data',
              actions:
                mode == RouteMode.EDIT
                  ? [
                      {
                        label: 'Delete',
                        type: 'deleteAction',
                        args: [
                          {
                            elementKey: 'id',
                          },
                        ],
                        actionPermissions: [UserPermissions.EDIT_PLANS],
                      },
                    ]
                  : [],
            },
          ] as ColumnHeader[],
          refreshTable: refreshTable,
          tableActionHandlers: {
            deleteAction,
          },
          defaultSortBy: {
            orderColumn: 'locationName',
            orderDirection: 'desc',
          },
          requestUrl: `/api/plan/${planId}/project/${applicationId}/feature/site-preparation`,
          rowClickAction: {
            label: 'View',
            type: 'transitionAction',
            args: [{ constant: `plans/${mode}/${planId}/plan/project/${applicationId}/action` }, { elementKey: 'id' }],
          },
          noResultsMessage: 'No site preparation actions added',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'proposedTotals.sitePreparationArea',
          conversion: sqmToHa,
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'proposedTotals.sitePreparationCost',
          conversion: toDollarCurrencyFormat,
        },
      },
    ]
  }, [mode, refreshTable, isMigrated, planId, applicationId, deleteAction])
}
