import { useParams } from 'react-router-dom'
import { Form } from '../../../../../../../../forms/components/form'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { LongTermMonitoringFields, useFormConfig } from './long-term-monitoring.form'
import { usePlanPermissions } from '../../../../../../../../hooks/use-plan-permissions'

export const LongTermMonitoring = () => {
  const { applicationId, mode } = useParams<ApplicationRouteParams>()
  const { hasEditPermission } = usePlanPermissions()
  const canEdit =
    usePermission([
      UserPermissions.EDIT_LONG_TERM_MONITORING,
      UserPermissions.EDIT_LONG_TERM_MONITORING_ASSIGNED,
      UserPermissions.EDIT_FONTERRA_LONG_TERM_MONITORING_ASSIGNED,
    ]) && hasEditPermission

  const { data, isLoading, errors, onSubmit } = useStateManagement<LongTermMonitoringFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/long-term-monitoring`,
    {
      workflowId: 'longTermMonitoring',
      sectionId: 'longTermMonitoring',
      formId: 'longTermMonitoring',
      instanceId: `${applicationId}`,
    },
    {
      success: 'Long term monitoring saved successfully',
      error: 'Unable to save long term monitoring',
    },
  )

  const config = useFormConfig()

  const initialState = {
    ...data,
    longTermMonitoringSiteVisitDates: data?.longTermMonitoringSiteVisitDates?.length
      ? data?.longTermMonitoringSiteVisitDates
      : [{ value: '' }],
  }

  return (
    <Form<LongTermMonitoringFields>
      key={Date.now()}
      isSkeleton={isLoading}
      initialState={initialState}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={canEdit}
      submitAction={(data) => onSubmit(data)}
      config={config}
    />
  )
}
