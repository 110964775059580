import { ContentContainer } from '@msaf/core-react'
import { AdvancedSearch } from '@msaf/generic-search-react'
import { useAuth } from '../../auth'
import { SEARCH_API_URL } from '../../environment'
import { usePageHeading } from '../../hooks/usePageHeading'

export const Plans = () => {
  usePageHeading('Sediment reduction plans')
  const { authStrategy } = useAuth()

  if (!authStrategy) {
    throw Error('403 - This page is only able to be reached with authenticated access')
  }

  return (
    <ContentContainer>
      <AdvancedSearch
        searchTypes={[
          {
            key: 'plan-search',
            label: 'SRPs',
          },
          {
            key: 'project-search',
            label: 'Projects',
          },
        ]}
        requestOptions={{
          urlPrefix: `${SEARCH_API_URL}`,
          authStrategy: 'token',
          authToken: authStrategy.token,
          authTokenScheme: authStrategy.tokenIdentifier,
        }}
        defaultSortBy={{
          orderColumn: 'planNo',
          orderDirection: 'desc',
        }}
        exportConfig={[
          {
            id: 'projectExport',
            url: `${SEARCH_API_URL}/export-search-results`,
            label: 'Export SRP project',
            searchTypeKey: 'project-search',
            viewKey: 'projectExport',
            exportFileName: `SRP-projects-${new Date().toLocaleDateString('en-NZ')}`,
          },
        ]}
        resultNameSingular='record'
        resultNamePlural='records'
        noResultsMessage='No records available.'
      />
    </ContentContainer>
  )
}
