import {
  AuthStrategy,
  FilterSetBase,
  RequestOptions,
  SearchTemplate,
  getSearchTemplateWithDisplayFilters,
  SearchQuery,
  SearchKeyProps,
  SearchResult,
  SearchView,
} from '@msaf/generic-search-common'
import { useState, useEffect, useMemo } from 'react'
import { useSearch } from './use-search'
import { useAdvancedSearch } from './use-advanced-search'
import { TableSortByProps, usePassedState } from '@msaf/core-react'
import { To, useNavigate } from '@msaf/router-react'
import { ClientCache } from '@msaf/core-common'

const ACTIVE_SEARCH_TAB_INDEX_CACHE_KEY = 'active-search-tab-index'

export interface UseSearchUIProps<T extends AuthStrategy = 'token'> {
  pageSize?: number
  filterBy?: FilterSetBase
  viewKey?: string
  requestOptions?: RequestOptions<T>
  defaultSortBy?: TableSortByProps
  searchTypes: SearchKeyProps[]
  isMapView?: boolean
}

export interface SearchUIResponse {
  searchQuery?: SearchQuery
  searchResults?: SearchResult
  currentView?: SearchView
  searchTemplate?: SearchTemplate
  isLoading: boolean
  showFilters: boolean
  activeTab: number
  activeSearchType: SearchKeyProps
  updateQuery: (newQuery: SearchQuery, resetToFirstPage?: boolean | undefined) => void
  setCurrentPage: (page: number) => void
  setSortColumn?: (orderColumn: string, orderDirection: 'asc' | 'desc') => void
  switchToMapView: (q: SearchQuery) => void
  onTabChange: (index: number) => void
}

const getSearchTypeKeyFromUrl = (location: Location) => {
  const urlSearchQueryParam = JSON.parse(new URLSearchParams(location.search).get('queryParamSearchFilters') ?? '{}')
  return urlSearchQueryParam?.searchTypeKey
}

export function useSearchUI<T extends AuthStrategy = 'token'>({
  pageSize,
  filterBy,
  defaultSortBy,
  viewKey,
  requestOptions,
  searchTypes,
  isMapView,
}: UseSearchUIProps<T>): SearchUIResponse {
  const navigate = useNavigate()
  const urlSearchTypeKey = getSearchTypeKeyFromUrl(window.location)
  const urlSearchTypeKeyIndex = searchTypes.findIndex(
    (searchType: SearchKeyProps) => searchType.key === urlSearchTypeKey,
  )
  const defaultActiveTab = urlSearchTypeKeyIndex !== -1 ? urlSearchTypeKeyIndex : 0
  const getInitialActiveTab = () =>
    ClientCache.getInstance().fetch<number>(ACTIVE_SEARCH_TAB_INDEX_CACHE_KEY) ?? defaultActiveTab
  const [activeTab, setActiveTab] = useState(getInitialActiveTab)
  const [activeSearchTemplate, setActiveSearchTemplate] = useState<SearchTemplate | undefined>()
  const { state: activeViewKey, setState: setActiveViewKey } = usePassedState(viewKey)

  const activeSearchType = useMemo(
    () =>
      // Default to 0 if `activeTab` index is not available in `searchTypes`
      searchTypes[activeTab] ?? searchTypes[0],
    [activeTab, searchTypes],
  )

  const {
    cacheSearchQuery,
    searchTemplate,
    cachedSearchQuery,
    isLoading: isLoadingTemplate,
  } = useAdvancedSearch<T>({
    searchTypeKey: activeSearchType.key,
    requestOptions,
  })

  useEffect(() => {
    if (searchTemplate) {
      // Filter out the hidden filters so that the rest of the logic need not worry about it
      const template = getSearchTemplateWithDisplayFilters(searchTemplate)
      setActiveSearchTemplate(template)
      setActiveViewKey(
        isMapView ? searchTemplate.views.find((view) => view.isMapView)?.viewKey : searchTemplate.views[0].viewKey,
      )
    }
  }, [searchTemplate])

  const {
    searchQuery,
    searchResults,
    currentView,
    isSearching,
    updateQuery,
    setCurrentPage,
    setSortColumn,
    updateFilterQueryParams,
  } = useSearch<T>({
    searchTemplate: activeSearchTemplate,
    pageSize: pageSize ?? activeSearchTemplate?.requestedPageLength ?? 10,
    requestOptions,
    cacheSearchQuery,
    filterBy,
    defaultSortBy,
    viewKey: activeViewKey,
    cachedSearchQuery,
  })

  const onTabChange = (index: number) => {
    setActiveTab(index)
    ClientCache.getInstance().create(ACTIVE_SEARCH_TAB_INDEX_CACHE_KEY, index)
  }

  /**
   * Opens the map search view and passes the active search query as url query params
   * or saves it in the client cache to be re-applied once the map view is rendered.
   * @param searchQuery Active search query
   */
  const switchToMapView = (searchQuery?: SearchQuery) => {
    if (searchQuery?.searchTypeKey && activeSearchTemplate?.mapConfig?.mapSearchRoute) {
      const to: To = {
        pathname: activeSearchTemplate.mapConfig.mapSearchRoute,
      }
      if (activeSearchTemplate?.allowsQueryParamFiltering) {
        const queryParams = updateFilterQueryParams(searchQuery)
        to.search = queryParams
      } else {
        cacheSearchQuery(searchQuery)
      }
      navigate(to)
    }
  }

  const showFilters = !activeSearchTemplate?.searchFiltersDisabled

  return {
    searchQuery,
    searchResults,
    currentView,
    searchTemplate,
    isLoading: isLoadingTemplate || isSearching,
    showFilters,
    activeTab,
    activeSearchType,
    updateQuery,
    setCurrentPage,
    setSortColumn,
    switchToMapView,
    onTabChange,
  }
}
