import { AuthStrategy, RequestOptions, SearchQuery, getSearchResultExport } from '@msaf/generic-search-common'
import { ExportConfigProps } from '..'

type ExportAction = { label: string; action: VoidFunction }

function tokenExportDownload(
  searchUrl: string,
  actionQuery: SearchQuery,
  requestOptions: RequestOptions<'token'> | undefined,
) {
  const result = getSearchResultExport<'token'>(actionQuery, requestOptions)

  result.then((data) => {
    const url = `${searchUrl}/${data.token}`
    if (data?.token) {
      const fakeAnchorElement = document.createElement('a')
      fakeAnchorElement.id = 'download-csv'
      fakeAnchorElement.style.display = 'none'
      fakeAnchorElement.href = url
      fakeAnchorElement.setAttribute('href', url)
      fakeAnchorElement.rel = 'noopener noreferrer'
      fakeAnchorElement.target = 'blank'
      fakeAnchorElement.click()

      document.body.appendChild(fakeAnchorElement)

      setTimeout(() => fakeAnchorElement.remove(), 1000)
    } else {
      console.error('No token returned from the server.')
    }
  })
}

function formExportDownload(searchUrl: string, actionQuery: SearchQuery, csrfToken: string | null) {
  const fakeFormElement = document.createElement('form')
  fakeFormElement.method = 'POST'
  fakeFormElement.action = searchUrl
  fakeFormElement.target = '_blank'

  if (csrfToken) {
    const csrfInputElement = document.createElement('input')
    csrfInputElement.type = 'hidden'
    csrfInputElement.name = 'csrfmiddlewaretoken'
    csrfInputElement.value = csrfToken
    fakeFormElement.appendChild(csrfInputElement)
  }

  if (actionQuery) {
    const queryInputElement = document.createElement('input')
    queryInputElement.type = 'hidden'
    queryInputElement.name = 'query'
    queryInputElement.value = JSON.stringify({ searchQuery: actionQuery })
    fakeFormElement.appendChild(queryInputElement)
  }

  document.body.appendChild(fakeFormElement)

  fakeFormElement.submit()

  setTimeout(() => fakeFormElement.remove(), 1000)
}

function exportAction<T extends AuthStrategy = 'token'>(
  config: ExportConfigProps,
  actionQuery: SearchQuery,
  requestOptions: RequestOptions<T> | undefined,
) {
  switch (requestOptions?.authStrategy) {
    case 'none':
      return () => formExportDownload(config.url, actionQuery, null)
    case 'cookie':
      return () => formExportDownload(config.url, actionQuery, requestOptions?.csrfToken)
    case 'token':
      return () => tokenExportDownload(config.url, actionQuery, requestOptions)
    default:
      // @ts-expect-error
      const _: undefined = requestOptions
  }
  return null
}

export function useExportSearchResultsAction<T extends AuthStrategy = 'token'>(
  searchQuery: SearchQuery | undefined,
  exportConfigs: ExportConfigProps[] | undefined,
  requestOptions: RequestOptions<T> | undefined,
): {
  actions: ExportAction[]
} {
  const actions = exportConfigs
    ?.map((config: ExportConfigProps) => {
      const actionQuery = {
        ...(searchQuery as SearchQuery),
        // Overwrite the ordering here to ensure export ordering is not overwritten by cached search query.
        // Ordering is set by the server for the export views.
        ordering: [],
        requestedExport: true,
        viewKey: config.viewKey,
        exportFileName: config.exportFileName,
      }
      const action = exportAction(config, actionQuery, requestOptions)
      if (!action) return null
      return {
        label: config.label,
        action: action,
      }
    })
    .filter((action): action is ExportAction => action !== null)
  return { actions: actions ?? [] }
}
