import { NoteType } from '@msaf/core-react'

export enum NoteRecordType {
  EOI = 'Expression of interest',
}

export enum NoteForm {
  NEW_EOI = 'New registration',
  DECISION = 'Decision',
  PRE_CHECKS = 'Pre-checks',
  PROPERTY_SYSTEMS_ASPIRATIONS = 'Property systems & aspirations',
  CATCHMENT_CONTEXT = 'Catchment context',
  SYSTEMS_AND_ASPIRATIONS = 'Systems & aspirations', // NOTE: This form is now labelled as Risks and opportunities.
  OVERVIEW = 'Overview',
  APPLICATION_DETAILS = 'Project details', // NOTE: Applications are referred to as Projects in the frontend client.
  PANELIST_REVIEW = 'Panelist review',
  APPROVAL = 'Review Approval',
  SUPPLY = 'Nursery supply',
  SIGN_OFF = 'Sign Off',
  CONTRACT = 'Contract details',
  REQUEST_FOR_PAYMENT = 'Request for payment',
  REVIEW = 'Review & Approval',
}

export interface AppNoteType extends NoteType<NoteForm, NoteRecordType> {
  createdTimestamp?: string
}
