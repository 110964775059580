import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function Redirector({ target }: { target: string }) {
  const params = useParams()
  const navigate = useNavigate()

  const builtTarget = target.replace(/:(\w+)/g, (_, key) => {
    const value = params[key]
    if (value != undefined) {
      return value
    }
    throw new Error(`Missing parameter ${key}`)
  })

  useEffect(() => navigate(builtTarget, { replace: true }), [params.mode, params.id, params.applicationId, navigate])

  return null
}
