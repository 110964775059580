import {
  Divider,
  GroupContainer,
  InlineNotification,
  LabelledGroup,
  MAP_NAVIGATION_BREAKPOINT,
  Toggle,
  useMediaQuery,
} from '@msaf/core-react'
import { FeatureButton, MarkerIcon } from '@msaf/maps-react'
import { Feature, Geometry, Id, Properties } from '@turf/helpers'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import RaygunClient from '../../../logging/raygun'
import { FeatureMapContext } from '../context'
import { FeatureFilterGroupPills } from '../add-feature-panel/components/feature-filter-group'
import { buildTitleForFeature } from '../utils'

export interface FeatureSelectionPanelProps {
  label: string
  setIsMenuPanelOpen: (isOpen: boolean) => void
  handleClick: (featureId: Id) => void
}

export function FeatureSelectionPanel({ label, handleClick, setIsMenuPanelOpen }: FeatureSelectionPanelProps) {
  const { collectionState, uiConfig, selectedFeature, clearFeatureSelection, filterGroups, setToggleAll, isLoading } =
    useContext(FeatureMapContext)
  // Used to determine whether menu panel should close on feature button click or not
  const isDesktop = useMediaQuery(`(min-width: ${MAP_NAVIGATION_BREAKPOINT})`)
  const [toggleAllDisplay, setToggleAllDisplay] = useState<boolean>(true)

  const onClick = (featureId?: Id) => {
    if (featureId) {
      if (selectedFeature?.feature.id === featureId) {
        clearFeatureSelection?.()
        if (!isDesktop) {
          setIsMenuPanelOpen(false)
        }
      } else {
        handleClick?.(featureId)
      }
    } else {
      RaygunClient.getInstance().trackError('Unable to select feature as no ID present', {
        location: label,
      })
    }
  }

  useEffect(() => {
    // Only show the toggle all button if all filter groups are selected
    const setDisplay =
      (filterGroups &&
        Object.values(filterGroups).every((filterGroup) =>
          filterGroup.filterObjects.every((filterObject) => filterObject.isSelected),
        )) ??
      false
    setToggleAllDisplay(setDisplay)
    setToggleAll?.(null)
  }, [filterGroups])

  const groupedFeatures = useMemo(
    () =>
      // Convert into an array of [Group name, Feature[]]
      Object.entries(
        collectionState?.collections?.filtered?.features.reduce(
          (prev, curr) => {
            const key = curr.properties?.application?.label ?? 'Property'
            prev[`${key}`] = [...(prev[`${key}`] ?? []), curr]
            return prev
          },
          {} as { [key: string]: Feature<Geometry, Properties>[] },
        ) ?? {},
      )
        // Sort the groups by name.
        .sort(([groupA], [groupB]) => {
          const groupALower = groupA.toLowerCase()
          const groupBLower = groupB.toLowerCase()
          if (groupALower < groupBLower) return -1
          if (groupALower > groupBLower) return 1
          return 0
        })
        // Now sort the features by title (type + name).
        .map(
          ([group, features]) =>
            [
              group,
              features.sort((a, b) => {
                const aName = uiConfig ? buildTitleForFeature(uiConfig, a).toLowerCase() : ''
                const bName = uiConfig ? buildTitleForFeature(uiConfig, b).toLowerCase() : ''
                if (aName < bName) return -1
                if (aName > bName) return 1
                return 0
              }),
            ] as [string, Feature<Geometry, Properties>[]],
        ),
    [collectionState?.collections?.filtered?.features, uiConfig],
  )

  return (
    <>
      {filterGroups?.map((filterGroup) => (
        <Fragment key={filterGroup.key}>
          <FeatureFilterGroupPills {...filterGroup} isSkeleton={isLoading} />
          <Divider isFullWidth verticalSpacingSize={'small'} />
        </Fragment>
      ))}
      <Toggle
        name='SelectAll'
        id='selectAll'
        describedBy='options-label'
        isChecked={toggleAllDisplay}
        onChange={() => setToggleAll?.(!toggleAllDisplay)}
        isSkeleton={isLoading}
      >
        Select all
      </Toggle>
      <Divider isFullWidth verticalSpacingSize={'small'} />
      {!isLoading && collectionState?.initialised && uiConfig && (
        <>
          {groupedFeatures.map(([group, features]) => (
            <LabelledGroup key={group} labelText={group} labelTag='legend' labelStyle='uppercase'>
              <GroupContainer orientation='vertical'>
                {collectionState.collections.filtered.features.length === 0 && (
                  <InlineNotification messageType='info' isDismissable={false}>
                    No features available
                  </InlineNotification>
                )}
                {features.map((feature) => {
                  const markerProps = uiConfig.featureConfig[feature.properties?.type]
                  const markerIcon = <MarkerIcon marker={markerProps} />
                  return markerProps ? (
                    <FeatureButton
                      key={feature.id}
                      onClick={() => onClick(feature.id)}
                      iconElement={markerIcon}
                      isActive={selectedFeature?.feature.id === feature.id}
                    >
                      {buildTitleForFeature(uiConfig, feature)}
                    </FeatureButton>
                  ) : null
                })}
              </GroupContainer>
            </LabelledGroup>
          ))}
        </>
      )}
    </>
  )
}
