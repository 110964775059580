import { TextInput } from '@msaf/core-react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { RepeatingTextFieldProps, TextFieldProps } from '../../types'
import { RepeatingTextField } from './repeating/text-field'
import { Wrapper } from './wrapper'

export function TextField<T extends FieldValues>(props: TextFieldProps<T> | RepeatingTextFieldProps<T>) {
  if ('repeating' in props && props.repeating) {
    return <RepeatingTextField {...props} />
  }
  const { fieldId, mode, isDisabled, isRequired, helpText, labelOverride, ...rest }: TextFieldProps<T> = props
  const { register } = useFormContext<T>()

  return (
    <Wrapper {...props} isRequired={isRequired} helpText={helpText} labelOverride={labelOverride}>
      <TextInput
        id={fieldId}
        {...rest}
        isDisabled={isDisabled || mode == RouteMode.VIEW}
        {...register(fieldId, {
          setValueAs: (value) => {
            if (props.type === 'number') {
              if (value == null || value === '') return null
              return isNaN(+value) ? null : +value
            }

            return value
          },
        })}
      />
    </Wrapper>
  )
}
