import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  startOfDay,
  startOfMonth,
  startOfYear,
  parseISO,
  isValid,
  startOfWeek,
} from 'date-fns'
import { formatDate, ISO_DATETIME_FORMAT } from '@msaf/core-common'

// Pre-defined Date range filter options
export enum DateRangeFilterOption {
  None = 'None',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  ThisMonth = 'This calendar month',
  LastMonth = 'Last calendar month',
  NextMonth = 'Next calendar month',
  NextThreeMonths = 'Next 3 calendar months',
  NextSixMonths = 'Next 6 calendar months',
  LastCalendarMonth = 'Last calendar month',
  ThisCalendarMonth = 'This calendar month',
  NextSevenDays = 'Next 7 days',
  NextFourteenDays = 'Next 14 days',
  NextTwentyEightDays = 'Next 28 days',
  LastSixMonths = 'Last 6 calendar months',
  LastTwelveMonths = 'Last 12 calendar months',
  LastCalendarYear = 'Last calendar year',
  NextCalendarYear = 'Next calendar year',
  LastCalendarWeek = 'Last calendar week',
  NextCalendarWeek = 'Next calendar week',
  // The 'NC' in these names stands for non-calendar
  // E.g. on the 10th of June:
  // - The last 3 months is the period from 10th March to 10th June
  // - The last 3 calendar months is the period from 1st March to 1st June
  NextThreeNCMonths = 'Next 3 months',
  NextSixNCMonths = 'Next 6 months',
  LastThreeNCMonths = 'Last 3 months',
  LastSixNCMonths = 'Last 6 months',
  LastTwelveNCMonths = 'Last 12 months',
}

// Allow date range filter options outside of the predefined DateRangeFilterOption
export type DateRangeFilterType = DateRangeFilterOption | string

export type DateFilter = {
  getISOLower?: () => string | null
  getISOUpper?: () => string | null
  getPillText?: () => string
  label: string
  value: string
}

type Tense = 'last' | 'next'
const isValidTense = (t: any): t is Tense => {
  return ['last', 'next'].includes(t.toLowerCase())
}
const isPast = (t: Tense) => t.toLowerCase() === 'last'

type PeriodType = 'days' | 'weeks' | 'months' | 'years'
const isValidPeriodType = (t: any): t is PeriodType => {
  return ['days', 'weeks', 'months', 'years'].includes(t.toLowerCase())
}

export class DateRange {
  private constructISOGetterGeneric(
    isPast: boolean,
    quantity: number,
    periodType: PeriodType,
    calendar: boolean = false,
  ): (() => string)[] {
    const now = new Date()
    let upper: Date | undefined = undefined
    let lower: Date | undefined = undefined
    switch (periodType.toLowerCase()) {
      case 'days':
        if (isPast) {
          upper = now
          lower = addDays(now, -quantity)
        } else {
          upper = addDays(now, quantity)
          lower = now
        }
        break
      case 'weeks':
        if (isPast) {
          if (calendar) {
            // The end point is the start of Monday of this week
            // The start date is that day minus (quantity * 7) days
            // The start of the week is set to Monday (1)
            upper = startOfWeek(now, { weekStartsOn: 1 })
            lower = startOfWeek(addWeeks(upper, -quantity), { weekStartsOn: 1 })
          } else {
            // The end point is today, the start point is (quantity * 7) days ago
            upper = startOfDay(now)
            lower = startOfDay(addWeeks(upper, -quantity))
          }
        } else {
          if (calendar) {
            // The start point is the start of Monday of next week
            // The end point is (quantity * 7) days after that
            lower = startOfWeek(addDays(now, 7), { weekStartsOn: 1 })
            upper = startOfWeek(addWeeks(lower, quantity), { weekStartsOn: 1 })
          } else {
            // The start point is today, end point is (quantity * 7) days from now
            lower = startOfDay(now)
            upper = startOfDay(addWeeks(lower, quantity))
          }
        }
        break
      case 'months':
        // Calendar months are whole months from the first
        // to the last day of that month. Non-calendar months
        // are counted to or from the date today.
        // Eg:
        // 'next 2 months' on March 10th is March 10 to May 10
        // 'next 2 calendar months' on March 10th is April 1 to May 31
        if (isPast) {
          if (calendar) {
            lower = startOfMonth(addMonths(now, -quantity))
            upper = startOfMonth(addMonths(lower, quantity))
          } else {
            lower = addMonths(now, -quantity)
            upper = now
          }
        } else {
          if (calendar) {
            lower = startOfMonth(addMonths(now, 1))
            upper = startOfMonth(addMonths(lower, quantity))
          } else {
            lower = now
            upper = addMonths(lower, quantity)
          }
        }
        break
      case 'years':
        if (isPast) {
          if (calendar) {
            // Last x calendar years
            // Start with the start of this year, this will be the end point
            // The start point will be x years before that
            upper = startOfYear(now)
            lower = startOfYear(addYears(now, -quantity))
          } else {
            // Last x years up until today
            upper = startOfDay(now)
            lower = startOfDay(addYears(upper, -quantity))
          }
        } else {
          if (calendar) {
            // Next x calendar years
            // Start with the start of next year, this will be the start point
            // The end point will be x years after that
            lower = startOfYear(addYears(now, 1))
            upper = startOfYear(addYears(lower, quantity))
          } else {
            // Next x years from today
            lower = startOfDay(now)
            upper = startOfDay(addYears(lower, quantity))
          }
        }
        break
      default:
        break
    }

    return [
      // First format as a _date_ only, then add time & timezone to the date.
      () => formatDate(formatDate(lower), ISO_DATETIME_FORMAT),
      () => formatDate(formatDate(upper), ISO_DATETIME_FORMAT),
    ]
  }

  private constructISOGettersByParts(parts: Array<string>) {
    /**
     * Date range filter option can be of the following two formats:
     *  1. [tense] [number] [periodType]
     *      Example: Next 14 days
     *  2. [tense] [number] calendar [periodType]
     *      Example: Next 3 calendar months
     */
    if (parts.length < 3 || parts.length > 4) {
      throw new Error('Date specified is not a properly formatted date for use in the filter object')
    }
    const tense: Tense = parts[0] as Tense
    const quantityStr: string = parts[1]

    // Flag to indicate if we're counting calendar months/years
    let calendar = false
    let periodType: PeriodType | 'calendar' = parts[2] as PeriodType
    if (parts.length === 4) {
      periodType = parts[3] as PeriodType
      calendar = parts[2].toLowerCase() === 'calendar'
    }

    if (!isValidTense(tense)) throw new Error('Date specified does not contain a valid tense')

    const quantity = parseInt(quantityStr)
    if (isNaN(quantity)) throw new Error('Date specified does not contain a valid quantity')

    if (!isValidPeriodType(periodType)) throw new Error('Date specified does not contain a valid period')

    return this.constructISOGetterGeneric(isPast(tense), quantity, periodType, calendar)
  }

  /**
   * Convenience function for date ranges like 'today' and 'tomorrow'.
   * @param daysFromToday How many days from today is the day that we want?
   *   0 = today
   *   1 = tomorrow
   *   -1 = yesterday
   */
  private singleDayRange(daysFromToday: number) {
    const lower = addDays(new Date(), daysFromToday)
    const upper = addDays(lower, 1)
    return [
      () => formatDate(startOfDay(lower), ISO_DATETIME_FORMAT),
      () => formatDate(startOfDay(upper), ISO_DATETIME_FORMAT),
    ]
  }

  private contructISOGetters(range: string): (() => string | null)[] {
    switch (range.trim()) {
      case DateRangeFilterOption.Today:
        return this.singleDayRange(0)
      case DateRangeFilterOption.Tomorrow:
        return this.singleDayRange(1)
      case DateRangeFilterOption.Yesterday:
        return this.singleDayRange(-1)
      case DateRangeFilterOption.LastCalendarWeek:
        return this.constructISOGettersByParts(['last', '1', 'calendar', 'weeks'])
      case DateRangeFilterOption.NextCalendarWeek:
        return this.constructISOGettersByParts(['next', '1', 'calendar', 'weeks'])
      case DateRangeFilterOption.ThisMonth:
        return [
          () => formatDate(startOfMonth(new Date()), ISO_DATETIME_FORMAT),
          () => formatDate(startOfMonth(addMonths(new Date(), 1)), ISO_DATETIME_FORMAT),
        ]
      case DateRangeFilterOption.LastMonth:
        return this.constructISOGettersByParts(['last', '1', 'calendar', 'months'])
      case DateRangeFilterOption.NextMonth:
        return this.constructISOGettersByParts(['next', '1', 'calendar', 'months'])
      case DateRangeFilterOption.LastCalendarYear:
        return this.constructISOGettersByParts(['last', '1', 'calendar', 'years'])
      case DateRangeFilterOption.NextCalendarYear:
        return this.constructISOGettersByParts(['next', '1', 'calendar', 'years'])
      case DateRangeFilterOption.None:
        return [() => null, () => null]
      case '':
        throw new Error('Date range specified is not valid')
      default:
        // If string doesn't match the allowed enum values, break down to check if able to use a custom value
        const parts = range.split(' ')
        return this.constructISOGettersByParts(parts)
    }
  }

  /**
   * Constructs date range filters given the options that are displayable to the user
   * @param options Range of options to allow for the date range filters
   * @returns Re-constructed date range filter options
   * @throws Throws an error if there are no dates passed
   * @todo Allow translations to be passed for dates
   */
  constructFilters(options: DateRangeFilterType[]): DateFilter[] {
    if (!options || !options.length) {
      throw new Error('Must provide a list of options')
    }

    const filters: DateFilter[] = options.map((option) => {
      const [getISOLower, getISOUpper] = this.contructISOGetters(option)
      return {
        // kebab-case the option
        value: option.toLowerCase().split(' ').join('-'),
        label: option,
        getISOLower,
        getISOUpper,
      }
    })

    filters.unshift({
      value: '',
      label: 'Custom',
    })

    return filters
  }
}

/**
 * Parses date string to display correct date and handle inValid dates in the datepicker
 * @param value Date field value
 * @returns Date
 */
export function parseDate(value: string | undefined): Date | undefined {
  const date = value ? parseISO(value) : undefined
  return isValid(date) ? date : undefined
}
