import { Button, Pill, Label, ButtonsContainer, GroupContainer } from '@msaf/core-react'
import { FilterStringComponent, flattenFiltersToSelected } from '@msaf/generic-search-common'
import React, { useEffect, useState } from 'react'
import { AppliedFiltersProps } from '..'

export function LabelledAppliedFilters({
  modifyValues,
  query,
  searchTemplate,
  lookupResolver,
  saveEnabled,
  isSkeleton,
  saveAction,
}: AppliedFiltersProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [displaySegments, setDisplaySegments] = useState<{
    [filter: string]: FilterStringComponent[][]
  }>({})

  useEffect(() => {
    async function getDisplaySegments() {
      setIsLoading(true)
      try {
        const segments = await flattenFiltersToSelected(query, searchTemplate, { lookupResolver })
        setDisplaySegments(segments)
      } catch {
        // TODO: Error handling
        setDisplaySegments({})
      }
      setIsLoading(false)
    }
    getDisplaySegments()
  }, [query, lookupResolver, searchTemplate])

  return (
    <>
      {searchTemplate.filters.flatMap((f, i) => {
        if (f.filterKey in displaySegments) {
          return (
            // TODO: create KVP component
            <div className='c-key-value-pair' key={`key-val-${f.label}-${i}`}>
              <Label labelText={f.label} />
              <GroupContainer>
                {displaySegments[f.filterKey].map((segments, j) => {
                  // In case of mutiple values separated by operators, display just the valus separated by a hyphen
                  const filterValues = segments
                    .map((segment) => ('value' in segment ? segment['value'] : null))
                    .filter(Boolean)
                  return (
                    <Pill
                      key={`key-val-pill-${f.label}-${j}`}
                      label={filterValues.join('-')}
                      isSelectable={false}
                      isSelected
                    />
                  )
                })}
              </GroupContainer>
            </div>
          )
        } else {
          return null
        }
      })}
      <ButtonsContainer containerStyle='left' verticalSpacing='small'>
        <Button
          label='Modify search'
          data-selenium-search-modify
          onClick={() => modifyValues()}
          buttonStyle='text-link'
          isSkeleton={isSkeleton || isLoading}
        />
        {saveAction && saveEnabled && (
          <Button
            label='Save search'
            onClick={() => saveAction()}
            buttonStyle='text-action'
            isSkeleton={isSkeleton || isLoading}
          />
        )}
      </ButtonsContainer>
    </>
  )
}
