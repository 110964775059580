import { ActionsMenu, Button, ButtonsContainer, DropdownMenu, HeadingRow, MenuItem } from '@msaf/core-react'
import { AuthStrategy, RequestOptions, SearchQuery } from '@msaf/generic-search-common'
import React, { useMemo } from 'react'
import { useExportSearchResultsAction } from './use-export-search-results-action'
import { ExportConfigProps, ResultViewConfig, viewIsValidForSearchType } from '../index'

export interface ResultsHeaderProps<T extends AuthStrategy = 'token'> {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  exportConfig?: ExportConfigProps[]
  searchQuery?: SearchQuery
  mapPath?: string
  requestOptions?: RequestOptions<T>
  resultViews?: Array<ResultViewConfig>
  currentResultView: ResultViewConfig
  changeResultView?: (cfg: ResultViewConfig) => void
}

export function ResultsHeader<T extends AuthStrategy = 'token'>({
  heading,
  headingLevel,
  exportConfig,
  searchQuery,
  mapPath,
  requestOptions,
  resultViews = [],
  currentResultView,
  changeResultView,
}: ResultsHeaderProps<T>) {
  const filteredExportConfig = useMemo(
    () =>
      // Filter the config by search type key. Allow if search type key is not set.
      exportConfig?.filter(({ searchTypeKey }) => !searchTypeKey || searchTypeKey === searchQuery?.searchTypeKey),
    [exportConfig, searchQuery?.searchTypeKey],
  )
  const filteredResultViews = useMemo(
    () =>
      // Filter the result views. Any that aren't valid for the current search type
      // will not be available to be switched to
      resultViews?.filter((rv: ResultViewConfig) => viewIsValidForSearchType(rv, searchQuery?.searchTypeKey)),
    [resultViews, searchQuery?.searchTypeKey],
  )

  const { actions } = useExportSearchResultsAction(searchQuery, filteredExportConfig, requestOptions)

  return (
    <HeadingRow headingLevel={headingLevel ?? 2} headingText={heading ?? ''}>
      {(mapPath || exportConfig) && (
        <ButtonsContainer containerStyle='right'>
          {changeResultView &&
            filteredResultViews?.map(
              (rv: ResultViewConfig) =>
                rv.name !== currentResultView.name && (
                  <Button
                    key={`toggle-${rv.name}`}
                    label={`Switch to ${rv.name} display`}
                    iconAriaLabel={`Switch to ${rv.name} display`}
                    onClick={() => changeResultView(rv)}
                  />
                ),
            )}
          {mapPath && (
            <Button
              label='Show results on map'
              icon='map-pin'
              iconAlignment='left'
              iconAriaLabel='Show results on map'
              path={mapPath}
            />
          )}
          {!!actions?.length && (
            <ActionsMenu label='Export' buttonStyle='secondary' isLeftAligned={false}>
              <DropdownMenu>
                {actions.map((action) => (
                  <MenuItem label={action.label} action={action.action} className='c-dropdown-menu__link' />
                ))}
              </DropdownMenu>
            </ActionsMenu>
          )}
        </ButtonsContainer>
      )}
    </HeadingRow>
  )
}
