import { createContext, PropsWithChildren, useContext } from 'react'
import { RefetchOptions } from 'react-query'

type PlanWorkflowState = {
  refetchState?: (options?: RefetchOptions) => void
}

const PlanWorkflowContext = createContext<PlanWorkflowState | undefined>(undefined)

export function PlanWorkflowStateProvider({
  children,
  planWorkflowState,
}: PropsWithChildren<{ planWorkflowState: PlanWorkflowState }>) {
  return <PlanWorkflowContext.Provider value={planWorkflowState}>{children}</PlanWorkflowContext.Provider>
}

export function usePlanWorkflowState() {
  const context = useContext(PlanWorkflowContext)
  if (context === undefined) {
    throw new Error('usePlanWorkflowState must be used within a PlanWorkflowProvider')
  }
  return context
}
