import { FormFieldRange, InputWidth, TextInput } from '@msaf/core-react'
import React, { useState, useEffect } from 'react'
import { AuthStrategy, createFilterSet, FilterInstance, NumericFilterOperator } from '@msaf/generic-search-common'
import { FilterProps } from '../../../utils'

const FILTER_OPERATOR = Object.freeze({
  LOWER: '>=',
  UPPER: '<=',
})

export function FilterNumericRange<T extends AuthStrategy = 'token'>(filterProps: FilterProps<T>) {
  const [lower, setLower] = useState('')
  const [upper, setUpper] = useState('')

  const { filterKey, value, setValue, onKeyDown } = filterProps

  useEffect(() => {
    if (value && typeof value !== 'string') {
      const queryFilters = value.filterSet.filters as FilterInstance[]
      queryFilters.forEach(({ filterOperator, filterValue }) => {
        if (filterOperator === FILTER_OPERATOR.LOWER) {
          setLower(filterValue ?? '')
        } else if (filterOperator === FILTER_OPERATOR.UPPER) {
          setUpper(filterValue ?? '')
        }
      })
    }
  }, [value])

  /**
   * Returns the filter set with the updated filter value.
   * Excludes filters with no value selected.
   * @param filterValue Selected date formatted as a string
   * @param filterOperator Use to identify which date value (upper or lower) to update
   * @returns Filter set with non empty filters
   */
  const getAppliedFilterSet = (filterValue: string, filterOperator: NumericFilterOperator) => {
    if (typeof value !== 'string') {
      const queryFilters = value.filterSet.filters as FilterInstance[]
      if (filterOperator === FILTER_OPERATOR.LOWER) {
        queryFilters.splice(0, 1, { filterKey, filterValue, filterOperator })
      } else if (filterOperator === FILTER_OPERATOR.UPPER) {
        queryFilters.splice(1, 1, { filterKey, filterValue, filterOperator })
      }
      return createFilterSet(queryFilters, 'AND')
    } else {
      return createFilterSet(
        [
          {
            filterKey,
            filterValue,
            filterOperator,
          },
        ],
        'AND',
      )
    }
  }

  const handleChange = (bound: 'lower' | 'upper', value: string) => {
    if (bound === 'lower') {
      setValue(getAppliedFilterSet(value, FILTER_OPERATOR.LOWER as NumericFilterOperator))
    } else if (bound === 'upper') {
      setValue(getAppliedFilterSet(value, FILTER_OPERATOR.UPPER as NumericFilterOperator))
    }
  }

  return (
    <FormFieldRange
      formFields={[
        <TextInput
          id='lower'
          type='number'
          placeholder='From'
          fieldSize={'large' as InputWidth}
          onBlur={(e) => handleChange('lower', e.target.value)}
          value={lower}
          onKeyDown={onKeyDown}
        />,
        <TextInput
          id='upper'
          type='number'
          placeholder='To'
          fieldSize={'large' as InputWidth}
          onBlur={(e) => handleChange('upper', e.target.value)}
          value={upper}
          onKeyDown={onKeyDown}
        />,
      ]}
    />
  )
}
