import { TextInput } from '@msaf/core-react'
import { ArrayPath, FieldValues, Path, useFieldArray, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../../types/route'
import { RepeatingTextFieldProps } from '../../../types'
import { Wrapper } from './wrapper'

export function RepeatingTextField<T extends FieldValues>(props: RepeatingTextFieldProps<T>) {
  const { control, register } = useFormContext<T>()
  const { fieldId, mode } = props
  const fieldArray = useFieldArray({ name: fieldId as ArrayPath<T>, control })

  return (
    <Wrapper
      {...props}
      addField={() => fieldArray.append({})}
      removeField={(index) => {
        fieldArray.remove(index)
      }}
    >
      {fieldArray.fields.map((field, index) => (
        <TextInput
          key={field.id}
          id={`${fieldId}.${index}`}
          isDisabled={props.isDisabled || mode == RouteMode.VIEW}
          {...register(`${fieldId}.${index}.value` as Path<T>)}
        />
      ))}
    </Wrapper>
  )
}
