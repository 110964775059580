import { useEffect, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { ComplexFieldConfig, FieldConfig, ButtonProps } from '../../types'
import { FormWatchResultProps } from './use-form-watch'
import isEqual from 'lodash.isequal'

export type FConfig<T extends FieldValues> = FieldConfig<T> | ComplexFieldConfig<T> | ButtonProps<T>

export interface FieldWatchResultProps<T extends FieldValues> extends FormWatchResultProps<T> {
  initialConfig: FConfig<T>
}

export type UseFieldWatchFunc<T extends FieldValues> = (props: FieldWatchResultProps<T>) => FConfig<T>

export function useFieldWatch<T extends FieldValues>(
  methods: UseFormReturn<T>,
  fieldConfig: FConfig<T>,
  func?: UseFieldWatchFunc<T>,
) {
  const [config, setConfig] = useState<FConfig<T>>(fieldConfig)

  useEffect(() => {
    setConfig({ ...fieldConfig })
  }, [fieldConfig])

  useEffect(() => {
    if (func) {
      const subscription = methods.watch((data, info) => {
        const res = func({ data, info, methods, initialConfig: config })
        if (!isEqual(res, config)) {
          setConfig(res)
        }
      })

      return () => {
        subscription.unsubscribe()
      }
    }
  }, [methods.watch, func, config])

  delete config.watch
  return config
}
