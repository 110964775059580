import { NewFile } from '@msaf/core-react'
import { useCallback, useEffect, useState } from 'react'
import { UploadedFile } from '.'
import { useResolveRealFileURL } from '../../../utils/files'

// Function to handle all the logic to choose which file to display.
export const useDisplayFileSelection = (files: (UploadedFile | NewFile)[]) => {
  const [displayFile, setDisplayFile] = useState<UploadedFile | null>(null)
  const [displayImageUrl, setDisplayImageUrl] = useState<string | null>(null)
  const resolveRealFileURL = useResolveRealFileURL()

  // Resolve the real file URL
  useEffect(() => {
    setDisplayImageUrl(null)
    ;(async () => {
      if (!displayFile) return null
      const url = await resolveRealFileURL(displayFile)
      setDisplayImageUrl(url)
    })()
  }, [displayFile?.url])

  const setNextImage = useCallback(
    (direction: 1 | -1 = 1) => {
      const limitToRange = (value: number, max: number) => {
        if (value < 0) return max + value
        return value % max
      }

      const currentId = displayFile?.id
      const currentIndex = currentId ? files.findIndex((f) => f.id === currentId) : -1
      let offset = limitToRange(currentIndex + direction, files.length)

      for (; offset != currentIndex; offset += direction) {
        offset = limitToRange(offset, files.length)

        const nextFile = files[offset]
        // Skip files that aren't images
        if ('mimeType' in nextFile && nextFile?.mimeType?.startsWith('image/')) {
          // If we find an image set it then exit the loop.
          setDisplayFile(nextFile)
          break
        }
      }
    },
    [files, setDisplayFile, displayFile?.id],
  )

  const nextDisplayFile = useCallback(() => setNextImage(), [setNextImage])
  const prevDisplayFile = useCallback(() => setNextImage(-1), [setNextImage])

  return { displayFile, displayImageUrl, setDisplayFile, nextDisplayFile, prevDisplayFile }
}
