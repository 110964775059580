import {
  Button,
  createToastMessage,
  RecordContainer,
  ToastMessage,
  useTitleChange,
  WorkflowNav,
} from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { AxiosError } from 'axios'
import { FormEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { UserForm } from '../../../forms/user'
import { UserInputs } from '../../../forms/user/type.config'
import { useBackLink } from '../../../hooks/useBackLink'
import { useEditForm } from '../../../hooks/useEditForm'

export const NewUser = () => {
  useTitleChange('Administration | New user')
  const location = useLocation()
  useBackLink({ label: 'Back', path: '/admin/users' })
  const navigate = useNavigate()
  const { register, mutate, handleSubmit, control, formState, isSaving, isFetchingData } = useEditForm<UserInputs>({
    url: '/api/user/',
    isNewRecord: true,
    defaults: {},
    onSuccess: ({ data }) => {
      createToastMessage(<ToastMessage messageType='success' title='Success' message={'New user created'} />)
      navigate(`/admin/users/view/${data.id}/user`)
    },
    onError: (error) => {
      let message = 'Could not create user.'
      if (error.code === AxiosError.ERR_BAD_REQUEST) {
        try {
          // TODO: Extract into general message handling function.
          const errorResponse = Object.values(error.response?.data as Record<string, string[]>)
            .flat()
            .flatMap((e) => {
              let message = e.trim()
              if (message.endsWith('.')) {
                message = message.substring(0, message.length - 1)
              }

              return message
            })
            .join('. ')

          if (errorResponse) {
            message += ` ${errorResponse}.`
          }
        } catch (e) {
          // Unable to parse response as JSON, or does not match expected format
          console.error(e)
        }
      }
      createToastMessage(<ToastMessage messageType='error' title='Error' message={message} />)
    },
  })

  return (
    <RecordContainer
      workflowNav={<WorkflowNav sections={[{ links: [{ label: 'Details', path: location.pathname }] }]} />}
    >
      <UserForm
        isNewRecord
        isLoading={isSaving || isFetchingData}
        register={register}
        control={control}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          handleSubmit((data) => mutate(data))(e)
        }}
        formState={formState}
        footerActions={
          <>
            <Button type='submit' buttonStyle='primary' label='Add user' isDisabled={isSaving} isLoading={isSaving} />
            <Button
              type='button'
              buttonStyle='text-action'
              label='Cancel'
              isDisabled={isSaving}
              onClick={() => navigate('/admin/users')}
            />
          </>
        }
      />
    </RecordContainer>
  )
}
