import { Button } from '@msaf/core-react'
import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SAVE_AND_CONTINUE_ID } from '../../../../../../../../forms/components/atom/footer'
import { Form } from '../../../../../../../../forms/components/form'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { ContractFields, getContractDetailWatch, useFormConfig } from './contract.form'
import { usePermission } from '../../../../../../../../services/permissions'
import { useBuildWorkflowErrorsAsFormNotice } from '../../../../../../../../hooks/use-build-workflow-errors'

export const Contract = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const navigate = useNavigate()
  const canEdit = usePermission(UserPermissions.EDIT_SUPPLY)
  const [enableProcessAction, setEnableProcessAction] = useState(false)

  const { data, workflowData, isLoading, isSaving, errors, onSubmit, isEditable } = useStateManagement<ContractFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/contract-details`,
    {
      workflowId: 'supply',
      sectionId: 'supply',
      formId: 'contract',
      instanceId: `${applicationId}`,
    },
    {
      success: 'Project saved successfully',
      error: 'Unable to save project',
    },
  )

  const processAction = usePostAction({
    url: `/api/plan/${planId}/project/${applicationId}/progress`,
    displayServerError: true,
    messages: { success: 'Project signed off', error: 'Could not process project sign off' },
  })

  const initialState = useMemo(
    () => ({
      ...data,
      contractUrl: data?.contractUrl?.length ? data.contractUrl : [{ value: '' }],
    }),
    [data],
  )

  useEffect(() => setEnableProcessAction(!!data?.readyForSignoff), [data?.readyForSignoff])

  const workflowErrorSection = useBuildWorkflowErrorsAsFormNotice(workflowData, 'supply', 'supply')

  const config = [...useFormConfig(data), ...workflowErrorSection]

  return (
    <Form<ContractFields>
      fieldWatch={getContractDetailWatch(setEnableProcessAction)}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      initialState={initialState}
      canEdit={canEdit && !!isEditable}
      config={config}
      footerActions={
        mode === RouteMode.EDIT ? (
          <>
            <Button
              id={SAVE_AND_CONTINUE_ID}
              type='submit'
              buttonStyle='primary'
              label='Process'
              isLoading={isSaving}
              isDisabled={isSaving || !enableProcessAction}
            />
            <Button type='submit' buttonStyle='primary' label='Save' isLoading={isSaving} isDisabled={isSaving} />
            <Button buttonStyle='text-action' label='Cancel' onClick={() => navigate('/')} isDisabled={isSaving} />
          </>
        ) : undefined
      }
      submitAction={async (data, submitter) => {
        const process = submitter?.id === SAVE_AND_CONTINUE_ID
        await onSubmit(data, {
          onSuccess: async () => {
            if (process) {
              const processResponse = await processAction.mutateAsync({})
              if (processResponse.status === 200) {
                navigate(`/plans/view/${planId}/supply/project/${applicationId}/contract`)
              }
            }
          },
        })
      }}
    />
  )
}
