import { FieldValues } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { GetFeatureFormConfigProps } from '..'
import { FeatureFormMode, FormElementConfig, PlantingAreaFormFields } from '../../types'
import { toDollarCurrencyFormat, isFormModes } from '../../utils'
import {
  MAX_PRICE_PER_PLANT,
  DEFAULT_PLANT_SPACING_STEMS_PER_HA,
  MAX_PRICE_PER_PLANT_SILVOPASTURE,
  DEFAULT_PLANT_SPACING_STEMS_PER_HA_SILVOPASTURE,
} from '../../constants'
import { FeatureType } from '../../../config/map'

const getPlantingSpacingStemsPerHaAssistiveText = (actionType: FeatureType) => {
  switch (actionType) {
    case 'hillCountrySilvopasturePlanting':
      return (
        <>
          <p>
            KMR can co-fund poles or wands at 60 - 120 stems per hectare depending on erosion severity, or small
            sub-hectare groups of wands at higher stocking rates (250 - 1,100 stems per hectare).
          </p>
          <p>The minimum order for wands is 50 and poles is 20.</p>
        </>
      )
    case 'hillCountryPlanting':
      return `KMR can co-fund 1,600 - 2,500 stems per hectare for native planting. Supplementary planting can also
        be mapped in areas of assisted native regeneration.`
    default:
      return undefined
  }
}

export const getPlantingAreaFormConfig = <T extends FieldValues>({
  planId,
  formMode,
  actionType,
}: Pick<
  GetFeatureFormConfigProps<T>,
  'planId' | 'formMode' | 'actionType'
>): FormElementConfig<PlantingAreaFormFields>[] => {
  const { isMapMode, isMapOrEditMode, isNotVerificationMapMode, isVerificationMode } = isFormModes(formMode)
  const displayStandardFeatureFields = isVerificationMode ? RouteMode.VIEW : RouteMode.EDIT

  const isHillCountry = [
    'hillCountryPlanting',
    'hillCountryAlternativeForestry',
    'hillCountryNativeRegeneration',
    'hillCountrySilvopasturePlanting',
  ].includes(actionType)
  const isHillCountryNativeRegeneration = actionType === 'hillCountryNativeRegeneration'
  const isHillCountryAlternativeForestry = actionType === 'hillCountryAlternativeForestry'
  const isHillCountrySilvopasturePlanting = actionType === 'hillCountrySilvopasturePlanting'
  const hasPlantingDifficulty = !(
    isHillCountryNativeRegeneration ||
    isHillCountryAlternativeForestry ||
    isHillCountrySilvopasturePlanting
  )
  const hasPlantingSpeciesComposition = [
    'hillCountryPlanting',
    'hillCountryAlternativeForestry',
    'hillCountrySilvopasturePlanting',
  ].includes(actionType)
  const hasPlantingSpacingStemsPerHa = [
    'hillCountryPlanting',
    'hillCountryAlternativeForestry',
    'hillCountrySilvopasturePlanting',
  ].includes(actionType)

  const isHillCountryNativeRegenerationOrAlternativeForestry = [
    'hillCountryNativeRegeneration',
    'hillCountryAlternativeForestry',
  ].includes(actionType)

  return [
    {
      renderOnlyWhen: formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        // Hack override type to cope with 'area' or 'proposed' field. We don't have verification form types here.
        fieldId: isVerificationMode ? ('proposedArea' as keyof PlantingAreaFormFields) : 'area',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: isHillCountryNativeRegeneration && formMode === FeatureFormMode.MAIN_MAP,
      type: 'atom',
      element: {
        type: 'paragraph',
        content: [
          'You can map a polygon to identify the area that the landowner will manage to regenerate',
          'over time. Use other action types to cost project actions (e.g. fencing, spray,',
          'supplementary planting) to support native regeneration.',
        ].join(' '),
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && isMapOrEditMode,
      type: 'field',
      element: {
        fieldId: 'application',
        type: 'lookup',
        lookupId: 'application',
        fieldFilters: { planId },
        isRequired: true,
        defaultToFirst: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'locationName',
        type: 'text',
        mode: displayStandardFeatureFields,
        isRequired: true,
      },
    },
    {
      renderOnlyWhen: isHillCountryNativeRegeneration,
      type: 'field',
      element: {
        fieldId: 'regenerationLocationNotes',
        type: 'text-area',
        mode: displayStandardFeatureFields,
        isRequired: false,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'anticipatedStart',
        type: 'date',
        isRequired: true,
        showMonthYearPicker: true,
        dateFormat: 'MMM yyyy',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: hasPlantingDifficulty && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'plantingDifficulty',
        type: 'lookup',
        isRequired: true,
        lookupId: 'terrain_difficulty',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isHillCountrySilvopasturePlanting && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'silvopasturePlantingMethod',
        type: 'lookup',
        isRequired: true,
        lookupId: 'silvopasture_planting_method',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'justification',
        formPrefix: 'action',
        type: 'text-area',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'intendedImplementer',
        type: 'lookup',
        isRequired: true,
        lookupId: 'work_implementor',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegenerationOrAlternativeForestry && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'averagePricePerPlant',
        type: 'number',
        min: 0,
        max: isHillCountrySilvopasturePlanting ? Number(MAX_PRICE_PER_PLANT_SILVOPASTURE) : Number(MAX_PRICE_PER_PLANT),
        step: '0.01',
        isRequired: true,
        mode: displayStandardFeatureFields,
        helpText: isHillCountrySilvopasturePlanting ? (
          <p>The price for poplar poles is $45; the average price for poplar wands is $4.70.</p>
        ) : (
          <p>
            Average price per plant varies by project size. Map the polygon, add any planting zone percentages, then
            press save to see plant numbers:
            <br />
            &lt; 10,000 = $2.10/plant (default),
            <br />
            10,000 &#8212; 25,000 = $1.90/plant,
            <br />
            &gt; 25,000 = $1.70/plant.
          </p>
        ),
      },
    },
    {
      renderOnlyWhen: hasPlantingSpacingStemsPerHa && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'plantingSpacingStemsPerHa',
        type: 'number',
        min: 1,
        max: isHillCountrySilvopasturePlanting
          ? Number(DEFAULT_PLANT_SPACING_STEMS_PER_HA_SILVOPASTURE)
          : Number(DEFAULT_PLANT_SPACING_STEMS_PER_HA),
        isRequired: true,
        mode: displayStandardFeatureFields,
        helpText: getPlantingSpacingStemsPerHaAssistiveText(actionType),
      },
    },
    {
      renderOnlyWhen: isHillCountryAlternativeForestry && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'estimatedPricePerHa',
        type: 'number',
        min: 0,
        max: 1500,
        isRequired: true,
        mode: displayStandardFeatureFields,
        helpText:
          'This total price is not calculated in Mātai Onekura. It is entered from a plan prepared by a third party registered forestry advisor.',
      },
    },
    {
      renderOnlyWhen: isHillCountryAlternativeForestry && isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'kmrContributionPerHa',
        type: 'number',
        min: 0,
        max: 2000,
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode && hasPlantingSpeciesComposition,
      type: 'field',
      element: {
        fieldId: 'plantingSpeciesComposition',
        type: 'text-area',
        isRequired: false,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isHillCountry && isNotVerificationMapMode,
      type: 'atom',
      element: {
        type: 'card',
        fields: [
          {
            type: 'atom',
            element: {
              type: 'heading',
              level: 3,
              content: 'Planting zones',
            },
          },
          {
            type: 'field',
            element: {
              fieldId: 'plantingZoneAWetlandPercent',
              type: 'number',
              isRequired: true,
              mode: displayStandardFeatureFields,
            },
          },
          {
            type: 'field',
            element: {
              fieldId: 'plantingZoneBLowerBankPercent',
              type: 'number',
              isRequired: true,
              mode: displayStandardFeatureFields,
            },
          },
          {
            type: 'field',
            element: {
              fieldId: 'plantingZoneCTransitionPercent',
              type: 'number',
              isRequired: true,
              mode: displayStandardFeatureFields,
            },
          },
        ],
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegenerationOrAlternativeForestry && formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        fieldId: 'numberOfPlants',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: !isHillCountry && !isMapMode,
      type: 'field',
      element: {
        fieldId: 'numberOfPlantsZoneA',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: !isHillCountry && !isMapMode,
      type: 'field',
      element: {
        fieldId: 'numberOfPlantsZoneB',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: !isHillCountry && !isMapMode,
      type: 'field',
      element: {
        fieldId: 'numberOfPlantsZoneC',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && !isMapMode,
      type: 'field',
      element: {
        fieldId: 'materialCost',
        formPrefix: 'actionPlanting',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && !isMapMode,
      type: 'field',
      element: {
        fieldId: 'labourCost',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
    {
      renderOnlyWhen: !isHillCountryNativeRegeneration && formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        fieldId: 'proposedCost',
        labelOverride: formMode == FeatureFormMode.VERIFICATION_FORM ? 'Contracted cost' : 'Total cost',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
  ]
}
