import { Card, Divider, TabbedContent, TabbedContentProps } from '@msaf/core-react'
import { AuthStrategy, RequestOptions, SearchKeyProps, SearchTemplate } from '@msaf/generic-search-common'
import React, { Dispatch, SetStateAction } from 'react'
import { SavedSearchControl } from './saved-search'
import { Filters, FiltersProps } from './search-filters'

export interface ControlsCardProps<T extends AuthStrategy = 'token'>
  extends Omit<FiltersProps<T>, 'searchTemplate' | 'searchTypeKey'>,
    Pick<TabbedContentProps, 'onTabChange' | 'activeTab'> {
  activeSearchTemplate: SearchTemplate
  searchTypes: Array<SearchKeyProps>
  requestOptions?: RequestOptions<T>
  savedSearchFilters?: { label: string; filter: (row: any) => boolean }[]
  manageSavedSearchAction?: () => void
  selectedSavedSearchOption: { value: any; label: string } | undefined
  setSelectedSavedSearchOption: Dispatch<SetStateAction<{ value: any; label: string } | undefined>>
}

export function ControlsCard<T extends AuthStrategy = 'token'>({
  activeSearchTemplate,
  searchTypes,
  isLoading,
  isMapMode = false,
  actions,
  requestOptions,
  dateFormat,
  searchQuery,
  activeTab,
  onSearch,
  onTabChange,
  savedSearchFilters,
  manageSavedSearchAction,
  saveAction,
  selectedSavedSearchOption,
  setSelectedSavedSearchOption,
}: ControlsCardProps<T>) {
  const displaySaveSearchControl = onSearch && !!activeSearchTemplate && !activeSearchTemplate.savedSearchDisabled

  const tabs = searchTypes.map(({ label }) => {
    return {
      label: label,
      tabContent: (
        <>
          {displaySaveSearchControl && (
            <>
              <SavedSearchControl<T>
                onSearch={onSearch}
                searchTypeKey={activeSearchTemplate.searchTypeKey}
                searchQuery={searchQuery}
                requestOptions={requestOptions}
                savedSearchFilters={savedSearchFilters}
                manageSavedSearchAction={manageSavedSearchAction}
                selectedOption={selectedSavedSearchOption}
                setSelectedOption={setSelectedSavedSearchOption}
              />
              <Divider isFullWidth verticalSpacingSize='small' />
            </>
          )}
          <Filters<T>
            searchTemplate={activeSearchTemplate}
            searchTypeKey={activeSearchTemplate.searchTypeKey}
            isLoading={isLoading}
            isMapMode={isMapMode}
            onSearch={onSearch}
            actions={actions}
            requestOptions={requestOptions}
            dateFormat={dateFormat}
            searchQuery={searchQuery}
            saveAction={saveAction}
          />
        </>
      ),
    }
  })
  return (
    <Card isFullWidth hasTabbedContent>
      <TabbedContent
        borderBottomStyle='shadow'
        includeHorizontalPadding={true}
        verticalSpacing='none'
        ariaLabel='Search filters'
        onTabChange={onTabChange}
        activeTab={activeTab}
        tabs={tabs}
      />
    </Card>
  )
}
