import { editableFeatureLayer, MapUIConfig } from '@msaf/maps-common'
import * as L from 'leaflet'
import { useEffect } from 'react'

export function useDrawingInit(
  config: MapUIConfig,
  handlerSetup: (l: L.GeoJSON) => void,
  map?: L.Map,
  featureLayer?: L.GeoJSON,
  disabled?: boolean,
) {
  useEffect(() => {
    if (map && featureLayer) {
      if (!disabled) {
        // Make the layer editable
        editableFeatureLayer(config, featureLayer, false)
        map.pm.setGlobalOptions({
          ...map.pm.getGlobalOptions(),
          layerGroup: featureLayer,
        })
        const customTranslations = {
          tooltips: {
            finishLine: 'Click on last marker to finish',
          },
        }
        map.pm.setLang('en', customTranslations)
      }

      handlerSetup(featureLayer)
    }
  }, [map, featureLayer, disabled])
}
