import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { Button, DateField as DatePicker, FormField, InputWidth, Modal, TypeaheadOption } from '@msaf/core-react'
import { parseDate } from '@msaf/generic-search-common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DISPLAY_DATE_FORMAT } from '../constants'
import { ActionsMenuItemWithId, useAppContext } from '../contexts/app-context'
import { useFeatureFlags } from '../hooks/use-feature-flags'
import { useMutationWithToast, UseMutationWithToastProps } from '../hooks/use-mutation-with-toast'
import { usePlanSummary } from '../hooks/usePlanSummary'
import { useRequest } from '../hooks/useRequest'
import { ApplicationStatusFields } from '../routes/plans/[mode]/[id]/application/[applicationId]/submission'
import { usePermission } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../types/route'
import { useRedirect } from './gatekeeper'
import LookupSearch from './lookup-typeahead'

const useProjectActionMutation = ({
  isEnabled,
  id,
  label,
  ...props
}: Omit<UseMutationWithToastProps, 'method'> & { isEnabled: boolean | undefined; id: string; label: string }) => {
  const mutation = useMutationWithToast({ method: 'POST', ...props })

  return useMemo(() => {
    if (!isEnabled) return null
    return {
      id,
      label,
      action: () => mutation.mutate({}),
    }
  }, [mutation.mutate, id, label, isEnabled])
}

function useRegisterMenuApplicationActions(setWithdrawModalOpen: CallableFunction) {
  const { id: planId, mode, applicationId } = useParams<ApplicationRouteParams>()
  const { addPageActions, removePageActions } = useAppContext()
  const { client } = useRequest()
  const { refetch: refetchPlanSummary } = usePlanSummary()
  const hasReopenPermission = usePermission([UserPermissions.REOPEN_APPLICATION])
  const hasEnableLongTermMonitoringPermission = usePermission([UserPermissions.ENABLE_LONG_TERM_MONITORING])
  const navigateToEdit = useRedirect({ mode: RouteMode.EDIT })
  const navigateToView = useRedirect({ mode: RouteMode.VIEW })
  const { hasFlag } = useFeatureFlags()

  const url = `/api/plan/${planId}/project/${applicationId}/status`

  const { data: statusData, refetch: refetchStatus } = useQuery<ApplicationStatusFields>(
    [url, mode],
    async () => (await client.get(url, {})).data,
  )

  const withdrawEnabled = hasReopenPermission && hasFlag('withdraw_projects')

  const refetch = useCallback(() => {
    refetchStatus()
    refetchPlanSummary()
  }, [refetchPlanSummary, refetchStatus])

  const reopenAction = useProjectActionMutation({
    id: 'project-reopen',
    label: 'Reopen project',
    isEnabled: hasReopenPermission && statusData?.availableActions.includes('reopen'),
    url: `/api/plan/${planId}/project/${applicationId}/reopen`,
    success: {
      action: () => {
        refetch()
        navigateToEdit()
      },
      toastMessage: 'Project reopened for edit.',
    },
    error: { toastMessage: 'Failed to reopen project.' },
  })

  const closeAction = useProjectActionMutation({
    id: 'project-reclose',
    label: 'Revert project status',
    isEnabled: hasReopenPermission && statusData?.availableActions.includes('reclose'),
    url: `/api/plan/${planId}/project/${applicationId}/reclose`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project status has been reverted.',
    },
    error: { toastMessage: 'Failed to revert project status.' },
  })

  const resumeAction = useProjectActionMutation({
    id: 'project-resume',
    label: 'Resume project',
    isEnabled: withdrawEnabled && statusData?.availableActions.includes('resume'),
    url: `/api/plan/${planId}/project/${applicationId}/resume`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project has been resumed.',
    },
    error: { toastMessage: 'Failed to resume project.' },
  })

  const enableLongTermMonitoring = useProjectActionMutation({
    id: 'enable-ltm',
    label: 'Enable long term monitoring',
    isEnabled:
      hasEnableLongTermMonitoringPermission && statusData?.availableActions.includes('enable_long_term_monitoring'),
    url: `/api/plan/${planId}/project/${applicationId}/long-term-monitoring`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Long term monitoring has been enabled.',
    },
    error: { toastMessage: 'Failed to enable long term monitoring.' },
  })

  useEffect(() => {
    if (!addPageActions || !removePageActions) return

    const withdrawAction =
      withdrawEnabled && statusData?.availableActions.includes('withdraw')
        ? {
            id: 'project-withdraw',
            label: 'Withdraw project',
            action: () => setWithdrawModalOpen(),
          }
        : undefined

    // Ordering here will effect display order
    const actions: ActionsMenuItemWithId[] = [
      reopenAction,
      closeAction,
      resumeAction,
      withdrawAction,
      enableLongTermMonitoring,
    ].filter((a): a is ActionsMenuItemWithId => !!a)

    addPageActions(actions)
    return () => {
      removePageActions(actions.map((a) => a.id))
    }
  }, [
    addPageActions,
    removePageActions,
    statusData?.availableActions,
    reopenAction,
    closeAction,
    resumeAction,
    enableLongTermMonitoring,
  ])

  return { refetch, statusData }
}

export const ProjectActions = () => {
  const { id, applicationId } = useParams<ApplicationRouteParams>()
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
  const [modalRef, setModalRef] = useState<HTMLDivElement | undefined>()
  const navigateToView = useRedirect({ mode: RouteMode.VIEW })
  const [withdrawDate, setWithdrawDate] = useState(new Date())
  const [withdrawReason, setWithdrawReason] = useState<TypeaheadOption>()

  const { refetch, statusData } = useRegisterMenuApplicationActions(() => setWithdrawModalOpen(true))

  const withdrawMutation = useMutationWithToast<void, void, { startDate: string; reason?: string }, void>({
    method: 'POST',
    url: `/api/plan/${id}/project/${applicationId}/withdraw`,
    success: {
      action: () => {
        refetch()
        navigateToView()
      },
      toastMessage: 'Project has been withdrawn.',
    },
    error: { toastMessage: 'Failed to withdraw project.' },
  })
  return (
    <Modal
      contentRef={(ref) => setModalRef(ref)}
      isOpen={withdrawModalOpen}
      onRequestClose={() => setWithdrawModalOpen(false)}
      heading='Withdraw the project'
      body={
        <>
          <FormField labelId='withdrawalDate' label='Please provide a date for when the project was withdrawn.'>
            <DatePicker
              menuPortalTarget={modalRef}
              dateFormat={DISPLAY_DATE_FORMAT}
              fieldSize={InputWidth.large}
              selectedDate={withdrawDate}
              setSelectedDate={(date: Date) => {
                setWithdrawDate(date)
              }}
              maxDate={new Date()}
              minDate={statusData?.isMigrated ? null : parseDate(statusData?.createdDate)}
            />
          </FormField>
          <FormField labelId='withdrawalReason' label='Please provide a reason for withdrawing the project.'>
            <LookupSearch
              menuPortalTarget={modalRef}
              lookupId='project_withdrawal_reason'
              value={withdrawReason}
              setValue={(value: TypeaheadOption) => {
                setWithdrawReason(value)
              }}
              labelledBy='withdrawalReason'
            />
          </FormField>
        </>
      }
      contentLabel='Confirm feature deletion'
      buttons={
        <>
          <Button
            buttonStyle='primary'
            type='button'
            label='Withdraw'
            onClick={() => {
              withdrawMutation.mutate({
                startDate: formatDate(withdrawDate, INTERNAL_DATE_FORMAT),
                reason: withdrawReason?.value,
              })
              setWithdrawModalOpen(false)
            }}
          />
          <Button buttonStyle='text-action' type='button' label='Cancel' onClick={() => setWithdrawModalOpen(false)} />
        </>
      }
    />
  )
}
