import { format, startOfYear as _startOfYear } from 'date-fns'
import { DISPLAY_DATE_FORMAT } from '../constants'

/**
 * Formats a date to a given format
 * @param date Date to format
 * @param dateFormat Format string/template to use
 * @returns Formatted date, or `-` if no date is passed
 */
export function formatDate(date: string | Date | undefined, dateFormat: string = DISPLAY_DATE_FORMAT): string {
  return date ? format(new Date(date), dateFormat) : '-'
}

export function startOfYear(date: Date) {
  return _startOfYear(date)
}
