import React from 'react'
import { LegendOption, LegendOptionProps } from './legend-option'
import { LegendOptionGroup, LegendOptionGroupProps } from './legend-option-group'
import { Heading } from '../../../../common'

export interface LegendSectionProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  sectionOptions: Array<LegendOptionProps | LegendOptionGroupProps>
}

export function LegendSection({ heading, headingLevel = 4, sectionOptions }: LegendSectionProps) {
  return (
    <div className='c-legend-menu__section'>
      {heading && (
        <Heading className='c-legend-menu__heading' level={headingLevel}>
          {heading}
        </Heading>
      )}
      <ul className='c-legend-menu__items'>
        {sectionOptions.map((optionProps) => {
          if ('groupName' in optionProps) {
            return (
              <li key={optionProps.groupName} className='c-legend-menu__item'>
                <LegendOptionGroup key={optionProps.groupName} {...optionProps} />
              </li>
            )
          } else {
            return (
              <li key={optionProps.id} className='c-legend-menu__item'>
                <LegendOption {...optionProps} />
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}
