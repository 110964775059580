import { Dispatch, useState } from 'react'

export type UseNotSetExternalStateOptions = {
  externalState: undefined
  setExternalState: undefined
}

export type UseSetExternalStateOptions<T> = {
  externalState?: T
  setExternalState?: Dispatch<T>
}

export type UseExternalStateOptions<T> =
  | {
      initialValue: T
    } & (UseNotSetExternalStateOptions | UseSetExternalStateOptions<T>)

/* Use external state handlers if they are provided. Otherwise handle state internally. */
export function useOptionalExternalState<T>({
  initialValue,
  ...options
}: UseExternalStateOptions<T>): [T, Dispatch<T>] {
  const [internalState, setInternalState] = useState<T>(initialValue)

  if (options.externalState === undefined || options.setExternalState == undefined) {
    return [internalState, setInternalState]
  }
  return [options.externalState, options.setExternalState]
}
