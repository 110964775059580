import { TableSortByProps } from '@msaf/core-react'
import {
  createDefaultFilterSet,
  createFilterSet,
  FilterSetBase,
  RequestOptions,
  SearchQuery,
  SearchTemplate,
  SearchView,
  FilterSet,
  FilterInstance,
  AuthStrategy,
} from '@msaf/generic-search-common'
import { useLocation, useNavigate } from '@msaf/router-react'
import { useEffect, useState } from 'react'
import { useSearchResult } from './use-api'

export interface UseSearchProps<T extends AuthStrategy = 'token'> {
  searchTemplate?: SearchTemplate
  viewKey?: string
  pageSize: number
  requestOptions?: RequestOptions<T>
  queryParamViewKey?: string
  cacheSearchQuery?: (query: SearchQuery) => void
  filterBy?: FilterSetBase
  defaultSortBy?: TableSortByProps
  cachedSearchQuery?: SearchQuery
}

export type QueryParamSearchType = {
  searchTypeKey: string
  filters: FilterSetBase
}

const QUERY_PARAM_SEARCH_FILTERS = 'queryParamSearchFilters'

export function useSearch<T extends AuthStrategy = 'token'>({
  searchTemplate,
  cacheSearchQuery,
  pageSize,
  viewKey,
  requestOptions,
  filterBy,
  defaultSortBy,
  cachedSearchQuery,
}: UseSearchProps<T>) {
  const navigate = useNavigate()
  const { search: urlQueryParam } = useLocation()
  const [currentView, setCurrentView] = useState<SearchView>()
  // `searchQuery` includes the filters that have been applied through the UI
  const [searchQuery, setSearchQuery] = useState<SearchQuery>()
  // `effectiveSearchQuery` is the search query used to fetch the results
  // It includes the filters that have been applied through the UI and
  // any filter(s) that must be applied to further filter the results.
  const [effectiveSearchQuery, setEffectiveSearchQuery] = useState<SearchQuery>()

  const { isLoading: isSearching, data } = useSearchResult<T>(
    effectiveSearchQuery,
    requestOptions,
    !!effectiveSearchQuery,
  )

  const getViewFromKey = (key?: string) => {
    return searchTemplate?.views.find((view) => view.viewKey === key) ?? searchTemplate?.views[0]
  }

  const updateFilterQueryParams = (searchQuery: SearchQuery) => {
    const appliedFilters = searchQuery.filterSet
    const currentSearchTypeKey = searchQuery.searchTypeKey
    const filterString = JSON.stringify({
      searchTypeKey: currentSearchTypeKey,
      filters: appliedFilters,
    })
    const params = new URLSearchParams()
    if (appliedFilters?.filters.length) {
      // Set the url
      params.append(QUERY_PARAM_SEARCH_FILTERS, filterString)
    } else {
      // Reset the url
      params.delete(QUERY_PARAM_SEARCH_FILTERS)
    }
    return params.toString()
  }

  useEffect(() => {
    const getSearchQuery = (searchTemplate: SearchTemplate): SearchQuery => {
      let filterSet: FilterSetBase | undefined
      // Check if the url has search filters
      const queryParamSearchFilters = new URLSearchParams(urlQueryParam).get(QUERY_PARAM_SEARCH_FILTERS)
      if (searchTemplate.allowsQueryParamFiltering && queryParamSearchFilters) {
        const { searchTypeKey, filters } = JSON.parse(queryParamSearchFilters) as QueryParamSearchType
        // Check if the search type key passed in params match the current search template
        // This helps in preventing passing 'Bad filter' to the server
        if (searchTypeKey === searchTemplate.searchTypeKey) {
          filterSet = filters
        }
      }

      if (!filterSet) {
        const defaultFilters: (FilterSet | FilterInstance)[] = createDefaultFilterSet(searchTemplate.filters)
        const defaultFilterSet = defaultFilters.length > 0 && createFilterSet(defaultFilters, 'AND')
        const filter = defaultFilterSet || createFilterSet([], 'AND')
        filterSet = filter.filterSet
      }

      return {
        viewKey: viewKey ?? searchTemplate.views[0].viewKey,
        searchTypeKey: searchTemplate.searchTypeKey,
        requestedPage: 1,
        requestedPageLength: searchTemplate?.requestedPageLength ?? pageSize,
        filterSet,
        ordering: defaultSortBy
          ? [
              {
                elementKey: defaultSortBy.orderColumn,
                direction: defaultSortBy.orderDirection ?? 'asc',
              },
            ]
          : [],
      }
    }

    if (searchTemplate) {
      // Reset the search query when the search template and/or view key changes
      // For instance, when switching from map view to table view of advanced search
      let query: SearchQuery

      /**
       * Build the query from cache only if no query params have been passed in the url
       * and a cached query matching the current search template exists
       * NOTE: Query params passed in the url take precedence over cached query
       */
      if (!urlQueryParam && cachedSearchQuery?.searchTypeKey === searchTemplate.searchTypeKey) {
        // Ensure that the search type key of the cached search query and the current search template are the same
        // Set the cached query as the search query.
        query = cachedSearchQuery
        // if the cached query has a different view key then the provided view key
        // we update the view key in the query. This should be safe to do as we have already
        // verified the cached query and the search template shared the same config/search type key
        if (viewKey && query.viewKey !== viewKey) {
          query = {
            ...query,
            viewKey,
          }
        }
      } else {
        // Get search query from url params, if present
        // otherwise build a search query with empty search filters
        query = getSearchQuery(searchTemplate)
      }
      setSearchQuery(query)
      // Update the view object every time the search template or view key changes
      // This is to avoid iterating over the search template everytime to get the view object
      // for the active view key
      setCurrentView(getViewFromKey(query.viewKey))
    }
    // Excluded `search` and `cachedSearchQuery` to prevent additional re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTemplate, viewKey, pageSize])

  // When the searchQuery changes
  useEffect(() => {
    if (searchQuery) {
      // Cache the search query
      cacheSearchQuery?.(searchQuery)

      // Add the search filters to the url as query params, if enabled
      if (searchTemplate?.allowsQueryParamFiltering) {
        const queryParams = updateFilterQueryParams(searchQuery)
        navigate({ search: queryParams })
      }

      // Update the effective search query that will eventually be used for the search
      let effectiveSearchQuery: SearchQuery = {
        ...searchQuery,
        viewKey: viewKey ?? searchQuery.viewKey,
      }

      // Update the requested page length in case of map mode, so that the `searchQuery`
      // always uses the pageSize from props
      if (searchTemplate?.hasMapMode) {
        effectiveSearchQuery = {
          ...effectiveSearchQuery,
          requestedPageLength: pageSize,
        }
      }

      // If a `filterBy` filter set is present, we add it to the existing search query filters
      if (filterBy) {
        // Add the filters passed in to the applied filters
        const effectiveFilters = [...searchQuery.filterSet.filters, ...filterBy.filters]
        const effectiveFilterSet = { ...searchQuery.filterSet, filters: effectiveFilters }
        effectiveSearchQuery = {
          ...effectiveSearchQuery,
          filterSet: effectiveFilterSet,
        }
      }

      setEffectiveSearchQuery(effectiveSearchQuery)
    }
    // Excluded `filterBy`and `cacheSearchQuery` object from the dependency list to prevent unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  function updateQuery(newQuery: SearchQuery, resetToFirstPage?: boolean) {
    const query: SearchQuery = { ...searchQuery, ...newQuery }

    if (resetToFirstPage) {
      query.requestedPage = 1
    }

    setSearchQuery(query)
  }

  function setSortColumn(orderColumn: string, orderDirection: 'asc' | 'desc' = 'asc') {
    searchQuery &&
      setSearchQuery({
        ...searchQuery,
        ordering: [
          {
            elementKey: orderColumn,
            direction: orderDirection,
          },
        ],
      })
  }

  function setCurrentPage(page: number) {
    if (!searchQuery) {
      throw new Error('searchQuery must be set to change the page')
    }

    // Update the search query only if the provided page number is within the range
    if (page > 0 && data?.searchResult.totalRows && page <= Math.ceil(data.searchResult.totalRows / pageSize)) {
      setSearchQuery({
        ...searchQuery,
        requestedPage: page,
      })
    }
  }

  return {
    // Query modifiers
    updateQuery,
    setSearchQuery,
    setCurrentPage,
    setSortColumn,
    updateFilterQueryParams,

    // State
    isSearching,
    searchResults: data?.searchResult,
    searchQuery: searchQuery || effectiveSearchQuery,
    currentView,
    searchTemplate,
  }
}
