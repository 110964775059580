import { GroupContainer, LabelledGroup, Pill } from '@msaf/core-react'
import { FeatureFilterGroup } from '../../feature-filter-groups/types'

export function FeatureFilterGroupPills({
  label,
  isSkeleton,
  filterObjects,
  toggleFilterObject,
}: FeatureFilterGroup & { isSkeleton?: boolean }) {
  return (
    <LabelledGroup labelText={label} labelTag='legend' labelStyle='uppercase'>
      <GroupContainer>
        {filterObjects.map((item) => {
          return (
            <Pill
              key={`pill-project-${item.id}`}
              label={item.name}
              isSelected={item.isSelected}
              isSkeleton={isSkeleton}
              handleClick={() => toggleFilterObject(item)}
            />
          )
        })}
      </GroupContainer>
    </LabelledGroup>
  )
}
