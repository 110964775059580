import React, { useEffect, useMemo, useState } from 'react'
import { Card, TableSortByProps, ConditionalCardWrapper } from '@msaf/core-react'
import { ControlsCard } from './controls-card'
import { ResultsHeader } from './results-header'
import { ResultView, ResultViewProps, TableView } from './results'

import { useSearchUI } from '../../hooks/use-search-ui'
import { DISPLAY_DATE_FORMAT } from '@msaf/core-common'
import { AuthStrategy, CommonAdvancedSearchProps } from '@msaf/generic-search-common'
import { SaveSearchModal, SaveSearchModalProps } from './save-search/modal/save-search-modal'

export interface ExportConfigProps {
  id: string
  label: string
  url: string
  searchTypeKey?: string
  viewKey: string
  exportFileName?: string
  csrfToken?: string
}

// Defines a way for results to be displayed. All searches always get the
// list view, and it is possible to provide customised result displays
// that are either shown for all search types (if searchTypeKey is undefined)
// or only for specific ones.
export interface ResultViewConfig {
  name: string
  component: ResultView
  searchTypeKeys?: string | Array<string>
}
export const viewIsValidForSearchType = (config: ResultViewConfig, name?: string) => {
  return (
    !config.searchTypeKeys ||
    (Array.isArray(config.searchTypeKeys) && name && config.searchTypeKeys.includes(name)) ||
    config.searchTypeKeys === name
  )
}
const DEFAULT_RESULT_VIEW = {
  name: 'list',
  component: TableView,
}

export interface AdvancedSearchProps<T extends AuthStrategy = 'token'> extends CommonAdvancedSearchProps<T> {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  showResultsOnly?: boolean
  customActions?: { [key: string]: (...args: any[]) => void }
  currentUserPermissions?: Array<string>
  showResultsInCard?: boolean
  defaultSortBy: TableSortByProps
  resultNameSingular?: string
  resultNamePlural?: string
  showResultsCount?: boolean
  noResultsMessage?: string
  exportConfig?: Array<ExportConfigProps>
  savedSearchFilters?: { label: string; filter: (row: any) => boolean }[]
  resultViews?: Array<ResultViewConfig>
  defaultView?: ResultViewConfig
  saveSearchModal?: SaveSearchModal
  manageSavedSearchAction?: () => void
}

export function AdvancedSearch<T extends AuthStrategy = 'token'>(props: AdvancedSearchProps<T>) {
  const {
    heading,
    searchTypes,
    pageSize,
    filterBy,
    viewKey,
    defaultSortBy,
    exportConfig,
    requestOptions,
    savedSearchFilters,
  } = props
  const searchUIProps = {
    pageSize,
    filterBy,
    defaultSortBy,
    viewKey,
    requestOptions,
    searchTypes,
  }
  const searchUIResponse = useSearchUI<T>(searchUIProps)
  const { dateFormat, showResultsInCard = true, headingLevel } = props
  const { searchTemplate, switchToMapView, showFilters, onTabChange, activeTab, searchQuery, updateQuery } =
    searchUIResponse

  const showResultsHeading = heading || switchToMapView || exportConfig
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false)

  const viewProps: ResultViewProps = {
    isSkeleton: searchUIResponse.isLoading,
    ...props,
    ...searchUIResponse,
    ...searchUIProps,
  }

  const defaultView = useMemo<ResultViewConfig>(() => props.defaultView ?? DEFAULT_RESULT_VIEW, [props.defaultView])

  // Add the list view to the available result views, and make it the default
  const allResultViews = useMemo<Array<ResultViewConfig>>(
    () => (props.resultViews ?? []).concat([defaultView]),
    [props.resultViews],
  )
  const [currentResultView, setCurrentResultView] = useState<ResultViewConfig>(defaultView)

  // If the active search tab changes then we need to ensure we don't have a
  // result view visible that shouldn't be available for the new tab
  useEffect(() => {
    if (!viewIsValidForSearchType(currentResultView, searchQuery?.searchTypeKey)) {
      setCurrentResultView(DEFAULT_RESULT_VIEW)
    }
  }, [searchQuery?.searchTypeKey, currentResultView])

  const ResultViewComponent = currentResultView?.component ?? TableView
  const [selectedSavedSearchOption, setSelectedSavedSearchOption] = useState<
    { label: string; value: any } | undefined
  >()

  // If the active search tab changes, reset selected saved search option
  useEffect(() => {
    if (selectedSavedSearchOption) {
      setSelectedSavedSearchOption({ label: 'Please select' + String.fromCharCode(8230), value: '' })
    }
  }, [searchQuery?.searchTypeKey])

  const saveSearchModalProps: SaveSearchModalProps<T> = {
    isOpen: isSaveSearchModalOpen,
    onRequestClose: () => setIsSaveSearchModalOpen(false),
    searchQuery,
    requestOptions,
    setSelectedOption: setSelectedSavedSearchOption,
  }

  const SaveSearchModalComponent = props.saveSearchModal ?? SaveSearchModal

  return (
    <>
      {searchTemplate?.savedSearchDisabled === false && <SaveSearchModalComponent<T> {...saveSearchModalProps} />}
      {showFilters && searchTemplate && (
        <ControlsCard<T>
          searchTypes={searchTypes}
          activeSearchTemplate={searchTemplate}
          onTabChange={onTabChange}
          activeTab={activeTab}
          onSearch={updateQuery}
          requestOptions={requestOptions}
          dateFormat={dateFormat ?? DISPLAY_DATE_FORMAT}
          searchQuery={searchQuery}
          saveAction={() => setIsSaveSearchModalOpen(true)}
          actions={{
            switchToMapView,
          }}
          savedSearchFilters={savedSearchFilters}
          manageSavedSearchAction={props.manageSavedSearchAction}
          selectedSavedSearchOption={selectedSavedSearchOption}
          setSelectedSavedSearchOption={setSelectedSavedSearchOption}
        />
      )}
      <ConditionalCardWrapper condition={showResultsInCard} wrapper={(children) => <Card isFullWidth>{children}</Card>}>
        <>
          {showResultsHeading && (
            <ResultsHeader<T>
              heading={heading}
              headingLevel={headingLevel}
              exportConfig={exportConfig}
              mapPath={searchTemplate?.mapConfig?.mapSearchRoute}
              searchQuery={searchQuery}
              requestOptions={requestOptions}
              resultViews={allResultViews}
              currentResultView={currentResultView}
              changeResultView={setCurrentResultView}
            />
          )}
          <ResultViewComponent {...viewProps} />
        </>
      </ConditionalCardWrapper>
    </>
  )
}
