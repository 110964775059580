import { FeatureCollection } from '@turf/helpers'
import { createFeatureLayer } from '../features'
import MapUIConfig, { OverrideConfig } from '../types/config'
import { MAP_DEFAULTS } from './projection'
import * as L from 'leaflet'

export interface MapCreationOptions extends L.MapOptions {
  // Base geo info
  baseLayers?: L.LayerGroup
  features?: L.FeatureGroup

  // Defaults
  zoomControlPosition?: L.ControlPosition

  // Editable
  editable?: boolean
}

export function createMapAndBaseLayers(
  mapElement: HTMLElement,
  {
    center = MAP_DEFAULTS.DEFAULT_CENTER,
    crs = MAP_DEFAULTS.CRS,
    zoom = MAP_DEFAULTS.DEFAULT_ZOOM,
    minZoom = MAP_DEFAULTS.MIN_ZOOM,
    maxZoom = MAP_DEFAULTS.MAX_ZOOM,
    zoomControlPosition = 'topright',
    editable = false,
    baseLayers,
    features,
    ...mapOptions
  }: MapCreationOptions,
): L.Map {
  const map = L.map(mapElement, {
    center,
    crs,
    zoom,
    minZoom,
    maxZoom,
    pmIgnore: !editable,
    ...mapOptions,
  })

  map.zoomControl?.setPosition(zoomControlPosition)

  if (baseLayers) {
    baseLayers.addTo(map)
  }

  features && map.addLayer(features)

  return map
}

export interface MapViewOptions {
  zoomLevel?: number
  bounds?: L.LatLngBoundsExpression
}

export function setMapViewWithMarker(map: L.Map, marker: L.Marker<any>, position: L.LatLng, options?: MapViewOptions) {
  setMapView(map, position, options)
  // Add marker at the position
  marker.addTo(map)
}

export function setMapView(map: L.Map, position: L.LatLng, options?: MapViewOptions): void {
  map.setView(position, options?.zoomLevel)

  if (options?.bounds) {
    map.fitBounds(options.bounds)
  }
}

export function constructBasemapLayers(config: MapUIConfig) {
  return new L.LayerGroup(
    config.basemapLayers.map((l) =>
      L.tileLayer(l.url, {
        attribution: l.attribution,
        maxZoom: l.maxZoom,
        minZoom: l.minZoom,
        ...(l.layerOptions ?? {}),
      }),
    ),
  )
}

/*
Gets the size of a Polyline as it appears on a map.
*/
function getPolylineSize(map: L.Map, layer: L.Polyline) {
  const topLeft = map.latLngToContainerPoint(layer.getBounds().getNorthWest())
  const bottomRight = map.latLngToContainerPoint(layer.getBounds().getSouthEast())
  const width = bottomRight.x - topLeft.x
  const height = bottomRight.y - topLeft.y
  return { width, height }
}

/*
Hide a Polyline's tooltip if it's bounds exceed the bounds of the Polyline.
*/
export function hideOverflowingPolylineTooltip(map: L.Map, layer: L.Polyline) {
  const node: HTMLElement | undefined = layer.getTooltip()?.getElement()
  if (!node) return
  const layerSize = getPolylineSize(map, layer)
  const tooltipBoundary = node.getBoundingClientRect()

  // Hide the object with opacity if its size is larger than the size of the layer
  node.style.opacity = layerSize.width < tooltipBoundary.width || layerSize.height < tooltipBoundary.height ? '0' : '1'
}

export function configureMapForDisplay(
  mapElement: HTMLElement,
  config: MapUIConfig,
  { features, overrides }: { features?: FeatureCollection; overrides?: OverrideConfig },
) {
  let featureLayer = features ? createFeatureLayer(config, features, overrides?.classMappings) : null

  return createMapAndBaseLayers(mapElement, {
    crs: config.crs,
    zoom: config.defaultZoom,
    minZoom: config.basemapLayers[0].minZoom,
    maxZoom: config.basemapLayers[0].maxZoom,
    center: featureLayer ? featureLayer.getBounds().getCenter() : overrides?.center ?? MAP_DEFAULTS.DEFAULT_CENTER,
    baseLayers: constructBasemapLayers(config),
  })
}
