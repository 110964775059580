import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RouteMode, RouteParams } from '../types/route'
import { useQuery } from 'react-query'
import { useRedirect, useViewRedirectGatekeeper } from '../components/gatekeeper'
import { useRequest } from './useRequest'
import { EDIT_PLAN_PERMISSIONS, UserPermissions } from '../types/permissions'

export function usePlanPermissions() {
  const { id } = useParams<RouteParams>()
  const { client } = useRequest()

  const { hasEditPermission, hasViewPermission, isLoading } = useViewRedirectGatekeeper(
    EDIT_PLAN_PERMISSIONS,
    UserPermissions.VIEW_PLANS,
  )

  const permissions_url = `/api/plan/${id}/permissions`
  const { data: permissions, isLoading: isLoadingPermissions } = useQuery<{ canEdit: boolean }>([permissions_url], () =>
    client.get(permissions_url).then((response) => response.data),
  )

  const canEdit = permissions?.canEdit ?? false

  const navigate = useRedirect({ mode: RouteMode.VIEW })

  useEffect(() => {
    if (!isLoadingPermissions && !canEdit) {
      navigate()
    }
  }, [isLoadingPermissions, canEdit])

  return {
    hasEditPermission: hasEditPermission && canEdit,
    hasViewPermission,
    isLoading: isLoading || isLoadingPermissions,
  }
}
