import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { Form } from '../../../../../../forms/components/form'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import { PreChecksFields, usePreChecksForm } from './pre-checks.form'

export function PreChecks() {
  const { id, mode } = useParams<RouteParams>()
  const { hasEditPermission, isMigratedPlan } = usePlanState()

  const { data, isLoading, errors, refetchErrors } = useFormState<PreChecksFields>(
    ([id]) => `/api/plan/${id}/pre-checks`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'preChecks',
      instanceId: `${id}`,
    },
  )

  const mutation = useMutationWithToast<
    AxiosResponse<PreChecksFields>,
    AxiosError<Map<keyof PreChecksFields, Array<string>>>,
    PreChecksFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/pre-checks`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save plan.',
    },
  })

  const isFonterraPlan = data?.isFonterraPlan
  const config = usePreChecksForm(isMigratedPlan, isFonterraPlan)

  const onSubmit = async (data: PreChecksFields) => {
    await mutation.mutateAsync(data)
  }

  const initialState = useMemo(() => {
    return { ...data, fieldAdvisors: !!data?.fieldAdvisors?.length ? data?.fieldAdvisors : [{}] }
  }, [data])

  return (
    <Form<PreChecksFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      nextPath={`/plans/${mode}/${id}/plan/applicant`}
      canEdit={hasEditPermission}
      config={config}
      submitAction={onSubmit}
    />
  )
}
