import { useContext, useEffect, useState } from 'react'
import {
  FeatureFilterGroup,
  FeatureFilterFunction,
  FeatureWithProperties,
  FilterObject,
  FilterObjectWithSelection,
  FilterFunctionBuilder,
  FeatureToFilterMapper,
} from './types'
import { FeatureMapContext } from '../context'
import isEqual from 'lodash.isequal'

export type UseFeatureFilterProps = {
  key: string
  label: string
  filterFunctionBuilder: FilterFunctionBuilder
} & ( // Can either provide the filter objects directly or a mapper to convert features to filter objects.
  | {
      featureToFilterObjects: FeatureToFilterMapper
    }
  | { filterObjects: FilterObject[] }
)

export const useRegisterFeatureFilterGroup = ({
  key,
  label,
  filterFunctionBuilder,
  ...props
}: UseFeatureFilterProps) => {
  const { collectionState, upsertFilterGroup, toggleAll } = useContext(FeatureMapContext)
  const features = collectionState?.collections?.unfiltered?.features

  const [filterObjects, setFilterObjects] = useState<FilterObjectWithSelection[]>([])
  const toggleFilterObject = (updateItem: FilterObjectWithSelection) =>
    setFilterObjects((items) =>
      items.map((item) => (item.id == updateItem.id ? { ...item, isSelected: !item.isSelected } : item)),
    )

  useEffect(() => {
    const newFilterObjects: FilterObject[] = []

    // Use the filterObjects if provided or a featureToFilterMapper if not.
    if ('filterObjects' in props) {
      newFilterObjects.push(...props.filterObjects)
    } else {
      // If the features don't exist or are empty we can't the filter objects.
      if (features == undefined || features.length === 0) {
        return
      }
      newFilterObjects.push(
        ...(props
          .featureToFilterObjects(features.filter((feature) => feature.properties) as FeatureWithProperties)
          // This looks confusing, all it does is filters the list for items with unique ids.
          .filter((p, idx, array) => array.indexOf(array.find((q) => q.id === p.id) as FilterObject) === idx)
          .sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }) ?? []),
      )
    }

    // Only update the objects if they are different otherwise we trigger a complete refilter of features.
    if (
      !isEqual(
        newFilterObjects,
        filterObjects.map((fo) => ({ id: fo.id, name: fo.name })),
      )
    ) {
      setFilterObjects((prevFilterObjects) =>
        newFilterObjects.map((fo) => ({
          ...fo,
          // Pull the state from the previous list otherwise if its new default to on.
          isSelected: prevFilterObjects.find((prevFo) => prevFo.id === fo.id)?.isSelected ?? true,
        })),
      )
    }
  }, [features])

  useEffect(() => {
    // Its undefined, so we should't toggle anything.
    if (toggleAll == null) return
    setFilterObjects((items) => items.map((item) => ({ ...item, isSelected: toggleAll })))
  }, [toggleAll])

  useEffect(() => {
    const filterFunction: FeatureFilterFunction | null = filterObjects.every((item) => item.isSelected)
      ? null
      : (feature) => filterFunctionBuilder(feature, filterObjects)

    const featureFilter: FeatureFilterGroup = {
      key,
      label,
      filterObjects,
      toggleFilterObject,
      filterFunction,
    }
    upsertFilterGroup?.(featureFilter)
    /* This will need to be done if we ever want to remove filters.
     * Currently this causes issues though because it will cause a re-ordering
     * of the filters and more of a re-render than is necessary. */
    // return () => removeFilterGroup?.(featureFilter)
  }, [filterObjects])
}
