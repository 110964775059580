import {
  Button,
  UploadedFile as CoreUploadedFile,
  DownloadAction,
  FileManager,
  FileManagerProps,
  FormField,
  LoadingIndicator,
  Modal,
  NewFile,
  NewOrUploadedFile,
  TextInput,
  buildDefaultValidators,
} from '@msaf/core-react'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useFileUpload } from '../../../hooks/useFileUpload'
import { useRequest } from '../../../hooks/useRequest'
import { useDisplayFileSelection } from './hooks'
import { useDownloadFile } from '../../../utils/files'

export type UploadedFile = CoreUploadedFile & { name?: string; mimeType?: string }

export interface ControlledFileManagerProps<TFieldValues extends FieldValues>
  extends Omit<FileManagerProps, 'files' | 'fileSaveAction'> {
  control: Control<TFieldValues>
  name: Path<TFieldValues>
}

const VALIDATORS = buildDefaultValidators({ size: 200 })

export const ControlledFileManager = <TFieldValues extends FieldValues>({
  control,
  name,
  isSkeleton,
  ...props
}: ControlledFileManagerProps<TFieldValues>) => {
  const { client } = useRequest()
  const uploadFileAction = useFileUpload(client)
  const { resolveAndDownloadFile } = useDownloadFile()

  const fileToUploadedFields = (file: UploadedFile | NewFile) => ({
    id: file.id,
    name: 'name' in file ? file.name : '',
  })

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        /*
          We need a local state to track the files because we need to be able to use the
          callback method for setting the state. On upload we can get multiple updates per
          render which results in files being lost if the callback update isn't use.

          Unfortunately however this makes it a bit of a juggle to manage the state.
        */
        const [fileState, setFileState] = useState<(UploadedFile | NewFile)[]>(
          control._options.defaultValues?.files ?? [],
        )

        const { displayFile, displayImageUrl, nextDisplayFile, prevDisplayFile, setDisplayFile } =
          useDisplayFileSelection(fileState)

        useEffect(() => {
          // Convert the file state to the format that the server needs
          const newValue = fileState.map(fileToUploadedFields)
          /*
            Map the existing field value to the same values to ensure we don't
            make the form dirty if the values are the same.
            */
          const existingValue = field.value?.map(fileToUploadedFields) ?? []
          // Exit if no change
          if (isEqual(newValue, existingValue)) return

          field.onChange(newValue)
        }, [fileState])

        // If the defaultValues changed we are probably on a new form.
        useEffect(() => {
          if (control._options.defaultValues?.files != null) setFileState(control._options.defaultValues?.files)
        }, [control._options.defaultValues?.files])

        const addFile = useCallback(
          (file: NewOrUploadedFile) => setFileState((prev) => [...prev, file]),
          [setFileState],
        )

        const removeFile = useCallback(
          (file: NewOrUploadedFile) => setFileState((prev) => prev.filter((f) => f.id !== file.id)),
          [setFileState],
        )

        const updateFile = useCallback(
          (id: string, updateCallback: (current: NewOrUploadedFile) => NewOrUploadedFile) =>
            setFileState((prev) => prev.map((f) => (f.id === id ? updateCallback(f) : f))),
          [setFileState],
        )

        const replaceFile = useCallback(
          (id: string, newFile: NewOrUploadedFile) =>
            setFileState((prev) => prev.map((f) => (f.id === id ? newFile : f))),
          [setFileState],
        )

        // Used to get actual file URLs that will be pulled form s3
        // Files only last 3 seconds.
        const getMetadataElement = useCallback(
          (file: UploadedFile | NewFile, isEditable: boolean) => {
            const fieldId = `fileName.${file.id}`
            const name = 'name' in file ? file.name : ''
            return (
              <FormField label='Name' htmlFor={fieldId}>
                <TextInput
                  isSkeleton={isSkeleton}
                  isDisabled={!isEditable}
                  id={fieldId}
                  type='text'
                  placeholder=''
                  onChange={(e) => updateFile(file.id, (f) => ({ ...f, name: e.target.value }))}
                  value={name}
                />
              </FormField>
            )
          },
          [updateFile, isSkeleton],
        )

        const handleKeyDown = useCallback(
          (e: KeyboardEvent) => {
            // Don't do anything if we aren't already displaying a file.
            if (!displayFile) return
            if (e.code === 'ArrowRight') nextDisplayFile()
            if (e.code === 'ArrowLeft') prevDisplayFile()
          },
          [nextDisplayFile, prevDisplayFile, displayFile],
        )

        useEffect(() => {
          document.addEventListener('keydown', handleKeyDown)

          return () => {
            document.removeEventListener('keydown', handleKeyDown)
          }
        }, [handleKeyDown])

        const imageTitle = useMemo(() => {
          if (displayFile?.name && displayFile?.name !== '') return displayFile?.name
          if (displayFile?.originalName && displayFile.originalName !== '') return displayFile?.originalName
          return 'Image'
        }, [displayFile?.name, displayFile?.originalName])

        return (
          <>
            <Modal
              isOpen={displayFile !== null}
              onRequestClose={() => setDisplayFile(null)}
              contentLabel={imageTitle}
              className='c-modal-image'
            >
              {displayFile &&
                (displayImageUrl ? (
                  <>
                    <div className='c-modal-image__header'>
                      <h2>{imageTitle}</h2>
                      <Button
                        className='c-model__download'
                        icon='download'
                        iconAriaLabel='Download'
                        onClick={() => resolveAndDownloadFile(displayFile)}
                      />
                      <a rel='noopener noreferrer' target='_blank' href={`/direct-image/${displayFile.uuid}`}>
                        <Button icon={'export'} iconAriaLabel='Open externally' buttonStyle='secondary' />
                      </a>
                      <Button
                        icon='close'
                        iconAriaLabel='Close'
                        onClick={() => setDisplayFile(null)}
                        buttonStyle='secondary'
                      />
                    </div>
                    <img
                      onClick={() => {
                        nextDisplayFile()
                      }}
                      className='c-modal__image'
                      src={displayImageUrl}
                    />
                  </>
                ) : (
                  <LoadingIndicator />
                ))}
            </Modal>
            <FileManager
              {...props}
              isSkeleton={isSkeleton}
              files={fileState}
              onFileUploading={addFile}
              onFileUploaded={(uploadedFile, tempId) => replaceFile(tempId, uploadedFile)}
              onFileRemove={removeFile}
              getMetadataElement={getMetadataElement}
              fileSaveAction={uploadFileAction}
              fileValidators={VALIDATORS}
              downloadAction={
                ((file: UploadedFile) => {
                  if (file.mimeType?.startsWith('image/')) {
                    setDisplayFile(file)
                  } else {
                    resolveAndDownloadFile(file)
                  }
                }) as DownloadAction
              }
            />
          </>
        )
      }}
    />
  )
}
