import { TabItemProps, usePageState } from '@msaf/core-react'
import { useEffect } from 'react'

export const usePageHeaderTabs = (headerTabs: TabItemProps[]) => {
  const { setPageState } = usePageState()

  useEffect(() => {
    setPageState((prevState) => ({ ...prevState, headerTabs }))
    return () => {
      setPageState((prevState) => ({ ...prevState, headerTabs: [] }))
    }
  }, [setPageState, headerTabs])
}
