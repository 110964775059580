import { AuthEventHandler, AuthStrategy, LoginOptions, LogoutOptions } from '../types'

/* eslint-disable @typescript-eslint/no-empty-function */

export abstract class AuthMethod {
  token?: string
  abstract tokenIdentifier: string
  _eventHandler: AuthEventHandler = () => {}

  constructor(eventHandler?: AuthEventHandler) {
    if (eventHandler) this._eventHandler = eventHandler
  }

  abstract use: () => boolean
  abstract refresh: () => Promise<void>
  abstract login: (options?: LoginOptions) => Promise<void>
  abstract logout: (options?: LogoutOptions) => Promise<void>
  init = () => {}

  setEventHandler = (eventHandler: AuthEventHandler) => {
    this._eventHandler = eventHandler
  }

  authStrategy() {
    if (this.token === undefined) throw Error('AuthStrategy cannot be created without a token.')
    return new AuthStrategy(this.tokenIdentifier, this.token)
  }
}
