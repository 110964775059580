import { PropsWithChildren } from 'react'
import { BaseApplicationWorkflow } from '.'
import { UserPermissions } from '../../types/permissions'
import { useViewRedirectGatekeeper } from '../gatekeeper'

interface WorkflowProps {
  recordId: string
}

export const PaymentsWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  useViewRedirectGatekeeper(UserPermissions.EDIT_GRANT_PAYMENT, UserPermissions.VIEW_GRANT_PAYMENT)

  return (
    <BaseApplicationWorkflow recordId={recordId} workflow={'payment'} noResultsText={'Not signed off'}>
      {children}
    </BaseApplicationWorkflow>
  )
}
