import ErrorPage from './error-page'

interface NotFoundProps {
  inPage?: boolean
}

export default function NotFound({ inPage = false }: NotFoundProps) {
  return (
    <ErrorPage inPage={inPage} headingText='Page not found' supportingText='Looks like this page cannot be found.' />
  )
}
