import { usePageState } from '@msaf/core-react'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useGatekeeper } from '../../components/gatekeeper'
import { usePageHeading } from '../../hooks/usePageHeading'
import { UserPermissions } from '../../types/permissions'

export const Administration = () => {
  useGatekeeper(UserPermissions.MANAGE_USERS)

  usePageHeading('Administration')
  const { setPageState } = usePageState()

  useEffect(() => {
    setPageState({
      heading: 'Administration',
      headerTabs: [
        {
          label: 'Users',
          path: `/admin/users`,
        },
      ],
    })
    // Reset page state on unmount
    return () => setPageState({})
  }, [setPageState])

  return <Outlet />
}
