import * as L from 'leaflet'
import MapUIConfig, { PointFeature, PolygonFeature, PolylineFeature } from '../types/config'
import { Feature, FeatureCollection } from '@turf/helpers'
import { compositionToDashArray, getConfigForFeature } from './utils'
import { hideOverflowingPolylineTooltip } from '../utils/map'

export const getMarkerOptions = (
  featureConfig: PointFeature,
  customClassMap?: Record<string, string>,
  customClassName?: string,
): L.MarkerOptions => {
  // Narrow type - check if this is the point type that has a path
  if ('iconFilePath' in featureConfig) {
    return {
      // Question: Do we make the size configurable?
      icon: new L.Icon({
        iconUrl: featureConfig.iconFilePath,
        iconSize: new L.Point(24, 24),
        className: customClassName,
      }),
    }
  }
  const divClassNames = ['c-marker-icon']
  if (featureConfig.markerStyle) {
    divClassNames.push(`c-marker-icon--${featureConfig.markerStyle}`)
  }

  if (featureConfig.customType) {
    if (customClassMap == undefined || !(featureConfig.customType in customClassMap)) {
      throw new Error('MAP-3: Unable to match passed type to a class')
    }

    divClassNames.push(customClassMap[featureConfig.customType])
  }

  if (customClassName) {
    divClassNames.push(customClassName)
  }

  const divClassName = divClassNames.join(' ')

  return {
    icon: L.divIcon({
      className: divClassName,
      html: `${featureConfig.initials}`,
      iconSize: new L.Point(24, 24),
    }),
  }
}

export const getPolylinePathOptions = (fCfg: PolylineFeature): L.PathOptions => {
  return {
    weight: fCfg.weight ?? 3,
    color: fCfg.color,
    dashArray: compositionToDashArray(fCfg.composition, fCfg.weight ?? 3),
  }
}

export const getPolygonPathOptions = (fCfg: PolygonFeature) => {
  let style: L.PathOptions = {
    color: fCfg.borderColor,
  }
  if (fCfg.noFill) {
    style.fillOpacity = 0
  } else {
    style['fillColor'] = fCfg.fillColor ?? fCfg.borderColor
    style['fillOpacity'] = fCfg.fillOpacity ?? 0.6
  }

  style['weight'] = fCfg.weight ?? 3

  return style
}

export const getFeatureStyle = (config: MapUIConfig, feature?: Feature<any, any>): L.PathOptions => {
  const fCfg = getConfigForFeature(config, feature)

  if (fCfg.geometryType === 'LineString') {
    return getPolylinePathOptions(fCfg)
  }

  if (fCfg.geometryType === 'Polygon') {
    return getPolygonPathOptions(fCfg)
  }

  return {}
}

export const styleFeatureLayer = (layer: L.GeoJSON, config: MapUIConfig) => {
  layer.setStyle((feature) => getFeatureStyle(config, feature))
}

export const createFeatureLayer = (
  config: MapUIConfig,
  features: FeatureCollection,
  customClassMap?: Record<string, string>,
) =>
  L.geoJSON(features, {
    style: (feature): L.PathOptions => getFeatureStyle(config, feature),
    onEachFeature: (feature, layer) => {
      const fCfg = getConfigForFeature(config, feature)
      if (fCfg?.geometryType == 'Polygon' && fCfg.contentPropertyKey) {
        const content = feature.properties[fCfg.contentPropertyKey]
        if (content) {
          layer
            .bindTooltip(`${content}`, {
              className: `c-polygon-label`,
              permanent: true,
              direction: 'center',
              opacity: 0,
            })
            .on('add', () => {
              const map: L.Map | undefined = (layer as any)?._map
              if (!map) return

              // Hide the tooltip now if its overflowing
              hideOverflowingPolylineTooltip(map, layer as L.Polyline)

              // Add a zoom handler to check the size each zoom.
              const onZoom = () => hideOverflowingPolylineTooltip(map, layer as L.Polyline)

              map.on('zoom', onZoom)
              layer.on('remove', () => {
                map.off('zoom', onZoom)
              })
            })
        }
      }
    },
    pointToLayer: (feature) => {
      const fCfg = getConfigForFeature(config, feature)
      if (fCfg.geometryType === 'Point') {
        return L.marker(
          { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
          getMarkerOptions(fCfg, customClassMap),
        )
      }

      throw new Error('MAP-2: Trying to render a non-point feature to a layer')
    },
  })
