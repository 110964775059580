import { Heading } from '@msaf/core-react'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import logo from '../../../../../assets/logo.png'
import { useFeatureState } from '../../../../components/feature-map/utils/state-management'
import { PdfMapLandscape } from '../../../../components/pdf/map-landscape'
import { PdfDocument } from '../../../../components/pdf/pdf-document'
import { PdfSubheading } from '../../../../components/pdf/pdf-subheading'
import { PdfNotFound } from '../../../../forms/application/export/not-found'
import { ExportProjectPage } from '../../../../forms/application/export/project-page'
import { getFeaturesByType, getNonMaintenancePreperationFeatures } from '../../../../forms/application/export/utils'
import { useRequest } from '../../../../hooks/useRequest'
import { RouteParams } from '../../../../types/route'
import { formatDisplayDate } from '../../../../utils/dates'
import { ApplicationDetailsFields } from './application/[applicationId]/details/config'

export interface SedimentReductionPlanProps {
  applicationIdentifier: string
  applicantName: string
  applicantOrganisation?: string
  applicantPhone?: string
  applicantAddresses: string[]
  assignedUsers: string[]
  isApproved: boolean
}

const stripFromStart = (base: string, strip: string) => (base.startsWith(strip) ? base.slice(strip.length) : base)

export const PlanExportView = () => {
  const { id: planId, applicationId } = useParams<RouteParams & { applicationId: string }>()
  const { client } = useRequest()

  // Fetch datasets for plan data
  const { data: srpData } = useQuery<SedimentReductionPlanProps>(
    `/api/plan/${planId}/project/${applicationId}/export/project-summary`,
    () => client.get(`/api/plan/${planId}/project/${applicationId}/export/project-summary`).then((res) => res.data),
  )
  const { data: projectDetailsData } = useQuery<ApplicationDetailsFields>(
    `/api/plan/${planId}/project/${applicationId}/details`,
    () => client.get(`/api/plan/${planId}/project/${applicationId}/details`).then((res) => res.data),
  )

  // Fetch features for all applications
  const { state: collectionState } = useFeatureState({ featuresUrl: `/api/plan/${planId}/features` })
  const projectFeatures = useMemo(
    () =>
      getNonMaintenancePreperationFeatures(collectionState?.collections?.filtered?.features, applicationId as string),
    [collectionState, collectionState?.collections?.filtered?.features],
  )

  const hillCountryNativeRegenerationFeatures = useMemo(
    () => getFeaturesByType(collectionState, 'hillCountryNativeRegeneration'),
    [collectionState, collectionState?.collections?.filtered?.features],
  )

  const propertyBoundaryFeature = useMemo(
    () => getFeaturesByType(collectionState, 'propertyBoundary'),
    [collectionState, collectionState?.collections?.filtered?.features],
  )

  const propertyMapFeatures = useMemo(
    () => [...propertyBoundaryFeature, ...hillCountryNativeRegenerationFeatures, ...projectFeatures],
    [propertyBoundaryFeature, hillCountryNativeRegenerationFeatures, projectFeatures],
  )

  if (!srpData || [planId, applicationId].includes(undefined)) return <PdfNotFound />
  const {
    applicationIdentifier,
    applicantName,
    applicantAddresses,
    isApproved,
    applicantOrganisation,
    applicantPhone,
    assignedUsers,
  } = srpData

  const displayApplicationIdentifier = !applicationIdentifier ? '-' : stripFromStart(applicationIdentifier, '0')

  return (
    <PdfDocument>
      {/* Cover Page */}
      <section className='l-pdf-container--page-break-after-always'>
        <div className='c-pdf-page__banner--flex-end'>
          <img src={logo} />
        </div>
        <Heading level={1}>Sediment Reduction Plan</Heading>
        <p>
          This Sediment Reduction Plan sets out works that KMR can co-fund to reduce erosion and the delivery of
          sediment to waterways and the Kaipara Moana (the Kaipara Harbour). This property is in the Kaipara Moana
          catchment, and ultimately drains into the Kaipara Moana. High levels of erosion and sediment are threatening
          the health of our waterways and the Kaipara Moana.
        </p>
        <Heading level={3}>Project {displayApplicationIdentifier}</Heading>
        <PdfSubheading title='Applicant' text={applicantName} />
        {applicantOrganisation && <PdfSubheading title='Organisation' text={applicantOrganisation} />}
        <PdfSubheading title='Address' text={applicantAddresses ? applicantAddresses?.join(', ') : '-'} />
        <PdfSubheading title='Phone' text={applicantPhone} />
        <PdfSubheading title='Field Advisor(s)' text={assignedUsers ? assignedUsers.join(', ') : '-'} />
        <PdfSubheading
          title='Anticipated start of works'
          text={projectDetailsData?.startYear ? formatDisplayDate(projectDetailsData?.startYear) : 'To be confirmed'}
        />
        <PdfSubheading
          title='Anticipated end of works'
          text={projectDetailsData?.endYear ? formatDisplayDate(projectDetailsData?.endYear) : 'To be confirmed'}
        />
        <PdfSubheading title='Last updated' text={formatDisplayDate(new Date())} />

        {!isApproved && (
          <Heading level={5}>NOTE: This project is in development. KMR co-funding is not yet approved.</Heading>
        )}
      </section>

      {/* Property map */}
      <PdfMapLandscape pageTitle='Property map' features={propertyMapFeatures} />

      {/* Plan */}
      <ExportProjectPage
        planId={planId as string}
        projectId={applicationId as string}
        projectDetailsData={projectDetailsData}
      />
      <section className='l-pdf-container--page-break-before-always'>
        <Heading level={5} className='c-field__heading--margin-small'>
          Privacy statement
        </Heading>
        <p className='p--fineprint'>
          With your permission, Kaipara Moana Remediation (KMR) has collected information from you to develop a
          property-specific plan and provide grant funding. Your information is stored securely and can be accessed by
          KMR Field Advisors, delivery partners, staff, technical reviewers and development partners. If it is shared
          with programme funders or accredited suppliers, it will be appropriately protected. Unless you agree, we will
          not use your information for any other purposes nor share it more widely. You can ask for a copy of any
          personal information KMR holds about you, and ask for it to be corrected if you think it is wrong. To do so,
          please contact us at hono@kmr.org.nz.
        </p>

        <Heading level={5} className='c-field__heading--margin-small'>
          KMR disclaimer
        </Heading>
        <p className='p--fineprint'>
          KMR provides information and advice on options to reduce erosion and sedimentation, and what may be eligible
          for KMR co-funding. All reasonable efforts have been made to consider the relevant factors available when
          giving our advice. The advice is intended to help you to make a decision about the use and management of land
          to reduce erosion and sedimentation. KMR does not provide financial, accounting, legal, or real estate advice,
          nor advice around the potential timber, carbon or other commercial returns from a change in land use. You
          should take specific advice from qualified professionals, as appropriate, before making your decisions and
          taking any action. KMR has taken reasonable measures to check the information in this plan but relies on the
          accuracy and completeness of the information that you and any third parties have provided. KMR is not
          responsible for the accuracy and completeness of this plan, and accepts no liability in relation to any loss,
          damage or other costs relating to the use of this information.
        </p>
      </section>
    </PdfDocument>
  )
}
