import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { Form } from '../../../../../../forms/components/form'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import { OverviewFields, useOverviewForm } from './overview.form'

export function PlanOverview() {
  const { id, mode } = useParams<RouteParams>()

  const { hasEditPermission, isMigratedPlan } = usePlanState()

  const { data, isLoading, errors, refetchErrors, refetchData } = useFormState<OverviewFields>(
    ([id]) => `/api/plan/${id}/overview`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'overview',
      instanceId: `${id}`,
    },
  )

  const overviewMutation = useMutationWithToast<
    AxiosResponse<OverviewFields>,
    AxiosError<Map<keyof OverviewFields, Array<string>>>,
    OverviewFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/overview`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save plan.',
    },
  })

  const config = useOverviewForm(id, data, mode, isMigratedPlan)

  const onSubmit = async (data: OverviewFields) => {
    await overviewMutation.mutateAsync(data)
  }

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          planPriorities: data.planPriorities?.length ? data.planPriorities : [{ value: null }],
        }
      : undefined
  }, [data])

  return (
    <Form<OverviewFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={hasEditPermission}
      config={config}
      submitAction={onSubmit}
    />
  )
}
