import { Button } from '@msaf/core-react'
import { useCallback, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../auth'
import RaygunClient, { ErrorType } from '../logging/raygun'
import { ErrorPage } from './error-full-page'
import { LoadingPage } from './loading-page'

export const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, error, login, logout } = useAuth()
  const { pathname } = useLocation()

  const loginWithRedirect = useCallback(() => login({ redirectPath: pathname }), [login, pathname])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) loginWithRedirect()
  }, [isLoading, isAuthenticated, error])

  useEffect(() => {
    if (!isLoading && !!error) {
      RaygunClient.getInstance().trackError(ErrorType.AUTH_ERROR, { error })
    }
  }, [isLoading, error])

  if (!isLoading && !!error) {
    console.warn('Login failed:', error)
    return (
      <ErrorPage header='Authentication error'>
        <Button label='Retry' onClick={async () => await logout()}></Button>
      </ErrorPage>
    )
  }

  if (isLoading || !isAuthenticated) return <LoadingPage />

  return <Outlet />
}
