import classNames from 'classnames'
import React from 'react'
import { Tooltip } from '../../common/tooltip'
import { ValidationMessage } from '../validation-message'
import { Label, LabelStyle } from './label'
import { SkeletonFormField } from './skeleton-form-field'

export interface FormFieldSkeletonProps {
  helpText?: string | React.ReactNode
  hasInlineLabel?: boolean
  hasInputWithButton?: boolean
}

export interface FormFieldProps extends FormFieldSkeletonProps {
  label: string
  labelStyle?: LabelStyle
  isRequired?: boolean
  requiredText?: string
  validationMessages?: Array<string>
  tooltipComponent?: JSX.Element
  hasInlineLabel?: boolean
  hasInputWithButton?: boolean
  isSkeleton?: boolean
  toolTipMessage?: string
  hasNoVerticalSpacing?: boolean
}

// Default type - label references id of form input via for attribute
export interface LabelWithForProps extends FormFieldProps {
  htmlFor: string
  labelId?: never
}

// Alternative - attribute has id so that faux inputs etc can reference label via aria-labelledby attribute
export interface LabelWithIdProps extends FormFieldProps {
  labelId: string
  htmlFor?: never
}

export function FormField({
  label,
  labelStyle,
  htmlFor,
  labelId,
  isRequired,
  requiredText = '*',
  helpText,
  validationMessages = [],
  tooltipComponent,
  hasInlineLabel,
  hasInputWithButton,
  children,
  isSkeleton,
  toolTipMessage = undefined,
  hasNoVerticalSpacing = false,
}: React.PropsWithChildren<LabelWithForProps | LabelWithIdProps>) {
  if (isSkeleton) {
    return (
      <SkeletonFormField
        helpText={helpText}
        children={children}
        hasInlineLabel={hasInlineLabel}
        hasInputWithButton={hasInputWithButton}
      />
    )
  }

  const containerClasses = classNames('c-form-field', {
    'c-form-field--inline-label': hasInlineLabel,
    'c-form-field--no-vertical-spacing': hasNoVerticalSpacing,
  })

  const inputClasses = classNames('c-form-field__input', {
    'c-form-field__input--multiple': hasInputWithButton,
    'c-form-field__input--no-vertical-spacing': hasNoVerticalSpacing,
  })

  const requiredClasses = classNames('c-form-field__required-text', {
    'c-form-field__required-text--single': requiredText.length === 1,
  })

  return (
    <div className={containerClasses}>
      <Label
        labelText={label}
        htmlFor={htmlFor}
        labelId={labelId}
        labelStyle={labelStyle}
        hasNoVerticalSpacing={hasNoVerticalSpacing && hasInlineLabel}
      >
        {isRequired && <div className={requiredClasses}>{requiredText}</div>}
        {toolTipMessage && <Tooltip message={toolTipMessage} ariaDescribedby={labelId ?? htmlFor} />}
        {tooltipComponent}
      </Label>
      <div className={inputClasses}>{children}</div>
      {helpText && (
        <div id={`${htmlFor}--help-text`} className='c-form-field__help-text'>
          {helpText}
        </div>
      )}

      {/* NOTE: message container needs to always be present for a11y (live region). */}
      {validationMessages?.map((errorMessage) => (
        // Since this list is unlikely to be re-rendered once loaded, using index as key is fine
        // If no htmlFor, use labelId instead (for checkable-field situation)
        <ValidationMessage id={htmlFor ?? labelId} errorMessage={errorMessage} key={errorMessage} />
      ))}
    </div>
  )
}
