import {
  DateFilter,
  DateFilterOperator,
  DateRange,
  createFilterSet,
  FilterInstance,
  FilterSet,
  parseDate,
  AuthStrategy,
} from '@msaf/generic-search-common'
import { DISPLAY_DATE_FORMAT, formatDate, ISO_DATETIME_FORMAT } from '@msaf/core-common'
import { DateField as DatePicker, FormFieldRange } from '@msaf/core-react'
import React, { useState, useEffect } from 'react'
import { add } from 'date-fns'
import { FilterSelect } from '../filter-select'
import { FilterProps } from '../../../utils'

const FILTER_OPERATOR = Object.freeze({
  LOWER: '>=',
  UPPER: '<',
})

export function FilterDateRange<T extends AuthStrategy = 'token'>(filterDateProps: FilterProps<T>) {
  const [customFilterOperator, setCustomFilterOperator] = useState('')
  const [dateLower, setDateLower] = useState<Date | undefined>()
  const [dateUpper, setDateUpper] = useState<Date | undefined>()
  const [customDateRangeOptions, setCustomDateRangeOptions] = useState([] as DateFilter[])

  const { filterKey, value, setValue, template, dateFormat, onKeyDown } = filterDateProps

  useEffect(() => {
    template.optionValues && setCustomDateRangeOptions(new DateRange().constructFilters(template.optionValues))
  }, [template.optionValues])

  useEffect(() => {
    if (value && typeof value !== 'string') {
      setCustomFilterOperator(value.templateFilterOperator ?? '')
      const queryFilters = value.filterSet.filters as FilterInstance[]
      queryFilters.forEach(({ filterOperator, filterValue }) => {
        if (filterOperator === FILTER_OPERATOR.LOWER) {
          const date = parseDate(filterValue)
          setDateLower(date)
        } else if (filterOperator === FILTER_OPERATOR.UPPER) {
          let date = parseDate(filterValue)
          if (date) {
            // Offset date to make it show as day earlier
            // What we send up to API is one day later to allow for a full day
            // if same lower and upper date selected.
            date = add(date, { days: -1 })
          }
          setDateUpper(date)
        }
      })
    }
  }, [value])

  /**
   * Returns the filter set with the updated filter value.
   * Excludes filters with no value selected.
   * @param filterValue Selected date formatted as a string
   * @param filterOperator Use to identify which date value (upper or lower) to update
   * @returns Filter set with non empty filters
   */
  const getAppliedFilterSet = (filterValue: string, filterOperator: DateFilterOperator) => {
    if (typeof value !== 'string') {
      const queryFilters = value.filterSet.filters as FilterInstance[]
      if (filterOperator === FILTER_OPERATOR.LOWER) {
        queryFilters.splice(0, 1, { filterKey, filterValue, filterOperator })
      } else if (filterOperator === FILTER_OPERATOR.UPPER) {
        queryFilters.splice(1, 1, { filterKey, filterValue, filterOperator })
      }
      return createFilterSet(queryFilters, 'AND')
    } else {
      return createFilterSet(
        [
          {
            filterKey,
            filterValue,
            filterOperator,
          },
        ],
        'AND',
      )
    }
  }

  /**
   * Update the search query with the selected date value
   * @param bound 'lower' for 'from' date and 'upper' for 'to' date
   * @param date Selected date value
   */
  const handleDateChange = (bound: 'lower' | 'upper', date: Date | string) => {
    if (date) {
      const filterValue: string = formatDate(formatDate(date), ISO_DATETIME_FORMAT)
      if (bound === 'lower') {
        setValue(getAppliedFilterSet(filterValue, FILTER_OPERATOR.LOWER as DateFilterOperator))
      } else if (bound === 'upper') {
        setValue(getAppliedFilterSet(filterValue, FILTER_OPERATOR.UPPER as DateFilterOperator))
      }
    }
  }

  const setCustomDateRange = (value: string | FilterSet) => {
    // Ignore if FilterSet
    if (typeof value === 'string') {
      const customDateFilter: DateFilter | undefined = customDateRangeOptions.find((option) => option.value === value)
      if (customDateFilter) {
        const { getISOLower, getISOUpper } = customDateFilter
        const customFilters = [
          {
            filterKey,
            filterOperator: FILTER_OPERATOR.LOWER,
            filterValue: getISOLower ? getISOLower() : '',
          },
          {
            filterKey,
            filterOperator: FILTER_OPERATOR.UPPER,
            filterValue: getISOUpper ? getISOUpper() : '',
          },
        ] as FilterInstance[]
        setValue({
          templateFilterOperator: value,
          ...createFilterSet(customFilters, 'AND'),
        })
      }
    }
  }

  return (
    <>
      {template.optionValues?.length && (
        <FilterSelect
          {...filterDateProps}
          value={customFilterOperator}
          setValue={setCustomDateRange}
          options={customDateRangeOptions.map(({ label, value }) => ({ label, value }))}
          onKeyDown={onKeyDown}
        />
      )}
      {customFilterOperator === '' && (
        <FormFieldRange
          verticalSpacing='x-small'
          formFields={[
            <DatePicker
              placeholder='From'
              dateFormat={dateFormat ?? DISPLAY_DATE_FORMAT}
              isDisabled={!!customFilterOperator}
              selectedDate={dateLower}
              setSelectedDate={(date: Date) => handleDateChange('lower', date)}
            />,
            <DatePicker
              placeholder='To'
              dateFormat={dateFormat ?? DISPLAY_DATE_FORMAT}
              isDisabled={!!customFilterOperator}
              selectedDate={dateUpper}
              setSelectedDate={(date: Date) => {
                const adjustedDate = add(date, { days: 1 })
                handleDateChange('upper', adjustedDate)
              }}
            />,
          ]}
        />
      )}
    </>
  )
}
