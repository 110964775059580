import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import LookupAddress from '../../../components/lookup-address'
import { RouteMode } from '../../../types/route'
import { AddressLookupFieldProps, RepeatingAddressLookupFieldProps } from '../../types'
import { useLabelProps } from '../hooks/use-label-props'
import { RepeatingLookupAddressField } from './repeating/lookup-address'
import { Wrapper } from './wrapper'

export function LookupAddressField<T extends FieldValues>(
  props: AddressLookupFieldProps<T> | RepeatingAddressLookupFieldProps<T>,
) {
  const { fieldId, mode } = props
  const { control } = useFormContext<T>()
  const labelProps = useLabelProps<T>(props)

  if ('repeating' in props && props.repeating) {
    return <RepeatingLookupAddressField {...props} />
  }

  return (
    <Wrapper {...props}>
      <Controller
        control={control}
        name={fieldId}
        render={({ field }) => (
          <LookupAddress
            id={fieldId}
            isDisabled={mode === RouteMode.VIEW}
            isSkeleton={props.isSkeleton}
            address={field.value as string}
            setAddressFields={(v) => {
              field.onChange(v)
            }}
            labelledBy={labelProps.labelId ?? labelProps.label}
          />
        )}
      />
    </Wrapper>
  )
}
