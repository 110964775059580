import { ArrayPath, Controller, FieldValues, Path, useFieldArray, useFormContext } from 'react-hook-form'
import LookupAddress from '../../../../components/lookup-address'
import { RouteMode } from '../../../../types/route'
import { RepeatingAddressLookupFieldProps } from '../../../types'
import { useLabelProps } from '../../hooks/use-label-props'
import { Wrapper } from './wrapper'

export function RepeatingLookupAddressField<T extends FieldValues>(props: RepeatingAddressLookupFieldProps<T>) {
  const { control } = useFormContext<T>()
  const { fieldId, mode, defaultValue } = props
  const fieldArray = useFieldArray({ name: fieldId as ArrayPath<T>, control })
  const labelProps = useLabelProps<T>(props)

  return (
    <Wrapper
      {...props}
      addField={() => fieldArray.append(defaultValue)}
      removeField={(index) => fieldArray.remove(index)}
    >
      {fieldArray.fields.map((field, index) => (
        <Controller
          key={field && 'id' in field ? field.id : null}
          control={control}
          name={`${fieldId}.${index}` as Path<T>}
          render={({ field }) => (
            <LookupAddress
              id={fieldId}
              isDisabled={props.isDisabled || mode === RouteMode.VIEW}
              isSkeleton={props.isSkeleton}
              address={field.value.address as string}
              setAddressFields={(v) => {
                field.onChange({ address: v })
              }}
              labelledBy={labelProps.labelId ?? fieldId}
            />
          )}
        />
      ))}
    </Wrapper>
  )
}
