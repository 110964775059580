import { FieldErrors, FieldPath, FieldValues } from 'react-hook-form'
import { RouteMode } from '../types/route'
import { AtomConfig, FeatureFormMode, FeatureFormModeKeys, FormElementConfig } from './types'
import { partialRight } from 'lodash'
import { BuiltFormError, FormState } from '../validation/constants'
import { Link } from '@msaf/core-react'
import labels from '../constants/labels'

export const getNumberFormatter = (options: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 0,
    ...options,
  })
}

export const toDollarCurrencyFormatter = (minimumFractionDigits = 0) => {
  return partialRight(toDollarCurrencyFormat, minimumFractionDigits)
}

export const toDecimalFormat = (
  value: unknown,
  minimumFractionDigits = 0,
  style: Intl.NumberFormatOptions['style'] = 'decimal',
) => {
  const formatter = getNumberFormatter({ minimumFractionDigits, style })
  switch (typeof value) {
    case 'string': {
      const newValue = Number(value)
      if (isNaN(newValue)) {
        return formatter.format(0)
      }
      return formatter.format(newValue)
    }
    case 'number': {
      return formatter.format(value)
    }
    default:
      return formatter.format(0)
  }
}

export const toDollarCurrencyFormat = (value: unknown, minimumFractionDigits = 0) =>
  toDecimalFormat(value, minimumFractionDigits, 'currency')

export const validationErrorForField = <TFieldValues extends FieldValues>(
  formErrors: FieldErrors<TFieldValues>,
  fieldId: FieldPath<TFieldValues>,
) => {
  const topFieldError = formErrors[fieldId]
  if (!topFieldError) return undefined

  // Incase we have nested object (LibLookupItem) then search for errors on the value field.
  const fieldError = topFieldError['type'] ? topFieldError : topFieldError?.value

  if (!fieldError) return undefined

  switch (fieldError.type) {
    case 'required':
      return ['This field is required']
    default:
      return [fieldError.message]
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertElementToView(config: FormElementConfig<any>): FormElementConfig<any> {
  const { type, element } = config
  if (type === 'field' && element.type !== 'table') {
    return { ...config, type: type, element: { ...element, mode: RouteMode.VIEW } }
  }
  if (type === 'atom' && element.type === 'card') {
    return {
      ...config,
      type: type,
      element: {
        ...element,
        fields: element.fields.map((f) => convertElementToView(f)),
      },
    }
  }

  return config
}

export function hasFormStarted(formState: FormState<unknown> | Array<FormState<unknown>>): boolean {
  if (Array.isArray(formState)) {
    return formState.length > 1 || (formState.length === 1 && ['started', 'reopened'].includes(formState[0].state))
  }
  return ['started', 'reopened'].includes(formState.state)
}

export const buildErrorsForDisplay = (errors: BuiltFormError[]) => (
  <ul>
    {errors.map((fe) => (
      <li key={fe.id}>
        <span>
          {/* Don't use a link if we don't have one or the path matches the current page. */}
          {fe.url && fe.url !== window.location.pathname.toString() ? (
            <Link path={fe.url}>{fe.formLabel}</Link>
          ) : (
            fe.formLabel
          )}
          :
        </span>
        <ul>
          {fe.errors.map((e) => (
            <li key={fe.id + e}>{e}</li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
)

export const errorsAsFormConfig = <T extends FieldValues>(errors: BuiltFormError[]) => {
  if (errors.length == 0) return []
  return [
    {
      type: 'atom',
      element: {
        type: 'inline-notification',
        isDismissable: false,
        messageType: 'warning',
        content: (
          <>
            <span>There are still a few things to complete before proceeding:</span>
            {buildErrorsForDisplay(errors)}
          </>
        ),
      },
    },
  ] as AtomConfig<T>[]
}

function formatOrdinalValue(n: number) {
  // Configure ordinal value rules
  const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' })
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const rule = enOrdinalRules.select(n)
  const suffix = suffixes.get(rule)
  return `${n}${suffix}`
}

const formatOrdinalFieldError = (errorField: string, showPosition: boolean) => {
  // expected output is ['sectionName', 'index', 'fieldName']
  const [sectionName, ordinalValue, fieldName] = errorField.split('.')
  const ordinalValueDisplay = Number(ordinalValue) + 1
  const displayName = labels[[sectionName, fieldName].join('.')] ?? [sectionName, fieldName].join(': ')

  if (showPosition) return `${formatOrdinalValue(ordinalValueDisplay)} ${displayName}`

  return displayName
}

export const getFieldLabel = (fieldId: string, formId?: string, showOrdinalPosition = false) => {
  const segments = fieldId.split('.')

  if (segments.length <= 2) {
    const simpleLabelOrRawId = labels[fieldId] ?? fieldId
    if (!formId) return simpleLabelOrRawId
    return labels[`${formId}.${fieldId}`] ?? simpleLabelOrRawId
  }

  return formatOrdinalFieldError(fieldId, showOrdinalPosition)
}

export const isFormMode = (mode: FeatureFormModeKeys, allowed: FeatureFormModeKeys[]) => allowed.includes(mode)

export const isFormModes = (mode: FeatureFormModeKeys) => ({
  isMapMode: isFormMode(mode, [FeatureFormMode.MAIN_MAP, FeatureFormMode.VERIFICATION_MAP]),
  isMapOrEditMode: isFormMode(mode, [FeatureFormMode.MAIN_MAP, FeatureFormMode.EDIT_FORM]),
  isVerificationMode: isFormMode(mode, [FeatureFormMode.VERIFICATION_MAP, FeatureFormMode.VERIFICATION_FORM]),
  isNotVerificationMapMode: isFormMode(mode, [
    FeatureFormMode.VERIFICATION_FORM,
    FeatureFormMode.EDIT_FORM,
    FeatureFormMode.MAIN_MAP,
  ]),
})
