import { Button, CheckableField, CheckableFields, Label, Typeahead } from '@msaf/core-react'
import { AuthStrategy, RequestOptions, SearchQuery } from '@msaf/generic-search-common'
import { useLookupSavedSearch, useSavedSearchResult } from '../../../../../../generic-search-react/src/hooks/use-api'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

const clearSearchFilters = (search: SearchQuery): SearchQuery => ({
  ...search,
  filterSet: { ...search.filterSet, filters: [] },
})

export interface SavedSearchControlProps<T extends AuthStrategy = 'token'> {
  onSearch: (searchQuery: SearchQuery, resetToFirstPage?: boolean) => void
  searchTypeKey: string
  searchQuery: any
  requestOptions?: RequestOptions<T>
  savedSearchFilters?: { label: string; filter: (row: any) => boolean }[]
  manageSavedSearchAction?: () => void
  selectedOption: { value: any; label: string } | undefined
  setSelectedOption: Dispatch<SetStateAction<{ value: any; label: string } | undefined>>
}

export function SavedSearchControl<T extends AuthStrategy = 'token'>({
  onSearch,
  searchTypeKey,
  searchQuery,
  requestOptions,
  savedSearchFilters,
  manageSavedSearchAction,
  selectedOption,
  setSelectedOption,
}: SavedSearchControlProps<T>) {
  const [currentSearchFilter, setCurrentSearchFilter] = useState<string | undefined>(savedSearchFilters?.at(0)?.label)
  const [activeSearchQuery, setActiveSearchQuery] = useState<SearchQuery | undefined>()

  const { isSuccess: optionsSuccess, data: savedSearches } = useLookupSavedSearch<T>(searchTypeKey, requestOptions)
  const { isSuccess, data } = useSavedSearchResult<T>(selectedOption?.value, requestOptions)

  useEffect(() => {
    if (activeSearchQuery) onSearch({ ...activeSearchQuery })
  }, [activeSearchQuery])

  useEffect(() => {
    if (searchQuery && !selectedOption) setActiveSearchQuery(clearSearchFilters(searchQuery))
    if (isSuccess && data.searchQuery) setActiveSearchQuery(data.searchQuery)
  }, [isSuccess, data?.searchQuery, selectedOption?.value])

  const options = useMemo(
    () =>
      savedSearches?.savedSearches.rows
        .filter((o: any) => savedSearchFilters?.find((sf) => sf.label === currentSearchFilter)?.filter(o) ?? true)
        .map((o: any) => ({ value: o.queryId, label: o.queryName })),
    [currentSearchFilter, optionsSuccess, savedSearches, savedSearchFilters],
  )

  return (
    <fieldset>
      <Label labelText='Load search' labelId={`load-search-label`} labelTag='legend' />
      <div className='c-save-search'>
        <Typeahead
          options={options}
          value={selectedOption}
          labelledBy='saveSearchTypeaheadLabel'
          onChange={(option: any) => setSelectedOption(option)}
        />
        {manageSavedSearchAction && (
          <Button buttonStyle='text-action' label='Manage my searches' onClick={manageSavedSearchAction} />
        )}
      </div>
      {savedSearchFilters && savedSearchFilters.length > 0 && (
        <>
          <label id='saveSearchFilterLabel' className='a11y--screen-reader-only'>
            Search type filter
          </label>
          <CheckableFields ariaLabelledBy='saveSearchFilterLabel' isHorizontal>
            {savedSearchFilters.map((sf: any) => (
              <CheckableField
                key={sf.label}
                id={sf.label}
                name='searchFilter'
                label={sf.label}
                type='radio'
                checked={currentSearchFilter === sf.label}
                onChange={() => setCurrentSearchFilter(sf.label)}
              />
            ))}
          </CheckableFields>
        </>
      )}
    </fieldset>
  )
}
