import L from 'leaflet'
import { FormField } from '@msaf/core-react'
import LookupAddress from './lookup-address'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useRequest } from '../hooks/useRequest'
import { USER_LOCATION } from '../config/map'
import { MENU_CONTEXT_WIDTH } from './feature-map/map/constants'

// The same thing but semantically different
type APILatLong = { lat: string; long: string }
type LatLong = { lat: number; lng: number }

const MAP_ZOOM_LEVEL = 12

export function AddressLookupPanel({ map }: { map: L.Map | undefined }) {
  const { client } = useRequest()
  const [address, setAddress] = useState<string | null>(null)
  const [location, setLocation] = useState<LatLong | null>(null)
  const hasAddress = address != null && address != ''

  useEffect(() => {
    setLocation(null)
  }, [hasAddress])

  useEffect(() => {
    if (map == null || location == null) return

    /* This is a little funky. We do the setView and the fitBounds separately because the fitBounds
     * accepts a padding option allowing us to account or the context menu, where as setView does not. */
    map.setView(location, MAP_ZOOM_LEVEL, { animate: false })
    map.fitBounds(map.getBounds(), { paddingTopLeft: [MENU_CONTEXT_WIDTH, 0], animate: false })

    const marker = new L.Marker(location, { icon: USER_LOCATION, draggable: false }).addTo(map)
    return () => {
      map.removeLayer(marker)
    }
  }, [map, location])

  const { isLoading } = useQuery<APILatLong | null, Error>(
    ['lookup', 'geocode_address', address],
    async function queryFn() {
      if (!hasAddress) return null
      return (await client.get<APILatLong>(`/api/lookup?field=geocode_address&query=${encodeURIComponent(address)}`))
        .data
    },
    {
      enabled: hasAddress,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // Type guard, but shouldn't be necessary given we are conditional on an address being present.
        if (data == null) return
        setLocation({ lat: parseFloat(data.lat), lng: parseFloat(data.long) })
      },
    },
  )

  return (
    <FormField labelId='address' label={'Address'}>
      <LookupAddress
        labelledBy={'address'}
        address={address}
        isDisabled={isLoading || !map}
        setAddressFields={setAddress}
      />
    </FormField>
  )
}
