import { Heading, Table } from '@msaf/core-react'
import { FeatureAction } from '../../../../components/pdf/types'
import { getFencingGatesTroughsSummary, getFencingGatesTroughsTotalCost, toSentenceCase } from '../utils'
import { toDecimalFormat, toDollarCurrencyFormat } from '../../../utils'
import { PdfReadOnlyField } from '../../../../components/pdf/read-only-field'

export const FencingGatesTroughsSummary = ({ features }: { features: FeatureAction[] }) => {
  const tablePages = 1
  const aggregates = getFencingGatesTroughsSummary(features)

  const expression = new RegExp('fencing', 'i')
  // Format aggregates for presentation
  const data = aggregates.map((feature) => ({
    locationName: feature.locationName,
    estimatedCost: toDollarCurrencyFormat(feature.estimatedCost),
    quantity: expression.test(feature.action)
      ? `${toDecimalFormat(feature.quantity)}m`
      : toDecimalFormat(feature.quantity),
    action: toSentenceCase(feature.action),
  }))

  const totalCost = getFencingGatesTroughsTotalCost(aggregates)

  if (features.length == 0) return null

  return (
    <section className='l-section--no-break-inside l-pdf-container--margin-small'>
      <Heading level={3} className='c-field__heading--margin-small'>
        Fencing, troughs, and gates project costings
      </Heading>
      <Table
        tableData={data}
        totalResults={data.length}
        showResultsCount={false}
        totalPages={tablePages}
        setPage={() => tablePages}
        currentPage={tablePages}
        setSortColumn={() => 'locationName'}
        defaultSortBy={{
          orderColumn: 'locationName',
          orderDirection: 'desc',
        }}
        columnHeaders={[
          {
            elementKey: 'locationName',
            columnHeading: 'Location',
            viewColumn: 'locationName',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'action',
            columnHeading: 'Actions',
            viewColumn: 'action',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'quantity',
            columnHeading: 'Quantity',
            viewColumn: 'quantity',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'estimatedCost',
            columnHeading: 'Estimated Total Cost ($)',
            viewColumn: 'estimatedCost',
            sortable: false,
            type: 'text',
          },
        ]}
      />
      <PdfReadOnlyField fieldName='Sub-total fencing, troughs and gates' value={toDollarCurrencyFormat(totalCost)} />
    </section>
  )
}
