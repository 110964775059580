import { useParams } from 'react-router-dom'
import { useRequest } from '../hooks/useRequest'
import { LoadingIndicator } from '@msaf/core-react'
import NotFound from '../components/not-found'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

export const DirectImage = () => {
  const { uuid } = useParams<{ uuid: string }>()

  const { client } = useRequest()

  const url = `/api/files/download/${uuid}/`

  const { data, isLoading } = useQuery<AxiosResponse<{ url: string }>>(url, () => client.get(url), {
    enabled: !!uuid,
  })

  if (!uuid) return <NotFound />

  if (isLoading) return <LoadingIndicator />

  return (
    <div className='c-full-screen-image__container'>
      <img src={data?.data.url} className='c-full-screen-image__image' />
    </div>
  )
}
