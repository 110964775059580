import {
  StandardFilterMapping,
  DateFilterMapping,
  BooleanMapping,
  StandardFilterOperator,
  SupportedDisplayFilterType,
} from './types/base'

export const FILTER_STRING_MAP: StandardFilterMapping = Object.freeze({
  '>': 'greater than',
  '<': 'less than',
  '>=': 'greater than or equal to',
  '<=': 'less than or equal to',
  '=': 'is',
  '@@': 'for',
  ilike: 'like',
  'ilike-bar': 'is',
  in: 'is',
  'is null': 'is',
})

export const DATE_RANGE_STRING_MAP: DateFilterMapping = Object.freeze({
  '>=': 'from',
  '<=': 'to',
  '>': 'after',
  '<': 'to',
})

export const BOOLEAN_STRING_MAP: BooleanMapping = Object.freeze({
  OR: 'or',
  AND: 'and',
})

export const BLANK_VALUE_STRING_MAP = 'blank'

export const DEFAULT_OPERATOR_MAP: Record<SupportedDisplayFilterType, StandardFilterOperator> = Object.freeze({
  text: 'ilike',
  date: '=',
  'date-range': '=',
  select: '=',
  typeahead: '=',
  'typeahead-selector': '=',
  'numeric-range': '=',
  'keyword-search': '@@',
})
