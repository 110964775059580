import { RepeatableField } from '@msaf/core-react'
import { PropsWithChildren } from 'react'
import { FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { RouteMode, RouteParams } from '../../../../types/route'
import { FieldConfig } from '../../../types'
import { useFieldErrors } from '../../hooks/use-field-errors'
import { useLabelProps } from '../../hooks/use-label-props'

export type WrapperProps<T extends FieldValues> = PropsWithChildren<FieldConfig<T>> & {
  removeField: (index: number) => void
  addField: () => void
}

export function Wrapper<T extends FieldValues>(props: WrapperProps<T>) {
  const { mode } = useParams<RouteParams>()
  const { fieldId, isRequired, removeField, addField, helpText, children } = props
  const { labelId, label } = useLabelProps(props)
  const fieldErrors: string[] = useFieldErrors<T>(fieldId)

  return (
    <RepeatableField
      label={label}
      labelId={labelId ?? label}
      isRequired={isRequired}
      components={children as JSX.Element[]}
      removeField={removeField}
      addField={addField}
      validationMessages={fieldErrors}
      helpText={helpText}
      isDisabled={props.isDisabled || mode === RouteMode.VIEW}
    />
  )
}
