import { AxiosError, AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { DefaultValues, Path, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useRequest } from './useRequest'

export type UseEditFormProps<T> = {
  url: string
  isNewRecord?: boolean
  defaults: DefaultValues<T>
  onSuccess?: (data: AxiosResponse<T>) => void
  onError?: (error: AxiosError<Record<keyof T, string[]>>) => void
}

export const useEditForm = <T>({ isNewRecord, url, defaults, onSuccess, onError }: UseEditFormProps<T>) => {
  const { client } = useRequest()
  const { register, handleSubmit, reset, formState, control, setError, getValues } = useForm<T>({
    defaultValues: { ...defaults },
    mode: 'onBlur',
  })

  // HACK: Reset form on unmount - combats strict mode shenanigans adding
  // an additional field instance to the form w/ remount
  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const {
    data: queryData,
    isSuccess,
    isLoading: isFetchingData,
  } = useQuery<AxiosResponse<T>, AxiosError<Record<Path<T>, Array<string>>>, AxiosResponse<DefaultValues<T>>>(
    url,
    () => client.get(url),
    { enabled: !isNewRecord },
  )

  useEffect(() => {
    // reset form with server response
    isSuccess && reset(queryData?.data)
    formState.isSubmitSuccessful && reset(getValues())
  }, [queryData, isSuccess, formState.isSubmitSuccessful])

  const {
    mutate,
    isLoading: isSaving,
    isError: isSaveError,
    error: saveError,
  } = useMutation<AxiosResponse<T>, AxiosError<Record<keyof T, Array<string>>>, T>(
    (data) => client(url, { method: isNewRecord ? 'POST' : 'PUT', data }),
    {
      onSuccess: onSuccess,
      onError: onError,
    },
  )

  useEffect(() => {
    const errors = saveError?.response?.data
    if (errors) {
      Object.entries(errors).forEach(([field, messages]) => {
        const errors = messages as string[]
        errors.length && setError(field as Path<T>, { type: 'server', message: errors[0] })
      })
    }
  }, [isSaveError])

  return {
    formState,
    control,
    isSaving,
    isFetchingData,
    register,
    handleSubmit,
    reset,
    mutate,
  }
}
