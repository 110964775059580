interface PlanOverviewResourcesProps {
  text: string
  url: string
  target: string
}

const planOverviewResourcesContent: PlanOverviewResourcesProps[] = [
  {
    text: 'Ministry for the Environment guidance on stock exclusion',
    url: 'https://environment.govt.nz/assets/publications/Essential-Freshwater-Stock-exclusion-factsheet.pdf',
    target: '_blank',
  },
  {
    text: 'Northland Regional Council guidance on stock exclusion',
    url: 'https://www.nrc.govt.nz/environment/farm-management/keeping-stock-out-of-waterways/#:~:text=Rules%20in%20the%20Resource%20Management,and%20are%20different%20for%20each',
    target: '_blank',
  },
  {
    text: 'Auckland Council guidance on stock exclusion ',
    url: 'https://www.aucklandcouncil.govt.nz/environment/looking-after-aucklands-water/water-strategy-policy-standards/freshwater-management-policy-standards/Pages/compliance-monitoring-freshwater-regulations.aspx#:~:text=%E2%80%8BCurrent%20regional%20livestock%20exclusion,18%20stock%20units%20per%20hectare',
    target: '_blank',
  },
]

export function PlanOverviewResources() {
  return (
    <>
      <span>The following resources provide context on the regulations:</span>
      <ul>
        {planOverviewResourcesContent.map(({ text, url, target }) => (
          <li key={text}>
            <a href={url} target={target}>
              {text}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}
