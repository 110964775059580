import { ButtonsContainer, Divider, Heading, InlineNotification } from '@msaf/core-react'
import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'
import { ControlledButton } from '../forms/components/action/button'
import { Card } from '../forms/components/atom/card'
import { GenericField } from '../forms/components/fields/generic-field'
import ConfirmAction from '../forms/components/modals/confirm-action'
import { FormElementConfig } from '../forms/types'
import { RouteMode } from '../types/route'
import { PlanWarningsSection } from '../forms/components/atom/plan-warnings'

type UseFormConfigProps<T extends FieldValues> = {
  config: FormElementConfig<T>[]
  mode: RouteMode
  isSkeleton?: boolean
  isMigrated?: boolean
}

export const useFormConfig = <T extends FieldValues>({
  config,
  mode,
  isSkeleton,
  isMigrated,
}: UseFormConfigProps<T>) => {
  return useMemo(
    () =>
      config
        // Filter out any elements we don't need to display
        .filter(({ renderOnlyWhen }) => renderOnlyWhen !== false)
        .map(({ type, element }, index) => {
          const key = `${index}--${type}.${element.type}`
          if (type === 'atom' && element.type === 'heading') {
            return (
              <Heading key={key} level={element.level}>
                {element.content}
              </Heading>
            )
          }

          if (type === 'atom' && element.type === 'card') {
            return <Card key={key} fields={element.fields} mode={mode} />
          }

          if (type === 'atom' && element.type === 'divider') {
            return <Divider key={key} isFullWidth />
          }

          if (type === 'atom' && element.type === 'paragraph') {
            return (
              <p key={key} className={element.className ?? 'c-form-text'}>
                {element.content}
              </p>
            )
          }

          if (type === 'atom' && element.type === 'custom-component') {
            return element.content
          }

          if (type === 'field') {
            return (
              <GenericField key={key} mode={mode} isSkeleton={isSkeleton} isMigrated={isMigrated} element={element} />
            )
          }

          if (type === 'atom') {
            if (element.type === 'inline-notification') {
              const { content, messageType, isDismissable } = element
              return (
                <InlineNotification key={key} messageType={messageType} isDismissable={isDismissable}>
                  {content}
                </InlineNotification>
              )
            }
            if (element.type === 'plan-warnings') {
              return <PlanWarningsSection />
            }
          }

          if (type === 'modal') {
            // Add other modal types
            if (element.type === 'confirm-action') {
              return <ConfirmAction key={index} {...element} />
            }
          }

          if (type === 'action') {
            return (
              <ButtonsContainer key={`${index}--${type}`} containerStyle='left'>
                <ControlledButton
                  {...element}
                  isDisabled={element.isDisabled || (!element.enabledInViewMode && mode === RouteMode.VIEW)}
                />
              </ButtonsContainer>
            )
          }

          return null
        }),
    [config, mode, isSkeleton],
  )
}
