import { Button, createToastMessage, Modal, ToastMessage } from '@msaf/core-react'
import { useLocation, useNavigate } from '@msaf/router-react'
import { Feature, Geometry } from '@turf/helpers'
import { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { collectionFromFeature } from '../../../../../../../../components/feature-map/utils'
import { useAppContext } from '../../../../../../../../contexts/app-context'
import { Form } from '../../../../../../../../forms/components/form'
import { FeatureFormFields, FeatureFormMode } from '../../../../../../../../forms/types'
import { useRequest } from '../../../../../../../../hooks/useRequest'
import { RouteMode, RouteParams } from '../../../../../../../../types/route'
import { useFormConfig } from './action.form'
import { useFormState } from '../../../../../../../../hooks/use-form-state'

export const Action = () => {
  const { addPageActions, removePageActions } = useAppContext()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id, mode, applicationId, actionId } = useParams<RouteParams & { applicationId: string; actionId: string }>()
  const navigate = useNavigate()
  const url = useLocation()

  const { client } = useRequest()

  const {
    data,
    isLoading,
    refetchData: refetch,
    isEditable,
  } = useFormState<Feature<Geometry>>(() => `/api/plan/${id}/features/${actionId}`, {
    workflowId: 'application',
    sectionId: 'details',
    formId: 'action',
    instanceId: `${applicationId}`,
    subFormId: `${actionId}`,
  })

  const { mutateAsync: deleteAsync } = useMutation(['action', id, actionId, 'delete'], () =>
    client.delete(`/api/plan/${id}/features/${actionId}`),
  )

  const { mutateAsync: updateAsync } = useMutation<
    AxiosResponse<FeatureFormFields>,
    AxiosError<unknown>,
    FeatureFormFields
  >(['action', id, actionId, 'update'], (data) => client.put(`/api/plan/${id}/features/${actionId}`, data), {
    onSuccess: () => {
      createToastMessage(<ToastMessage messageType='success' title='Success' message='Action saved.' />)
      // Refetch saved data to update the form data
      refetch?.()
    },
    onError: () => {
      createToastMessage(<ToastMessage messageType='error' title='Error' message='Could not save action.' />)
    },
  })

  const collection = useMemo(() => collectionFromFeature(data), [data])

  const config = useFormConfig(id, data?.properties?.type, data?.properties, FeatureFormMode.EDIT_FORM)

  useEffect(() => {
    addPageActions?.([
      {
        id: 'action-delete',
        label: 'Delete',
        action: () => {
          setDeleteModalOpen(true)
        },
      },
    ])
    return () => {
      removePageActions?.('action-delete')
    }
  }, [])

  const onSubmit = async (data: FeatureFormFields) => updateAsync(data)

  return (
    <>
      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        heading='Delete feature?'
        body='Are you sure you wish to delete this feature and any associated data?'
        contentLabel='Confirm feature deletion'
        buttons={
          <>
            <Button
              buttonStyle='primary'
              type='button'
              label='Delete'
              onClick={async () => {
                const response = await deleteAsync()
                if (response.status === 204) {
                  createToastMessage(<ToastMessage title='Feature deleted' messageType='success' />)
                  navigate(`/plans/${mode}/${id}/plan/project/${applicationId}/details`)
                } else {
                  createToastMessage(
                    <ToastMessage
                      title='Something went wrong'
                      messageType='error'
                      message='Unable to delete feature'
                    />,
                  )
                }
              }}
            />
            <Button buttonStyle='text-action' type='button' label='Cancel' onClick={() => setDeleteModalOpen(false)} />
          </>
        }
      />
      <Form
        isSkeleton={isLoading}
        mode={mode ?? RouteMode.VIEW}
        initialState={config.initialState}
        submitAction={onSubmit}
        canEdit={!!isEditable}
        config={[
          {
            type: 'action',
            element: {
              type: 'button',
              path: `/plans/${mode}/${id}/plan/project/${applicationId}/details`,
              label: 'Back',
              buttonStyle: 'text-action',
              icon: 'arrow-left',
              iconAlignment: 'left',
              enabledInViewMode: true,
            },
          },
          {
            type: 'atom',
            element: { type: 'heading', level: 2, content: 'Action details' },
          },
          {
            type: 'field',
            element: {
              type: 'static-map',
              features: collection,
              actions: [
                {
                  iconAriaLabel: 'map',
                  icon: 'map',
                  label: 'Map mode',
                  onClick: () => {
                    navigate(`/plans/${mode}/${id}/map?origin=${url.pathname}`)
                  },
                },
              ],
              zoom: 11,
            },
          },
          // TODO: Fix the type
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(config.formConfig as any),
        ]}
      />
    </>
  )
}
