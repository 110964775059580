import { useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { DisplayContext } from '../../../../../../../components/gatekeeper'
import { ErrorCode } from '../../../../../../../constants'
import { RouteParams } from '../../../../../../../types/route'
import { LongTermMonitoringWorkflow } from '../../../../../../../components/workflow-containers/long-term-monitoring'

export const PlanLongTermMonitoring = () => {
  const { id } = useParams<RouteParams>()
  const { setError } = useContext(DisplayContext)
  if (!id) {
    setError(ErrorCode.NOT_FOUND_404)
    return null
  }
  return (
    <LongTermMonitoringWorkflow recordId={id}>
      <Outlet />
    </LongTermMonitoringWorkflow>
  )
}
