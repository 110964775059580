import { MainMenuItemProps } from '@msaf/core-react'
import Logo from '../../assets/logo.svg'

export const APP_LOGO = {
  iconLogoImagePath: Logo,
  fullLogoImagePath: Logo,
  logoTextLine1: 'Mātai Onekura',
  logoTextLine2: 'KMR',
  altText: 'Mātai Onekura',
  icon: undefined,
}

export const DISPLAY_DATE_FORMAT = 'd MMM yyyy'
export const DISPLAY_DATETIME_FORMAT = 'd MMM yyyy hh:mm a'

export type PlanStatusNames = 'eoi' | 'eoi_deferred' | 'eoi_declined' | 'plan_allocation_pending' | 'plan_dev'

export const EOI_STATUS_NAMES: PlanStatusNames[] = ['eoi', 'eoi_deferred', 'eoi_declined']
export const INITIAL_HEADING = 'Landowner grant - New Registration'

export const NAV_PANEL_LINKS: MainMenuItemProps[] = [
  { path: '/', label: 'Dashboard', icon: 'dashboard' },
  { path: '/plans', label: 'SRPs', icon: 'file', exact: false },
]

export const ADMIN_NAV_PANEL_LINKS: MainMenuItemProps[] = [
  {
    path: '/admin',
    label: 'Administration',
    icon: 'settings',
    exact: false,
    isNewSection: true,
  },
]

export enum ErrorCode {
  UNAUTHENTICATED_401 = 'UNAUTHENTICATED',
  PERMISSION_DENIED_403 = 'PERMISSION_DENIED',
  NOT_FOUND_404 = 'NOT_FOUND',
  SERVER_ERROR_500 = 'SERVER_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const DEBOUNCE_MS = 300

export const PLAN_MAP_PATH = '/plans/:mode/:id/map'
export const APPLICATION_MAP_PATH = '/plans/:mode/:id/progress/project/:applicationId/action-verification/map'

export const ISO_DATETIME_MS_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXX"

export const ACTIONS_CATEGORIES_MEASURED_IN_HECTARES: string[] = [
  'propertyBoundary',
  'planting',
  'sitePreparation',
  'siteMaintenance',
]
