import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { Form } from '../../../../../../forms/components/form'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import { CatchmentContextFields, useCatchmentContextForm } from './catchment-context.form'

export function CatchmentContext() {
  const { id, mode } = useParams<RouteParams>()
  const { hasEditPermission, isMigratedPlan } = usePlanState()
  const { data, isLoading, errors, refetchErrors, refetchData } = useFormState<CatchmentContextFields>(
    ([id]) => `/api/plan/${id}/catchment-context`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'catchmentContext',
      instanceId: `${id}`,
    },
  )

  const mutation = useMutationWithToast<
    AxiosResponse<CatchmentContextFields>,
    AxiosError<Map<keyof CatchmentContextFields, Array<string>>>,
    CatchmentContextFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/catchment-context`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save plan.',
    },
  })

  const config = useCatchmentContextForm(id, data, isMigratedPlan)

  const onSubmit = async (data: CatchmentContextFields) => mutation.mutateAsync(data)

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          surfaceWaterCatchments: data.surfaceWaterCatchments?.length ? data.surfaceWaterCatchments : [{}],
          relevantAwaWaterways: data.relevantAwaWaterways?.length ? data.relevantAwaWaterways : [{}],
          relevantAwaWaterwaysLocalNames: data.relevantAwaWaterwaysLocalNames?.length
            ? data.relevantAwaWaterwaysLocalNames
            : [{}],
          ecologicalDistricts: data.ecologicalDistricts?.length ? data.ecologicalDistricts : [{}],
          localMaraes: data.localMaraes?.length ? data.localMaraes : [{}],
          priorities:
            'This property is within the Kaipara Moana catchment. All land and waterways in the catchment ultimately drain into the Kaipara Moana (the Kaipara Harbour).\n\nHigh levels of erosion and sedimentation in the Kaipara Moana catchment are threatening the long-term health and sustainability of waterways and the harbour.',
        }
      : undefined
  }, [data])

  return (
    <Form<CatchmentContextFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={hasEditPermission}
      config={config}
      nextPath={`/plans/${mode}/${id}/plan/property-systems-aspirations`}
      submitAction={onSubmit}
    />
  )
}
