import React, { MutableRefObject, useEffect, useRef } from 'react'
import { Select } from '@msaf/core-react'
import { FilterProps } from '../../../utils'
import { AuthStrategy } from '@msaf/generic-search-common'

type FilterSelectOption = {
  label: string
  value: string
}
interface FilterSelectProps<T extends AuthStrategy = 'token'> extends FilterProps<T> {
  options?: FilterSelectOption[]
}

export function FilterSelect<T extends AuthStrategy = 'token'>({
  filterKey,
  value,
  setValue,
  options,
  onKeyDown,
  template,
}: FilterSelectProps<T>) {
  if (typeof value !== 'string') throw new Error('Invalid value type for `select`')

  const inputRef: MutableRefObject<HTMLSelectElement | null> = useRef(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <Select
      ref={inputRef}
      id={`search-filter-input-label-${filterKey}`}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      value={value}
      options={options ?? template.options}
      onKeyDown={onKeyDown}
      placeholderType='none'
    />
  )
}
