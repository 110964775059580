import { NavLinkProps, SectionConfig } from '@msaf/core-react'
import {
  FormState,
  WorkflowModelState,
  SectionState,
  Workflow,
  WorkflowSectionConfig,
  WorkflowState,
  WORKFLOWS_CONFIG,
} from './constants'
import { RouteMode } from '../types/route'

type SectionHeadingFunction = (index: number, label: string) => string

type WorkflowConstructionArgs<T> = {
  workflowId: Workflow
  state: WorkflowState<T> | undefined
  mode: RouteMode
  displayCheckFunction?: (state: WorkflowModelState<T>) => { display: false; adviceText: string } | { display: true }
  sectionHeadingFunction?: SectionHeadingFunction
} & (
  | {
      multiRecord?: false
      recordId: string | undefined
      baseRecordId?: string
    }
  | {
      multiRecord: true
      recordId?: undefined
      baseRecordId: string
    }
)

export const buildSectionsForWorkflow = <T>({
  workflowId,
  state,
  multiRecord,
  recordId,
  baseRecordId,
  mode,
  displayCheckFunction,
  sectionHeadingFunction,
}: WorkflowConstructionArgs<T>): SectionConfig[] => {
  if (!state) {
    return []
  }
  const workflowState = state[workflowId]

  const sections = Object.entries(WORKFLOWS_CONFIG[workflowId])

  const recordStates = Object.entries(workflowState)

  if (multiRecord) {
    const states = recordStates.map(([recordId, state], index) => {
      return sections.map(([sectionId, config]) => {
        const sectionState = state.state[sectionId]
        if (!sectionState) throw new Error(`No state for section (${sectionId}). Check workflow configuration.`)
        const ids = [baseRecordId, recordId].filter((id) => id != null)
        const displayCheck = displayCheckFunction?.(state)
        if (!displayCheck || displayCheck.display)
          return buildSectionConfig(config, ids, sectionState, index, mode, sectionHeadingFunction)
        return {
          heading: `${sectionHeadingFunction?.(index, config.label) ?? config.label}`,
          adviceText: displayCheck.adviceText,
        }
      })
    })

    return states.flat()
  }

  if (!recordId || !workflowState[recordId]) {
    throw new Error('Unable to get workflow content if no recordId provided')
  }

  return sections.map(([sectionId, config], index) => {
    const sectionState = workflowState[recordId].state[sectionId]
    const ids: string[] = [baseRecordId, recordId].filter((id) => id != null) as string[]
    return buildSectionConfig(config, ids, sectionState, index, mode, sectionHeadingFunction)
  })
}

export function hasFormBeenCompleted<T>(formState: Partial<FormState<T>> | Partial<FormState<T>>[] = {}): boolean {
  if (Array.isArray(formState)) {
    return formState.every((f) => hasFormBeenCompleted(f))
  }
  return formState?.errors?.length === 0 && ['started', 'closed', 'reopened'].includes(formState?.state ?? '')
}

export function formHasErrors<T>(formState: Partial<FormState<T>> | Partial<FormState<T>>[] = {}): boolean {
  if (Array.isArray(formState)) {
    return formState.every((f) => formHasErrors(f))
  }
  return (formState?.errors?.length ?? 0) >= 1
}

export const buildSectionConfig = <T>(
  config: WorkflowSectionConfig,
  recordIds: string[],
  state: SectionState<T>,
  index: number,
  mode: RouteMode,
  sectionHeadingFunction?: SectionHeadingFunction,
): SectionConfig => {
  const formsState = state.forms
  const sectionConfig = config?.configFunc?.(recordIds, state)

  const heading = `${sectionHeadingFunction?.(index, config.label) ?? config.label}`

  if (sectionConfig?.adviceText) {
    return {
      heading,
      adviceText: sectionConfig.adviceText,
    }
  }

  const links = Object.entries(config.forms).map(([formId, formConfig]) => {
    const path = formConfig.pathFunc(recordIds, mode)

    const form = formsState[formId as string]

    const formArray = Array.isArray(form) ? form : [form]

    // If the form has subforms, the checked status will depend on both the
    // parent form and the subform's completed state
    const subforms = Object.keys(formConfig.subforms ?? []).flatMap((subformId) => {
      const subform = formsState[subformId]
      return Array.isArray(subform) ? subform : [subform]
    })

    const checked = [...formArray, ...subforms].every((f) => hasFormBeenCompleted(f))
    const warning = [...formArray, ...subforms].some((f) => formHasErrors(f))

    return {
      label: formConfig.label,
      checked: checked || warning,
      path,
      status: warning ? 'warning' : 'success',
    } as NavLinkProps
  })

  return {
    heading,
    links,
  }
}
