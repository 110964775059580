import { TextArea as CoreTextArea } from '@msaf/core-react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { TextAreaFieldProps } from '../../types'
import { Wrapper } from './wrapper'

export function TextArea<T extends FieldValues>({
  fieldId,
  mode,
  isDisabled,
  isRequired,
  formPrefix,
  labelOverride,
  helpText,
  type,
  ...props
}: TextAreaFieldProps<T>) {
  const { register } = useFormContext<T>()
  return (
    <Wrapper
      fieldId={fieldId}
      isRequired={isRequired}
      formPrefix={formPrefix}
      labelOverride={labelOverride}
      helpText={helpText}
      type={type}
      isSkeleton={props.isSkeleton}
    >
      <CoreTextArea id={fieldId} isDisabled={isDisabled || mode === RouteMode.VIEW} {...props} {...register(fieldId)} />
    </Wrapper>
  )
}
