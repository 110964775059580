import { AxiosError, AxiosResponse } from 'axios'
import { useEffect } from 'react'
import { DefaultValues, Path, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useRequest } from './useRequest'

export type UseViewFormProps<T> = {
  url: string
  defaults: DefaultValues<T>
  onSuccess?: (data: AxiosResponse<DefaultValues<T>>) => void
  onError?: (error: AxiosError<Record<keyof T, string[]>>) => void
}

export const useViewForm = <T>({ url, defaults, onSuccess, onError }: UseViewFormProps<T>) => {
  const { client } = useRequest()
  const { register, formState, setError, reset, control } = useForm<T>({
    defaultValues: { ...defaults },
    mode: 'onBlur',
  })

  const {
    data: queryData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useQuery<AxiosResponse<T>, AxiosError<Record<keyof T, Array<string>>>, AxiosResponse<DefaultValues<T>>>(
    url,
    () => client.get(url),
    {
      onSuccess: onSuccess,
      onError: onError,
    },
  )

  useEffect(() => {
    const errors = error?.response?.data
    if (errors) {
      Object.entries(errors).forEach(([field, messages]) => {
        const errors = messages as string[]
        errors.length && setError(field as Path<T>, { type: 'server', message: errors[0] })
      })
    }
    // reset form with new data
    isSuccess && reset(queryData?.data)
  }, [isError, queryData, isSuccess])

  return {
    isLoading,
    formState,
    control,
    register,
  }
}
