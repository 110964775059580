import { FormField, Toggle } from '@msaf/core-react'

interface SnapActionProps {
  snappable: boolean
  setSnappable: React.Dispatch<React.SetStateAction<boolean>>
}

export function SnapAction({ snappable, setSnappable }: SnapActionProps) {
  return (
    <FormField
      label='Options'
      labelStyle='uppercase'
      labelId='options-label'
      helpText='Snapping makes it easier to place points along existing shapes or lines.'
    >
      <Toggle
        name='Snappable'
        id='snappable'
        describedBy='options-label'
        isChecked={snappable}
        onChange={() => setSnappable(!snappable)}
      >
        Snapping enabled
      </Toggle>
    </FormField>
  )
}
