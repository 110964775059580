import { UploadedFile } from '@msaf/core-react'
import { Organisation } from '../../../../../../components/lookups/organisation/type'
import { Person } from '../../../../../../components/lookups/person/type'
import { LookupItem } from '../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'
import { FieldWatchResultProps } from '../../../../../../forms/components/hooks/use-field-watch'
import { FormElementConfig } from '../../../../../../forms/types'

export interface ApplicantFields {
  addresses: { address: string }[]
  propertyIdentifiers: { value: string }[]
  applicant: Person
  applicantOrganisation: Organisation
  isApplicantPrimaryContact: boolean
  primaryContact: Person | null
  primaryContactOrganisation: Organisation | null
  primaryContactRelationship: LookupItem | null
  files?: UploadedFile[]
  notes: AppNoteType
}

export interface ApplicationFormConfigProps {
  data?: ApplicantFields
  isMigratedPlan?: boolean
}

export function applicantFormConfig({
  data: initialData,
  isMigratedPlan: isMigrated,
}: ApplicationFormConfigProps): FormElementConfig<ApplicantFields>[] {
  return [
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Property location' },
    },
    {
      type: 'field',
      element: {
        type: 'address-lookup',
        fieldId: 'addresses',
        formPrefix: 'applicant',
        repeating: true,
        defaultValue: { address: '' },
        isRequired: !isMigrated,
      },
    },
    {
      type: 'field',
      element: {
        type: 'text',
        fieldId: 'propertyIdentifiers',
        repeating: true,
        defaultValue: { value: '' },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Applicant' },
    },
    {
      type: 'field',
      element: {
        type: 'person-crud',
        fieldId: 'applicant',
        labels: { singular: 'person', heading: 'Applicant details', modal: 'Edit applicant details' },
        isRequired: !isMigrated,
      },
    },
    {
      type: 'field',
      element: {
        type: 'organisation-crud',
        fieldId: 'applicantOrganisation',
        labels: {
          singular: 'organisation',
          heading: 'Applicant organisation',
          modal: 'Edit applicant organisation',
        },
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    {
      type: 'atom',
      element: { type: 'heading', level: 2, content: 'Primary contact person' },
    },
    {
      type: 'field',
      element: {
        type: 'boolean',
        fieldId: 'isApplicantPrimaryContact',
        isRequired: !isMigrated,
      },
    },
    {
      type: 'field',
      element: {
        type: 'person-crud',
        fieldId: 'primaryContact',
        labels: { singular: 'person', heading: 'Primary contact details', modal: 'Edit primary contact details' },
        isHidden: initialData?.isApplicantPrimaryContact,
        isRequired: !isMigrated,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicantFields>) => {
          if (info.type === 'change') {
            if (info.name === 'isApplicantPrimaryContact') {
              methods.setValue('primaryContact', data.isApplicantPrimaryContact ? methods.getValues('applicant') : null)
              return { ...initialConfig, isHidden: data.isApplicantPrimaryContact }
            }
            if (info.name === 'applicant' && data.isApplicantPrimaryContact) {
              methods.setValue('primaryContact', methods.getValues('applicant'))
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        type: 'organisation-crud',
        fieldId: 'primaryContactOrganisation',
        labels: {
          singular: 'organisation',
          heading: 'Primary contact organisation',
          modal: 'Edit primary contact organisation',
        },
        validationDisabled: isMigrated,
        isHidden: initialData?.isApplicantPrimaryContact,
        watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ApplicantFields>) => {
          if (info.type === 'change') {
            if (info.name === 'isApplicantPrimaryContact') {
              methods.setValue(
                'primaryContactOrganisation',
                data.isApplicantPrimaryContact ? methods.getValues('applicantOrganisation') : null,
              )
              return { ...initialConfig, isHidden: data.isApplicantPrimaryContact }
            }
            if (info.name === 'applicantOrganisation' && data.isApplicantPrimaryContact) {
              methods.setValue('primaryContactOrganisation', methods.getValues('applicantOrganisation'))
            }
          }
          return initialConfig
        },
      },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'primaryContactRelationship',
        lookupId: 'person_land_relationship',
        isRequired: !isMigrated,
      },
    },
    {
      type: 'atom',
      element: { type: 'divider' },
    },
    { type: 'atom', element: { type: 'heading', content: 'Approval to engage with KMR', level: 3 } },
    { type: 'field', element: { type: 'files', fieldId: 'files' } },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.PRE_CHECKS,
      },
    },
  ]
}
