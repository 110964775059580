export enum UserPermissions {
  VIEW_EOI = 'global.view_eoi',
  CREATE_EOI = 'global.create_eoi',
  CREATE_MIGRATED_PLAN = 'global.create_migrated_plan',
  CREATE_FONTERRA_PLAN = 'global.create_fonterra_plan',
  PROCESS_EOI = 'global.process_eoi',
  VIEW_PLANS = 'global.view_plans',
  EDIT_PLANS = 'global.edit_plans',
  EDIT_PLANS_ASSIGNED = 'global.edit_plans_assigned',
  VIEW_REVIEW = 'global.view_review',
  EDIT_REVIEW_ASSIGN_TEAM_MEMBER_ONLY = 'global.edit_review_assign_team_member_only',
  EDIT_REVIEW = 'global.edit_review',
  EDIT_REVIEW_PANEL_FEEDBACK = 'global.edit_review_panel_feedback',
  EDIT_APPLICATION_PRICE_EFFECTIVE_DATE = 'global.edit_application_price_effective_date',
  EDIT_FONTERRA_APPLICATION_PRICE_EFFECTIVE_DATE = 'global.edit_fonterra_application_price_effective_date',
  EDIT_FONTERRA_APPLICATION_DATE_COMMITTED = 'global.edit_fonterra_application_date_committed',
  EDIT_FONTERRA_APPLICATION_DATE_COMMITTED_ASSIGNED = 'global.edit_fonterra_application_date_committed_assigned',
  EDIT_FONTERRA_PLAN = 'global.edit_fonterra_plan',
  EDIT_APPROVAL_RECOMMENDATION = 'global.edit_approval_recommendation',
  EDIT_APPROVAL_DELEGATED_AUTHORITY = 'global.edit_approval_delegated_authority',
  PROCESS_REVIEW = 'global.process_review',
  REOPEN_APPLICATION = 'global.reopen_application',
  REOPEN_EOI = 'global.reopen_expression_of_interest',
  VIEW_SUPPLY = 'global.view_supply',
  EDIT_SUPPLY = 'global.edit_supply',
  VIEW_PROGRESS = 'global.view_progress',
  EDIT_PROGRESS = 'global.edit_progress',
  EDIT_PROGRESS_ASSIGNED = 'global.edit_progress_assigned',
  EDIT_FONTERRA_PROGRESS_ASSIGNED = 'global.edit_fonterra_progress_assigned',
  ASSIGN_PROGRESS = 'global.assign_progress',
  VIEW_GRANT_PAYMENT = 'global.view_grant_payment',
  EDIT_GRANT_PAYMENT = 'global.edit_grant_payment',
  MANAGE_USERS = 'global.manage_users',
  ADMIN = 'global.admin',
  EDIT_FONTERRA_APPROVAL_RECOMMENDATION = 'global.edit_fonterra_approval_recommendation',
  EDIT_FONTERRA_APPROVAL_DELEGATED_AUTHORITY = 'global.edit_fonterra_approval_delegated_authority',
  ENABLE_LONG_TERM_MONITORING = 'global.enable_long_term_monitoring',
  VIEW_LONG_TERM_MONITORING = 'global.view_long_term_monitoring',
  EDIT_LONG_TERM_MONITORING = 'global.edit_long_term_monitoring',
  EDIT_LONG_TERM_MONITORING_ASSIGNED = 'global.edit_long_term_monitoring_assigned',
  EDIT_FONTERRA_LONG_TERM_MONITORING_ASSIGNED = 'global.edit_fonterra_long_term_monitoring_assigned',
}

// Review section permissions
export const REVIEW_EDIT_PERMISSIONS = [
  UserPermissions.EDIT_REVIEW,
  UserPermissions.EDIT_REVIEW_ASSIGN_TEAM_MEMBER_ONLY,
  UserPermissions.EDIT_REVIEW_PANEL_FEEDBACK,
]
export const REVIEW_VIEW_PERMISSIONS = UserPermissions.VIEW_REVIEW

// Plan workflow permissions
export const EDIT_PLAN_PERMISSIONS = [
  UserPermissions.EDIT_PLANS,
  UserPermissions.EDIT_PLANS_ASSIGNED,
  UserPermissions.EDIT_FONTERRA_PLAN,
]
export const VIEW_PLAN_PERMISSIONS = UserPermissions.VIEW_PLANS
export const APPROVAL_EDIT_PERMISSIONS = [
  UserPermissions.PROCESS_REVIEW,
  UserPermissions.EDIT_APPROVAL_DELEGATED_AUTHORITY,
  UserPermissions.EDIT_APPROVAL_RECOMMENDATION,
  UserPermissions.EDIT_FONTERRA_APPROVAL_RECOMMENDATION,
  UserPermissions.EDIT_FONTERRA_APPROVAL_DELEGATED_AUTHORITY,
]

export const EDIT_APPLICATION_PROGRESS_PERMISSIONS = [
  UserPermissions.EDIT_PROGRESS,
  UserPermissions.EDIT_PROGRESS_ASSIGNED,
  UserPermissions.EDIT_FONTERRA_PROGRESS_ASSIGNED,
]
