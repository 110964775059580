import { Controller, useFormContext } from 'react-hook-form'
import { BooleanRadio } from '../../../components/boolean-radio'
import { RouteMode } from '../../../types/route'
import { BooleanFieldProps } from '../../types'
import { useFieldErrors } from '../hooks/use-field-errors'
import { useLabelProps } from '../hooks/use-label-props'
import { ReadOnlyField } from './read-only-field'

export function BooleanField<T>(props: BooleanFieldProps<T>) {
  const { fieldId, isRequired, isSkeleton, mode, helpText, isDisabled } = props
  const { control } = useFormContext<T>()

  const { label } = useLabelProps(props)
  if (!label) {
    throw new Error('A label for this component was not provided.')
  }

  const errors = useFieldErrors(fieldId)

  if (mode === RouteMode.VIEW) {
    return <ReadOnlyField {...{ ...props, type: 'read-only' }} />
  }

  return (
    <Controller
      control={control}
      name={fieldId}
      render={({ field }) => (
        <BooleanRadio
          id={fieldId}
          label={label}
          isSkeleton={isSkeleton}
          value={field.value as boolean}
          setValue={(state) => {
            field.onChange(state)
          }}
          isRequired={isRequired}
          isDisabled={isDisabled}
          validationMessages={errors}
          helpText={helpText}
        />
      )}
    />
  )
}
