import { FormField } from '@msaf/core-react'
import { PropsWithChildren } from 'react'
import { FieldValues } from 'react-hook-form'
import { LabelledFieldConfig } from '../../types'
import { useFieldErrors } from '../hooks/use-field-errors'
import { useLabelProps } from '../hooks/use-label-props'

export function Wrapper<T extends FieldValues>(props: PropsWithChildren<LabelledFieldConfig<T>>) {
  const { fieldId, children, ...formFieldProps } = props
  const fieldErrors: string[] = useFieldErrors<T>(fieldId)

  const labelProps = useLabelProps<T>(props)

  return (
    <FormField {...formFieldProps} {...labelProps} validationMessages={fieldErrors}>
      {children}
    </FormField>
  )
}
