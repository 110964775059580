import { Heading, Table } from '@msaf/core-react'
import { FeatureAction } from '../../../../components/pdf/types'
import { getPlantingPreparationMaintenanceCost, getPlantingPreparationMaintenanceSummary } from '../utils'
import { toDollarCurrencyFormat } from '../../../utils'
import { PdfReadOnlyField } from '../../../../components/pdf/read-only-field'
import { ApplicationDetailsFields } from '../../../../routes/plans/[mode]/[id]/application/[applicationId]/details/config'

interface PPMSummaryProps {
  features: FeatureAction[]
  applicationDetailsData: ApplicationDetailsFields | undefined
}

export const PlantingPreparationMaintenanceSummary = ({ features, applicationDetailsData }: PPMSummaryProps) => {
  const tablePages = 1
  const aggregates = getPlantingPreparationMaintenanceSummary(applicationDetailsData, features)

  // Format aggregates for presentation
  const data = aggregates.map((feature) => ({
    ...feature,
    estimatedCost: toDollarCurrencyFormat(feature.estimatedCost),
  }))
  const totalCost = getPlantingPreparationMaintenanceCost(aggregates)

  return (
    <section className='l-section--no-break-inside l-pdf-container--margin-small'>
      <Heading level={3} className='c-field__heading--margin-small'>
        Planting, preparation, maintenance and project admin costings
      </Heading>
      <Table
        tableData={data}
        showResultsCount={false}
        totalResults={data.length}
        totalPages={tablePages}
        setPage={() => tablePages}
        currentPage={tablePages}
        setSortColumn={() => 'locationName'}
        defaultSortBy={{
          orderColumn: 'locationName',
          orderDirection: 'desc',
        }}
        columnHeaders={[
          {
            elementKey: 'action',
            columnHeading: 'Actions',
            viewColumn: 'action',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'quantity',
            columnHeading: 'Quantity',
            viewColumn: 'quantity',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'estimatedCost',
            columnHeading: 'Estimated Total Cost ($)',
            viewColumn: 'estimatedCost',
            sortable: false,
            type: 'text',
          },
        ]}
      />
      <PdfReadOnlyField
        fieldName='Sub-total planting, preparation, maintenance and project admin costings'
        value={toDollarCurrencyFormat(totalCost)}
      />
    </section>
  )
}
