import { Button, ButtonsContainer, Divider } from '@msaf/core-react'
import {
  AuthStrategy,
  FilterSet,
  LookupDisplayValue,
  RequestOptions,
  SearchQuery,
  SearchQueryByFilter,
  SearchTemplate,
  SupportedDisplayFilterType,
  addValue,
  constructAppliedQueryByFilter,
  createFilterSet,
  getLookupDisplayValue,
  removeAllValues,
  removeRedundantFilters,
  removeValue,
  resetSearch,
  setValue,
} from '@msaf/generic-search-common'
import React, { Component, RefObject } from 'react'
import { AppliedFilters } from './applied-filters'
import { FilterInputs } from './filter-inputs'
import { FilterPills } from './filter-pills'
import { KeywordSearch } from './keyword-search'

export interface FiltersProps<T extends AuthStrategy = 'token'> {
  isLoading?: boolean
  isMapMode?: boolean
  searchTemplate: SearchTemplate
  searchTypeKey: string
  onSearch?: (searchQuery: SearchQuery, resetToFirstPage?: boolean) => void
  actions?: { [key: string]: (...args: any[]) => void }
  requestOptions?: RequestOptions<T>
  spritePath?: string
  dateFormat: string
  searchQuery?: SearchQuery
  saveAction?: () => void
}

export interface FiltersState {
  showAppliedFilters?: boolean
  noFiltersSelected: boolean
  query: SearchQueryByFilter
  searchQuery: SearchQuery
  searchButtonRef?: RefObject<HTMLButtonElement>
  hasKeywordSearch?: boolean
}

const EMPTY_FILTER_SET: FilterSet = { filterSet: { booleanOperator: 'AND', filters: [] } }

/**
 * This component encapsulates the core of the search filter logic
 * TODO:
 * Consider making this a functional component and break the functionalities into smaller, manageable pieces using hooks.
 */
export class Filters<T extends AuthStrategy = 'token'> extends Component<FiltersProps<T>, FiltersState> {
  private searchButtonRef: RefObject<HTMLButtonElement>

  constructor(props: FiltersProps<T>) {
    super(props)

    const byFilter: SearchQueryByFilter = {}
    props.searchTemplate.filters.forEach((f) => {
      byFilter[f.filterKey] = []
    })

    this.state = {
      noFiltersSelected: !this.props.searchQuery?.filterSet?.filters.length,
      query: byFilter,
      searchQuery: this.props.searchQuery || { searchTypeKey: props.searchTypeKey, ...EMPTY_FILTER_SET },
      showAppliedFilters: !!this.props.searchQuery?.filterSet?.filters.length,
      hasKeywordSearch: !!byFilter['keywordSearch'],
    }

    this.searchButtonRef = React.createRef()
  }

  componentDidMount() {
    const { query, searchQuery } = this.state
    const appliedQuery = this.updateAppliedQueryByFilter(searchQuery, query)
    this.setState({ query: appliedQuery })
  }

  componentDidUpdate(prevProps: FiltersProps<T>, prevState: FiltersState) {
    const { noFiltersSelected, searchQuery } = this.state

    const keywordSearchChanged = prevState.query.keywordSearch !== this.state.query.keywordSearch

    // Perform search when all filters are cleared and the search query is updated
    // This will be triggered on `Reset search` or on manually clearing all the filters
    if (noFiltersSelected && JSON.stringify(searchQuery) !== JSON.stringify(prevState.searchQuery)) {
      this.performSearch(false)
    }

    // If the search query has changed, the local state is no longer valid.
    // So, reset the local state using the new search query unless keywordSearch
    if (
      prevProps.searchQuery?.searchTypeKey !== this.props.searchQuery?.searchTypeKey ||
      (!keywordSearchChanged && JSON.stringify(prevProps.searchQuery) !== JSON.stringify(this.props.searchQuery))
    ) {
      const byFilter: SearchQueryByFilter = {}
      this.props.searchTemplate.filters.forEach((f) => {
        byFilter[f.filterKey] = []
      })
      const searchQuery = this.props.searchQuery ?? {
        searchTypeKey: this.props.searchTypeKey,
        ...EMPTY_FILTER_SET,
      }
      const appliedQuery = this.updateAppliedQueryByFilter(searchQuery, byFilter)
      this.setState({
        searchQuery,
        query: appliedQuery,
        showAppliedFilters: !!searchQuery?.filterSet?.filters.length,
        noFiltersSelected: Object.keys(appliedQuery).filter((k) => appliedQuery[k].length > 0).length === 0,
      })
    }
  }

  /**
   * Updates the query grouped by filters by merging the values from applied search query filters
   * @param searchQuery Applied search query
   * @param queryByFilter Query grouped by filters in search template
   * @returns Query grouped by filters
   */
  updateAppliedQueryByFilter(searchQuery: SearchQuery, queryByFilter: SearchQueryByFilter) {
    let appliedQuery = queryByFilter
    if (searchQuery?.filterSet?.filters.length) {
      const appliedQueryByFilter: SearchQueryByFilter = {}
      constructAppliedQueryByFilter(searchQuery.filterSet.filters, appliedQueryByFilter)
      appliedQuery = { ...queryByFilter, ...appliedQueryByFilter }
    }
    return appliedQuery
  }

  /**
   * This gets called after changing the internal representation of the query, in order to construct:
   * 1. The searchQuery JSON
   * 2. The filter values passed to child components
   */
  queryChanged() {
    let baseQuery = createFilterSet(
      Object.keys(this.state.query).map((k) => createFilterSet(this.state.query[k], 'OR')),
      'AND',
    )

    const searchFilters = removeRedundantFilters(baseQuery, this.props.searchTemplate, true, false)

    if (searchFilters === undefined) {
      this.setState({
        searchQuery: { searchTypeKey: this.props.searchTypeKey, ...createFilterSet([], 'AND') },
      })
    } else {
      if ('filterSet' in searchFilters) {
        this.setState({ searchQuery: { searchTypeKey: this.props.searchTypeKey, ...searchFilters } })
      } else {
        throw new Error('removeRedundantFilters failed to produce a consistent filterSet')
      }
    }

    const noFiltersSelected = Object.keys(this.state.query).filter((k) => this.state.query[k].length > 0).length === 0
    this.setState({ noFiltersSelected })
  }

  /**
   * Adds a new instance of a filter to the state to be rendered
   * @param filterKey Filter to add
   */
  addValue(filterKey: string) {
    if (filterKey in this.state.query) {
      this.setState(
        ({ query, ...rest }) => {
          const filterTemplate = this.props.searchTemplate.filters.find((f) => f.filterKey === filterKey)
          if (filterTemplate === undefined) {
            throw new Error('filterKey must be present in template')
          }

          const newQuery = addValue(
            filterKey,
            query,
            // The filter type, at this point, should only the supported display filter types
            filterTemplate.type as SupportedDisplayFilterType,
            filterTemplate.filterOperator,
            filterTemplate.initialValue,
          )
          const hasKwSearch = newQuery.keywordSearch && newQuery.keywordSearch.length > 0
          return {
            ...rest,
            query: newQuery,
            hasKeywordSearch: hasKwSearch,
          }
        },
        () => {
          this.queryChanged()
        },
      )
    }
  }

  /**
   * Sets the value for a rendered filter
   * @param filterKey Filter to set
   * @param index Index of the filters rendered to set
   * @param value Value to set - must be either a string or a filterSet (in case of date-range)
   */
  setValue(filterKey: string, index: number, value: string | FilterSet) {
    this.setState(
      ({ query, ...rest }) => {
        const newQuery = setValue(filterKey, index, value, query)
        return {
          ...rest,
          query: newQuery,
        }
      },
      () => this.queryChanged(),
    )
  }

  /**
   * Removes a filter from array, dropping down the items rendered
   * @param filterKey Filter to update
   * @param index Index of filter to remove
   */
  removeValue(filterKey: string, index: number) {
    this.setState(
      ({ query, ...rest }) => {
        const newQuery = removeValue(filterKey, index, query)
        return {
          ...rest,
          query: newQuery,
        }
      },
      () => this.queryChanged(),
    )
  }

  /**
   * Resets search to the base state - no filters applied or listed
   */
  resetSearch() {
    this.setState(
      ({ query, ...rest }) => {
        const newQuery = resetSearch(query)
        return {
          ...rest,
          query: newQuery,
        }
      },
      () => this.queryChanged(),
    )
  }

  /**
   * Resets a given filter - removing all active values and setting the pill
   * @param filterKey Filter to reset
   */
  removeAllValues(filterKey: string) {
    this.setState(
      ({ query, ...rest }) => {
        const newQuery = removeAllValues(filterKey, query)
        return {
          ...rest,
          query: newQuery,
        }
      },
      () => this.queryChanged(),
    )
  }

  /**
   * Performs the search with the currently set filters
   * `isCollapseFilters` collapses the filters on search, by default
   */
  performSearch(isCollapseFilters: boolean = true) {
    this.props.onSearch && this.props.onSearch(this.state.searchQuery, false)
    this.setState({ showAppliedFilters: isCollapseFilters })
  }

  /**
   * Fetches the display label for a given lookup value
   */
  lookupResolver = async (value: string, optionsKey?: string, searchTypeKey?: string): Promise<string> => {
    try {
      const result: LookupDisplayValue = await getLookupDisplayValue<T>(
        {
          searchTypeKey,
          optionsKey,
          internalValue: value,
        },
        this.props.requestOptions,
      )

      return result?.lookupDisplayValueResult?.displayValue
    } catch (e: any) {
      // TODO: Add proper error handling
      throw Error(e)
    }
  }

  handleKeyboardEvent: React.KeyboardEventHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.searchButtonRef.current?.focus()
    }
  }

  render() {
    const { showAppliedFilters, noFiltersSelected, query } = this.state
    const {
      isLoading,
      actions,
      isMapMode,
      onSearch,
      searchTemplate,
      searchTypeKey,
      requestOptions,
      dateFormat,
      saveAction,
    } = this.props
    const hasKeywordSearch = searchTemplate.filters.find((f) => f.type === 'keyword-search')

    return (
      <>
        {showAppliedFilters ? (
          <AppliedFilters
            modifyValues={() => this.setState({ showAppliedFilters: false })}
            query={query}
            searchTemplate={searchTemplate}
            lookupResolver={this.lookupResolver}
            saveEnabled={!searchTemplate.savedSearchDisabled}
            isSkeleton={isLoading}
            showLabelsSeparately={isMapMode}
            saveAction={saveAction}
          />
        ) : (
          <>
            {hasKeywordSearch && (
              <KeywordSearch
                query={this.state.query}
                searchQuery={this.props.searchQuery}
                onSearch={onSearch}
                addValue={(filter: string) => this.addValue(filter)}
                setValue={(filter: string, index: number, value: string | FilterSet) =>
                  this.setValue(filter, index, value)
                }
                removeValue={(filter: string, index: number) => this.removeValue(filter, index)}
                onKeyDown={this.handleKeyboardEvent}
              />
            )}
            <FilterPills
              filters={searchTemplate.filters.filter((f) => f.type !== 'keyword-search')}
              filterCategories={searchTemplate.filterCategories}
              addValue={(key) => {
                this.addValue(key)
              }}
              removeAllValues={(key) => this.removeAllValues(key)}
              query={query}
              isSkeleton={isLoading}
            />
            <Divider isFullWidth verticalSpacingSize='small' />
            <FilterInputs<T>
              filters={Object.entries(this.state.query).filter(([key]) => key !== 'keywordSearch')}
              noFiltersSelected={noFiltersSelected}
              searchTemplate={searchTemplate}
              addValue={(filter: string) => this.addValue(filter)}
              setValue={(filter: string, index: number, value: string | FilterSet) =>
                this.setValue(filter, index, value)
              }
              removeValue={(filter: string, index: number) => this.removeValue(filter, index)}
              search={() => this.performSearch()}
              searchTypeKey={searchTypeKey}
              requestOptions={requestOptions}
              dateFormat={dateFormat}
              onKeyDown={this.handleKeyboardEvent}
            />
            <ButtonsContainer containerStyle='left'>
              {searchTemplate.searchActions &&
                searchTemplate.searchActions.map((action) => {
                  if (!(action.hideForMapMode && isMapMode)) {
                    const actionName = action.action

                    let onClick = actions && actions[action.action]

                    // OnClick for performSearch, etc.
                    if (!onClick && actionName in this) {
                      // Needs to be an arrow function to inherit context from parent
                      onClick = (...args: any[]) => this[actionName as keyof Filters](...args)
                    }
                    const args = (action.args || []).map((a) => {
                      if (a in this.state) {
                        return this.state[a as keyof FiltersState]
                      } else if (a in this.props) {
                        return this.props[a as keyof FiltersProps]
                      }
                      return undefined
                    })

                    return (
                      <Button
                        label={action.label}
                        ref={action.label === 'Search' ? this.searchButtonRef : undefined}
                        key={action.label}
                        onClick={() => onClick && onClick(...args)}
                        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) =>
                          e.key === 'Enter' && onClick && onClick(...args)
                        }
                        buttonStyle={
                          action.isPrimary ? 'primary' : action.label === 'Reset search' ? 'text-action' : 'secondary'
                        }
                        aria-label={action.label}
                      />
                    )
                  }
                  return null
                })}
              {!searchTemplate.searchActions && (
                <>
                  {onSearch && (
                    <Button
                      label='Search'
                      data-selenium-search-perform
                      aria-label='Search'
                      onClick={() => this.performSearch()}
                      onKeyDown={() => this.performSearch()}
                      buttonStyle='primary'
                    />
                  )}
                  <Button
                    label='Reset'
                    aria-label='Reset'
                    data-test-search-reset
                    onClick={() => this.resetSearch()}
                    onKeyDown={() => this.resetSearch()}
                    buttonStyle='text-action'
                  />
                </>
              )}
            </ButtonsContainer>
          </>
        )}
      </>
    )
  }
}
