import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { useQuery } from 'react-query'
import { Outlet, useParams } from 'react-router-dom'
import { ApplicationsState, usePlanWorkflowLinks } from '../../../../config/workflow-nav'
import { useRequest } from '../../../../hooks/useRequest'
import { RouteParams } from '../../../../types/route'
import { useWorkflowState } from '../../../../validation/hooks'
import { PlanWorkflowStateProvider } from '../../../../contexts/plan-workflow-context'

export const Plan = () => {
  const { id, mode } = useParams<RouteParams>()

  const { client } = useRequest()

  const project_url = `/api/plan/${id}/project/`
  const { data: applications, refetch: refreshApplications } = useQuery<Array<ApplicationsState>>([project_url], () =>
    client.get(project_url).then((response) => response.data),
  )

  const { data: planWorkflowState, refetch: refetchPlanWorkflowState } = useWorkflowState(id, 'plan', mode)

  const workflowNavLinks = usePlanWorkflowLinks(id, mode, planWorkflowState, applications)

  return (
    <PlanWorkflowStateProvider
      planWorkflowState={{
        refetchState: () => {
          refreshApplications()
          refetchPlanWorkflowState()
        },
      }}
    >
      <RecordContainer workflowNav={<WorkflowNav sections={workflowNavLinks} />}>
        <Outlet />
      </RecordContainer>
    </PlanWorkflowStateProvider>
  )
}
