import React, { MutableRefObject, useEffect, useRef } from 'react'
import { TextInput } from '@msaf/core-react'
import { FilterProps } from '../../../utils'
import { AuthStrategy } from '@msaf/generic-search-common'

export function FilterText<T extends AuthStrategy = 'token'>({
  value,
  setValue,
  labelledBy,
  onKeyDown,
}: FilterProps<T>) {
  if (typeof value !== 'string') throw new Error('Invalid value type for `text`')

  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  useEffect(() => {
    inputRef && (inputRef as MutableRefObject<HTMLInputElement>).current.focus()
  }, [inputRef])

  return (
    <TextInput
      id={labelledBy}
      type='text'
      value={value}
      onBlur={(e) => setValue(e.target.value)}
      onKeyDown={onKeyDown}
      ref={inputRef}
    />
  )
}
