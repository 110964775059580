import classNames from 'classnames'
import React from 'react'

export type LabelStyle = 'default' | 'uppercase' | 'hidden'

export interface LabelProps {
  labelText: string
  labelTag?: 'label' | 'legend' | 'div'
  htmlFor?: string
  labelId?: string
  labelStyle?: LabelStyle
  className?: string
  isSkeleton?: boolean
  hasNoVerticalSpacing?: boolean
}

export function Label({
  labelTag: LabelTag = 'label',
  htmlFor,
  labelId,
  labelText,
  labelStyle = 'default',
  className,
  isSkeleton,
  children,
  hasNoVerticalSpacing = false,
}: React.PropsWithChildren<LabelProps>) {
  if (isSkeleton && labelStyle !== 'hidden') return <div className='c-skeleton c-skeleton--label' />

  const classes =
    labelStyle === 'hidden'
      ? classNames('a11y--screen-reader-only')
      : classNames('c-label', {
          [`c-label--${labelStyle}`]: labelStyle !== 'default',
          [`${className}`]: !!className,
          'c-label--no-vertical-spacing': hasNoVerticalSpacing,
        })

  return (
    <LabelTag className={classes} htmlFor={htmlFor} id={labelId}>
      <span className='c-label__text'>{labelText}</span>
      {children}
    </LabelTag>
  )
}
