import { AdvancedSearchMap } from '@msaf/generic-search-maps-react'
import { useTitleChange } from '@msaf/core-react'
import { DEFAULT_MARKER, linzBasemap, SELECTED_MARKER } from '../../../config/map'
import { useAuth } from '../../../auth'
import { SEARCH_API_URL } from '../../../environment'
import L from 'leaflet'
import { AddressLookupPanel } from '../../../components/address-lookup-panel'
import { useEffect, useState } from 'react'
import { usePropertyBoundariesLayer } from '../../../hooks/use-property-boundaries-layer'

export function PlanMapSearch() {
  useTitleChange('Plan - Map search')
  const { authStrategy } = useAuth()
  const [map, setMap] = useState<L.Map | undefined>()

  if (!authStrategy) {
    throw Error('403 - This page is only able to be reached with authenticated access')
  }

  const { layer: propertyBoundariesLayer } = usePropertyBoundariesLayer({
    style: { color: '#FFE600', fillColor: '#FFE60077', weight: 2 },
  })

  useEffect(() => {
    if (map && propertyBoundariesLayer) {
      propertyBoundariesLayer.layer.addTo(map)
      return () => {
        map.removeLayer(propertyBoundariesLayer.layer)
      }
    }
  }, [propertyBoundariesLayer, map])

  return (
    <AdvancedSearchMap
      setMap={(map) => {
        setMap(map)
      }}
      searchTypes={[
        {
          key: 'plan-search',
          label: 'SRPs',
        },
        {
          key: 'project-search',
          label: 'Projects',
        },
      ]}
      requestOptions={{
        urlPrefix: `${SEARCH_API_URL}`,
        authStrategy: 'token',
        authToken: authStrategy.token,
        authTokenScheme: authStrategy.tokenIdentifier,
      }}
      additionalMenuItems={[
        {
          menuName: 'Location search',
          menuKey: 'location',
          menuIcon: 'location',
          menuComponent: <AddressLookupPanel map={map} />,
        },
      ]}
      mapOptions={{
        baseLayers: L.layerGroup([linzBasemap()]),
      }}
      defaultIcon={DEFAULT_MARKER}
      selectedMarkerIcon={SELECTED_MARKER}
      backLinkConfig={{
        path: '/plans',
        label: 'Back to list search',
        mobileLabel: 'Back',
      }}
      showMapPageHeader
      appLogo={{
        icon: 'map',
      }}
    />
  )
}
