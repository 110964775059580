import { useCallback, useState } from 'react'

export interface TableState {
  rowId: number | null
  showModal: boolean
  refreshTable: boolean
  deleteAction: (id: number) => void
  postDeleteAction: () => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function useTableState(): TableState {
  const [rowId, setRowId] = useState<number | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)

  const deleteAction = useCallback((id: number) => {
    setRowId(id)
    setShowModal(true)
  }, [])

  const postDeleteAction = useCallback(() => {
    setShowModal(false)
    setRefreshTable(!refreshTable)
  }, [])

  return {
    deleteAction,
    postDeleteAction,
    setShowModal,
    rowId,
    showModal,
    refreshTable,
  }
}
