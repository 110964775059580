import { ReactNode, useMemo } from 'react'
import { AtomConfig, FormElementConfig } from '../../../../../../../forms/types'
import {
  buildErrorsForDisplay,
  toDollarCurrencyFormat,
  toDollarCurrencyFormatter,
} from '../../../../../../../forms/utils'
import { ApplicationDetailsFields } from '../details/config'
import { BuiltFormError } from '../../../../../../../validation/constants'

type ApplicationSubmissionPrerequisites = {
  errors: BuiltFormError[]
  canBeSubmitted?: boolean
  isSubmitted?: boolean
}

const getSubmissionMessages = ({
  errors,
  canBeSubmitted,
  isSubmitted,
}: ApplicationSubmissionPrerequisites): AtomConfig<ApplicationDetailsFields>[] => {
  let content: ReactNode
  let messageType: 'warning' | 'info'

  let additionalMessage: string | undefined

  if (isSubmitted == null || canBeSubmitted == null) {
    // Currently loading, return nothing
    return []
  } else if (!canBeSubmitted) {
    content =
      'Only one project will be reviewed and approved at a time. This project cannot be submitted until an earlier project has been approved.'
    messageType = 'warning'
  } else {
    if (errors.length) {
      content = (
        <>
          <span>There are still a few things to complete before submitting:</span>
          {buildErrorsForDisplay(errors)}
        </>
      )
      messageType = 'warning'
      additionalMessage =
        'Once all required details have been provided, you will be able to submit the sediment reduction plan and accompanying project.'
    } else {
      if (isSubmitted) {
        content = 'This project has been submitted for review'
        messageType = 'info'
      } else {
        content =
          'Once submitted the sediment reduction plan and corresponding project will be reviewed by the KMR maurikura and technical panel'
        messageType = 'info'
        additionalMessage =
          'You will be notified if any changes to the sediment reduction plan or project are required.'
      }
    }
  }

  const messages: AtomConfig<ApplicationDetailsFields>[] = [
    {
      type: 'atom',
      element: {
        type: 'inline-notification',
        isDismissable: false,
        messageType,
        content,
      },
    },
  ]

  if (additionalMessage) {
    messages.push({
      type: 'atom',
      element: {
        type: 'paragraph',
        content: additionalMessage,
      },
    })
  }

  return messages
}

export const useApplicationSubmissionConfig = (
  prereqs: ApplicationSubmissionPrerequisites,
): FormElementConfig<ApplicationDetailsFields>[] => {
  return useMemo(() => {
    const messages = getSubmissionMessages(prereqs)
    return [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Summary of proposed costs' },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          conversion: toDollarCurrencyFormatter(2),
          fieldId: 'proposedTotals.totalProposedCost',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          conversion: toDollarCurrencyFormatter(2),
          fieldId: 'proposedTotals.landOwnerContribution',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          conversion: toDollarCurrencyFormatter(2),
          fieldId: 'proposedTotals.kmrContribution',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          formPrefix: 'submission',
          conversion: toDollarCurrencyFormat,
          fieldId: 'proposedTotals.totalCoFunderContribution',
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Submit project' },
      },
      {
        type: 'atom',
        element: {
          type: 'plan-warnings',
        },
      },
      ...messages,
    ]
  }, [prereqs])
}
