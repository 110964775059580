import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'
import { ButtonsContainer } from '../../layout/buttons-container'
import { Heading } from '../heading'
import classNames from 'classnames'

// See https://reactcommunity.org/react-modal/accessibility/

export interface BaseModalProps extends Partial<Pick<ReactModal.Props, 'contentLabel' | 'overlayRef' | 'contentRef'>> {
  isOpen: boolean
  onRequestClose: VoidFunction
  className?: string
}

export interface CommonModalProps extends BaseModalProps {
  heading?: string
  buttons?: JSX.Element
  children?: never
}

export interface BoilerplateModalProps extends CommonModalProps {
  bodyText?: never
  body?: ReactNode
}

export interface DeprecatedModalProps extends CommonModalProps {
  bodyText?: string
  body?: never
}

export interface CustomModalProps extends BaseModalProps {
  heading?: never
  bodyText?: never
  body?: never
  buttons?: never
}

export type ModalProps = BoilerplateModalProps | DeprecatedModalProps | React.PropsWithChildren<CustomModalProps>

export function Modal({
  isOpen,
  onRequestClose,
  heading,
  bodyText,
  body: bodyRaw,
  buttons,
  children,
  className,
  ...props
}: ModalProps) {
  const body = bodyRaw ?? bodyText
  if (bodyText) {
    console.warn('Modal: bodyText prop is deprecated and will be removed in a future version, use body instead')
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={classNames('c-modal', className)}
      overlayClassName='c-modal__overlay'
      portalClassName='c-modal__portal'
      {...props}
    >
      {/* Allow custom content inside modal: */}
      {children}

      {/* Boilerplate style - pass in heading, body text and buttons */}
      {!children && (
        <>
          {heading && (
            <Heading level={3} className='c-modal__heading'>
              {heading}
            </Heading>
          )}
          {body && <div className='c-modal__body'>{body}</div>}
          {buttons && <ButtonsContainer containerStyle='right'>{buttons}</ButtonsContainer>}
        </>
      )}
    </ReactModal>
  )
}
