import { ActionsBar, Button, Divider, FormField, Heading, RecordContent, RecordFooter } from '@msaf/core-react'
import { useNavigate } from 'react-router-dom'
import { useViewForm } from '../../../hooks/useViewForm'
import { ReadOnlyField } from '../../../components/read-only-field'
import { UserInputs } from '../type.config'
import { Controller } from 'react-hook-form'
import LookupSearch from '../../../components/lookup-typeahead'

export interface ViewUserFormProps {
  userId?: string
}

export const ViewUserForm = ({ userId }: ViewUserFormProps) => {
  const { control } = useViewForm<UserInputs>({
    url: `/api/user/${userId}`,
    defaults: {},
  })
  const navigate = useNavigate()
  return (
    <>
      <RecordContent>
        <Heading level={2}>User details</Heading>
        <FormField label='First name' htmlFor='firstName'>
          <ReadOnlyField<UserInputs> control={control} fieldName='firstName' labelledBy='firstName' />
        </FormField>
        <FormField label='Last name' htmlFor='lastName'>
          <ReadOnlyField<UserInputs> control={control} fieldName='lastName' labelledBy='lastName' />
        </FormField>
        <FormField label='Email' htmlFor='email'>
          <ReadOnlyField<UserInputs> control={control} fieldName='email' labelledBy='email' />
        </FormField>
        <FormField label='Contact number' htmlFor='contactNumber'>
          <ReadOnlyField<UserInputs> control={control} fieldName='contactNumber' labelledBy='contactNumber' />
        </FormField>
        <FormField label='Organisation' htmlFor='organisation'>
          <ReadOnlyField<UserInputs> control={control} fieldName='organisation' labelledBy='organisation' />
        </FormField>
        <Divider isFullWidth />
        <Heading level={2}>User permissions</Heading>
        <FormField label='Role' labelId='groups'>
          <Controller
            control={control}
            name='groups'
            render={({ field }) => (
              <LookupSearch
                lookupId='role'
                isDisabled
                value={field.value}
                setValue={({ value }) => field.onChange(value)}
                labelledBy={'groups'}
              />
            )}
          />
        </FormField>
        <FormField label='Active' labelId='isActive'>
          <ReadOnlyField<UserInputs> control={control} fieldName='isActive' labelledBy='isActive' />
        </FormField>
      </RecordContent>
      <RecordFooter>
        <ActionsBar>
          <Button
            buttonStyle='primary'
            label='Edit user'
            onClick={() => navigate(`/admin/users/edit/${userId}/user`)}
          />
          <Button buttonStyle='text-action' label='Cancel' onClick={() => navigate('/admin/users')} />
        </ActionsBar>
      </RecordFooter>
    </>
  )
}
