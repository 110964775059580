import { ActionVerificationFields } from '.'

const fieldMapping = Object.freeze({
  actualNumberOfPlants: 'numberOfPlants',
  plantingDifficulty: 'plantingDifficulty',
  actualFenceType: 'fenceType',
  actualLength: 'length',
  actualMaintenanceType: 'maintenanceType',
  actualCost: 'proposedCost',
} as const)

// Pulls the field from the above fallback mapping if its not set in the data.
const getDataOrInitialStateValue = (data: Record<string, unknown>, fieldName: keyof typeof fieldMapping) => {
  // objects are treated as null values. Values should be type checked to ensure they are not treated as a null
  // and default to the fallback value.
  const defaultValue =
    typeof data?.[fieldName] !== 'undefined' ? data?.[fieldName as keyof ActionVerificationFields] : null
  return defaultValue ?? data?.[fieldMapping[fieldName]]
}

export const getActionVariationInitialState = <T extends string>(data: Partial<Record<T, unknown>>) => ({
  ...data,
  hasVariation: ('hasVariation' in data ? data?.['hasVariation' as keyof typeof data] : false) ?? false,
  actualNumberOfPlants: getDataOrInitialStateValue(data, 'actualNumberOfPlants'),
  actualFenceType: getDataOrInitialStateValue(data, 'actualFenceType'),
  actualLength: getDataOrInitialStateValue(data, 'actualLength'),
  actualMaintenanceType: getDataOrInitialStateValue(data, 'actualMaintenanceType'),
  actualCost: getDataOrInitialStateValue(data, 'actualCost'),
})
