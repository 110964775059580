import React, { PropsWithChildren } from 'react'
import { Heading } from '../../common/heading'

export interface DropdownSectionProps {
  headingText?: string
}

export function DropdownSection({ headingText, children }: PropsWithChildren<DropdownSectionProps>) {
  return (
    <div className='c-dropdown-section'>
      {headingText && (
        <Heading level={4} className='c-dropdown-section__heading'>
          {headingText}
        </Heading>
      )}
      {children}
    </div>
  )
}
