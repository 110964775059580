import { useParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { useFormState } from '../../../hooks/use-form-state'
import { ApplicationFeedbackRouteParams, RouteMode } from '../../../types/route'
import { Form } from '../../components/form'
import { ApplicationPanelistReviewFields, useApplicationPanelistReviewConfig } from './config'
import { useMemo } from 'react'
import { useMutationWithToast } from '../../../hooks/use-mutation-with-toast'

export function ApplicationPanelistReviewForm() {
  const { id: planId, applicationId, reviewId, mode } = useParams<ApplicationFeedbackRouteParams>()
  const { data, isLoading, errors, isEditable, refetchErrors, refetchData } =
    useFormState<ApplicationPanelistReviewFields>(
      () => `/api/plan/${planId}/project/${applicationId}/panelist-review/${reviewId}`,
      {
        workflowId: 'review',
        sectionId: 'review',
        formId: 'panelistReview',
        instanceId: `${applicationId}`,
        subFormId: reviewId,
      },
    )

  const mutation = useMutationWithToast<
    AxiosResponse<ApplicationPanelistReviewFields>,
    AxiosError<Map<keyof ApplicationPanelistReviewFields, Array<string>>>,
    ApplicationPanelistReviewFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${planId}/project/${applicationId}/panelist-review/${reviewId}`,
    success: {
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
      toastMessage: 'Panel feedback saved.',
    },
    error: {
      toastMessage: 'Could not save panel feedback.',
    },
  })

  const onSubmit = async (data: ApplicationPanelistReviewFields) => {
    await mutation.mutateAsync(data)
  }

  const config = useApplicationPanelistReviewConfig(data, mode, planId, applicationId)

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          plantingRecommendedVariations: data?.recommendedVariations?.length
            ? data.recommendedVariations.filter((action) => action.featureCategory == 'planting')
            : [{}],
          fencingRecommendedVariations: data?.recommendedVariations?.length
            ? data.recommendedVariations.filter((action) => action.featureCategory == 'fencing')
            : [{}],
          gateTroughRecommendedVariations: data?.recommendedVariations?.length
            ? data.recommendedVariations.filter((action) => action.featureCategory == 'gate_trough')
            : [{}],
        }
      : undefined
  }, [data])

  return (
    <Form<ApplicationPanelistReviewFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={isEditable && !!data?.meetsPanelReviewThreshold}
      config={config}
      submitAction={onSubmit}
    />
  )
}
