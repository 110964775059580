import React, { useCallback, useMemo } from 'react'
import { Pill, Label, GroupContainer } from '@msaf/core-react'
import {
  FilterCategory,
  FilterCategoryStructure,
  FilterStructure,
  SearchQueryByFilter,
} from '@msaf/generic-search-common'

const NO_CATEGORY_ASSIGNED = 'no-category'

export interface FilterPillsProps {
  filtersLabel?: string
  filterCategories?: FilterCategoryStructure
  filters: Array<FilterStructure>
  addValue: (key: string) => void
  removeAllValues: (key: string) => void
  query: SearchQueryByFilter
  isSkeleton?: boolean
}

export function FilterPills({
  filtersLabel = 'Search criteria',
  filterCategories,
  filters,
  addValue,
  removeAllValues,
  query,
  isSkeleton,
}: FilterPillsProps) {
  const filterCategoriesMap: Map<string, FilterCategory> = useMemo(() => {
    let categories = new Map<string, FilterCategory>()
    categories.set(NO_CATEGORY_ASSIGNED, { displayName: '' })
    if (filterCategories) {
      // Only categories which have any filters assigned will be included
      // Map will be sorted by order of appearance in filter definitions
      filters.forEach(({ category }) => {
        category && categories.set(category, filterCategories[category])
      })
    }
    return categories
  }, [filterCategories])

  const getFiltersForCategoryKey = useCallback(
    (categoryKey: string) => {
      return filters.filter(
        ({ category }) => category === categoryKey || (categoryKey === NO_CATEGORY_ASSIGNED && category === undefined),
      )
    },
    [filters],
  )

  return (
    <GroupContainer maxWidth='large' verticalSpacing='x-small'>
      <fieldset>
        <Label
          labelText={filtersLabel}
          labelId={`${filtersLabel.replace(' ', '')}-label`}
          labelTag='legend'
          isSkeleton={isSkeleton}
        />
        {Array.from(filterCategoriesMap.keys()).map((categoryKey) => {
          return (
            <GroupContainer
              maxWidth='large'
              verticalSpacing={categoryKey === NO_CATEGORY_ASSIGNED ? 'none' : 'x-small'}
            >
              <fieldset>
                {categoryKey !== NO_CATEGORY_ASSIGNED && (
                  <Label
                    labelText={filterCategoriesMap.get(categoryKey)?.displayName ?? ''}
                    labelId={`${categoryKey}-label`}
                    labelTag='div'
                    isSkeleton={isSkeleton}
                  />
                )}
                <GroupContainer>
                  {getFiltersForCategoryKey(categoryKey).map(({ label, filterKey }) => {
                    const isSelected = !!query[filterKey]?.length
                    return (
                      <Pill
                        key={`pill-${categoryKey}-${filterKey}`}
                        label={label}
                        isSelected={isSelected}
                        isSkeleton={isSkeleton}
                        handleClick={() => (isSelected ? removeAllValues(filterKey) : addValue(filterKey))}
                      />
                    )
                  })}
                </GroupContainer>
              </fieldset>
            </GroupContainer>
          )
        })}
      </fieldset>
    </GroupContainer>
  )
}
