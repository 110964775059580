import { useNavigate } from '@msaf/router-react'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import { FormConfig, LabelledFieldConfig } from '../../../../../../../../forms/types'
import { toDollarCurrencyFormatter } from '../../../../../../../../forms/utils'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { useTableState } from '../../../../../../../../hooks/use-table-state'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { FieldWatchResultProps } from '../../../../../../../../forms/components/hooks/use-field-watch'
import { ColumnHeader } from '@msaf/core-react'
import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'

export interface RequestForPaymentFields {
  totalCostsSummary: {
    totalActualCost: string
    totalAgreedKmrContribution: string
    totalKmrPaymentAmount: string
    totalProposedCost: string
  }
  dateContractEnds: string | null
  dateContractEndsVariation: boolean
  dateContractEndsVariationDate: string | null
  newPaymentBtn: null
  completedDate: string | null
  notes: AppNoteType[]
}

export interface RequestForPaymentTableFields {
  id: string
  panelist: string
  dateReviewed: string
  changesRecommended: string
  meetsPanelReviewThreshold: boolean
}

export const useFormConfig = (
  data: RequestForPaymentFields | undefined,
  mode: RouteMode | undefined,
): FormConfig<RequestForPaymentFields> => {
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const { user } = useAuth()
  const { rowId, showModal, refreshTable, setShowModal, deleteAction, postDeleteAction } = useTableState()
  const navigate = useNavigate()

  const createPayment = usePostAction<
    Partial<RequestForPaymentTableFields>,
    Partial<RequestForPaymentTableFields>,
    AxiosError<{ message: string | undefined }>
  >({
    url: `/api/plan/${planId}/project/${applicationId}/payments/`,
    displayServerError: true,
    messages: {
      success: 'Payment created.',
      error: 'Could not create payment.',
    },
  })

  return useMemo(() => {
    return [
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Request for payment summary',
        },
      },
      {
        type: 'field',
        element: {
          type: 'table',
          currentUserPermissions: user?.permissions,
          columnHeaders: [
            {
              elementKey: 'id',
              columnHeading: 'Id',
              viewColumn: 'id',
              type: 'hidden',
            },
            {
              elementKey: 'kmrPaymentIdentifier',
              columnHeading: 'Payment ID',
              viewColumn: 'kmrPaymentIdentifier',
              sortable: true,
              type: 'text',
            },
            {
              elementKey: 'paymentReason',
              columnHeading: 'Payment for',
              viewColumn: 'paymentReason',
              sortable: true,
              type: 'text',
            },
            {
              elementKey: 'paymentRegistered',
              columnHeading: 'Payment registered',
              viewColumn: 'paymentRegistered',
              sortable: true,
              type: 'text',
            },
            {
              elementKey: 'kmrPaymentAmount',
              columnHeading: 'Payment amount',
              viewColumn: 'kmrPaymentAmount',
              sortable: true,
              type: 'currency',
            },
            {
              elementKey: 'actions',
              columnHeading: '',
              viewColumn: 'actions',
              type: 'actions-no-data',
              actions:
                mode == RouteMode.EDIT
                  ? [
                      {
                        label: 'Delete',
                        type: 'deleteAction',
                        args: [
                          {
                            elementKey: 'id',
                          },
                        ],
                        actionPermissions: [UserPermissions.EDIT_GRANT_PAYMENT],
                      },
                    ]
                  : [],
            },
          ] as ColumnHeader[],
          refreshTable: refreshTable,
          tableActionHandlers: {
            deleteAction,
          },
          defaultSortBy: {
            orderColumn: 'id',
            orderDirection: 'desc',
          },
          requestUrl: `/api/plan/${planId}/project/${applicationId}/payments`,
          rowClickAction: {
            label: 'View',
            type: 'transitionAction',
            args: [
              { constant: `plans/${mode}/${planId}/payments/project/${applicationId}/payments` },
              { elementKey: 'id' },
            ],
          },
          noResultsMessage: `No payments added`,
        },
      },
      {
        type: 'action',
        element: {
          fieldId: 'newPaymentBtn',
          buttonStyle: 'primary',
          type: 'button',
          icon: 'add',
          iconAlignment: 'left',
          label: 'New payment',
          className: 'c-btn--form-field',
          onClick: () => {
            createPayment.mutate(
              {},
              {
                onSuccess: ({ data }) => {
                  navigate(`/plans/${mode}/${planId}/payments/project/${applicationId}/payments/${data.id}`)
                },
              },
            )
          },
        },
      },
      {
        type: 'modal',
        element: {
          isOpen: showModal,
          type: 'confirm-action',
          heading: 'Delete payment action',
          body: 'Are you sure you want to delete this payment?',
          contentLabel: 'Payment delete confirmation dialog',
          successMessage: 'Payment deleted',
          errorMessage: 'Could not delete payment. Please try again.',
          primaryActionLabel: 'Delete',
          requestUrl: `/api/plan/${planId}/project/${applicationId}/payments/${rowId}`,
          postActionFunc: postDeleteAction,
          onRequestClose: () => setShowModal(false),
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Summary of costs',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalCostsSummary.totalProposedCost',
          conversion: toDollarCurrencyFormatter(2),
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalCostsSummary.totalActualCost',
          conversion: toDollarCurrencyFormatter(2),
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalCostsSummary.totalAgreedKmrContribution',
          conversion: toDollarCurrencyFormatter(2),
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalCostsSummary.totalKmrPaymentAmount',
          conversion: toDollarCurrencyFormatter(2),
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEnds,
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEnds,
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Contract dates',
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEnds,
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'dateContractEnds',
          isDisabled: true,
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEnds,
        type: 'field',
        element: {
          fieldId: 'dateContractEndsVariation',
          type: 'boolean',
          isRequired: true,
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEnds,
        type: 'field',
        element: {
          fieldId: 'dateContractEndsVariationDate',
          type: 'date',
          isDisabled: !data?.dateContractEndsVariationDate,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<RequestForPaymentFields>) => {
            if (info.type === 'change' && info.name === 'dateContractEndsVariation') {
              if (!data?.dateContractEndsVariation) {
                methods.setValue('dateContractEndsVariationDate', null)
                return {
                  ...(initialConfig as LabelledFieldConfig<RequestForPaymentFields>),
                  type: 'date',
                  isDisabled: true,
                }
              } else {
                methods.setValue('dateContractEndsVariationDate', methods.getValues('dateContractEnds'))
                return {
                  ...(initialConfig as LabelledFieldConfig<RequestForPaymentFields>),
                  type: 'date',
                  isDisabled: false,
                }
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'completedDate',
          maxDate: formatDate(new Date(), INTERNAL_DATE_FORMAT),
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.REQUEST_FOR_PAYMENT,
        },
      },
    ]
  }, [user, planId, applicationId, mode, showModal, data?.dateContractEnds])
}
