import { ReactNode, useMemo } from 'react'
import { DefaultValues } from 'react-hook-form'
import { RouteMode } from '../../types/route'
import { FormErrors } from '../../validation/constants'
import { Form } from '../components/form'
import { getEoiConfig, getEoiWatch } from './eoi-config'
import { EoiFormFields } from './type.config'

export * from './type.config'

export interface EoiFormProps {
  initialState?: DefaultValues<EoiFormFields> | EoiFormFields
  submitAction: (data: EoiFormFields, submitter: HTMLElement | null) => Promise<void>
  onValidate?: (state: boolean) => void
  isSkeleton?: boolean
  mode: RouteMode
  errors?: FormErrors<EoiFormFields>
  footerActions?: ReactNode
  nextPath?: string
  canEdit?: boolean
}

export const EoiForm = ({
  initialState,
  submitAction,
  onValidate,
  errors,
  mode,
  footerActions,
  nextPath,
  canEdit,
}: EoiFormProps) => {
  const hardValidator = useMemo(() => {
    if (onValidate) {
      return getEoiWatch(onValidate)
    }
    return undefined
  }, [onValidate])

  const config = useMemo(() => {
    return getEoiConfig()
  }, [initialState])

  return (
    <Form<EoiFormFields>
      initialState={initialState}
      fieldWatch={hardValidator}
      footerActions={footerActions}
      config={config}
      nextPath={nextPath}
      canEdit={canEdit}
      submitAction={submitAction}
      mode={mode}
      errors={errors}
    />
  )
}
