import * as L from 'leaflet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MapCreationOptions, MAP_DEFAULTS } from '@msaf/maps-common'
import { LeafletMap } from '../leaflet-map'
import { SkeletonMap } from '../skeleton-map'

interface InlineMapProps {
  setLocation?: (coordinates: [number, number]) => void
  position?: L.LatLngTuple
  markerIcon?: L.Icon<L.IconOptions>
  getBaseLayers?: () => L.LayerGroup<any>
  gotoStartPosition?: boolean
  zoom?: number
  isMarkerDraggable?: boolean
  showMarker?: boolean
  center?: L.LatLngExpression
  isEditable?: boolean
  isSkeleton?: boolean
  setMap?: (map: L.Map | undefined) => void
}

export function InlineMap({
  setLocation,
  position,
  markerIcon,
  getBaseLayers,
  gotoStartPosition = false,
  zoom,
  isMarkerDraggable = true,
  showMarker = true,
  center = MAP_DEFAULTS.DEFAULT_CENTER,
  isEditable = true,
  isSkeleton = false,
  setMap,
}: InlineMapProps) {
  const [map, setInternalMap] = useState<L.Map | undefined>()
  const [marker, setMarker] = useState<L.Marker<any> | undefined>()
  const [isLocationChangedByUser, setIsLocationChangedByUser] = useState(false)

  const isMarkerMovable = isMarkerDraggable && isEditable

  const moveMarker = useCallback(
    (location: L.LatLngExpression, updateMapPosition: boolean = false) => {
      if (!map || !showMarker) return

      const newMarker = new L.Marker(location, { icon: markerIcon, draggable: isMarkerMovable })
      marker && map.removeLayer(marker)
      newMarker.addTo(map)
      setMarker(newMarker)

      if (updateMapPosition) {
        const currentZoom = map.getZoom()
        const newZoom = zoom && currentZoom > zoom ? currentZoom : zoom
        map.setView(location, newZoom)
      }
    },
    [map, marker, showMarker, zoom, markerIcon, isMarkerMovable],
  )

  useEffect(() => {
    if (map && position) {
      /**
       * Move the marker to the specified position if
       * a. The map is in view mode (i.e. isMarkerDraggable is set to false) or
       * b. The coordinates were set by a set of form fields - Latitude/Longitude and/or Easting/Northing fields). In that case gotoStartPosition is set to true.
       *
       * If its none of the above two conditions, then the user has dragged the marker and set it to a particular position on the map.
       * So, we need not worry about updating the map view ourselves
       */
      moveMarker(position, !isLocationChangedByUser || gotoStartPosition)
    }
    // Disable due to suggestion of moveMarker which causes infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, position, isLocationChangedByUser, gotoStartPosition])

  const mapUpdated = useCallback(
    (map?: L.Map) => {
      setInternalMap(map)
      setMap?.(map)

      if (!map) return

      if (isMarkerMovable) {
        map.on('click', ({ latlng }: L.LeafletMouseEvent) => {
          moveMarker(latlng)
          setLocation?.([latlng.lat, latlng.lng])
          // Prevents it from entering an infinite loop where the
          // start position gets reset each time the user sets a new location
          setIsLocationChangedByUser(true)
        })
      }

      // gis.stackexchange.com/questions/111887/leaflet-mouse-wheel-zoom-only-after-click-on-map
      // Sensible defaults for in-line map to prevent map scrolling on page scroll
      map.scrollWheelZoom.disable()
      map.on('focus', () => {
        map.scrollWheelZoom.enable()
      })
      map.on('blur', () => {
        map.scrollWheelZoom.disable()
      })
    },
    [isMarkerMovable, setInternalMap, setMap, setLocation, setIsLocationChangedByUser, moveMarker],
  )

  const disableControls = useMemo<MapCreationOptions>(() => {
    return isEditable
      ? {}
      : {
          dragging: false,
          closePopupOnClick: false,
          keyboard: false,
          tap: false,

          // All Zoom
          zoomControl: false,
          scrollWheelZoom: false,
          touchZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
        }
  }, [isEditable])

  if (isSkeleton) return <SkeletonMap isInlineMap={true} />

  return (
    <LeafletMap
      options={{
        center: center,
        crs: MAP_DEFAULTS.CRS,
        ...(getBaseLayers && { baseLayers: getBaseLayers() }),
        zoom,
        ...disableControls,
      }}
      setMap={mapUpdated}
      isInlineMap={true}
    />
  )
}
