import { NewFile, UploadedFile } from '@msaf/core-react'
import { AxiosInstance } from 'axios'
import { useCallback } from 'react'

export const useFileUpload = (client: AxiosInstance) =>
  useCallback(
    async (file: NewFile) => {
      const formData = new FormData()
      formData.append('file', file.file)
      const { data } = await client.post<Omit<UploadedFile, 'state'>>(`/api/files/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return data
    },
    [client],
  )
