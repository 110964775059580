import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Card } from '../card'
import { HeadingRow } from '../../layout'
import { Icon } from '../icon'
import { CollapsibleCardProps } from './types'

export function CollapsibleCard({
  heading,
  shouldDisplayCard = false,
  expandCard = { text: 'Add', icon: 'add' },
  collapseCard = { text: 'Remove', icon: 'trash' },
  onOpen,
  onCollapse,
  children,
}: React.PropsWithChildren<CollapsibleCardProps>) {
  const [displayCard, setDisplayCard] = useState(shouldDisplayCard)
  const { text: expandText, icon: expandIcon } = expandCard
  const { text: collapseText, icon: collapseIcon } = collapseCard

  useEffect(() => {
    setDisplayCard(shouldDisplayCard)
  }, [shouldDisplayCard])

  const contentClasses = classNames('c-collapsible-card__content', {
    'c-collapsible-card__content--display': displayCard,
  })

  const handleActionBtnClick = () => {
    if (displayCard) {
      onCollapse && onCollapse()
      setDisplayCard(false)
    } else {
      onOpen && onOpen()
      setDisplayCard(true)
    }
  }
  return (
    <Card>
      <HeadingRow
        headingLevel={4}
        headingText={heading}
        headingClassNames='c-collapsible-card__heading'
        verticalSpacing='none'
      >
        <AnimatePresence initial={false}>
          <button
            onClick={handleActionBtnClick}
            className='c-btn c-btn--secondary c-btn--icon-left c-collapsible-card__btn'
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ type: 'easeInOut', duration: 0.2 }}
            >
              {displayCard ? collapseText : expandText}
            </motion.span>
            <Icon
              icon={displayCard ? collapseIcon : expandIcon}
              ariaHidden={true}
              className='c-btn__icon--align-left c-btn__icon'
            />
          </button>
        </AnimatePresence>
      </HeadingRow>
      <div className={contentClasses}>
        <AnimatePresence initial={false}>
          {displayCard && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ ease: 'easeInOut' }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Card>
  )
}
