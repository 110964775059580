import { useTitleChange, Button, ContentContainer } from '@msaf/core-react'
import { AdvancedSearch } from '@msaf/generic-search-react'
import { useAuth } from '../../../auth'
import { SEARCH_API_URL } from '../../../environment'

export default function AdminUsers() {
  useTitleChange('Administration | Users')

  const { authStrategy } = useAuth()

  if (!authStrategy) {
    throw Error('403 - This page is only able to be reached with authenticated access')
  }

  return (
    <ContentContainer>
      <Button icon='new' iconAlignment='left' label='Add new user' path='/admin/users/new' />
      <AdvancedSearch<'token'>
        searchTypes={[
          {
            key: 'user-search',
            label: 'Users',
          },
        ]}
        viewKey='userSearch'
        requestOptions={{
          urlPrefix: `${SEARCH_API_URL}`,
          authStrategy: 'token',
          authToken: authStrategy.token,
          authTokenScheme: authStrategy.tokenIdentifier,
        }}
        defaultSortBy={{
          orderColumn: 'name',
          orderDirection: 'desc',
        }}
        resultNameSingular='result'
        resultNamePlural='results'
        noResultsMessage='No results available.'
      />
    </ContentContainer>
  )
}
