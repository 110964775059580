import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { NoteForm } from '../../types/notes'
import { formWatchHardValidator, Validation } from '../../validation/resolver'
import { FormElementConfig } from '../types'
import { EoiFormFields } from './type.config'

const hardRequiredFields: Validation<EoiFormFields>[] = [
  { name: 'addresses', validate: ['atLeastOne'], key: 'address' },
  { name: 'dateReceived', validate: ['date', 'populated'] },
  {
    name: 'groupOrCollective',
    validate: ['conditionallyPopulated'],
    key: 'groupOrCollective',
    dependencyCheck: ({ isRepresentingGroupOrCollective, groupOrCollective }) =>
      isRepresentingGroupOrCollective && !groupOrCollective,
  },
]

export const getEoiWatch = (onValidate: (state: boolean) => void) => {
  return formWatchHardValidator(hardRequiredFields, onValidate)
}

export const getEoiConfig = (): FormElementConfig<EoiFormFields>[] => {
  const currentDate = formatDate(new Date(), INTERNAL_DATE_FORMAT)
  return [
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Grant type' } },
    {
      type: 'field',
      element: {
        type: 'date',
        fieldId: 'dateReceived',
        isRequired: true,
        maxDate: currentDate,
      },
    },
    { type: 'field', element: { type: 'read-only', fieldId: 'registeredBy' } },
    { type: 'atom', element: { type: 'divider' } },
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Property location' } },
    {
      type: 'field',
      element: {
        type: 'address-lookup',
        fieldId: 'addresses',
        repeating: true,
        isRequired: true,
        defaultValue: { address: '' },
      },
    },
    { type: 'atom', element: { type: 'divider' } },
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Applicant' } },
    {
      type: 'field',
      element: {
        type: 'person-crud',
        fieldId: 'applicant',
        isRequired: true,
        labels: { heading: 'Applicant details', singular: 'person', modal: 'Edit applicant details' },
      },
    },
    {
      type: 'field',
      element: {
        type: 'organisation-crud',
        fieldId: 'applicantOrganisation',
        isRequired: false,
        labels: { heading: 'Applicant organisation', singular: 'organisation', modal: 'Edit applicant organisation' },
      },
    },
    {
      type: 'field',
      element: {
        type: 'boolean',
        fieldId: 'isRepresentingGroupOrCollective',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        type: 'text',
        fieldId: 'groupOrCollective',
        isDisabled: true,
        watch: ({ info, data, methods, initialConfig }) => {
          if (info.type === 'change' && info.name === 'isRepresentingGroupOrCollective') {
            // set the display value for groupOrCollective on toggle
            if (data.isRepresentingGroupOrCollective) {
              methods.setValue(
                'groupOrCollective',
                data.isRepresentingGroupOrCollective ? methods.getValues('groupOrCollective') : null,
              )
            } else {
              methods.setValue('groupOrCollective', null)
            }
            return {
              ...initialConfig,
              isDisabled: !data.isRepresentingGroupOrCollective,
              isRequired: data.isRepresentingGroupOrCollective,
            }
          }
          // A new EOI defaults to false and existing EOI's will use the isRepresentingGroupOrCollective value from the response
          // to conditionally display on initial render
          return {
            ...initialConfig,
            isDisabled: !data.isRepresentingGroupOrCollective,
            isRequired: data.isRepresentingGroupOrCollective,
          }
        },
      },
    },
    { type: 'atom', element: { type: 'divider' } },
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Additional information' } },
    {
      type: 'field',
      element: {
        type: 'number',
        fieldId: 'estPropertySizeHa',
        step: '0.01',
        isRequired: false,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'sectors',
        type: 'lookup',
        lookupId: 'sector',
        repeating: true,
        isRequired: false,
        defaultValue: { label: '', value: '' },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'existingFieldAdvisor',
        type: 'text',
        placeholder: '-',
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'personGroupReferral',
        type: 'text',
        placeholder: '-',
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'activitiesInterestedIn',
        type: 'lookup',
        repeating: true,
        allowDuplicates: false,
        lookupId: 'activitiesInterestedIn',
        defaultValue: { address: '' },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'marketingChannel',
        type: 'lookup',
        lookupId: 'marketingChannel',
        defaultValue: { address: '' },
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'marketingChannelAdditionalInformation',
        type: 'text-area',
      },
    },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.NEW_EOI,
      },
    },
  ]
}
