import { useMutation, useQuery } from 'react-query'
import {
  AuthStrategy,
  getDetailedMapResult,
  getLookupDisplayValue,
  getLookupResult,
  getSavedSearches,
  getSavedSearchResult,
  getSearchConfig,
  getSearchResult,
  getSearchResultExport,
  postSavedSearch,
  RequestOptions,
  searchAddress,
  SearchQuery,
} from '@msaf/generic-search-common'

export function useSearchConfig<T extends AuthStrategy = 'token'>(
  searchTypeKey: string,
  options?: RequestOptions<T>,
  enableQuery: boolean = true,
) {
  return useQuery(
    ['searchConfig', searchTypeKey, options?.urlPrefix],
    () => getSearchConfig<T>(searchTypeKey, options),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery,
    },
  )
}

export function useSearchResult<T extends AuthStrategy = 'token'>(
  searchQuery?: SearchQuery,
  options?: RequestOptions<T>,
  enableQuery: boolean = true,
) {
  return useQuery(['searchResult', searchQuery, options?.urlPrefix], () => getSearchResult<T>(searchQuery, options), {
    refetchOnWindowFocus: false,
    enabled: enableQuery,
    keepPreviousData: true,
  })
}

export function useLookupResult<T extends AuthStrategy = 'token'>(
  lookupQuery: Record<string, string | undefined>,
  options?: RequestOptions<T>,
) {
  return useQuery(['lookupResult', lookupQuery, options?.urlPrefix], () => getLookupResult(lookupQuery, options), {
    refetchOnWindowFocus: false,
    // Wait for 200ms before marking the data as stale.
    // This prevents from triggering multiple lookup requests while other parts of the page state is still loading.
    // TODO: Make the time value configurable
    staleTime: 200,
  })
}

export function useLookupDisplayValue<T extends AuthStrategy = 'token'>(
  lookupDisplayValueQuery: Record<string, string | undefined>,
  options?: RequestOptions<T>,
  enableQuery: boolean = true,
) {
  return useQuery(
    ['lookupDisplayValue', lookupDisplayValueQuery, options?.urlPrefix],
    () => getLookupDisplayValue<T>(lookupDisplayValueQuery, options),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery,
    },
  )
}

export function useSearchAddress(query: string, options?: RequestOptions) {
  return useQuery(['searchAddress', query, options?.urlPrefix], () => searchAddress(query, options), {
    refetchOnWindowFocus: false,
  })
}

export function useDetailedMapResult<T extends AuthStrategy = 'token'>(
  searchTypeKey: string,
  identifier: string | number | boolean | null,
  options?: RequestOptions<T>,
  enableQuery: boolean = true,
) {
  return useQuery(
    ['detailedMapResult', searchTypeKey, identifier, options?.urlPrefix],
    () => getDetailedMapResult<Record<string, string>, T>(searchTypeKey, identifier, options),
    {
      refetchOnWindowFocus: false,
      enabled: enableQuery,
    },
  )
}

export function useSearchResultExport<T extends AuthStrategy = 'token'>(
  searchQuery?: SearchQuery,
  options?: RequestOptions<T>,
) {
  return useMutation(['searchResultExport', searchQuery, options?.urlPrefix], () =>
    getSearchResultExport<T>(searchQuery, options),
  )
}

export function useSaveSearch<T extends AuthStrategy = 'token'>(options?: RequestOptions<T>) {
  return useMutation(['saveSearch', options?.urlPrefix], (params: { searchName: string; searchQuery: SearchQuery }) =>
    postSavedSearch<any, T>(params.searchName, params.searchQuery, options),
  )
}

export function useLookupSavedSearch<T extends AuthStrategy = 'token'>(
  searchTypeKey: string,
  options?: RequestOptions<T>,
) {
  return useQuery(['lookupSavedSearch', searchTypeKey, options?.urlPrefix], () =>
    getSavedSearches(searchTypeKey, options),
  )
}

export function useSavedSearchResult<T extends AuthStrategy = 'token'>(
  id: number | undefined,
  options?: RequestOptions<T>,
) {
  return useQuery(['savedSearchResult', id, options?.urlPrefix], () => getSavedSearchResult(id, options), {
    enabled: id !== undefined,
  })
}
