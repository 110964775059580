import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from '../../../../../../../../forms/components/form'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { RequestForPaymentRouteParams, RouteMode } from '../../../../../../../../types/route'
import { RequestForPaymentFields, useFormConfig } from './request-for-payment.form'

export const RequestForPayment = () => {
  const { applicationId, paymentId, mode } = useParams<RequestForPaymentRouteParams>()
  const canEdit = usePermission(UserPermissions.EDIT_GRANT_PAYMENT)

  const { data, isLoading, errors, onSubmit } = useStateManagement<RequestForPaymentFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/payments/${paymentId}`,
    {
      workflowId: 'payment',
      sectionId: 'payment',
      formId: 'requestsForPayment',
      instanceId: `${applicationId}`,
      subFormId: paymentId,
    },
    {
      success: 'Payment saved successfully',
      error: 'Unable to save payment',
    },
  )

  const config = useFormConfig()

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          paymentReason: data.paymentReason.length ? data.paymentReason : [{}],
        }
      : undefined
  }, [data])

  return (
    <Form<RequestForPaymentFields>
      isSkeleton={isLoading}
      initialState={initialState}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={canEdit}
      submitAction={(data) => onSubmit(data)}
      config={config}
    />
  )
}
