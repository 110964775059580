import { CheckableField } from '../../../../../forms/checkable-fields/checkable-field'
import React, { ChangeEvent, useState } from 'react'
import { LegendIndicator, LegendIndicatorProps } from '../../../legend-indicator'
import { LegendOptionLabel } from './legend-option-label'

export interface LegendOptionProps {
  label: string
  id: string
  name: string
  isChecked: boolean
  mapFeatureTheme?: LegendIndicatorProps
  onChange: (isChecked: boolean, id: string) => void
}

export function LegendOption({ label, id, name, isChecked = false, mapFeatureTheme, onChange }: LegendOptionProps) {
  const [isOptionChecked, setIsOptionChecked] = useState(isChecked)

  const handleOptionCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setIsOptionChecked(isChecked)
    onChange(isChecked, e.target.id)
  }

  return (
    <div className='c-legend-option'>
      <CheckableField
        type='checkbox'
        id={id}
        label={<LegendOptionLabel label={label} indicator={<LegendIndicator {...mapFeatureTheme} />} />}
        name={name}
        checked={isOptionChecked}
        onChange={handleOptionCheckboxChange}
        hasNoVerticalSpacing
      />
    </div>
  )
}
