import { createContext, PropsWithChildren, useContext } from 'react'

type PlanState = {
  isMigratedPlan?: boolean
  hasEditPermission: boolean
}

const PlanContext = createContext<PlanState | undefined>(undefined)

export function PlanStateProvider({ children, planState }: PropsWithChildren<{ planState: PlanState }>) {
  return <PlanContext.Provider value={planState}>{children}</PlanContext.Provider>
}

export function usePlanState() {
  const context = useContext(PlanContext)
  if (context === undefined) {
    throw new Error('usePlanState must be used within a PlanStateProvider')
  }
  return context
}
