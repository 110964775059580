import {
  ActionsBar,
  ConfirmTransition,
  Divider,
  FormField,
  Heading,
  RecordContent,
  RecordFooter,
  TextInput,
  Toggle,
} from '@msaf/core-react'
import { FormEvent, ReactNode } from 'react'
import { Control, Controller, FormState, UseFormRegister } from 'react-hook-form'
import LookupSearch from '../../components/lookup-typeahead'
import { UserInputs } from './type.config'

export interface UserFormProps {
  formState: FormState<UserInputs>
  control: Control<UserInputs>
  footerActions: ReactNode
  isNewRecord?: boolean
  isLoading?: boolean
  register: UseFormRegister<UserInputs>
  setValid?: (state: boolean) => void
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
}

export const UserForm = ({
  isNewRecord,
  isLoading,
  footerActions: FooterActions,
  control,
  formState,
  register,
  onSubmit,
}: UserFormProps) => {
  const { errors } = formState

  return (
    <>
      <ConfirmTransition
        when={formState.isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            type: 'cancel',
            key: 'continue',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            type: 'confirm',
            key: 'discard',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <form onSubmit={onSubmit}>
        <RecordContent>
          <Heading level={2}>User details</Heading>
          <FormField label='First name' htmlFor='firstName' isRequired>
            <TextInput
              isSkeleton={isLoading}
              id='firstName'
              type='text'
              isInvalid={!!errors.firstName}
              {...register('firstName', { required: true })}
            />
          </FormField>
          <FormField label='Last name' htmlFor='lastName' isRequired>
            <TextInput
              isSkeleton={isLoading}
              id='lastName'
              type='text'
              isInvalid={!!errors.lastName}
              {...register('lastName', { required: true })}
            />
          </FormField>
          <FormField
            label='Email'
            htmlFor='email'
            isRequired
            validationMessages={errors.email && [errors.email?.message ?? '']}
          >
            <TextInput
              isSkeleton={isLoading}
              id='email'
              type='text'
              isInvalid={!!errors.email}
              {...register('email', { required: true })}
            />
          </FormField>
          <FormField label='Contact number' htmlFor='contactNumber'>
            <TextInput isSkeleton={isLoading} id='contactNumber' type='number' {...register('contactNumber')} />
          </FormField>
          <FormField label='Organisation' htmlFor='organisation'>
            <TextInput isSkeleton={isLoading} id='organisation' type='text' {...register('organisation')} />
          </FormField>
          <Divider isFullWidth />
          <Heading level={2}>User permissions</Heading>
          <FormField label='Role' labelId='groups' isRequired>
            <Controller
              control={control}
              name='groups'
              rules={{ required: true }}
              render={({ field }) => (
                <LookupSearch
                  lookupId='role'
                  isSkeleton={isLoading}
                  value={field.value}
                  setValue={field.onChange}
                  isInvalid={!!errors.groups}
                  labelledBy={'groups'}
                />
              )}
            />
          </FormField>
          {!isNewRecord && (
            <FormField label='Status' htmlFor='isActive'>
              <Controller
                control={control}
                name='isActive'
                render={({ field }) => (
                  <Toggle isSkeleton={isLoading} id='isActive' isChecked={field.value} {...field}>
                    {field.value ? 'Active' : 'Archived'}
                  </Toggle>
                )}
              />
            </FormField>
          )}
        </RecordContent>
        <RecordFooter>
          <ActionsBar>{FooterActions}</ActionsBar>
        </RecordFooter>
      </form>
    </>
  )
}
