import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions } from '../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../types/route'
import { ApplicationWrapper } from './application-wrapper'

export const ProgressWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()

  useViewRedirectGatekeeper(
    [...EDIT_APPLICATION_PROGRESS_PERMISSIONS, UserPermissions.ASSIGN_PROGRESS],
    UserPermissions.VIEW_PROGRESS,
  )

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'progress', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not contracted</InlineNotification>
      </RecordContent>
    )
  }

  return <ApplicationWrapper />
}
