import { Button } from '@msaf/core-react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../../../../../components/gatekeeper'
import { SAVE_AND_CONTINUE_ID } from '../../../../../../../../forms/components/atom/footer'
import { Form } from '../../../../../../../../forms/components/form'
import { usePostAction } from '../../../../../../../../hooks/use-post-action'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import {
  APPROVAL_EDIT_PERMISSIONS,
  REVIEW_VIEW_PERMISSIONS,
  UserPermissions,
} from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { Validation, formWatchHardValidator } from '../../../../../../../../validation/resolver'
import { ApplicationApprovalFields, useApplicationApprovalConfig } from './approval.form'
import { useBuildWorkflowErrorsAsFormNotice } from '../../../../../../../../hooks/use-build-workflow-errors'

export function ApplicationApproval() {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const [enableProcessAction, setEnableProcessAction] = useState(false)
  const { data, workflowData, isLoading, isSaving, errors, onSubmit, isEditable } =
    useStateManagement<ApplicationApprovalFields>(
      () => `/api/plan/${planId}/project/${applicationId}/approval`,
      {
        workflowId: 'review',
        sectionId: 'review',
        formId: 'approval',
        instanceId: `${applicationId}`,
      },
      {
        error: 'Could not save project',
        success: 'Project saved.',
      },
    )

  useViewRedirectGatekeeper(APPROVAL_EDIT_PERMISSIONS, REVIEW_VIEW_PERMISSIONS)

  const hasEditPermission = usePermission(UserPermissions.PROCESS_REVIEW)

  const navigate = useNavigate()

  const process = usePostAction({
    url: `/api/plan/${planId}/project/${applicationId}/process`,
    displayServerError: true,
    messages: {
      success: 'Project approval successfully processed.',
      error: 'Could not process project approval',
    },
  })

  useEffect(() => setEnableProcessAction(!!data?.readyForProcess), [data?.readyForProcess])

  const workflowErrorSection = useBuildWorkflowErrorsAsFormNotice(workflowData, 'review', 'review')

  const config = [...useApplicationApprovalConfig(mode), ...workflowErrorSection]

  const hardRequiredFields: Validation<ApplicationApprovalFields>[] = [
    { name: 'delegatedAuthority', validate: ['populated'], key: 'value' },
    { name: 'recommendationFromKmr', validate: ['populated'], key: 'value' },
    { name: 'finalDecision', validate: ['populated'], key: 'value' },
    { name: 'dateCommitted', validate: ['populated', 'date'], key: 'value' },
  ]

  return (
    <Form<ApplicationApprovalFields>
      fieldWatch={formWatchHardValidator(hardRequiredFields, (state) =>
        setEnableProcessAction(!!data?.readyForProcess && state),
      )}
      initialState={data}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={hasEditPermission && !!isEditable}
      config={config}
      footerActions={
        mode === RouteMode.EDIT && !!isEditable ? (
          <>
            {hasEditPermission && (
              <Button
                // Navigation magically happens in form.tsx
                id={SAVE_AND_CONTINUE_ID}
                type='submit'
                buttonStyle='primary'
                label='Process'
                isLoading={isLoading || isSaving}
                isDisabled={isLoading || isSaving || !enableProcessAction}
              />
            )}
            <Button
              type='submit'
              buttonStyle='secondary'
              label='Save'
              isLoading={isLoading || isSaving}
              isDisabled={isLoading || isSaving}
            />
            <Button
              buttonStyle='text-action'
              label='Cancel'
              onClick={() => navigate('/')}
              isDisabled={isLoading || isSaving}
            />
          </>
        ) : undefined
      }
      submitAction={async (data, submitter) => {
        const shouldProcess = submitter?.id === SAVE_AND_CONTINUE_ID
        await onSubmit(data, {
          onSuccess: async () => {
            if (shouldProcess) {
              const processResponse = await process.mutateAsync({})
              if (processResponse.status === 200) {
                navigate(`/plans/view/${planId}/review/project/${applicationId}/approval`)
              }
            }
          },
        })
      }}
    />
  )
}
