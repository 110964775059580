import { Card as CoreCard } from '@msaf/core-react'
import { FieldValues } from 'react-hook-form'
import { useFormConfig } from '../../../hooks/use-form-config'
import { RouteMode } from '../../../types/route'
import { FormElementConfig } from '../../types'

export type CardProps<T extends FieldValues> = {
  fields: FormElementConfig<T>[]
  mode: RouteMode
  isSkeleton?: boolean
}

export function Card<T extends FieldValues>({ fields, mode, isSkeleton }: CardProps<T>) {
  const FormConfig = useFormConfig<T>({ config: fields, mode, isSkeleton })

  return <CoreCard>{FormConfig}</CoreCard>
}
