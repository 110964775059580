import { FieldValues } from 'react-hook-form'
import { GetFeatureFormConfigProps } from '..'
import { MeasurementFormFields, FormElementConfig } from '../../types'
import { isFormModes } from '../../utils'

export const getMeasurementFormConfig = <T extends FieldValues>({
  formMode,
}: Pick<GetFeatureFormConfigProps<T>, 'formMode'>): FormElementConfig<MeasurementFormFields>[] => {
  const { isMapMode } = isFormModes(formMode)

  return [
    {
      renderOnlyWhen: isMapMode,
      type: 'field',
      element: {
        fieldId: 'context',
        formPrefix: 'action',
        type: 'text-area',
      },
    },
  ]
}
