import {
  Button,
  ButtonsContainer,
  Card,
  createToastMessage,
  Divider,
  Heading,
  HeadingRow,
  InlineNotification,
  Modal,
  ToastMessage,
} from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { ComponentType, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useRequest } from '../../hooks/useRequest'

enum EditMode {
  None,
  New,
  Existing,
}

type WithId = {
  id: string
}

interface Labels {
  singular: string
  capitalised: string
}

export type CrudErrors<T> = Record<keyof T, string[]>

interface UseCrudLookupResultBase<T> {
  setObject: (o: T | undefined) => void
  onSubmit: (data: Omit<T, 'id'>) => void
  isSaving: boolean
  editMode: EditMode
  setEditMode: (m: EditMode) => void
  errors: CrudErrors<T> | null
}

type UseCrudLookupResult<T> =
  | ({
      object: undefined
      objectFields: undefined
    } & UseCrudLookupResultBase<T>)
  | ({
      object: T
      objectFields: Omit<T, 'id'>
    } & UseCrudLookupResultBase<T>)

export function useCrudLookup<T extends WithId>(
  initial: T | undefined | null,
  url: (id: string | undefined) => string,
  { singular, capitalised }: Labels,
  onObjectChange?: (o: T | undefined) => void,
): UseCrudLookupResult<T> {
  const [object, setObject] = useState<T | undefined>(initial ?? undefined)
  const [editMode, setEditMode] = useState<EditMode>(EditMode.None)
  const [errors, setErrors] = useState<CrudErrors<T> | null>(null)

  const { client } = useRequest()

  const methodVerb = editMode === EditMode.New ? 'create' : 'update'

  useEffect(() => {
    if (initial === null) {
      setObject(undefined)
    }
    if (initial && initial.id !== object?.id) {
      setObject(initial)
    }
  }, [initial])

  const setObjectAndEmitChange = (object: T | undefined) => {
    onObjectChange?.(object)
    setObject(object)
  }

  const onSuccess = useCallback(
    (data: AxiosResponse<T>) => {
      createToastMessage(
        <ToastMessage messageType='success' title={`Success`} message={`${capitalised} has been ${methodVerb}d`} />,
      )

      setEditMode(EditMode.None)
      setObjectAndEmitChange(data.data)
      setErrors(null)
    },
    [editMode],
  )

  const onError = useCallback(
    (errors: AxiosError<CrudErrors<T>>) => {
      createToastMessage(
        <ToastMessage messageType='error' title='Error' message={`Could not ${methodVerb} the ${singular}`} />,
      )
      setErrors(errors.response?.data ?? null)
    },
    [editMode],
  )

  const { mutate: save, isLoading: isSaving } = useMutation<AxiosResponse<T>, AxiosError<CrudErrors<T>>, Omit<T, 'id'>>(
    (data) =>
      client(url(editMode == EditMode.Existing ? object?.id : undefined), {
        method: editMode === EditMode.New ? 'POST' : 'PUT',
        data,
      }),
    {
      onSuccess,
      onError,
    },
  )

  const onSubmit = useCallback(
    (data: Omit<T, 'id'>) => {
      setErrors(null)

      if (editMode === EditMode.None) {
        throw new Error('Cannot submit with EditMode.None')
      }

      if (editMode === EditMode.Existing) {
        if (object == null) {
          throw new Error('Cannot update a `' + singular + '` that does not exist')
        }
        save(data)
      } else {
        save(data)
      }
    },
    [editMode, object, setErrors, save],
  )

  if (object !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...fields } = object
    return {
      object,
      objectFields: fields,
      setObject: setObjectAndEmitChange,
      onSubmit,
      isSaving,
      editMode,
      setEditMode,
      errors,
    }
  }

  return {
    object: undefined,
    objectFields: undefined,
    setObject: setObjectAndEmitChange,
    onSubmit,
    isSaving,
    editMode,
    setEditMode,
    errors,
  }
}

export type CrudLabels = {
  singular: string
  heading: string
  modal: string
}

export type CrudLookup<T> = ComponentType<{ object: T | undefined; setObject: (o?: T) => void }>

interface CrudLookupWrapperProps<T> {
  editForm: (
    props: PropsWithChildren<{
      fields: Partial<Omit<T, 'id'>>
      submitAction: (data: Omit<T, 'id'>) => void
      errors?: CrudErrors<T>
      isSkeleton?: boolean
      validationDisabled?: boolean
    }>,
  ) => JSX.Element
  viewForm: (props: PropsWithChildren<{ fields: Omit<T, 'id'>; isSkeleton?: boolean }>) => JSX.Element
  labels: CrudLabels
  urlFunc: (id: string | undefined) => string
  lookup: CrudLookup<T>
  initialObject?: T
  onObjectChange?: (o: T | undefined) => void
  isSkeleton?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  validationDisabled?: boolean
}

export function CrudLookupWrapper<T extends WithId>({
  editForm: EditForm,
  viewForm: ViewForm,
  labels,
  urlFunc,
  lookup: Lookup,
  initialObject,
  onObjectChange,
  isSkeleton,
  isDisabled,
  isRequired,
  validationDisabled,
}: CrudLookupWrapperProps<T>) {
  const capitalisedSingular = useMemo(() => {
    return labels.singular.charAt(0).toUpperCase() + labels.singular.slice(1)
  }, [labels.singular])
  const { object, objectFields, setObject, onSubmit, isSaving, editMode, setEditMode, errors } = useCrudLookup<T>(
    initialObject,
    urlFunc,
    {
      singular: labels.singular,
      capitalised: capitalisedSingular,
    },
    onObjectChange,
  )

  return (
    <>
      <Card>
        <HeadingRow
          headingText={
            <>
              {labels.heading}
              {isRequired && <span className='c-form-field__required-text c-form-field__required-text--single'>*</span>}
            </>
          }
          headingLevel={3}
          verticalSpacing='none'
        >
          <ButtonsContainer>
            {!isDisabled && (
              <Button
                isSkeleton={isSkeleton}
                buttonStyle='secondary'
                type='button'
                label={`New ${labels.singular}`}
                onClick={() => setEditMode(EditMode.New)}
              />
            )}
          </ButtonsContainer>
        </HeadingRow>
        {!isDisabled && <Lookup object={object} setObject={setObject} />}
        {objectFields && (
          <>
            {!isDisabled && <Divider isFullWidth />}
            <ViewForm isSkeleton={isSkeleton} fields={objectFields} />
            {!isDisabled && (
              <Button
                isSkeleton={isSkeleton}
                type='button'
                buttonStyle='secondary'
                label='Edit details'
                onClick={() => setEditMode(EditMode.Existing)}
              />
            )}
          </>
        )}
        {isDisabled && !objectFields && (
          <InlineNotification messageType='info' isDismissable={false}>
            {`No ${labels.singular} has been set.`}
          </InlineNotification>
        )}
      </Card>
      {!isDisabled && (
        <Modal
          contentLabel={labels.modal}
          isOpen={editMode !== EditMode.None}
          onRequestClose={() => setEditMode(EditMode.None)}
        >
          <Heading level={2}>{capitalisedSingular} details</Heading>
          <EditForm
            submitAction={onSubmit}
            fields={objectFields != null && editMode === EditMode.Existing ? objectFields : {}}
            errors={errors ?? undefined}
            validationDisabled={validationDisabled}
          >
            <ButtonsContainer containerStyle='right'>
              <Button buttonStyle='primary' label='Confirm' type='submit' isDisabled={isSaving} isLoading={isSaving} />
              <Button
                buttonStyle='text-action'
                label='Cancel'
                onClick={() => setEditMode(EditMode.None)}
                isDisabled={isSaving}
              />
            </ButtonsContainer>
          </EditForm>
        </Modal>
      )}
    </>
  )
}
