import classNames from 'classnames'
import React from 'react'
import { LeafletMapProps } from '../leaflet-map'

export function SkeletonMap({ isInlineMap }: Partial<LeafletMapProps>) {
  const mapClasses = classNames('c-leaflet-map c-skeleton c-skeleton--map', {
    'c-leaflet-map--inline': isInlineMap,
  })

  return (
    <div className={mapClasses}>
      <div className='c-skeleton c-skeleton--btn-label'></div>
    </div>
  )
}
