import { ImageWithActions } from '@msaf/core-react'
import { useMemo } from 'react'
import { InlineFeatureMap } from '../../../components/feature-map/inline'
import { InlineFeatureMapFieldProps } from '../../types'

export function InlineFeatureMapField<T>({
  actions,
  featuresUrl,
  isSkeleton,
  ...props
}: InlineFeatureMapFieldProps<T>) {
  const map = useMemo(
    () => <InlineFeatureMap url={featuresUrl} {...props} isEditable={false} isSkeleton={isSkeleton} />,
    [props],
  )

  if (actions) return <ImageWithActions imageElement={map} actions={actions} isSkeleton={isSkeleton} />

  return map
}
