import * as L from 'leaflet'

interface DataMarkerOptions<T> extends L.MarkerOptions {
  data?: T
}

/**
 * Adds an additional `data` atrtribute to the default leaflet marker.
 * The `data` attribute can any arbitrary value and
 * can be passed along with the leaflet marker options during the creation of the `DataMarker`
 */
export class DataMarker<T> extends L.Marker {
  data?: T

  constructor(latLng: L.LatLngExpression, options?: DataMarkerOptions<T>) {
    super(latLng, options)
    this.data = options?.data
  }
}
