import { AxiosError, AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import { Form } from '../../../../../../forms/components/form'
import { SystemsAspirationsFields, useSystemAspirationsForm } from './system-aspirations.form'
import { useMemo } from 'react'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'

export function SystemAspirations() {
  const { id, mode } = useParams<RouteParams>()
  const { hasEditPermission, isMigratedPlan } = usePlanState()
  const { data, isLoading, errors, refetchErrors, refetchData } = useFormState<SystemsAspirationsFields>(
    ([id]) => `/api/plan/${id}/system-aspirations`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'systemAspirations',
      instanceId: `${id}`,
    },
  )

  const mutation = useMutationWithToast<
    AxiosResponse<SystemsAspirationsFields>,
    AxiosError<Map<keyof SystemsAspirationsFields, Array<string>>>,
    SystemsAspirationsFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/system-aspirations`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save plan.',
    },
  })

  const config = useSystemAspirationsForm(data, isMigratedPlan)

  const onSubmit = async (data: SystemsAspirationsFields) => {
    await mutation.mutateAsync(data)
  }

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          soilTypes: data.soilTypes.length ? data.soilTypes : [{}],
        }
      : undefined
  }, [data])

  return (
    <Form<SystemsAspirationsFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={hasEditPermission}
      nextPath={`/plans/${mode}/${id}/plan/overview`}
      config={config}
      submitAction={onSubmit}
    />
  )
}
