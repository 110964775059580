import * as ReactRouter from 'react-router-dom'
import type { To } from 'history'
import type { PathPattern } from 'react-router'

/*
This file contains wrappers for react-router-dom functions used across
the MSAF codebase. The purpose of this is to provide an point to abstract
any functionality so when react-router-dom is upgrade.
*/

export const useNavigate = (): ((to: To | number) => void) => {
  const parent = ReactRouter.useNavigate()
  return (to: To | number) => {
    if (typeof to === 'number') {
      parent(to)
    } else {
      parent(to)
    }
  }
}

export const useLocation = <T>() => {
  const { pathname, search, state } = ReactRouter.useLocation()
  return { pathname, search, state: state as T }
}

export const matchPath = (path: string, pattern: string, exact?: boolean) => {
  const patternObject: PathPattern = {
    path: pattern,
    end: exact,
  }
  return ReactRouter.matchPath(patternObject, path)
}
