import { CheckableField, CheckableFields, FormField } from '@msaf/core-react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { CheckableFieldGroupProps } from '../../types'
import { useLabelProps } from '../hooks/use-label-props'

export function CheckableGroup<T extends FieldValues>({
  label,
  labelId,
  fields,
  helpText,
  mode,
  isSkeleton,
}: CheckableFieldGroupProps<T>) {
  const { register } = useFormContext<T>()

  return (
    <FormField label={label} labelId={labelId} helpText={helpText}>
      <CheckableFields ariaLabelledBy={labelId}>
        {fields.map((field) => {
          const { label } = useLabelProps(field)

          return (
            <CheckableField
              key={field.fieldId}
              id={field.fieldId}
              isSkeleton={isSkeleton}
              isDisabled={mode == RouteMode.VIEW}
              disabled={mode == RouteMode.VIEW}
              describedBy={labelId}
              label={label}
              type={field.type}
              {...register(field.fieldId)}
            />
          )
        })}
      </CheckableFields>
    </FormField>
  )
}
