import { AxiosError, AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useRequest } from './useRequest'

const FEATURE_FLAG_URL = '/api/feature-flag/'

export type FeatureFlagNames = 'pdf_export' | 'withdraw_projects' | 'funding_threshold'

export type FeatureFlag = {
  name: FeatureFlagNames
  description: string
}

export const useFeatureFlags = () => {
  const { client } = useRequest()
  const { data, isLoading } = useQuery<AxiosResponse<FeatureFlag[]>, AxiosError, AxiosResponse<FeatureFlag[]>>(
    FEATURE_FLAG_URL,
    () => client.get(FEATURE_FLAG_URL),
  )
  const featureFlags = data?.data ?? []

  const hasFlag = useCallback(
    (...names: FeatureFlagNames[]) => !!featureFlags.find((f) => names.includes(f.name)),
    [featureFlags],
  )

  return { featureFlags, isLoading, hasFlag }
}
