import { AUTOCOMPLETE_MIN_CHARS, ReadOnlyField, Typeahead, TypeaheadOption } from '@msaf/core-react'
import debounce from 'lodash.debounce'
import { useCallback, useMemo } from 'react'
import { OptionsType, OptionTypeBase } from 'react-select'
import { DEBOUNCE_MS } from '../../constants'
import { useRequest } from '../../hooks/useRequest'

interface BaseLookupProps<T> {
  labelledBy: string
  object: T | undefined
  setObject: (object: T | undefined) => void
  isReadOnly?: boolean
  requestUrl: (query: string) => string

  isSkeleton?: boolean
  isDisabled?: boolean
  autoFocus?: boolean
  autoCompleteMinChars?: number
}

interface WithDisplayFields<T> {
  displayFunc?: undefined
  displayFields: (keyof T)[]
  idField: keyof T
}

interface WithDisplayFunc<T> {
  displayFunc: (o: T) => string
  displayFields?: undefined
  idField: keyof T
}

type LookupProps<T extends object> = (WithDisplayFields<T> | WithDisplayFunc<T>) & BaseLookupProps<T>

export default function Lookup<T extends object>({
  labelledBy,
  object,
  setObject,
  displayFields,
  idField,
  displayFunc,
  requestUrl,
  isSkeleton = false,
  isReadOnly = false,
  isDisabled = false,
  autoFocus = false,
  autoCompleteMinChars = AUTOCOMPLETE_MIN_CHARS,
}: LookupProps<T>) {
  const getDisplay = useCallback(
    (object: T) => {
      if (displayFields) {
        return {
          label: displayFields.map((field) => object[field as keyof T]).join(' '),
          value: String(object[idField as keyof T]),
        }
      } else {
        return { label: displayFunc(object), value: String(object[idField as keyof T]) }
      }
    },
    [displayFunc, displayFields, idField],
  )

  const selectedOption = useMemo(() => {
    if (!object) return { label: '', value: '' }

    return getDisplay(object)
  }, [object, displayFields, displayFunc])

  const { client } = useRequest()

  const getSuggestions = debounce(async (query: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    const { data } = await client.get<T[]>(requestUrl(encodeURIComponent(query)))

    const withDisplay = data.map((d) => ({ ...getDisplay(d), ...d }))

    callback(withDisplay)
  }, DEBOUNCE_MS)

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < autoCompleteMinChars) return callback([])
    if (inputValue.length >= autoCompleteMinChars) {
      getSuggestions(inputValue, callback)
    }
  }

  // Pull out keys from typeahead that are unused.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelection = ({ label, value, ...option }: TypeaheadOption) => {
    /* If there are no keys in the object other than label value the object has been
     * cleared. This should probably be fixed in MSAF.
     */
    const convertedOption = (Object.keys(option).length === 0 ? undefined : option) as T | undefined
    setObject(convertedOption)
  }

  if (isReadOnly) {
    return <ReadOnlyField isSkeleton={isSkeleton} labelledBy={labelledBy} value={String(selectedOption.value)} />
  }

  return (
    <Typeahead
      isSkeleton={isSkeleton}
      selectType='async'
      labelledBy={labelledBy}
      handleChange={handleSelection}
      selectedOption={selectedOption}
      options={[]}
      loadOptions={loadOptions}
      isClearable
      isDisabled={isDisabled}
      autoFocus={autoFocus}
    />
  )
}
