import { useParams } from 'react-router-dom'
import { useRequest } from './useRequest'
import { useQuery } from 'react-query'
import { useFeatureFlags } from './use-feature-flags'

export function usePlanWarning() {
  const { id: planId } = useParams<{ id: string }>()
  const { client } = useRequest()

  if (planId == null) {
    throw new Error('usePlanWarnings can only be used in a plan context.')
  }

  const { hasFlag } = useFeatureFlags()

  const { data: warnings, isLoading } = useQuery<{ code: string; level: 'WARNING' | 'ERROR'; message: string }[]>(
    [`/api/plan/${planId}/warnings`],
    () => client.get(`/api/plan/${planId}/warnings`).then((response) => response.data),
    { enabled: hasFlag('funding_threshold') ?? false },
  )

  return { warnings, isLoading }
}
