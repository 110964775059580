import { PropsWithChildren } from 'react'
import { BaseApplicationWorkflow } from '.'
import { REVIEW_EDIT_PERMISSIONS, UserPermissions } from '../../types/permissions'
import { useViewRedirectGatekeeper } from '../gatekeeper'

interface WorkflowProps {
  recordId: string
}

export const ReviewWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  useViewRedirectGatekeeper(REVIEW_EDIT_PERMISSIONS, UserPermissions.VIEW_REVIEW)

  return (
    <BaseApplicationWorkflow recordId={recordId} workflow={'review'} noResultsText={'Not submitted'}>
      {children}
    </BaseApplicationWorkflow>
  )
}
