import React, { useCallback, useEffect, useState } from 'react'
import { OptionsType, OptionTypeBase } from 'react-select'
import { RequestOptions, getAddressCoordinates, searchAddress, AuthStrategy } from '@msaf/generic-search-common'
import { AUTOCOMPLETE_MIN_CHARS, Button, Typeahead } from '@msaf/core-react'
import debounce from 'lodash.debounce'

export type AddressOption = { label: string; value: string }

export type LocationMetaData = {
  lat: number
  lng: number
  extents?: {
    latMin: number
    lngMin: number
    latMax: number
    lngMax: number
  }
}

type GotoControlProps<T extends AuthStrategy = 'token'> = {
  requestOptions?: RequestOptions<T>
  onAddressChange?: (option: AddressOption) => void
  goToLocation?: (locationMetaData: LocationMetaData) => void | undefined
  onSearch?: (selectedAddress: AddressOption) => void
  selectedAddress?: AddressOption
  labelledBy?: string
  autoFocus?: boolean
  placeholderText?: string
  debounceIntervalMs?: number
}

type AddressSearchResult = {
  error: boolean
  data: Array<{ key: string; text: string }>
}

export function GotoControl<T extends AuthStrategy = 'token'>({
  requestOptions,
  onAddressChange,
  goToLocation,
  onSearch,
  selectedAddress,
  labelledBy,
  autoFocus = true,
  placeholderText = 'Enter address or location',
  debounceIntervalMs = 300,
}: GotoControlProps<T>) {
  const [selectedOption, setSelectedOption] = useState<AddressOption | null>(null)

  const fetchSuggestions = async (inputValue: string, callback: (options: AddressOption[]) => void) => {
    const result = await searchAddress<AddressSearchResult, T>(inputValue, requestOptions)
    if (!result.error) {
      const options = result.data.map(({ text, key }: any) => ({ label: text, value: key }))
      callback(options)
    }
  }

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < AUTOCOMPLETE_MIN_CHARS) return callback([])
    if (inputValue.length >= AUTOCOMPLETE_MIN_CHARS) {
      fetchSuggestions(inputValue, callback)
    }
  }

  const handleAddressSelection = useCallback(
    async (address: AddressOption) => {
      setSelectedOption(address)

      if (onAddressChange) {
        onAddressChange(address)
      }

      const { label, value } = address

      if (label && value && requestOptions) {
        const coordinates = await getAddressCoordinates<{ data: LocationMetaData; error: boolean }, T>(
          label,
          value,
          requestOptions,
        )
        if (!coordinates?.error && coordinates?.data) {
          const metaData = { ...coordinates.data }
          goToLocation && goToLocation(metaData)
        }
      }
    },
    [goToLocation, onAddressChange, requestOptions],
  )

  const handleClick = () => {
    onSearch && selectedOption && onSearch(selectedOption)
  }

  useEffect(() => {
    if (selectedAddress) {
      handleAddressSelection(selectedAddress)
    }
  }, [selectedAddress, handleAddressSelection])

  return (
    <>
      <Typeahead
        selectType='async'
        labelledBy={labelledBy ?? 'gotoAddress'}
        handleChange={handleAddressSelection}
        selectedOption={selectedOption}
        options={[]}
        loadOptions={debounce(loadOptions, debounceIntervalMs)}
        isClearable
        autoFocus={autoFocus}
        placeholder={placeholderText}
        // Override `react-select` styling to match our design
        styles={{
          clearIndicator: (styles: any) => ({
            ...styles,
            display: 'none',
          }),
          dropdownIndicator: (styles: any) => ({
            ...styles,
            display: 'none',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          indicatorsContainer: (styles: any) => ({
            ...styles,
            display: 'none',
          }),
        }}
      />
      {onSearch && <Button onClick={handleClick} label='Search' />}
    </>
  )
}
