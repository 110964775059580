import { AxiosInstance } from 'axios'
import { UploadedFile } from '../forms/components/file-controller'
import { useRequest } from '../hooks/useRequest'
import { useCallback } from 'react'

const resolveRealFileURL = async (client: AxiosInstance, file: UploadedFile) => {
  if (!file.url) throw new Error('No URL for file.')
  const { data } = await client.get<{ url: string }>(file.url)

  return data.url
}

const downloadFile = async (url: string, name: string) => {
  // Create a fake link on the page and click it.
  const fakeAnchorElement = document.createElement('a')
  fakeAnchorElement.href = '#fake'
  fakeAnchorElement.style.display = 'none'
  fakeAnchorElement.setAttribute('href', url)
  fakeAnchorElement.target = 'blank'
  fakeAnchorElement.rel = 'noopener noreferrer'
  fakeAnchorElement.download = name
  fakeAnchorElement.click()

  // Remove the element once its been used.
  setTimeout(() => fakeAnchorElement.remove(), 1000)
}

export const useDownloadFile = () => {
  const { client } = useRequest()

  return {
    downloadFile: useCallback(
      (file: UploadedFile) => {
        if (!file.url) throw new Error('No URL for file.')
        downloadFile(file.url, file.originalName)
      },
      [client],
    ),
    resolveAndDownloadFile: useCallback(
      async (file: UploadedFile) => {
        const url = await resolveRealFileURL(client, file)
        downloadFile(url, file.originalName)
      },
      [client],
    ),
  }
}

export const useResolveRealFileURL = () => {
  const { client } = useRequest()
  return useCallback((file: UploadedFile) => resolveRealFileURL(client, file), [client])
}
