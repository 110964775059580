import { useLocation, useNavigate } from '@msaf/router-react'
import { AxiosError } from 'axios'
import { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DisplayContext } from '../components/gatekeeper'
import { ErrorCode } from '../constants'
import { ApplicationRouteParams, RouteMode } from '../types/route'
import { FormErrors, FormState, WorkflowLocators, WorkflowState } from '../validation/constants'
import { useWorkflowState } from '../validation/hooks'
import { useErrorHandler } from './use-error-handler'
import { FormUrlFunc } from './use-state-management'
import { useRequest } from './useRequest'

function useFormErrors<T, TErrors extends FormErrors<T> = FormErrors<T>>(
  workflowState: WorkflowState<TErrors> | undefined,
  { workflowId, sectionId, formId, instanceId, subFormId }: WorkflowLocators,
) {
  const { errors, state: formState } = useMemo(() => {
    const form = workflowState?.[workflowId]?.[instanceId]?.state[sectionId]?.forms?.[formId]

    let formState: Partial<FormState<TErrors>> = {
      errors: undefined,
      state: undefined,
    }

    if (form) {
      if (Array.isArray(form)) {
        const subForm = form.find((f) => String(f.objectId) === String(subFormId))
        if (subForm) {
          formState = subForm
        }
      } else {
        formState = form
      }
    }

    return formState
  }, [workflowState, workflowId, instanceId, sectionId, formId, subFormId])

  const formErrors = useMemo(() => {
    return errors?.reduce(
      (acc, error) => {
        const { field, ...rest } = error
        if (field in acc) {
          acc[field].push(rest)
        } else {
          acc[field] = [rest]
        }
        return acc
      },
      {} as Record<keyof TErrors, unknown[]>,
    ) as TErrors
  }, [errors])

  return {
    formState,
    errors: formErrors,
    isEditable: workflowState?.[workflowId]?.[instanceId]?.meta?.['isEditable'],
  }
}

export function useFormData<T>(url: string, enabled = true) {
  const { client } = useRequest()
  const { data, isLoading, isError, refetch, error } = useQuery<T>(
    [url],
    () => client.get(url).then((response) => response.data),
    { enabled },
  )

  return {
    data,
    fetchError: error as AxiosError<T> | null,
    isLoading,
    isError,
    refetchData: () => refetch(),
  }
}

export function useFormState<T, TErrors extends FormErrors<T> = FormErrors<T>>(
  urlFunc: FormUrlFunc,
  locators: WorkflowLocators,
  enableQuery = true,
) {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const { setError } = useContext(DisplayContext)

  if (!(locators.workflowId && planId)) {
    setError(ErrorCode.NOT_FOUND_404)
    // Early exit
    return {}
  }

  const validSegments = [planId, applicationId].filter((id) => id !== undefined) as string[]

  const formData = useFormData<T>(urlFunc(validSegments), enableQuery)
  const workflowQuery = useWorkflowState<TErrors>(planId, locators.workflowId, mode)
  const formErrors = useFormErrors<T, TErrors>(workflowQuery.data, locators)

  // If either query errors, set page to error state
  useErrorHandler(formData.isError, formData.fetchError)
  useErrorHandler(workflowQuery.isError, workflowQuery.error as AxiosError<T> | null)

  // If formState returns as closed and form is in edit, change mode to view
  useEffect(() => {
    if (mode === RouteMode.EDIT && formErrors.formState === 'closed') {
      const to = location.pathname.replace(`/${RouteMode.EDIT}/`, `/${RouteMode.VIEW}/`)
      navigate({ pathname: to })
    }
  }, [formErrors.formState, mode])

  return {
    data: formData.data,
    isLoading: formData.isLoading || workflowQuery.isLoading,
    errors: formErrors.errors,
    isEditable: (formErrors.isEditable as boolean) ?? formErrors.formState !== 'closed',
    workflowData: workflowQuery.data,
    refetchErrors: () => workflowQuery.refetch(),
    refetchData: () => formData.refetchData(),
  }
}
