import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from '../../../../../../../../forms/components/form'
import { useStateManagement } from '../../../../../../../../hooks/use-state-management'
import { usePermission } from '../../../../../../../../services/permissions'
import { UserPermissions } from '../../../../../../../../types/permissions'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../../types/route'
import { NurseryFields, useFormConfig } from './nursery.form'

export const Nursery = () => {
  const { id, applicationId, mode } = useParams<ApplicationRouteParams>()
  const canEdit = usePermission(UserPermissions.EDIT_SUPPLY)

  const { data, isLoading, errors, onSubmit, isEditable } = useStateManagement<NurseryFields>(
    ([id, applicationId]) => `/api/plan/${id}/project/${applicationId}/nursery-supply`,
    {
      workflowId: 'supply',
      sectionId: 'supply',
      formId: 'nursery',
      instanceId: `${applicationId}`,
    },
    {
      success: 'Project saved successfully',
      error: 'Unable to save project',
    },
  )

  const config = useFormConfig(data)

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          nurserySuppliers: data.nurserySuppliers.length ? data.nurserySuppliers : [{}],
        }
      : undefined
  }, [data])

  return (
    <Form<NurseryFields>
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      initialState={initialState}
      nextPath={`/plans/${mode}/${id}/supply/project/${applicationId}/contract`}
      canEdit={canEdit && !!isEditable}
      submitAction={(data) => onSubmit(data)}
      config={config}
    />
  )
}
