import { ReadOnlyField as CoreReadOnlyField } from '@msaf/core-react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { ReadOnlyFieldProps } from '../../types'
import { useLabelProps } from '../hooks/use-label-props'
import { Wrapper } from './wrapper'

export function ReadOnlyField<T extends FieldValues>(props: ReadOnlyFieldProps<T>) {
  const { fieldId, isSkeleton, conversion } = props
  const { control } = useFormContext<T>()
  const labelProps = useLabelProps<T>(props)

  return (
    <Wrapper {...props}>
      <Controller
        control={control}
        name={fieldId}
        render={({ field }) => (
          <CoreReadOnlyField
            value={conversion ? conversion(field.value) : (field.value as string)}
            isSkeleton={isSkeleton}
            labelledBy={labelProps.labelId ?? ''}
          />
        )}
      />
    </Wrapper>
  )
}
