import { ConfirmTransition } from '@msaf/core-react'
import { useEffect, useMemo, useState } from 'react'
import { MAP_CONFIG } from '../../config/map'
import { FeatureMapContextProvider } from './context'
import { useCenterMode, useFeatureMapMethods } from './hooks'
import { Page, PageMapOptions } from './map/page'
import { CenterMode, FeatureMapFeatureOptions } from './types'
import { MENU_CONTEXT_WIDTH } from './map/constants'

export type FeatureMapProps = PageMapOptions & {
  centerMode?: CenterMode
  featureToggle: FeatureMapFeatureOptions
  actionUrlTemplate?: string
  featuresUrl: string
  defaultMenuIsOpen?: boolean
}

export function FeatureMap(mapOptions: FeatureMapProps) {
  const { featuresUrl, featureToggle, actionUrlTemplate, defaultMenuIsOpen, ...pageOptions } = mapOptions
  const [map, setMap] = useState<L.Map>()
  const config = useMemo(() => MAP_CONFIG, [])

  const { layer, isDirty, onSave, setDrawOptions, ...contextMethods } = useFeatureMapMethods(
    featuresUrl,
    featureToggle,
    map,
    config,
  )

  useEffect(() => {
    if (!map) return
    /*
      Not ideal to do in the hook here, in future it would be better to adapt the map config
      to take the drawing. This is a temporary solution to enable the drawing options without
      large changes to the "Aoraki" bits.
    */
    setDrawOptions?.({ measurement: { totalLength: true, segmentLength: true } })
  }, [map, setDrawOptions])

  // Pad center to the context menu width.
  useCenterMode(map, layer, mapOptions?.centerMode, { paddingTopLeft: [MENU_CONTEXT_WIDTH, 0] })

  return (
    <FeatureMapContextProvider
      actionUrlTemplate={actionUrlTemplate}
      featureToggle={featureToggle}
      uiConfig={config}
      {...contextMethods}
    >
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <Page
        mapOptions={pageOptions}
        featureToggle={featureToggle}
        setMap={(map) => setMap(map)}
        onSave={onSave}
        isLoading={contextMethods.isLoading}
        defaultMenuIsOpen={defaultMenuIsOpen}
      />
    </FeatureMapContextProvider>
  )
}
