import React from 'react'
import { DISPLAY_DATE_FORMAT } from '@msaf/core-common'
import { TabbedContent, TabbedContentProps } from '@msaf/core-react'
import { AuthStrategy, RequestOptions, SearchQuery, SearchTemplate, SearchKeyProps } from '@msaf/generic-search-common'
import { Filters } from '@msaf/generic-search-react'

interface MapSearchPanelProps<T extends AuthStrategy = 'token'>
  extends Pick<TabbedContentProps, 'onTabChange' | 'activeTab'> {
  activeSearchTemplate: SearchTemplate
  searchTypes: Array<SearchKeyProps>
  updateQuery: (newQuery: SearchQuery, resetToFirstPage?: boolean | undefined) => void
  requestOptions?: RequestOptions<T>
  searchQuery: SearchQuery
  dateFormat?: string
  isLoading?: boolean
}

export function MapSearchPanel<T extends AuthStrategy = 'token'>({
  activeSearchTemplate,
  searchTypes,
  updateQuery,
  requestOptions,
  searchQuery,
  dateFormat,
  isLoading,
  onTabChange,
  activeTab,
}: MapSearchPanelProps<T>) {
  return (
    <TabbedContent
      borderBottomStyle='solid'
      verticalSpacing='none'
      ariaLabel='Search filters'
      onTabChange={onTabChange}
      activeTab={activeTab}
      tabs={searchTypes.map(({ label }) => {
        return {
          label: label,
          tabContent: (
            <Filters<T>
              searchTemplate={activeSearchTemplate}
              searchTypeKey={activeSearchTemplate.searchTypeKey}
              isLoading={isLoading}
              onSearch={updateQuery}
              requestOptions={requestOptions}
              dateFormat={dateFormat ?? DISPLAY_DATE_FORMAT}
              searchQuery={searchQuery}
              isMapMode
            />
          ),
        }
      })}
    />
  )
}
