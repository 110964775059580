import * as L from 'leaflet'
import {
  AuthStrategy,
  CommonAdvancedSearchProps,
  FilterInstance,
  FilterSet,
  SearchView,
  SearchViewColumn,
} from '@msaf/generic-search-common'
import React, { useMemo, useState } from 'react'
import { MapAreaProps, MapHeaderProps, MapMenuConfig, MapPanels } from '@msaf/maps-react'
import { AppLogoProps, LoadingIndicator, usePassedState } from '@msaf/core-react'
import { MapSearchPanel } from '../map-search-panel'
import useMapSearch, { MapBoundsCacheType } from '../../hooks/use-map-search'
import { useSearchUI } from '@msaf/generic-search-react'
import { ClientCache } from '@msaf/core-common'
import { MAP_BOUNDS_CACHE_KEY } from '../../config/constants'
import { SearchMarkerPanel } from '../search-marker-panel'
import { MapPage } from '@msaf/maps-react'

export interface AdvancedSearchMapProps<T extends AuthStrategy = 'token'>
  extends CommonAdvancedSearchProps<T>,
    MapAreaProps,
    MapHeaderProps {
  customPopupComponent?: React.ElementType
  defaultIcon: L.Icon<L.IconOptions>
  selectedMarkerIcon?: L.Icon<L.IconOptions>
  locationIcon?: L.Icon<L.IconOptions> | L.DivIcon
  customActions?: { [key: string]: (...args: any[]) => void }
  addInitialLayersAndMarkers?: (map: L.Map) => void
  showMapPageHeader?: boolean
  appLogo?: AppLogoProps
  additionalMenuItems?: MapMenuConfig[]
}

export function AdvancedSearchMap<T extends AuthStrategy = 'token'>({
  appLogo,
  searchTypes,
  customActions,
  defaultIcon,
  selectedMarkerIcon,
  mapOptions,
  pageSize = 100000, // no limit by default
  addInitialLayersAndMarkers,
  filterBy,
  requestOptions,
  viewKey,
  backLinkConfig,
  headerActionsConfig,
  showMapPageHeader = false,
  additionalMenuItems = [],
  setMap: setExternalMap,
}: AdvancedSearchMapProps<T>) {
  const { state: map, setState: setMap } = usePassedState<L.Map | undefined>(undefined, setExternalMap)
  const [mapDetailHeadingCol, setMapDetailHeadingCol] = useState<SearchViewColumn>()

  const { searchQuery, searchResults, searchTemplate, updateQuery, onTabChange, activeTab, activeSearchType } =
    useSearchUI<T>({
      pageSize,
      filterBy,
      viewKey,
      requestOptions,
      searchTypes,
      isMapView: true,
    })

  const {
    viewAction,
    nextAction,
    previousAction,
    isMenuPanelOpen,
    setIsMenuPanelOpen,
    isContextPanelOpen,
    toggleContextPanelState: setIsContextPanelOpen,
    showNextButton,
    popupData,
  } = useMapSearch<T>({
    searchTypeKey: activeSearchType.key,
    map,
    activeSearchTemplate: searchTemplate,
    defaultIcon,
    markerIcon: selectedMarkerIcon,
    searchResults,
    searchRequestOptions: requestOptions,
    cachedMapBounds: ClientCache.getInstance().fetch<MapBoundsCacheType>(MAP_BOUNDS_CACHE_KEY),
  })

  const appliedFilterCount = useMemo(() => {
    return searchQuery?.filterSet.filters.reduce((count: number, filter: FilterInstance | FilterSet) => {
      if ('filterSet' in filter) {
        return count + filter.filterSet.filters.length
      }
      return count
    }, 0)
  }, [searchQuery])

  // Check if the search template and search query are loaded
  if (!searchTemplate || !searchQuery) {
    return <LoadingIndicator>Loading&hellip;</LoadingIndicator>
  }

  const mapDetailView = searchTemplate?.views.find(
    (v: SearchView) => v.viewKey === searchTemplate?.mapConfig?.detailKey,
  )

  const actionConfig = mapDetailView?.table.onRowClick

  // Extract columns relevant to the popup and sort them so they respect the
  // order in the search config.
  const mapDetailCols: SearchViewColumn[] = []

  mapDetailView?.table.columns.forEach((col: SearchViewColumn) => {
    if (col.type === 'hidden') return

    if (col.isMapDetailHeading) {
      setMapDetailHeadingCol(col)
    } else if (col.isMapDetail) {
      mapDetailCols.push(col)
    }
  })

  return (
    <MapPage
      appLogo={appLogo}
      backLinkConfig={backLinkConfig}
      headerActionsConfig={headerActionsConfig}
      showMapPageHeader={showMapPageHeader}
      searchTemplate={searchTemplate}
      menuConfig={[
        {
          menuIcon: 'search',
          menuName: MapPanels.SEARCH,
          menuKey: 'search',
          menuComponent: (
            <MapSearchPanel
              searchTypes={searchTypes}
              onTabChange={onTabChange}
              activeTab={activeTab}
              searchQuery={searchQuery}
              activeSearchTemplate={searchTemplate}
              updateQuery={updateQuery}
              requestOptions={requestOptions}
            />
          ),
          badgeCount: appliedFilterCount,
        },
        ...additionalMenuItems,
      ]}
      setMap={setMap}
      mapOptions={mapOptions}
      addInitialLayersAndMarkers={addInitialLayersAndMarkers}
      isMenuPanelOpen={isMenuPanelOpen}
      setIsMenuPanelOpen={setIsMenuPanelOpen}
      isContextPanelOpen={isContextPanelOpen}
      setIsContextPanelOpen={setIsContextPanelOpen}
      contextPanelConfig={{
        heading: mapDetailHeadingCol ? popupData?.[mapDetailHeadingCol.elementKey] : 'Details',
        panelContent: (
          <SearchMarkerPanel
            config={mapDetailCols}
            data={popupData}
            actionConfig={actionConfig}
            viewAction={viewAction}
            customActions={customActions}
            previousAction={previousAction}
            nextAction={nextAction}
            showNextButton={showNextButton}
          />
        ),
      }}
    />
  )
}
