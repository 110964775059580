import { InlineMap } from '@msaf/maps-react'
import { FeatureCollection, Geometry } from '@turf/helpers'
import { useMemo, useState } from 'react'
import { linzBasemap, MAP_CONFIG } from '../../config/map'
import { useCenterMode } from './hooks'
import { useFeatureLayer } from './utils/feature-management'
import L from 'leaflet'

export function StaticInlineFeatureMap({
  features,
  isEditable,
  isSkeleton,
  center,
  zoom,
  centerMode = 'toFeatures',
  setMap,
}: {
  features?: FeatureCollection<Geometry>
  isEditable?: boolean
  isSkeleton?: boolean
  center?: [number, number]
  zoom?: number
  centerMode?: 'static' | 'toFeatures'
  setMap?: (map: L.Map | undefined) => void
}) {
  const [mapLocal, setMapLocal] = useState<L.Map>()
  const config = useMemo(() => MAP_CONFIG, [])

  // Set up feature layer with state from collection
  const featureLayerMethods = useFeatureLayer({
    map: mapLocal,
    config,
    features,
  })
  useCenterMode(mapLocal, featureLayerMethods.state.layer, centerMode)

  return (
    <InlineMap
      isSkeleton={isSkeleton || !featureLayerMethods.state.initialised}
      setMap={(map) => {
        setMap?.(map)
        setMapLocal(map)
      }}
      getBaseLayers={() => L.layerGroup([linzBasemap()])}
      isEditable={isEditable}
      center={center}
      zoom={zoom}
    />
  )
}
