import { ReadOnlyField as MsafCoreReadOnlyField, Label } from '@msaf/core-react'

interface ReadOnlyFieldProps {
  fieldName: string
  value?: string | number
  classNames?: string
}

export const PdfReadOnlyField = ({ fieldName, value, classNames }: ReadOnlyFieldProps) => {
  const defaultClassNames = 'c-container__field--no-break-inside c-container__field--margin-top-small'
  return (
    <div className={classNames ? classNames : defaultClassNames}>
      <Label labelText={fieldName} />
      <MsafCoreReadOnlyField isSkeleton={false} labelledBy={fieldName} value={value} />
    </div>
  )
}
