import { buildQueryString } from '@msaf/core-common'
import { LookupDisplayValue, LookupResult, SearchQuery, SearchResult, SearchResultTokenResponse } from '../types/state'
import { SearchTemplate } from '../types/structure'

export type AuthStrategy = 'cookie' | 'none' | 'token'

export type BaseRequestOptions = {
  authStrategy: AuthStrategy
  beforeSend?: (input: RequestInfo, init: RequestInit) => RequestInit
  urlPrefix?: string
}

export type NoneAuthOptions = BaseRequestOptions & {
  authStrategy: 'none'
}

export type CookieAuthOptions = BaseRequestOptions & {
  authStrategy: 'cookie'
  csrfToken: string
}

export type TokenAuthOptions = BaseRequestOptions & {
  authStrategy: 'token'
  authToken: string
  authTokenScheme: string
}

export type RequestOptions<T extends AuthStrategy = 'token'> = T extends 'token'
  ? TokenAuthOptions
  : T extends 'cookie'
  ? CookieAuthOptions
  : NoneAuthOptions

async function _makeRequest<T extends AuthStrategy = 'token'>(
  input: RequestInfo,
  init?: RequestInit,
  options?: RequestOptions<T>,
): Promise<Response> {
  let reqInit = init ?? {}

  // Set auth for request
  if (options?.authStrategy === 'token') {
    const tokenAutOptopns = options as TokenAuthOptions
    if (!tokenAutOptopns.authToken) {
      throw new Error('No auth token set for request to generic-search backend')
    }

    if (Array.isArray(reqInit.headers)) {
      throw new Error('Array type is not currently supported for generic search header init')
    }

    const bearer: string = `${tokenAutOptopns.authTokenScheme ?? 'Bearer'} ${tokenAutOptopns.authToken}`

    // Handle different ways `headers` object can exist in RequestInit
    if (!reqInit.headers) {
      reqInit.headers = {
        Authorization: bearer,
      }
    } else if ('Authorization' in reqInit.headers) {
      reqInit.headers['Authorization'] = bearer
    } else if (reqInit.headers instanceof Headers) {
      reqInit.headers.set('Authorization', bearer)
    } else {
      reqInit.headers['Authorization'] = bearer
    }
  }

  // Set URL for request
  if (typeof input !== 'string') {
    throw new Error('Request input that is not a string literal is not currently supported for generic search')
  }

  // De-dup slashes in request url
  const reqInput = `${options?.urlPrefix ?? '/api/generic-search'}/${input}`

  // Allow modification of request by caller prior to send.
  // Primarily used for custom authentication, but can be used to modify any aspect of the request
  if (options?.beforeSend) {
    reqInit = options.beforeSend(reqInput, reqInit)
  }

  return await fetch(reqInput, reqInit)
}

async function fetchApi<R = any, T extends AuthStrategy = 'token'>(
  input: RequestInfo,
  init?: RequestInit,
  options?: RequestOptions<T>,
): Promise<R> {
  const response = await _makeRequest<T>(input, init, options)
  if (!response.ok) {
    const cause = await response.json()
    const error = new Error(response.statusText, { cause })
    throw error
  }
  return response.json()
}

export function getSearchConfig<T extends AuthStrategy = 'token'>(searchTypeKey: string, options?: RequestOptions<T>) {
  return fetchApi<{ searchTemplate: SearchTemplate }, T>(`fetch-search-config/${searchTypeKey}`, undefined, options)
}

export function getSearchResult<T extends AuthStrategy = 'token'>(
  searchQuery?: SearchQuery,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('query', JSON.stringify({ searchQuery }))

  return fetchApi<{ searchResult: SearchResult }, T>(
    `fetch-search-results`,
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

export function getLookupResult<T extends AuthStrategy = 'token'>(
  lookupQuery: Record<string, string | undefined>,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('query', JSON.stringify({ lookupQuery }))

  return fetchApi<LookupResult, T>(
    `fetch-lookup-results`,
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

export function getLookupDisplayValue<T extends AuthStrategy = 'token'>(
  lookupDisplayValueQuery: any,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('query', JSON.stringify({ lookupDisplayValueQuery }))

  return fetchApi<LookupDisplayValue, T>(
    `fetch-lookup-display-value`,
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

/**
 * Fetch the coordinates for the selected address
 *
 * TODO: Move this to a new 'esri-lookup' package
 *
 * @param text Address text returned by the address search API
 * @param key Address key returned by the address search API
 * @param options Fetch request options
 * @returns
 */
export function getAddressCoordinates<R = any, T extends AuthStrategy = 'token'>(
  text: string,
  key: string,
  options?: RequestOptions<T>,
) {
  const queryString = buildQueryString({ text, key })
  return fetchApi<R, T>(`esri-address/get-coordinates/?${queryString}`, undefined, options)
}

/**
 * Fetch address suggestions from the esri server
 *
 * TODO: Move this to a new 'esri-lookup' package
 *
 * @param query Entered by the user
 * @param options Fetch request options
 * @returns
 */
export function searchAddress<R = any, T extends AuthStrategy = 'token'>(query: string, options?: RequestOptions<T>) {
  const queryString = buildQueryString({ q: query, includePlaces: true })
  return fetchApi<R, T>(`esri-address/search-physical/?${queryString}`, undefined, options)
}

export function getDetailedMapResult<R = any, T extends AuthStrategy = 'token'>(
  searchTypeKey: string,
  identifier: string | number | boolean | null,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('query', JSON.stringify({ mapDetailQuery: { searchTypeKey, identifier } }))

  return fetchApi<R, T>(
    `fetch-detailed-map-result`,
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

export function getSearchResultExport<T extends AuthStrategy = 'token'>(
  searchQuery?: SearchQuery,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('query', JSON.stringify({ searchQuery }))

  return fetchApi<SearchResultTokenResponse, T>(
    `export-search-results`,
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

export function postSavedSearch<R = any, T extends AuthStrategy = 'token'>(
  searchQueryName: string,
  searchQueryJson: SearchQuery,
  options?: RequestOptions<T>,
) {
  const data = new FormData()
  data.append('name', searchQueryName)
  data.append('query', JSON.stringify({ searchQuery: searchQueryJson }))

  return fetchApi<R, T>(
    'add-saved-query',
    {
      body: data,
      method: 'POST',
    },
    options,
  )
}

export function getSavedSearches<R = any, T extends AuthStrategy = 'token'>(
  searchTypeKey: string,
  options?: RequestOptions<T>,
) {
  return fetchApi<R, T>(`fetch-saved-query-list/${searchTypeKey}`, undefined, options)
}

export function getSavedSearchResult<R = any, T extends AuthStrategy = 'token'>(
  id: number | undefined,
  options?: RequestOptions<T>,
) {
  return fetchApi<R, T>(`fetch-saved-query/${id}`, undefined, options)
}
