import { FieldValues } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { GetFeatureFormConfigProps } from '..'
import { FeatureFormMode, FencingFormFields, FormElementConfig } from '../../types'
import { isFormModes, toDollarCurrencyFormat } from '../../utils'

export const getFencingFormConfig = <T extends FieldValues>({
  planId,
  formMode,
}: Pick<GetFeatureFormConfigProps<T>, 'planId' | 'formMode'>): FormElementConfig<FencingFormFields>[] => {
  const { isMapMode, isMapOrEditMode, isNotVerificationMapMode, isVerificationMode } = isFormModes(formMode)
  const displayStandardFeatureFields = isVerificationMode ? RouteMode.VIEW : RouteMode.EDIT

  return [
    {
      renderOnlyWhen: formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        // Hack override type to cope with 'area' or 'proposed' field. We don't have verification form types here.
        fieldId: isVerificationMode ? ('proposedLength' as keyof FencingFormFields) : 'length',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: isMapOrEditMode,
      type: 'field',
      element: {
        fieldId: 'application',
        type: 'lookup',
        lookupId: 'application',
        fieldFilters: { planId },
        isRequired: true,
        defaultToFirst: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'locationName',
        type: 'text',
        mode: displayStandardFeatureFields,
        isRequired: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'anticipatedStart',
        type: 'date',
        isRequired: true,
        showMonthYearPicker: true,
        dateFormat: 'MMM yyyy',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'justification',
        formPrefix: 'action',
        type: 'text-area',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'intendedImplementer',
        type: 'lookup',
        isRequired: true,
        lookupId: 'work_implementor',
        mode: displayStandardFeatureFields,
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'fenceType',
        type: 'lookup',
        isRequired: true,
        lookupId: 'fence_type',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'terrainDifficulty',
        type: 'lookup',
        isRequired: true,
        lookupId: 'terrain_difficulty',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isMapMode,
      type: 'field',
      element: {
        fieldId: 'materialPrice',
        formPrefix: 'actionFencing',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
    {
      renderOnlyWhen: formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        fieldId: 'proposedCost',
        labelOverride: formMode == FeatureFormMode.VERIFICATION_FORM ? 'Contracted cost' : 'Total cost',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
  ]
}
