import { useMemo } from 'react'
import { buildLayersObject, createMapboxLeafletLayers } from '../config/mapbox/layers'
import { useRequest } from './useRequest'
import { useQuery } from 'react-query'
import { MapboxConfig } from '../config/mapbox/layers'
import { usePropertyBoundariesLayer } from './use-property-boundaries-layer'

export function useMapLayers({ planId }: { planId?: string }) {
  const { client } = useRequest()

  const { data: mapboxLayers, isLoading: isLoadingMapboxLayers } = useQuery<MapboxConfig>(['map-layers'], () =>
    client.get('/api/map-layer').then((response) => response.data),
  )

  const { layer: propertyBoundariesLayer, isLoading: isLoadingPropertyBoundariesLayers } = usePropertyBoundariesLayer({
    excludePlanIds: planId ? [planId] : [],
  })

  const leafletLayers = useMemo(() => {
    const mapboxLeafletLayers = mapboxLayers ? createMapboxLeafletLayers(mapboxLayers.layers, mapboxLayers.token) : []
    return [...mapboxLeafletLayers, propertyBoundariesLayer]
  }, [mapboxLayers, propertyBoundariesLayer])

  const defaultLayers = leafletLayers.filter(({ config }) => !!config.enabledByDefault)

  const leafletLayerObject = useMemo(() => buildLayersObject(leafletLayers), [leafletLayers])

  return {
    layers: leafletLayerObject,
    defaultLayers: defaultLayers.map(({ layer }) => layer),
    isLoading: isLoadingMapboxLayers || isLoadingPropertyBoundariesLayers,
  }
}
