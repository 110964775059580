import { Button, RecordFooter } from '@msaf/core-react'
import { useLocation } from '@msaf/router-react'
import { memo, ReactNode } from 'react'
import { RouteMode } from '../../../types/route'

interface FooterProps {
  mode: RouteMode
  footerActions?: ReactNode
  nextPath?: string
  canEdit?: boolean
  isDisabled?: boolean
}

export const SAVE_AND_CONTINUE_ID = 'save-and-continue'

export default memo(function ({ mode, footerActions, nextPath, canEdit, isDisabled }: FooterProps) {
  const { pathname } = useLocation()

  // Early exit if explicitly provided actions
  if (footerActions !== undefined) {
    return <RecordFooter>{footerActions}</RecordFooter>
  }

  const actions = [
    <Button
      key='cancel'
      type='button'
      buttonStyle='text-action'
      path='/'
      label={mode == RouteMode.EDIT ? 'Cancel' : 'Close'}
    />,
  ]

  if (mode === RouteMode.EDIT) {
    const saveAction = <Button key='save' label='Save' type='submit' isDisabled={isDisabled} />
    actions.unshift(saveAction)

    if (nextPath) {
      const saveAndContinueAction = (
        <Button
          key='saveandcontinue'
          label='Save and continue'
          id={SAVE_AND_CONTINUE_ID}
          type='submit'
          isDisabled={isDisabled}
        />
      )
      actions.unshift(saveAndContinueAction)
    }
  } else {
    if (canEdit) {
      actions.unshift(
        <Button key='edit' label='Edit' path={pathname.replace(`/${RouteMode.VIEW}/`, `/${RouteMode.EDIT}/`)} />,
      )
    }
  }

  return <RecordFooter>{actions}</RecordFooter>
})
