import { FeatureCollection, Geometry, Properties } from '@turf/helpers'
import { useMemo } from 'react'
import { FeatureFormFields, FormConfig, FormElementConfig } from '../../../../../../../../forms/types'
import { ActionFormConfigType } from '../../action/[actionId]/action.form'

export function updateVerificationFields(
  data: Properties | undefined,
  config: FormConfig<FeatureFormFields>,
): FormConfig<FeatureFormFields> {
  const initiallyRequiredConfig = data?.hasVariation
    ? {
        isRequired: true,
        isDisabled: false,
      }
    : {
        isRequired: false,
        isDisabled: true,
      }

  return config.map((c) => {
    if (
      'fieldId' in c.element &&
      ['actualCost', 'actualMaintenanceType', 'actualFenceType', 'actualNumberOfPlants'].includes(
        c.element?.fieldId ?? '',
      )
    )
      return {
        ...c,
        element: { ...c.element, ...initiallyRequiredConfig },
      } as FormElementConfig<FeatureFormFields>
    return c
  })
}

type GetFormConfigProps = {
  actionConfig: ActionFormConfigType
  returnPath: string
  mapPath: string
  data?: Properties
  features?: FeatureCollection<Geometry, Properties>
  isMigrated?: boolean
}

// Needs to be an any type as we're returning an unknown action config
export const useActionVerificationFormConfig = ({
  actionConfig,
  returnPath,
  mapPath,
  data,
  features,
  isMigrated,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
GetFormConfigProps): FormConfig<any> =>
  useMemo(
    () => [
      {
        type: 'action',
        element: {
          type: 'button',
          path: returnPath,
          label: 'Back',
          iconAlignment: 'left',
          icon: 'arrow-left',
          buttonStyle: 'text-action',
          enabledInViewMode: true,
        },
      },
      {
        type: 'atom',
        element: { type: 'heading', content: 'Action verification', level: 2 },
      },
      {
        renderOnlyWhen: !isMigrated,
        type: 'field',
        element: {
          type: 'static-map',
          features,
          actions: [
            {
              iconAriaLabel: 'map',
              icon: 'map',
              label: 'Map mode',
              path: mapPath,
            },
          ],
          zoom: 11,
        },
      },
      ...updateVerificationFields(data, actionConfig),
    ],
    [isMigrated, actionConfig, data?.hasVariation, returnPath, features],
  )
