import { UploadedFile } from '@msaf/core-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import { FieldWatchResultProps } from '../../../../../../../../forms/components/hooks/use-field-watch'
import { FormConfig, LabelledFieldConfig } from '../../../../../../../../forms/types'
import { LookupItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { ApplicationRouteParams } from '../../../../../../../../types/route'
import { formWatchHardValidator, Validation } from '../../../../../../../../validation/resolver'

export interface ContractFields {
  identifier: string
  plan: LookupItem
  contractAssignedMaurikuraTeamMember: LookupItem
  contractSent: boolean
  dateContractSent: string | null
  contractUrl: { value: string }[]
  contractReturned: boolean
  dateContractReceived: string | null
  dateContractEnds: string | null
  dateContractEndsVariationDate: string | null
  readyForSignoff: boolean
  files?: UploadedFile[]
  notes: Array<AppNoteType>
}

const hardRequiredFields: Validation<ContractFields>[] = [
  { name: 'contractAssignedMaurikuraTeamMember', validate: ['populated'], key: 'value' },
  { name: 'contractSent', validate: ['populated'] },
  {
    name: 'dateContractSent',
    validate: ['conditionallyPopulated'],
    dependencyCheck: (data: ContractFields) => !!data.contractSent,
  },
  {
    name: 'contractUrl',
    validate: ['atLeastOne'],
    dependencyCheck: (data: ContractFields) => !!data.contractSent,
    key: 'value',
  },
  { name: 'contractReturned', validate: ['populated'] },
  {
    name: 'dateContractReceived',
    validate: ['conditionallyPopulated'],
    dependencyCheck: (data: ContractFields) => !!data.contractReturned,
  },
  {
    name: 'dateContractEnds',
    validate: ['conditionallyPopulated'],
    dependencyCheck: (data: ContractFields) => !!data.contractReturned,
  },
]

export const getContractDetailWatch = (onValidate: (state: boolean) => void) => {
  return formWatchHardValidator(hardRequiredFields, onValidate)
}

export const useFormConfig = (data?: ContractFields): FormConfig<ContractFields> => {
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const { user } = useAuth()

  return useMemo(() => {
    return [
      {
        type: 'atom',
        element: {
          type: 'heading',
          content: 'KMR Maurikura review',
          level: 2,
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'contractAssignedMaurikuraTeamMember',
          lookupId: 'contractMaurikuraTeamMember',
          isRequired: true,
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          content: 'Contract sent',
          level: 2,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'contractSent',
          type: 'boolean',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'dateContractSent',
          type: 'date',
          isRequired: !!data?.contractSent,
          isDisabled: !data?.contractSent,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ContractFields>) => {
            if (info.type === 'change' && info.name === 'contractSent') {
              if (!data?.contractSent) {
                methods.setValue('dateContractSent', null)
              }
              return {
                ...(initialConfig as LabelledFieldConfig<ContractFields>),
                type: 'date',
                isRequired: !!data?.contractSent,
                isDisabled: !data?.contractSent,
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'contractUrl',
          type: 'text',
          repeating: true,
          isRequired: !!data?.contractSent,
          isDisabled: !data?.contractSent,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ContractFields>) => {
            if (info.type === 'change' && info.name === 'contractSent') {
              if (!data?.contractSent) {
                methods.setValue('contractUrl', [])
              }
              return {
                ...(initialConfig as LabelledFieldConfig<ContractFields>),
                type: 'text',
                isRequired: !!data?.contractSent,
                isDisabled: !data?.contractSent,
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          content: 'Contract returned by landowner',
          level: 2,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'contractReturned',
          type: 'boolean',
          isRequired: true,
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'dateContractReceived',
          type: 'date',
          isRequired: !!data?.contractReturned,
          isDisabled: !data?.contractReturned,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ContractFields>) => {
            if (info.type === 'change' && info.name === 'contractReturned') {
              if (!data?.contractReturned) {
                methods.setValue('dateContractReceived', null)
              }
              return {
                ...(initialConfig as LabelledFieldConfig<ContractFields>),
                type: 'date',
                isRequired: !!data?.contractReturned,
                isDisabled: !data?.contractReturned,
              }
            }
            return initialConfig
          },
        },
      },
      {
        type: 'field',
        element: {
          fieldId: 'dateContractEnds',
          type: 'date',
          isRequired: !!data?.contractReturned,
          isDisabled: !data?.contractReturned,
          watch: ({ info, data, methods, initialConfig }: FieldWatchResultProps<ContractFields>) => {
            if (info.type === 'change' && info.name === 'contractReturned') {
              if (!data?.contractReturned) {
                methods.setValue('dateContractEnds', null)
              }
              return {
                ...(initialConfig as LabelledFieldConfig<ContractFields>),
                type: 'date',
                isRequired: !!data?.contractReturned,
                isDisabled: !data?.contractReturned,
              }
            }
            return initialConfig
          },
        },
      },
      {
        renderOnlyWhen: !!data?.dateContractEndsVariationDate,
        type: 'field',
        element: {
          fieldId: 'dateContractEndsVariationDate',
          type: 'date',
          isDisabled: true,
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Photos and documents',
        },
      },
      {
        type: 'field',
        element: {
          type: 'files',
          fieldId: 'files',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.CONTRACT,
        },
      },
    ]
  }, [user, planId, applicationId, data])
}
