import { useState, useEffect } from 'react'

/**
 * Use to detect current viewport size via media queries in JS
 *
 * Example usage:
 * const isDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT})`)
 *
 * @param mediaQuery
 * @returns boolean
 */
export function useMediaQuery(mediaQuery: string): boolean {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(mediaQuery)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, mediaQuery])

  return matches
}
