export type AuthEvent = 'authenticated' | 'unauthenticated'

export interface AuthContext<T> {
  authStrategy?: AuthStrategy
  logout: (options?: LogoutOptions) => Promise<void>
  login: (options?: LoginOptions) => Promise<void>
  user?: T | undefined
  isLoading: boolean
  isAuthenticated: boolean
  error?: unknown
}

export interface AuthEventHandler {
  (event: AuthEvent): void
}

export class AuthStrategy {
  token: string
  tokenIdentifier: string
  constructor(tokenIdentifier: string, token: string) {
    this.tokenIdentifier = tokenIdentifier
    this.token = token
  }
  toHeader() {
    return {
      Authorization: `${this.tokenIdentifier} ${this.token}`,
    }
  }
}

export type LoginOptions = { redirectPath?: string; email?: string }
export type LogoutOptions = { redirectPath?: string }

export type WhoAmI<T> = (authStrategy: AuthStrategy) => Promise<T>
