import React, { useCallback, useMemo } from 'react'
import { DEFAULT_MIME_TYPES } from '../../../constants'
import { FileList, FileListProps, RemoveFileCallback } from './file-list'
import { FileUpload, FileUploadProps } from './file-upload'
import { FileState, NewOrUploadedFile } from './types'

export * from './file-list'
export * from './file-upload'
export * from './types'

export type FileManagerProps = Partial<Omit<FileListProps, 'fileRemove'>> &
  Partial<FileUploadProps> & {
    isUploadAllowed?: boolean
    files: Array<NewOrUploadedFile>
    onFileRemove?: RemoveFileCallback
  }

export function FileManager({
  files = [],
  heading = 'Uploaded files',
  isEditable = false,
  validationErrors,
  isUploadAllowed = true,
  isSkeleton = false,
  getMetadataElement,
  fileSaveAction,
  onFileUploading,
  onFileUploaded,
  onFileRemove,
  fileValidators,
  allowMultiple = true,
  allowedFileTypes = DEFAULT_MIME_TYPES,
  downloadAction = null,
}: FileManagerProps) {
  const showUpload = isEditable && isUploadAllowed

  if (isEditable && !fileSaveAction) {
    throw new Error('REACTCORE-2 isEditable is set but no fileSaveAction has been provided.')
  }

  const removeFileHandler = useCallback((file: NewOrUploadedFile) => {
    if (file.state === FileState.Uploading) file.abortController.abort()
    onFileRemove?.(file)
  }, [])

  // Assume files without a state have come from the API and are saved.
  const filesWithState = useMemo(
    () => files.map((f) => ({ ...f, state: f.state ?? FileState.Saved } as NewOrUploadedFile)),
    [files],
  )

  return (
    <div className='c-file-manager'>
      <FileList
        heading={heading}
        files={filesWithState}
        isSkeleton={isSkeleton}
        isEditable={isEditable}
        removeFile={onFileRemove}
        validationErrors={validationErrors}
        getMetadataElement={getMetadataElement}
        downloadAction={downloadAction}
      />
      {showUpload && !!fileSaveAction && (
        <FileUpload
          allowedFileTypes={allowedFileTypes}
          isSkeleton={isSkeleton}
          allowMultiple={allowMultiple}
          fileSaveAction={fileSaveAction}
          onFileUploading={onFileUploading}
          onFileUploaded={onFileUploaded}
          onFileUploadFailed={removeFileHandler}
          fileValidators={fileValidators}
        />
      )}
    </div>
  )
}
