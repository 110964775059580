import { useFeatureState } from '../../../../components/feature-map/utils/state-management'
import { PdfMapLandscape } from '../../../../components/pdf/map-landscape'
import { Feature } from '@turf/helpers'
import { useMemo } from 'react'
import { PdfPages } from '../../../../components/pdf/pdf-pages'
import { Heading } from '@msaf/core-react'
import { PlantingSummary } from './planting-summary'
import { FencingGatesTroughsSummary } from './fencing-troughs-gates-summary'
import { PlantingPreparationMaintenanceSummary } from './planting-preperation-maintenance-summary'
import { toDollarCurrencyFormat } from '../../../utils'
import { PdfReadOnlyField } from '../../../../components/pdf/read-only-field'
import { FeatureAction } from '../../../../components/pdf/types'
import { ApplicationDetailsFields } from '../../../../routes/plans/[mode]/[id]/application/[applicationId]/details/config'
import { getFeaturesByType } from '../utils'

interface ExportProjectPageProps {
  planId: string
  projectId: string
  projectDetailsData: ApplicationDetailsFields | undefined
}

export function ExportProjectPage({ planId, projectId, projectDetailsData }: ExportProjectPageProps) {
  // Fetch data
  const { state: collectionState } = useFeatureState({
    featuresUrl: `/api/plan/${planId}/features`,
  })

  const existingFencing = useMemo(() => [...getFeaturesByType(collectionState, 'existingFencing')], [collectionState])
  const features: FeatureAction[] | undefined = collectionState?.collections?.filtered?.features

  // Filter actions by category and type
  const getFeatures = (features: FeatureAction[] | undefined, categoryTypes: string[]) =>
    features?.filter((feature: Feature) => {
      const isPropertyBoundary = feature.properties?.type === 'propertyBoundary'
      if (categoryTypes.includes('existingFencing') && feature.properties?.type === 'existingFencing') return true

      return (
        !isPropertyBoundary &&
        categoryTypes?.includes(feature.properties?.category || feature.properties?.type) &&
        feature.properties?.application?.value == projectId
      )
    }) ?? []

  const fencingFeatures = useMemo(() => [...getFeatures(features, ['fencing'])], [features])
  const fencingGatesTroughsFeatures = useMemo(() => [...getFeatures(features, ['fencing', 'gateTrough'])], [features])
  const plantingFeatures = useMemo(() => [...getFeatures(features, ['planting'])], [features])
  const preperationMaintenanceFeatures = useMemo(
    () => [...getFeatures(features, ['maintenance', 'preperation'])],
    [features],
  )
  const totalFeatureCount =
    fencingFeatures.length +
    fencingGatesTroughsFeatures.length +
    plantingFeatures.length +
    preperationMaintenanceFeatures.length

  return (
    <>
      <PdfMapLandscape pageTitle='Fencing map' features={[...existingFencing, ...fencingFeatures]} />
      <PdfMapLandscape pageTitle='Planting map' features={plantingFeatures} />

      <PdfPages>
        <section className='l-pdf-container--margin-small'>
          <FencingGatesTroughsSummary features={fencingGatesTroughsFeatures} />
          <PlantingSummary features={plantingFeatures} />
          {
            // Enable the display of planting, site preperation and maintenance actions are present.
            plantingFeatures.length + preperationMaintenanceFeatures.length > 0 && (
              <PlantingPreparationMaintenanceSummary
                features={plantingFeatures}
                applicationDetailsData={projectDetailsData}
              />
            )
          }
          {totalFeatureCount != 0 ? (
            <section className='l-section--no-break-inside l-pdf-container--margin-medium'>
              <Heading level={3} className='c-field__heading--margin-small'>
                Total project costs
              </Heading>
              <p className='c-field--no-vertical-margin'>
                The total project budget is set out below. All costs exclude GST. The KMR grant is a contribution
                towards the estimated total costs of the project.
              </p>

              <PdfReadOnlyField
                fieldName='Total estimated project costs'
                value={toDollarCurrencyFormat(projectDetailsData?.proposedTotals.totalProposedCost)}
              />
              <p>Estimated project costs will be covered by the following contributions:</p>
              <PdfReadOnlyField
                fieldName='KMR grant contribution'
                value={toDollarCurrencyFormat(projectDetailsData?.proposedTotals.kmrContribution)}
              />
              <PdfReadOnlyField
                fieldName='Other co-funding contribution (if any)'
                value={toDollarCurrencyFormat(projectDetailsData?.proposedTotals.totalCoFunderContribution)}
              />
              <PdfReadOnlyField
                fieldName='Estimated value of landowner contribution'
                value={toDollarCurrencyFormat(projectDetailsData?.proposedTotals.landOwnerContribution)}
              />
              <br />
              <p className='p--fineprint'>
                NOTE that the KMR grant amount is based on ESTIMATED project costs at the time of writing, including the
                value of time and materials. Actual costs may differ. A landowner may meet their contribution through
                cash or in-kind (i.e. if they do some of the works themselves) or a mix of the two. In all cases, KMR
                quality standards must be met in order for the contribution to be recognised / paid out.
              </p>
            </section>
          ) : (
            <Heading level={5}>This project does not have any actions added yet.</Heading>
          )}
        </section>
      </PdfPages>
    </>
  )
}
