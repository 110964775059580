import { DISPLAY_DATE_FORMAT } from '@msaf/core-common'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { InputWidth } from '../../../types'
import { Icon } from '../../common'
import { SkeletonTextInput } from '../text-input/skeleton-text-input'

export interface DateFieldProps extends Omit<ReactDatePickerProps, 'onChange'> {
  startDate?: Date
  fieldSize?: InputWidth
  isRequired?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  placeholder?: string
  dateFormat?: string
  selectedDate?: Date
  setSelectedDate: (date: Date) => void
  isSkeleton?: boolean
  menuPortalTarget?: HTMLElement | null
}

export const DateField = React.forwardRef<ReactDatePicker, DateFieldProps>(
  (
    {
      startDate,
      fieldSize,
      isRequired,
      isDisabled,
      isInvalid,
      placeholder,
      dateFormat = DISPLAY_DATE_FORMAT,
      selectedDate,
      setSelectedDate,
      isSkeleton,
      menuPortalTarget,
      ...props
    }: DateFieldProps,
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonTextInput />
    }

    const inputContainerClasses = classNames('c-input-container', {
      [`c-input-container--${fieldSize}`]: !!fieldSize,
    })

    const inputClasses = classNames('c-text-input', 'c-text-input--has-icon', {
      'c-text-input--invalid': isInvalid,
      'c-text-input--disabled': isDisabled,
      [`c-text-input--${fieldSize}`]: !!fieldSize,
    })

    // On calendar date select, reset focus to input element and close calendar
    const handleSelect = () => {
      const datePickerRef = ref as React.MutableRefObject<ReactDatePicker>
      if (ref) {
        datePickerRef.current?.setFocus()
        datePickerRef.current?.setOpen(false)
      }
    }

    // Ensure calendar element is closed when focus moved to another field
    // NOTE: onBlur function not currently working (see: https://github.com/Hacker0x01/react-datepicker/issues/2028)
    const handleBlur = () => {
      ref && (ref as React.MutableRefObject<ReactDatePicker>).current?.setOpen(false)
    }

    useEffect(() => {
      // The ReactDatePicker needs a id for the portal but we take in a ref so as to be consistent
      // with other msaf components such as the dropdown. If the component for the passed in ref doesn't
      // have an ID already then we assign a generated one.
      if (menuPortalTarget && !menuPortalTarget.id)
        menuPortalTarget.id = `msafDateFieldMenuPortalTarget-${new Date().getUTCMilliseconds()}`
    }, [menuPortalTarget])

    return (
      <div className={inputContainerClasses}>
        <Icon className='c-input-container__icon' icon='calendar' ariaHidden={true} />
        <ReactDatePicker
          ref={ref}
          className={inputClasses}
          calendarClassName='c-datepicker'
          required={isRequired}
          startDate={startDate}
          selected={selectedDate}
          onChange={(date: Date) => setSelectedDate(date)}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          // Use the passed in ref ID or adds portal at end of DOM to render datepicker pop-up:
          portalId={menuPortalTarget ? menuPortalTarget.id : 'datepicker-portal'}
          disabled={isDisabled}
          onSelect={handleSelect}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    )
  },
)
