import { Dispatch, useCallback, useEffect, useState } from 'react'

export function usePassedState<T>(
  externalState: T,
  setExternalState?: (v: T) => void,
): { state: T; setState: Dispatch<T> } {
  const [state, setInternalState] = useState<T>(externalState)

  useEffect(() => setInternalState(externalState), [externalState])

  const setState = useCallback(
    (newState: T) => {
      setInternalState(newState)
      setExternalState?.(newState)
    },
    [setInternalState, setExternalState],
  )

  return { state, setState }
}
