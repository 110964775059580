import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { useMemo } from 'react'
import { FormElementConfig } from '../../../../../../forms/types'
import { usePermission } from '../../../../../../services/permissions'
import { LookupItem } from '../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../types/notes'
import { UserPermissions } from '../../../../../../types/permissions'

export interface PreChecksFields {
  identifier: string
  type: Partial<LookupItem>
  fieldAdvisors: Partial<LookupItem>[]
  fieldAdvisorAssignedDate: string
  proposedVisitDate: string
  ownerContacted: boolean
  mapRiskInspected: boolean
  boundariesDrafted: boolean
  wetWeatherGearPacked: boolean
  phoneCharged: boolean
  colleagueNotified: boolean
  isFonterraPlan: boolean
  notes: AppNoteType[]
}

export const usePreChecksForm = (isMigrated: boolean | undefined, isFonterraPlan: boolean | undefined) => {
  const hasUnscoped = usePermission([UserPermissions.EDIT_PLANS, UserPermissions.EDIT_FONTERRA_PLAN])
  const faConfig: FormElementConfig<PreChecksFields>[] = useMemo(
    () => [
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'fieldAdvisors',
          lookupId: 'advisor',
          formPrefix: 'preChecks',
          repeating: true,
          defaultValue: {},
          allowDuplicates: false,
          isDisabled: !hasUnscoped,
          isRequired: !isMigrated,
        },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'fieldAdvisorAssignedDate',
          maxDate: formatDate(new Date(), INTERNAL_DATE_FORMAT),
          isDisabled: !hasUnscoped,
        },
      },
    ],
    [hasUnscoped, isMigrated],
  )

  return useMemo(
    () => [
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Record details' },
      },
      {
        type: 'field',
        element: {
          type: 'text',
          fieldId: 'identifier',
          isDisabled: !(hasUnscoped && (isMigrated || isFonterraPlan)),
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'type',
          formPrefix: 'preChecks',
          lookupId: 'plan_type',
          defaultValue: {},
          isRequired: true,
        },
      },
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Assigned field advisors' },
      },
      ...faConfig,
      {
        type: 'atom',
        element: { type: 'divider' },
      },
      {
        type: 'atom',
        element: { type: 'heading', level: 2, content: 'Initial site visit details' },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'proposedVisitDate',
        },
      },
      {
        type: 'field',
        element: {
          type: 'checkable-group',
          label: 'Field advisor checklist',
          labelId: 'faChecklistLabel',
          helpText: 'This checklist is a preparation guide for your site visit',
          fields: [
            {
              type: 'checkbox',
              fieldId: 'ownerContacted',
            },
            {
              type: 'checkbox',
              fieldId: 'mapRiskInspected',
            },
            {
              type: 'checkbox',
              fieldId: 'boundariesDrafted',
            },
            {
              type: 'checkbox',
              fieldId: 'wetWeatherGearPacked',
            },
            {
              type: 'checkbox',
              fieldId: 'phoneCharged',
            },
            {
              type: 'checkbox',
              fieldId: 'colleagueNotified',
            },
          ],
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.PRE_CHECKS,
        },
      },
    ],
    [isFonterraPlan, isMigrated, hasUnscoped],
  ) as FormElementConfig<PreChecksFields>[]
}
