import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { ReactNode, useMemo } from 'react'
import { DefaultValues } from 'react-hook-form'
import { usePermission } from '../../services/permissions'
import { LookupItem } from '../../types'
import { AppNoteType, NoteForm } from '../../types/notes'
import { UserPermissions } from '../../types/permissions'
import { RouteMode } from '../../types/route'
import { FormErrors } from '../../validation/constants'
import { Form } from '../components/form'
import { FieldWatchResultProps } from '../components/hooks/use-field-watch'
import { FieldConfig, FormElementConfig } from '../types'

interface DecisionFormProps {
  submitAction: (data: DecisionFormValues, submitter?: HTMLElement | null) => Promise<void>
  data?: DecisionFormValues
  footerActions: ReactNode
  errors?: FormErrors<DecisionFormValues>
  mode?: RouteMode
  canEdit: boolean
}

export type DecisionFormValues = {
  assignedReviewer: LookupItem
  meetsCriteria: boolean
  decision: LookupItem
  fieldAdvisors: Partial<LookupItem>[]
  fieldAdvisorAssignedDate: string | null
  justification: LookupItem | null
  notes?: Array<AppNoteType>
}

const getDecisionConfig = (
  initialState?: DefaultValues<DecisionFormValues>,
  userCanAssign?: boolean,
  mode?: RouteMode,
): FormElementConfig<DecisionFormValues>[] => {
  const decisionWatch = ({ initialConfig, info, data, methods }: FieldWatchResultProps<DecisionFormValues>) => {
    if (info.name === 'meetsCriteria' && info.type === 'change') {
      if (data.meetsCriteria === true) {
        methods.setValue('justification', null)
        return {
          ...initialConfig,
          type: 'read-only',
          fieldId: 'justification.label',
          isRequired: false,
        } as FieldConfig<DecisionFormValues>
      } else {
        return {
          ...initialConfig,
          type: 'lookup',
          fieldId: 'justification',
          isRequired: true,
          lookupId: 'justification',
        } as FieldConfig<DecisionFormValues>
      }
    }

    return initialConfig
  }

  const fieldAdvisorWatch = ({ initialConfig, info, data, methods }: FieldWatchResultProps<DecisionFormValues>) => {
    if ((info.name === 'meetsCriteria' || info.name === 'decision') && info.type === 'change') {
      const progressToSrp = data.meetsCriteria && data.decision?.name === 'full'
      if (!progressToSrp) {
        methods.setValue('fieldAdvisors', [{}])
      }
      return {
        ...initialConfig,
        isDisabled: !progressToSrp,
        isRequired: progressToSrp,
      } as FieldConfig<DecisionFormValues>
    }

    return initialConfig
  }

  const fieldAdvisorAssignedDateWatch = ({
    initialConfig,
    info,
    data,
    methods,
  }: FieldWatchResultProps<DecisionFormValues>) => {
    if ((info.name === 'meetsCriteria' || info.name === 'decision') && info.type === 'change') {
      if (data.meetsCriteria && data.decision?.name === 'full') {
        return {
          ...initialConfig,
          type: 'date',
          maxDate: formatDate(new Date(), INTERNAL_DATE_FORMAT),
        } as FieldConfig<DecisionFormValues>
      } else {
        methods.setValue('fieldAdvisorAssignedDate', null)
        return {
          ...initialConfig,
          type: 'read-only',
        } as FieldConfig<DecisionFormValues>
      }
    }

    return initialConfig
  }

  return [
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Reviewer' } },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'assignedReviewer',
        isRequired: true,
        lookupId: 'reviewer',
      },
    },
    { type: 'atom', element: { type: 'divider' } },
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Decision to proceed' } },
    {
      type: 'field',
      element: {
        type: 'boolean',
        fieldId: 'meetsCriteria',
        isRequired: true,
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'justification.label',
        isRequired: false,
        watch: decisionWatch,
      },
    },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'decision',
        lookupId: 'decision',
        isRequired: true,
      },
    },
    { type: 'atom', element: { type: 'divider' } },
    { type: 'atom', element: { type: 'heading', level: 2, content: 'Assigned field advisors' } },
    {
      type: 'field',
      element: {
        type: 'lookup',
        fieldId: 'fieldAdvisors',
        formPrefix: 'decision',
        lookupId: 'advisor',
        isRequired: initialState?.meetsCriteria && initialState?.decision?.name === 'full',
        isDisabled: !(initialState?.meetsCriteria && initialState?.decision?.name === 'full'),
        repeating: true,
        defaultValue: {},
        allowDuplicates: false,
        mode: userCanAssign ? mode : RouteMode.VIEW,
        watch: fieldAdvisorWatch,
      },
    },
    {
      type: 'field',
      element: {
        type: 'read-only',
        fieldId: 'fieldAdvisorAssignedDate',
        mode: userCanAssign ? mode : RouteMode.VIEW,
        watch: fieldAdvisorAssignedDateWatch,
      },
    },
    {
      type: 'field',
      element: {
        type: 'notes',
        fieldId: 'notes',
        form: NoteForm.DECISION,
      },
    },
  ] as FormElementConfig<DecisionFormValues>[]
}

export const DecisionForm = ({
  submitAction,
  data,
  errors,
  footerActions: FooterActions,
  mode,
  canEdit,
}: DecisionFormProps) => {
  const initialState = useMemo(() => {
    return { fieldAdvisors: data?.fieldAdvisors == undefined ? [{}] : data?.fieldAdvisors, ...data }
  }, [data])
  const userCanAssign = usePermission([UserPermissions.EDIT_PROGRESS, UserPermissions.ASSIGN_PROGRESS])
  const config = useMemo(() => {
    return getDecisionConfig(data, userCanAssign, mode)
  }, [data, userCanAssign])

  return (
    <Form<DecisionFormValues>
      submitAction={submitAction}
      initialState={initialState}
      mode={mode ?? RouteMode.VIEW}
      footerActions={FooterActions}
      canEdit={canEdit}
      errors={errors}
      config={config}
    />
  )
}
