import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { PropsWithChildren, useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { ApplicationRouteParams, RouteMode } from '../../types/route'
import { Workflow } from '../../validation/constants'
import { useWorkflowState } from '../../validation/hooks'
import { buildSectionsForWorkflow } from '../../validation/utils'

interface WorkflowProps {
  recordId: string
  workflow: Workflow
  noResultsText: string
}

export const BaseApplicationWorkflow = ({
  children,
  recordId,
  workflow,
  noResultsText,
}: PropsWithChildren<WorkflowProps>) => {
  const { applicationId, mode } = useParams<ApplicationRouteParams>()

  const stateQuery = useWorkflowState(recordId, workflow, mode)
  const sections = useMemo(() => {
    if (stateQuery.isSuccess) {
      return buildSectionsForWorkflow({
        baseRecordId: recordId,
        multiRecord: true,
        state: stateQuery.data,
        mode: mode ?? RouteMode.VIEW,
        workflowId: workflow,
        sectionHeadingFunction: (index, label) => `Project ${index + 1} ${label}`,
        displayCheckFunction: (state) => {
          if (state.meta['showWorkflow'] ?? true) {
            return { display: true }
          }
          return { display: false, adviceText: (state.meta['adviceText'] as string) || noResultsText }
        },
      })
    }
    return undefined
  }, [stateQuery.data, stateQuery.isSuccess, recordId])

  const firstLink = !applicationId && sections?.[0].links != undefined ? sections[0].links[0].path : undefined

  return (
    <RecordContainer workflowNav={<WorkflowNav sections={sections ?? []} />}>
      {firstLink ? <Navigate to={firstLink} /> : null}
      {children}
    </RecordContainer>
  )
}
