import { StaticInlineFeatureMap } from './static-inline'
import { useFeatureState } from './utils/state-management'

export function InlineFeatureMap({
  url,
  isEditable,
  isSkeleton,
  center,
  zoom,
  centerMode = 'toFeatures',
  setMap,
}: {
  url: string
  isEditable?: boolean
  isSkeleton?: boolean
  center?: [number, number]
  zoom?: number
  centerMode?: 'static' | 'toFeatures'
  setMap?: (map: L.Map | undefined) => void
}) {
  // Initialise feature collection state
  const { state: collectionState } = useFeatureState({ featuresUrl: url })

  return (
    <StaticInlineFeatureMap
      isSkeleton={isSkeleton}
      isEditable={isEditable}
      center={center}
      centerMode={centerMode}
      zoom={zoom}
      features={collectionState.collections?.filtered}
      setMap={setMap}
    />
  )
}
