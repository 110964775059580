import { useMemo } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useUser } from '../auth'
import { ErrorCode } from '../constants'
import { UserPermissions } from '../types/permissions'
import { User } from '../types/user'

/*
Checks a given user have any of the specified permissions.
*/
export function canUser(user: User, permissions: UserPermissions | UserPermissions[]) {
  const permissionsList = Array.isArray(permissions) ? permissions : [permissions]
  return user.permissions.some((perm) => permissionsList.includes(perm))
}

/*
Old depreciated method that doesn't handle the loading status of the permissions.
*/
export function usePermission(permissions: UserPermissions | UserPermissions[]): boolean {
  return usePermissionLoading(permissions).hasPermission
}

/*
Hook to check if the current user has any of the specified permissions.
Depends on useUsers so should only be used within ProtectedRoutes.
*/
export function usePermissionLoading(permissions: UserPermissions | UserPermissions[]): {
  hasPermission: boolean
  isLoading: boolean
} {
  const handler = useErrorHandler()
  const [user, isLoading] = useUser()
  const hasPermission = useMemo(() => !!user && canUser(user, permissions), [user, permissions])

  if (isLoading) {
    handler(ErrorCode.UNAUTHENTICATED_401)
  }

  return { hasPermission, isLoading }
}

/**
 * Check if the current user is the owner/creator of the note or has admin permissions.
 * @param user Logged in user
 * @param authorId Author of the note
 * @returns True or False
 */
export function canEditNotes(user: User, authorId: number): boolean {
  return canUser(user, UserPermissions.ADMIN) || authorId === user.id
}
