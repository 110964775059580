import classNames from 'classnames'
import React from 'react'

export interface DefinitionListItemProps {
  term: string
  definition: string
  width?: 'half' | 'full'
}

export interface DefinitionListProps {
  items: Array<DefinitionListItemProps>
  verticalSpacing?: 'default' | 'small' | 'none'
}

export function DefinitionList({ items, verticalSpacing = 'default' }: DefinitionListProps) {
  const classes = classNames('c-definition-list', {
    [`c-definition-list--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'default',
  })

  return (
    <div className={classes}>
      {items.map(({ term, definition, width = 'half' }) => {
        const itemClasses = classNames('c-definition-list__item', {
          [`c-definition-list__item--${width}`]: !!width,
        })

        return (
          <div className={itemClasses} key={`${term}${definition.substring(0, 3)}`}>
            <div className='c-definition-list__term'>{term}</div>
            <div className='c-definition-list__def'>{definition}</div>
          </div>
        )
      })}
    </div>
  )
}
