import { FieldValues, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { FileFieldProps } from '../../types'
import { ControlledFileManager } from '../file-controller'

export function FilesField<T extends FieldValues>({ fieldId, mode, isDisabled, ...props }: FileFieldProps<T>) {
  const { control } = useFormContext<T>()

  return (
    <ControlledFileManager
      control={control}
      name={fieldId}
      isEditable={mode !== RouteMode.VIEW && !isDisabled}
      {...props}
    />
  )
}
