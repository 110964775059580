import { FormField, TextInput } from '@msaf/core-react'
import { FilterInstance, FilterSet, SearchQuery, SearchQueryByFilter } from '@msaf/generic-search-common'
import debounce from 'lodash.debounce'
import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react'

export interface KeywordSearchProps {
  onSearch?: (searchQuery: SearchQuery) => void
  query: SearchQueryByFilter
  searchQuery?: SearchQuery
  addValue: (filter: string) => void
  setValue: (filter: string, index: number, value: string | FilterSet) => void
  removeValue: (filter: string, index: number) => void
  onKeyDown: React.KeyboardEventHandler<Element>
}

const getSearchFilter = (filterValue: string): FilterInstance => {
  return {
    filterKey: 'keywordSearch',
    filterValue: filterValue,
    filterOperator: '@@',
  }
}

export function KeywordSearch({
  onSearch,
  query,
  searchQuery,
  addValue,
  setValue,
  removeValue,
  onKeyDown,
}: KeywordSearchProps) {
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)
  useEffect(() => {
    inputRef && (inputRef as MutableRefObject<HTMLInputElement>).current.focus()
  }, [inputRef])

  const filterValue: string | undefined = useMemo(() => {
    const kwQuery = query.keywordSearch.length && query.keywordSearch[0] ? query.keywordSearch[0] : query.keywordSearch
    if ('filterValue' in kwQuery) {
      return kwQuery.filterValue
    }
    // Returning '' here is not clearing state of input, hence direct modification of current value of inputRef
    if (inputRef && (inputRef as MutableRefObject<HTMLInputElement>).current) {
      ;(inputRef as MutableRefObject<HTMLInputElement>).current.value = ''
    }
    return ''
  }, [query])

  const handleChange = debounce((input: string) => {
    if (query.keywordSearch.length === 0) {
      addValue('keywordSearch')
    }
    if (!input) {
      removeValue('keywordSearch', 0)
    }
    if (searchQuery && onSearch && !!input) {
      onSearch({
        ...searchQuery,
        filterSet: { ...searchQuery.filterSet, filters: [getSearchFilter(input)] },
      })
      setValue('keywordSearch', 0, input)
    }
  }, 300)

  return (
    <FormField label={'Keyword(s)'} labelId={`search-filter-input-label-keyword-search`}>
      <TextInput
        id={'keyword-search'}
        type='text'
        value={filterValue ?? ''}
        ref={inputRef}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        onKeyDown={onKeyDown}
      />
    </FormField>
  )
}
