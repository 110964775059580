import { UploadedFile } from '@msaf/core-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../../../../../../auth'
import { FormConfig } from '../../../../../../../../forms/types'
import { toDollarCurrencyFormat } from '../../../../../../../../forms/utils'
import { LookupItem, RepeatingLookupItem } from '../../../../../../../../types'
import { AppNoteType, NoteForm } from '../../../../../../../../types/notes'
import { ApplicationRouteParams } from '../../../../../../../../types/route'
import { PLANTING_TABLE_COLUMNS } from '../../../../../../../../forms/constants'

export interface NurseryFields {
  identifier: string
  plan: LookupItem
  dateNurseryContacted: Date
  supplyAssignedMaurikuraTeamMember: LookupItem
  nurserySupplyComment: string
  nurserySuppliers: RepeatingLookupItem
  files?: UploadedFile[]
  notes: Array<AppNoteType>
  totalNumberOfPlants: number
  totalNumberOfPlantsZoneA: number
  totalNumberOfPlantsZoneB: number
  totalNumberOfPlantsZoneC: number
  totalLabourCost: number
  totalPlantCost: number
  totalPlantingCost: number
  hasPlantingAction: boolean
  plantingSummary: string
}

export const useFormConfig = (data: NurseryFields | undefined): FormConfig<NurseryFields> => {
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const { user } = useAuth()

  return useMemo(
    () => [
      {
        type: 'atom',
        element: {
          type: 'heading',
          content: 'KMR Maurikura review',
          level: 2,
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'supplyAssignedMaurikuraTeamMember',
          lookupId: 'maurikuraTeamMember',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          content: 'Planting summary',
          level: 2,
        },
      },
      {
        type: 'field',
        element: {
          type: 'table',
          currentUserPermissions: user?.permissions,
          columnHeaders: PLANTING_TABLE_COLUMNS,
          defaultSortBy: {
            orderColumn: 'locationName',
            orderDirection: 'desc',
          },
          requestUrl: `/api/plan/${planId}/project/${applicationId}/feature/planting`,
          rowClickAction: {
            label: 'View',
            type: 'transitionAction',
            args: [
              { constant: `plans/edit/${planId}/supply/project/${applicationId}/nursery` },
              { elementKey: 'id' },
              { constant: 'transfer' },
            ],
          },
          noResultsMessage: 'No planting actions added',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Nursery supply',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalNumberOfPlants',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalNumberOfPlantsZoneA',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalNumberOfPlantsZoneB',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalNumberOfPlantsZoneC',
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalPlantCost',
          helpText:
            data?.totalLabourCost == 0 && data?.totalPlantingCost
              ? 'The plant cost is $0 because the value was set before separate plant prices were calculated.'
              : '',
          conversion: toDollarCurrencyFormat,
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalLabourCost',
          helpText:
            data?.totalLabourCost == 0 && data?.totalPlantingCost
              ? 'The labour cost is $0 because the value was set before separate labour prices were calculated.'
              : '',
          conversion: toDollarCurrencyFormat,
        },
      },
      {
        type: 'field',
        element: {
          type: 'read-only',
          fieldId: 'totalPlantingCost',
          conversion: toDollarCurrencyFormat,
        },
      },
      {
        type: 'field',
        element: {
          type: 'lookup',
          fieldId: 'nurserySuppliers',
          repeating: true,
          lookupId: 'nurserySupplier',
          defaultValue: { label: '' },
          isDisabled: !data?.hasPlantingAction,
        },
      },
      {
        type: 'field',
        element: {
          type: 'date',
          fieldId: 'dateNurseryContacted',
          isDisabled: !data?.hasPlantingAction,
        },
      },
      {
        type: 'field',
        element: {
          type: 'text-area',
          fieldId: 'nurserySupplyComment',
          isDisabled: !data?.hasPlantingAction,
        },
      },
      {
        type: 'atom',
        element: {
          type: 'divider',
        },
      },
      {
        type: 'atom',
        element: {
          type: 'heading',
          level: 2,
          content: 'Photos and documents',
        },
      },
      {
        type: 'field',
        element: {
          type: 'files',
          fieldId: 'files',
        },
      },
      {
        type: 'field',
        element: {
          type: 'notes',
          fieldId: 'notes',
          form: NoteForm.SUPPLY,
        },
      },
    ],
    [user, planId, applicationId, data?.hasPlantingAction],
  )
}
