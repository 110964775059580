import classNames from 'classnames'
import React, { ReactNode } from 'react'

export interface JustifyContentProps {
  // Should match up with `$vertical-spacing--<size>` variables in client/src/styles/0-settings/_sizes.scss:
  verticalSpacing?: 'none' | 'default' | 'small' | 'x-small'
  contentClassNames?: string
  leftChildren?: ReactNode
  rightChildren?: ReactNode
}

export function JustifyContent({
  verticalSpacing = 'none',
  contentClassNames,
  leftChildren,
  rightChildren,
}: JustifyContentProps) {
  const classes = classNames('l-justify-content', {
    [`l-justify-content--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'none',
    [`${contentClassNames}`]: !!contentClassNames,
  })

  return (
    <div className={classes}>
      <div className='l-justify-content__left'>{leftChildren}</div>
      <div className='l-justify-content__right'>{rightChildren}</div>
    </div>
  )
}
