import { Outlet } from 'react-router-dom'
import { usePlanSummary } from '../hooks/usePlanSummary'
import NotFound from './not-found'
import { useRegisterMenuEoiActions } from '../hooks/use-register-eoi-actions'

export const EoiPage = () => {
  const { hasEoi, isLoading } = usePlanSummary()
  useRegisterMenuEoiActions()

  if (isLoading) return null

  if (!hasEoi) return <NotFound />

  return <Outlet />
}
