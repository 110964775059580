import { useEffect, useState } from 'react'
import { ColumnHeader, Table, TableAction, TableSortByProps, PaginationProps, usePassedState } from '@msaf/core-react'
import { useQuery } from 'react-query'
import { useRequest } from '../../hooks/useRequest'
import { NoResultsComponent } from './no-results-component'

export interface ConfirmDeleteModalProps {
  isOpen: boolean
  contentLabel: string
  heading: string
  body: string
  buttons: JSX.Element
  onRequestClose: VoidFunction
}

export type RowClickActionType = Omit<TableAction, 'handler' | 'actionPermission'>
export interface SummaryTableProps<T> {
  columnHeaders: Array<ColumnHeader>
  requestUrl: string
  defaultSortBy: TableSortByProps
  rowClickAction: RowClickActionType
  // Action handlers are generic and should allow function with variable args
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableActionHandlers?: { [key: string]: (...args: any[]) => void }
  showResultsCount?: boolean
  resultNameSingular?: string
  resultNamePlural?: string
  deleteRequestUrl?: string
  noResultsMessage?: string
  isSuccess?: boolean
  isLoading?: boolean
  data?: T
  currentUserPermissions?: Array<string>
  refreshTable?: boolean
}

export interface TableResults extends Pick<PaginationProps, 'currentPage' | 'totalPages' | 'totalResults'> {
  results: Record<string, string | number | boolean>[]
}

export function SummaryTable<T>({
  columnHeaders,
  requestUrl,
  defaultSortBy,
  rowClickAction,
  tableActionHandlers,
  showResultsCount,
  resultNameSingular,
  resultNamePlural,
  noResultsMessage,
  ...props
}: SummaryTableProps<T>) {
  const [ordering, setOrdering] = useState<string>(
    `${defaultSortBy.orderDirection === 'desc' ? '-' : ''}${defaultSortBy.orderColumn}`,
  )
  const [currentPage, setCurrentpage] = useState(1)
  const { state: tableRefreshToggle } = usePassedState(props.refreshTable)
  const { client } = useRequest()
  const { data, isLoading, refetch } = useQuery<TableResults>(
    [requestUrl, ordering, currentPage],
    () =>
      client
        .get(`${requestUrl}${requestUrl.endsWith('/') ? '' : '/'}?ordering=${ordering}&page=${currentPage}`)
        .then((response) => response.data),
    {
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    // Refetch table data on toggle
    refetch()
  }, [tableRefreshToggle, refetch])

  const setPage = (page: number) => {
    if (page >= 1 && data?.totalPages && page <= data.totalPages) {
      setCurrentpage(page)
    }
  }

  const setSortColumn = (orderColumn: string, orderDirection: 'desc' | 'asc') => {
    // Updates the `ordering` state. This, in turn, updates the `fetchUrl`.
    // Since, both the url and ordering values are passed as dependency to the query, react-query refetches the data.
    setOrdering(`${orderDirection === 'desc' ? '-' : ''}${orderColumn}`)
  }

  if (!isLoading && data?.results && !data.results.length) {
    return <NoResultsComponent message={!!noResultsMessage ? noResultsMessage : `No ${resultNamePlural} found.`} />
  }

  return (
    <Table
      isSkeleton={isLoading}
      columnHeaders={columnHeaders}
      tableData={data?.results ?? []}
      onRowClick={rowClickAction}
      defaultSortBy={defaultSortBy}
      customActions={tableActionHandlers}
      setSortColumn={setSortColumn}
      currentPage={data?.currentPage ?? 1}
      setPage={setPage}
      totalResults={data?.totalResults ?? 0}
      totalPages={data?.totalPages ?? 0}
      resultNameSingular={resultNameSingular}
      resultNamePlural={resultNamePlural}
      showResultsCount={showResultsCount}
      {...props}
    />
  )
}
