import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRequest } from './useRequest'
import { useDownloadFile } from '../utils/files'
import { RouteParams } from '../types/route'
import { toast } from 'react-toastify'
import { ToastMessage } from '@msaf/core-react'

const MAX_EXPORT_POLLING_ERRORS = 5
const MAX_EXPORT_POLLING_ATTEMPTS = 60
const TOAST_EXPORT_AUTO_CLOSE_DELAY = 5000

const ExportGenerationFailedToast = <ToastMessage messageType='error' title='Error' message='Failed to generate PDF.' />

export const useGenerateExport = () => {
  const { id: planId, applicationId } = useParams<RouteParams & { applicationId: string }>()
  const { client } = useRequest()
  const { downloadFile } = useDownloadFile()

  const pollForExport = useCallback(
    async (exportId: string, toastId: string | number, count = 0, errorCount = 0) => {
      if (count > MAX_EXPORT_POLLING_ATTEMPTS || errorCount > MAX_EXPORT_POLLING_ERRORS) {
        toast.update(toastId, {
          render: <ToastMessage messageType='error' title='Error' message='PDF generation timed out.' />,
          autoClose: TOAST_EXPORT_AUTO_CLOSE_DELAY,
          closeButton: true,
        })
        return
      }
      try {
        const { data } = await client.get(`/api/plan/${planId}/project/${applicationId}/export/${exportId}`)
        if (!!data.file) {
          // Update the existing toast message with the download link.
          toast.update(toastId, {
            render: (
              <ToastMessage messageType='success' title='Success' message='The PDF has successfully been downloaded.' />
            ),
            autoClose: TOAST_EXPORT_AUTO_CLOSE_DELAY,
            closeButton: true,
          })
          downloadFile(data.file)
          // Exit so we don't poll again.
          return
        }
        // Wait for another couple of seconds and poll again.
        setTimeout(pollForExport, 2000, exportId, toastId, count + 1, errorCount)
      } catch (error) {
        setTimeout(pollForExport, 2000, exportId, toastId, count + 1, errorCount + 1)
      }
    },
    [client, planId, applicationId],
  )

  const mutation = useMutation(() => client.post(`/api/plan/${planId}/project/${applicationId}/export/`), {
    onSuccess: ({ data }) => {
      const toastId = toast(
        <ToastMessage
          messageType='info'
          title='PDF Generation in progress'
          message='A download will begin once generation is complete.'
        />,
        { autoClose: false, closeButton: false },
      )

      setTimeout(pollForExport, 5000, data.id, toastId)
    },
    onError: () => {
      toast(ExportGenerationFailedToast)
    },
  })

  return mutation
}
