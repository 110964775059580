import { GetFeatureFormConfigProps } from '..'
import { FormConfig, LongTermMonitoringFormFields } from '../../types'
import { isFormModes } from '../../utils'

export const getLongTermMonitoringFormConfig = ({
  formMode,
}: Pick<
  GetFeatureFormConfigProps<LongTermMonitoringFormFields>,
  'formMode'
>): FormConfig<LongTermMonitoringFormFields> => {
  const { isNotVerificationMapMode } = isFormModes(formMode)

  return [
    {
      type: 'field',
      renderOnlyWhen: isNotVerificationMapMode,
      element: {
        fieldId: 'application.label',
        type: 'read-only',
      },
    },
    {
      type: 'field',
      element: {
        fieldId: 'locationName',
        type: 'read-only',
      },
    },
  ]
}
