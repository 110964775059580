import { DateField } from '@msaf/core-react'
import { ArrayPath, Controller, FieldValues, Path, useFieldArray, useFormContext } from 'react-hook-form'
import { RouteMode } from '../../../../types/route'
import { RepeatingDateFieldProps } from '../../../types'
import { Wrapper } from './wrapper'
import { parseDate } from '@msaf/generic-search-common'
import { DISPLAY_DATE_FORMAT, formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'

export function RepeatingDateField<T extends FieldValues>(props: RepeatingDateFieldProps<T>) {
  const { control } = useFormContext<T>()
  const { fieldId, mode, isDisabled, defaultValue } = props

  const fieldArray = useFieldArray({ name: fieldId as ArrayPath<T>, control })

  return (
    <Wrapper
      {...props}
      addField={() => fieldArray.append(defaultValue)}
      removeField={(index) => {
        fieldArray.remove(index)
      }}
    >
      {fieldArray.fields.map((field, index) => (
        <Controller
          key={field && 'id' in field ? field.id : null}
          control={control}
          name={`${fieldId}.${index}.value` as Path<T>}
          render={({ field }) => (
            <DateField
              isDisabled={mode === RouteMode.VIEW || isDisabled}
              isSkeleton={props.isSkeleton}
              dateFormat={DISPLAY_DATE_FORMAT}
              selectedDate={parseDate(field.value as string)}
              setSelectedDate={(date: Date) => {
                field.onChange(date ? formatDate(date, INTERNAL_DATE_FORMAT) : null)
              }}
              maxDate={parseDate(props.maxDate)}
              minDate={parseDate(props.minDate)}
            />
          )}
        />
      ))}
    </Wrapper>
  )
}
