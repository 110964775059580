import React from 'react'
import { AuthStrategy, FilterInstance, FilterSet, SearchTemplate } from '@msaf/generic-search-common'
import { FilterInput, FilterInputProps } from './filter-input'
import { NoContentMessage } from '@msaf/core-react'

export type InputsByFilterKey = Array<[string, Array<FilterSet | FilterInstance>]>

export interface FilterInputsProps<T extends AuthStrategy = 'token'>
  extends Omit<FilterInputProps<T>, 'filter' | 'query' | 'filterKey'> {
  filters: InputsByFilterKey
  noFiltersSelected?: boolean
  searchTemplate: SearchTemplate
  addValue: (filter: string) => void
  setValue: (filter: string, index: number, value: string | FilterSet) => void
  removeValue: (filter: string, index: number) => void
}

export function FilterInputs<T extends AuthStrategy = 'token'>({
  filters,
  noFiltersSelected,
  ...passthroughProps
}: FilterInputsProps<T>) {
  return (
    <>
      {noFiltersSelected ? (
        <NoContentMessage>Select from the above criteria to refine your search.</NoContentMessage>
      ) : (
        filters.map(([filter, query], index: number) => {
          return (
            <FilterInput<T>
              key={`as-filter-input-group--${index}`}
              filterKey={filter}
              query={query}
              {...passthroughProps}
            />
          )
        })
      )}
    </>
  )
}
