import { FormField } from '@msaf/core-react'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PersonCrudFieldProps } from '../../../forms/types'
import { RouteMode } from '../../../types/route'
import Lookup from '../base-lookup'
import { CrudLabels, CrudLookup, CrudLookupWrapper } from '../crud'
import { EditForm, ViewForm } from './form'
import { Person as PersonType } from './type'

interface PersonProps {
  onPersonSelected: (person?: PersonType) => void
  person?: PersonType
  label?: string
  isSkeleton?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  labels: CrudLabels
}

const PersonLookupWrapper: (label?: string) => CrudLookup<PersonType> =
  (label) =>
  ({ object, setObject }) => (
    <FormField label='Search contacts' labelId={label ?? 'contactLabel'}>
      <Lookup<PersonType>
        labelledBy={label ?? 'contactLabel'}
        object={object}
        setObject={setObject}
        requestUrl={(query) => `/api/person/?query=${query}`}
        displayFields={['firstName', 'lastName']}
        idField={'id'}
      />
    </FormField>
  )

export const Person = ({ onPersonSelected, person, ...props }: PersonProps) => {
  const personLookup = useMemo(() => PersonLookupWrapper(props.label), [])

  return (
    <CrudLookupWrapper<PersonType>
      editForm={EditForm}
      viewForm={ViewForm}
      initialObject={person}
      onObjectChange={onPersonSelected}
      lookup={personLookup}
      {...props}
      urlFunc={(id) => `/api/person/${id ?? ''}`}
    />
  )
}

export const PersonCrudField = <T,>(props: PersonCrudFieldProps<T>) => {
  const { fieldId, mode, isDisabled } = props
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={fieldId}
      render={({ field }) => (
        <Person
          isDisabled={isDisabled || mode == RouteMode.VIEW}
          person={field.value as PersonType}
          onPersonSelected={(person) => {
            field.onChange(person)
          }}
          {...props}
        />
      )}
    />
  )
}
