import { Heading, Table } from '@msaf/core-react'
import { FeatureAction } from '../../../../components/pdf/types'
import { getPlantingSummary } from '../utils'
import { toDecimalFormat } from '../../../utils'

export const PlantingSummary = ({ features }: { features: FeatureAction[] }) => {
  const tablePages = 1
  const data = getPlantingSummary(features)

  if (features.length == 0) return null

  return data.length > 1 ? (
    <section className='l-section--no-break-inside l-pdf-container--margin-small'>
      <Heading level={3} className='c-field__heading--margin-small'>
        Plants
      </Heading>
      <Table
        tableData={data}
        totalResults={data.length}
        totalPages={tablePages}
        showResultsCount={false}
        setPage={() => tablePages}
        currentPage={tablePages}
        setSortColumn={() => 'locationName'}
        defaultSortBy={{
          orderColumn: 'locationName',
          orderDirection: 'desc',
        }}
        columnHeaders={[
          {
            elementKey: 'locationName',
            columnHeading: 'Location name',
            viewColumn: 'locationName',
            sortable: false,
            type: 'text',
          },
          {
            elementKey: 'area',
            columnHeading: 'Area (m2)',
            viewColumn: 'area',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
          {
            elementKey: 'numberOfPlantsZoneA',
            columnHeading: ' Zone A',
            viewColumn: 'numberOfPlantsZoneA',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
          {
            elementKey: 'numberOfPlantsZoneB',
            columnHeading: ' Zone B',
            viewColumn: 'numberOfPlantsZoneB',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
          {
            elementKey: 'numberOfPlantsZoneC',
            columnHeading: ' Zone C',
            viewColumn: 'numberOfPlantsZoneC',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
          {
            elementKey: 'numberOfPlantsZoneD',
            columnHeading: ' Zone D',
            viewColumn: 'numberOfPlantsZoneD',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
          {
            elementKey: 'numberOfPlants',
            columnHeading: 'Total plants',
            viewColumn: 'numberOfPlants',
            sortable: false,
            type: 'text',
            fieldFormatter: toDecimalFormat,
          },
        ]}
      />
    </section>
  ) : (
    <></>
  )
}
