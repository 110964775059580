import { Outlet, useLocation, useParams } from 'react-router-dom'
import { usePlanPermissions } from '../../../../hooks/use-plan-permissions'
import { usePlanSummary } from '../../../../hooks/usePlanSummary'
import { useBackLink } from '../../../../hooks/useBackLink'
import { useEffect, useMemo } from 'react'
import { getPlanWorkflowTabs } from '../../../../config/workflow-nav'
import { ApplicationRouteParams, RouteMode } from '../../../../types/route'
import { usePageHeaderTabs } from '../../../../hooks/usePageHeaderTabs'
import { ProjectActions } from '../../../../components/project-actions'
import { PlanStateProvider } from '../../../../contexts/plan-context'

export const PlanWrapper = () => {
  const { hasEditPermission, isLoading } = usePlanPermissions()
  const { isMigratedPlan, hasLongTermMonitoring } = usePlanSummary()

  const location = useLocation()
  const { id, mode, applicationId } = useParams<ApplicationRouteParams>()
  const { statusName, hasEoi, refetch: updateHeading } = usePlanSummary()

  useBackLink({ label: 'Dashboard', path: '/' })

  useEffect(() => {
    updateHeading()
  }, [location.pathname])

  const tabs = useMemo(() => {
    if (statusName == null || id == null) {
      return []
    }
    return getPlanWorkflowTabs({
      id,
      statusName,
      currentPath: location.pathname,
      mode: mode ?? RouteMode.VIEW,
      hasEoi: hasEoi,
      hasLongTermMonitoring: hasLongTermMonitoring,
    })
  }, [location.pathname, statusName, applicationId, hasEoi, hasLongTermMonitoring])

  usePageHeaderTabs(tabs)

  if (isLoading) return null

  return (
    <PlanStateProvider
      planState={{
        isMigratedPlan,
        hasEditPermission: hasEditPermission,
      }}
    >
      {applicationId && <ProjectActions />}
      <Outlet />
    </PlanStateProvider>
  )
}
