import { AxiosError, AxiosResponse } from 'axios'
import { FieldValues } from 'react-hook-form'
import { MutateOptions } from 'react-query'
import { useParams } from 'react-router-dom'
import { ApplicationRouteParams } from '../types/route'
import { WorkflowLocators } from '../validation/constants'
import { useFormState } from './use-form-state'
import { useMutationWithToast } from './use-mutation-with-toast'

export type FormUrlFunc = (idSegments: string[]) => string
export type Messages = {
  success?: string
  error?: string
}

export function useStateManagement<T extends FieldValues>(
  urlFunc: FormUrlFunc,
  locators: WorkflowLocators,
  messages?: Messages,
  enableQuery = true,
) {
  const { id, applicationId } = useParams<ApplicationRouteParams>()
  const validSegments = [id, applicationId].filter((id) => id != null) as string[]

  const url = urlFunc(validSegments)
  const { data, workflowData, isLoading, errors, refetchErrors, refetchData, isEditable } = useFormState<T>(
    urlFunc,
    locators,
    enableQuery,
  )
  const saveAction = useMutationWithToast<AxiosResponse<T>, AxiosError<Map<keyof T, Array<string>>>, T, void>({
    method: 'PUT',
    url,
    success: {
      toastMessage: messages?.success ?? 'Record Saved',
      action: () => {
        // Refetch saved data to update the form data
        enableQuery && refetchData?.()
        // Refetch on success for any new validation errors
        enableQuery && refetchErrors?.()
      },
    },
    error: {
      toastMessage: messages?.error ?? 'Could not save record',
    },
  })

  return {
    onSubmit: async (data: T, options?: MutateOptions<AxiosResponse<T>, AxiosError<Map<keyof T, string[]>>, T>) => {
      return saveAction.mutateAsync(data, options)
    },
    data,
    workflowData,
    isLoading,
    isSaving: saveAction.isLoading,
    errors,
    refresh: () => {
      refetchData?.()
      refetchErrors?.()
    },
    isEditable,
  }
}
