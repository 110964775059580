import { FEATURE_CATEGORIES, MAP_CONFIG } from '../../../config/map'
import { useRegisterFeatureFilterGroup } from './use-register-feature-filter-group'
import { safeGetConfigForFeature } from '../utils'

export const useRegisterFeatureFilterGroups = () => {
  useRegisterFeatureFilterGroup({
    key: 'project',
    label: 'Project',
    featureToFilterObjects: (features) =>
      features
        // Some features aren't attached to applications
        .filter((feature) => feature.properties?.application)
        // Get all the projects from the features
        .map((feature) => ({
          id: feature.properties?.application.value,
          name: feature.properties?.application.label,
        })),
    filterFunctionBuilder: (feature, filterObjects) =>
      feature.properties?.application === undefined ||
      filterObjects
        .filter((filterObject) => filterObject.isSelected)
        .map((filterObject) => filterObject.id)
        .includes(feature.properties?.application.value),
  })

  useRegisterFeatureFilterGroup({
    key: 'type',
    label: 'Action type',
    filterObjects: Object.keys(FEATURE_CATEGORIES).map((key) => ({
      id: key,
      name: FEATURE_CATEGORIES[key],
    })),
    filterFunctionBuilder: (feature, filterObjects) => {
      const featureConfig = safeGetConfigForFeature(MAP_CONFIG, feature)
      return (
        // If there is no config its a migrated feature so ignore it.
        !featureConfig ||
        filterObjects
          .filter((filterObject) => filterObject.isSelected)
          .map((filterObject) => filterObject.id)
          .includes(featureConfig.categoryKey)
      )
    },
  })

  useRegisterFeatureFilterGroup({
    key: 'completionStatus',
    label: 'Completion status',
    featureToFilterObjects: (features) => [
      ...features
        // Some features aren't attached to applications
        .filter((feature) => feature.properties?.completionStatus)
        .map((feature) => ({
          id: feature.properties?.completionStatus.value,
          name: feature.properties?.completionStatus.label,
        })),
      { id: '<<null>>', name: 'Not assessed' },
    ],
    filterFunctionBuilder: (feature, filterObjects) =>
      /*
        This filter should features without applications / projects as they never assessed.
        The features are attached to plans only.
      */
      feature.properties?.application == undefined ||
      filterObjects
        .filter((filterObject) => filterObject.isSelected)
        .map((filterObject) => filterObject.id)
        .includes(feature.properties?.completionStatus?.value ?? '<<null>>'),
  })
}
