import { Button, Modal, InlineNotification } from '@msaf/core-react'
import { FeatureFormWrapper, MapContextPanel } from '@msaf/maps-react'
import { useContext, useMemo, useState } from 'react'
import { FeatureMapContext } from '../context'
import { useContextPanelHeading } from './use-heading'

interface ContextPanelProps {
  isContextPanelOpen: boolean
  setIsContextPanelOpen: (isOpen: boolean) => void
}

export const ContextPanel = ({ isContextPanelOpen, setIsContextPanelOpen }: ContextPanelProps) => {
  const { selectedFeature, removeFeature, upsertFeature, featureToggle, isLoading } = useContext(FeatureMapContext)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const heading = useContextPanelHeading()

  const actionButtonNotification =
    featureToggle?.delete &&
    (!selectedFeature?.feature.properties?.readOnly ? (
      <Button
        label='Remove feature'
        buttonStyle='danger'
        icon='trash'
        onClick={() => setDeleteModalOpen(true)}
        isSkeleton={isLoading}
      />
    ) : (
      <InlineNotification messageType='info' isDismissable={false}>
        This action cannot be modified or deleted as it is connected to an project that is already approved.
      </InlineNotification>
    ))

  const panelContent = useMemo(() => {
    if (!selectedFeature) return null
    return (
      <>
        <FeatureFormWrapper
          state={selectedFeature.feature}
          context={{ ...selectedFeature.config, isLoading }}
          updateState={upsertFeature}
          renderContent={selectedFeature.config.renderContent ?? (() => <></>)}
        />
        {actionButtonNotification}
      </>
    )
  }, [selectedFeature, actionButtonNotification])

  return (
    <>
      {selectedFeature && (
        <Modal
          isOpen={deleteModalOpen}
          onRequestClose={() => setDeleteModalOpen(false)}
          heading='Delete feature?'
          body='Are you sure you wish to delete this feature and any associated data?'
          contentLabel='Confirm feature deletion'
          buttons={
            <>
              <Button
                buttonStyle='primary'
                type='button'
                label='Delete'
                onClick={() => {
                  removeFeature?.(selectedFeature.feature.id)
                  setDeleteModalOpen(false)
                }}
              />
              <Button
                buttonStyle='text-action'
                type='button'
                label='Cancel'
                onClick={() => setDeleteModalOpen(false)}
              />
            </>
          }
        />
      )}
      <MapContextPanel
        isPanelOpen={isContextPanelOpen}
        setIsPanelOpen={setIsContextPanelOpen}
        contextPanelConfig={{
          ...heading,
          panelContent,
        }}
      />
    </>
  )
}
