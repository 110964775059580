import { Outlet, useParams } from 'react-router-dom'
import { ApplicationStateProvider } from '../../../../contexts/application-context'
import { useFormData } from '../../../../hooks/use-form-state'
import { ApplicationRouteParams } from '../../../../types/route'
import { ApplicationStatusFields } from './application/[applicationId]/submission'
import { useAddGenerateExportMenu } from '../../../../hooks/use-add-generate-export-menu'

export const ApplicationWrapper = () => {
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${planId}/project/${applicationId}/status`)

  useAddGenerateExportMenu(applicationState.data)

  return (
    <ApplicationStateProvider applicationState={{ ...applicationState.data }}>
      <Outlet />
    </ApplicationStateProvider>
  )
}
