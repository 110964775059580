import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { Form } from '../../../../../../forms/components/form'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import { ApplicantFields, applicantFormConfig } from './applicant.form'

export function Applicant() {
  const { id, mode } = useParams<RouteParams>()
  const { hasEditPermission, isMigratedPlan } = usePlanState()

  const { data, isLoading, errors, refetchErrors } = useFormState<ApplicantFields>(
    ([id]) => `/api/plan/${id}/applicant`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'applicant',
      instanceId: `${id}`,
    },
  )

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          addresses: data.addresses.length ? data.addresses : [{ address: '' }],
          propertyIdentifiers: data.propertyIdentifiers?.length ? data.propertyIdentifiers : [{ value: '' }],
        }
      : undefined
  }, [data])

  const mutation = useMutationWithToast<
    AxiosResponse<ApplicantFields>,
    AxiosError<Map<keyof ApplicantFields, Array<string>>>,
    ApplicantFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/applicant`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: { toastMessage: 'Could not save plan.' },
  })

  const onSubmit = async (data: ApplicantFields) => {
    await mutation.mutateAsync(data)
  }

  const config = useMemo(() => applicantFormConfig({ data, isMigratedPlan }), [data, isMigratedPlan])

  return (
    <Form<ApplicantFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      canEdit={hasEditPermission}
      nextPath={`/plans/${mode}/${id}/plan/catchment-context`}
      config={config}
      submitAction={onSubmit}
    />
  )
}
