import { createToastMessage, FormField, TextInput, ToastMessage } from '@msaf/core-react'
import { FormEvent, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { validationErrorForField } from '../../../forms/utils'
import LookupSearch from '../../lookup-typeahead'
import { ReadOnlyField } from '../../read-only-field'
import { OrganisationFields } from './type'
import { useTranslateErrors } from '../../../hooks/use-translate-errors'

interface EditFormProps {
  submitAction: (data: OrganisationFields) => void
  fields: Partial<OrganisationFields>
  errors?: Record<keyof OrganisationFields, Array<string>>
  isMigrated?: boolean
}

interface FieldConfig<T> {
  fieldId: keyof T
  label: string
  type: 'text' | 'type'
  isRequired: boolean
  isMigrated?: boolean
}

const FORM_CONFIG: FieldConfig<OrganisationFields>[] = [
  { label: 'Type', fieldId: 'type', type: 'type', isRequired: true },
  { label: 'Organisation name', fieldId: 'tradingName', type: 'text', isRequired: true },
  { label: 'Business or trust ID', fieldId: 'nzbn', type: 'text', isRequired: false },
  { label: 'GST number', fieldId: 'gstNumber', type: 'text', isRequired: false },
]

export const EditForm = ({ submitAction, children, fields, errors, isMigrated }: PropsWithChildren<EditFormProps>) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, submitCount },
    setError,
    clearErrors,
    control,
  } = useForm<OrganisationFields>({ defaultValues: { ...fields }, reValidateMode: 'onBlur', mode: 'all' })
  const fieldConfig: FieldConfig<OrganisationFields>[] = useMemo(
    // Only check the migrated state if the field is marked as required
    () => FORM_CONFIG.map((config) => ({ ...config, isRequired: config.isRequired && !isMigrated })),
    [isMigrated],
  )

  useTranslateErrors<OrganisationFields, string>({
    errors,
    setError,
    clearErrors,
    submitCount,
  })

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      handleSubmit(
        (data) => {
          submitAction({ ...data })
        },
        () => {
          createToastMessage(
            <ToastMessage
              messageType='error'
              message='Please fill in all required fields before submitting'
              title='Unable to submit'
            />,
          )
        },
      )(e)
      e.stopPropagation()
    },
    [handleSubmit, submitAction],
  )

  return (
    <form onSubmit={onSubmit} data-testid='organisation-edit-form'>
      {fieldConfig.map(({ label, fieldId, type, isRequired }) => {
        const validationErrors = validationErrorForField(formErrors, fieldId)
        if (type === 'type') {
          return (
            <FormField
              key={fieldId}
              label={label}
              htmlFor={`${fieldId}Label`}
              isRequired={isRequired}
              validationMessages={validationErrors}
            >
              <Controller
                control={control}
                name={fieldId}
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <LookupSearch
                    labelledBy={`${fieldId}Label`}
                    lookupId={`organisation_type`}
                    id={fieldId}
                    value={
                      // Field could be a string or a object with a label / value.
                      field.value === undefined || typeof field.value == 'string'
                        ? { value: field.value, label: field.value }
                        : field.value
                    }
                    menuPortalTarget={null}
                    setValue={field.onChange}
                  />
                )}
              />
            </FormField>
          )
        }
        return (
          <FormField
            key={fieldId}
            label={label}
            htmlFor={fieldId}
            isRequired={isRequired}
            validationMessages={validationErrors}
          >
            <TextInput
              id={fieldId}
              type={type}
              isInvalid={!!formErrors[fieldId as keyof OrganisationFields]}
              {...register(fieldId, { required: isRequired })}
            />
          </FormField>
        )
      })}
      {children}
    </form>
  )
}

export const ViewForm = ({ fields }: { fields: OrganisationFields }) => {
  const { control, reset } = useForm<OrganisationFields>({ defaultValues: { ...fields } })
  const fieldConfig: FieldConfig<OrganisationFields>[] = useMemo(() => FORM_CONFIG, [])

  useEffect(() => {
    // Form is only for viewing so always reset it to new values.
    reset(fields)
  }, [fields])

  return (
    <>
      {fieldConfig.map(({ label, fieldId }) => (
        <FormField key={fieldId} label={label} labelId={`${fieldId}Label`}>
          <ReadOnlyField<OrganisationFields> control={control} fieldName={fieldId} labelledBy={`${fieldId}Label`} />
        </FormField>
      ))}
    </>
  )
}
