import React from 'react'
import { LegendSection, LegendSectionProps } from './legend-section'

export interface LegendOptionsProps {
  menuSections: Array<LegendSectionProps>
}

export function LegendOptions({ menuSections }: LegendOptionsProps) {
  return (
    <div className='c-legend-menu__options'>
      {menuSections.map((menuSection) => {
        return <LegendSection key={menuSection.heading} {...menuSection} />
      })}
    </div>
  )
}
