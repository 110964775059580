import {
  Button,
  createToastMessage,
  RecordContainer,
  ToastMessage,
  useTitleChange,
  WorkflowNav,
} from '@msaf/core-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBackLink } from '../../../../../hooks/useBackLink'
import { UserInputs } from '../../../../../forms/user/type.config'
import { RouteMode, RouteParams } from '../../../../../types/route'
import { ViewUserForm } from '../../../../../forms/user/view'
import { UserForm } from '../../../../../forms/user'
import { FormEvent, useMemo } from 'react'
import { useEditForm } from '../../../../../hooks/useEditForm'
import { DefaultValues } from 'react-hook-form'

export const User = () => {
  const { id, mode } = useParams<RouteParams>()
  const modeText = useMemo(() => (mode === RouteMode.EDIT ? 'Edit' : 'View'), [mode])

  useTitleChange(`Administration | ${modeText} user`)
  const navigate = useNavigate()
  useBackLink({ label: 'Back', path: '/admin/users' })
  const { register, mutate, handleSubmit, reset, isSaving, isFetchingData, formState, control } =
    useEditForm<UserInputs>({
      url: id ? `/api/user/${id}` : `/api/user/`,
      defaults: { isActive: true },
      onSuccess: ({ data }) => {
        // Reset form fields with values received from the server
        reset(data as DefaultValues<UserInputs>)
        createToastMessage(<ToastMessage messageType='success' title='Success' message={'User record updated'} />)
      },
      onError: () => {
        createToastMessage(<ToastMessage messageType='error' title='Error' message={'Could not update the user'} />)
      },
    })

  return (
    <RecordContainer
      workflowNav={<WorkflowNav sections={[{ links: [{ label: 'Details', path: location.pathname }] }]} />}
    >
      {mode === 'view' ? (
        <ViewUserForm userId={id} />
      ) : (
        <UserForm
          isLoading={isSaving || isFetchingData}
          register={register}
          control={control}
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            handleSubmit((data) => mutate(data))(e)
          }}
          formState={formState}
          footerActions={
            <>
              <Button
                type='submit'
                buttonStyle='primary'
                label='Update user'
                isDisabled={isSaving}
                isLoading={isSaving}
              />
              <Button
                type='button'
                buttonStyle='text-action'
                label='Cancel'
                isDisabled={isSaving}
                onClick={() => navigate(`/admin/users/view/${id}/user`)}
              />
            </>
          }
        />
      )}
    </RecordContainer>
  )
}
