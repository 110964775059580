import { Button, createToastMessage, ToastMessage } from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from '../../../../../../../hooks/use-error-handler'
import { useFormData } from '../../../../../../../hooks/use-form-state'
import { useRequest } from '../../../../../../../hooks/useRequest'
import { ApplicationRouteParams, RouteMode } from '../../../../../../../types/route'
import { WorkflowState, WorkflowModelSectionState } from '../../../../../../../validation/constants'
import { SAVE_AND_CONTINUE_ID } from '../../../../../../../forms/components/atom/footer'
import { Form } from '../../../../../../../forms/components/form'
import { ApplicationDetailsFields } from '../details/config'
import { useApplicationSubmissionConfig } from './config'
import { useWorkflowState } from '../../../../../../../validation/hooks'
import { useBuildWorkflowErrors } from '../../../../../../../hooks/use-build-workflow-errors'
import { usePlanApplicationState } from '../../../../../../../contexts/plan-application-context'

export type ApplicationStatusFields = WorkflowModelSectionState<ApplicationDetailsFields> & {
  isSubmitted: boolean
  canBeSubmitted: boolean
  availableActions: ('reopen' | 'reclose' | 'withdraw' | 'resume' | 'enable_long_term_monitoring')[]
  isMigrated: boolean
  createdDate: string
}

function useApplicationState() {
  const { mode, id: planId, applicationId } = useParams<ApplicationRouteParams>()
  if (!planId || !applicationId) throw new Error('`useApplicationState` can only be used within an application route')

  const planState = useFormData<WorkflowState<unknown>>(`/api/plan/${planId}/workflow/plan/status`)

  const applicationWorkflow = useWorkflowState(planId, 'application', mode)
  const applicationState = useFormData<ApplicationStatusFields>(`/api/plan/${planId}/project/${applicationId}/status`)
  const applicationDetails = useFormData<ApplicationDetailsFields>(
    `/api/plan/${planId}/project/${applicationId}/details`,
  )

  useErrorHandler(applicationWorkflow.isError, applicationWorkflow.error as AxiosError<unknown>)
  useErrorHandler(applicationDetails.isError, applicationDetails.fetchError)
  useErrorHandler(planState.isError, planState.fetchError)
  useErrorHandler(applicationState.isError, applicationState.fetchError)

  const planErrors = useBuildWorkflowErrors(planState?.data, 'plan', 'planCreation')
  const applicationErrors = useBuildWorkflowErrors(applicationWorkflow.data, 'application', 'details')

  return {
    data: applicationDetails.data,
    loading:
      planState.isLoading ||
      applicationState.isLoading ||
      applicationDetails.isLoading ||
      applicationWorkflow.isLoading,
    errors: [...planErrors, ...applicationErrors],
    isSubmitted: applicationState.data?.isSubmitted,
    canBeSubmitted: applicationState.data?.canBeSubmitted,
    refetchData: applicationState.refetchData,
  }
}

export function ApplicationSubmission() {
  const { hasEditPermission } = usePlanApplicationState()
  const { loading, errors, data, canBeSubmitted, isSubmitted, refetchData } = useApplicationState()
  const config = useApplicationSubmissionConfig({ errors, canBeSubmitted, isSubmitted })
  const { id: planId, applicationId } = useParams<ApplicationRouteParams>()
  const { client } = useRequest()

  const submitMutation = useMutation<AxiosResponse<unknown>, AxiosError<{ message: string | undefined }>>(
    () => client.post(`/api/plan/${planId}/project/${applicationId}/submit`, {}),
    {
      onSuccess: () => {
        createToastMessage(
          <ToastMessage messageType='success' title='Success' message='Project successfully submitted.' />,
        )
        refetchData()
      },
      onError: (e) => {
        let message = 'Could not submit project.'
        if (e.response?.data?.message) {
          message = `Could not submit project: ${e.response?.data?.message}`
        }
        createToastMessage(<ToastMessage messageType='error' title='Error' message={message} />)
        refetchData()
      },
    },
  )

  const actionsDisabled =
    errors.length > 0 || loading || submitMutation.isLoading || !canBeSubmitted || !hasEditPermission

  return (
    <Form<ApplicationDetailsFields>
      isSkeleton={loading}
      initialState={data}
      mode={RouteMode.VIEW}
      canEdit={hasEditPermission}
      config={config}
      footerActions={
        <Button id={SAVE_AND_CONTINUE_ID} type='submit' label='Submit for approval' isDisabled={actionsDisabled} />
      }
      submitAction={async () => submitMutation.mutateAsync()}
    />
  )
}
