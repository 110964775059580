import { Button } from '@msaf/core-react'
import { useMemo } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { ButtonProps } from '../../types'
import { useFieldWatch } from '../hooks/use-field-watch'

export function ControlledButton<T extends FieldValues>(props: ButtonProps<T>) {
  const methods = useFormContext<T>()
  const { watch, ...buttonProps } = props
  const initialConfig = useMemo(() => ({ ...buttonProps }), [props.isDisabled])
  const { fieldId, ...rest } = useFieldWatch(methods, initialConfig, watch) as ButtonProps<T>

  return <Button id={fieldId} {...rest} {...(fieldId && { ...methods.register(fieldId) })} />
}
