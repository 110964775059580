import { useQuery, UseQueryResult } from 'react-query'
import { useRequest } from '../hooks/useRequest'
import { RouteMode } from '../types/route'
import { Workflow, WorkflowState } from './constants'

export const useWorkflowState = <TErrors>(
  recordId: string | undefined,
  workflowId: Workflow,
  mode?: RouteMode,
): UseQueryResult<WorkflowState<TErrors>, unknown> => {
  const { client } = useRequest()
  const statusQuery = useQuery<WorkflowState<TErrors>>(
    ['workflowState', recordId, workflowId, mode],
    () => client.get(`/api/plan/${recordId}/workflow/${workflowId}/status`).then((response) => response.data),
    { cacheTime: 300, enabled: recordId != null }, // Cache so can also be called on the contained form, with no additional request
  )

  return statusQuery
}
