import { AxiosError, AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DEFAULT_CENTER } from '../../../../../../config/map'
import { usePlanState } from '../../../../../../contexts/plan-context'
import { Form } from '../../../../../../forms/components/form'
import { useFormState } from '../../../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../../../hooks/use-mutation-with-toast'
import { usePlanSummary } from '../../../../../../hooks/usePlanSummary'
import { RouteMode, RouteParams } from '../../../../../../types/route'
import {
  PropertySystemsAspirationsFields,
  usePropertySystemsAspirationsConfig,
} from './property-systems-aspirations.form'

export function PropertySystemsAspirations() {
  const { id, mode } = useParams<RouteParams>()
  const { hasEditPermission, isMigratedPlan } = usePlanState()
  const { data, isLoading, errors, refetchErrors, refetchData } = useFormState<PropertySystemsAspirationsFields>(
    ([id]) => `/api/plan/${id}/property-systems-aspirations`,
    {
      workflowId: 'plan',
      sectionId: 'planCreation',
      formId: 'propertySystemsAspirations',
      instanceId: `${id}`,
    },
  )

  const { location } = usePlanSummary()

  const mutation = useMutationWithToast<
    AxiosResponse<PropertySystemsAspirationsFields>,
    AxiosError<Map<keyof PropertySystemsAspirationsFields, Array<string>>>,
    PropertySystemsAspirationsFields,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/property-systems-aspirations`,
    success: {
      toastMessage: 'Plan saved.',
      action: () => {
        // Refetch saved data to update the form data
        refetchData?.()
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
    },
    error: {
      toastMessage: 'Could not save plan.',
    },
  })

  const onSubmit = async (data: PropertySystemsAspirationsFields) => {
    await mutation.mutateAsync(data)
  }

  const initialState = useMemo(() => {
    return data
      ? {
          ...data,
          sectors: data.sectors.length ? data.sectors : [{}],
          landCovers: data.landCovers?.length ? data.landCovers : [{ landCoverType: null, estPercentageCover: null }],
        }
      : undefined
  }, [data])

  const config = usePropertySystemsAspirationsConfig(id, data, mode, location ?? DEFAULT_CENTER, isMigratedPlan)

  return (
    <Form<PropertySystemsAspirationsFields>
      initialState={initialState}
      isSkeleton={isLoading}
      errors={errors}
      mode={mode ?? RouteMode.VIEW}
      nextPath={`/plans/${mode}/${id}/plan/system-aspirations`}
      canEdit={hasEditPermission}
      config={config}
      submitAction={onSubmit}
    />
  )
}
