import { Button, ButtonsContainer } from '@msaf/core-react'
import { AdvancedSearch } from '@msaf/generic-search-react'
import { useNavigate } from '@msaf/router-react'
import { AxiosError } from 'axios'
import { EmptyObject } from 'react-hook-form'
import { useAuth } from '../auth'
import { ContentContainer } from '../components/content-container'
import { SEARCH_API_URL } from '../environment'
import { CreateMigratedRecordResponse } from '../forms/types'
import { usePostAction } from '../hooks/use-post-action'
import { usePageHeading } from '../hooks/usePageHeading'
import { usePermission } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { useCallback } from 'react'

export const Dashboard = () => {
  usePageHeading('Dashboard')
  const canCreateEoi = usePermission(UserPermissions.CREATE_EOI)
  const canCreateMigratedPlan = usePermission(UserPermissions.CREATE_MIGRATED_PLAN)
  const canCreateFonterraPlan = usePermission(UserPermissions.CREATE_FONTERRA_PLAN)
  const { authStrategy } = useAuth()
  const navigate = useNavigate()
  if (!authStrategy) return null

  const createLegacyPlan = usePostAction<
    EmptyObject,
    CreateMigratedRecordResponse,
    AxiosError<{ message: string | undefined }>
  >({
    url: `/api/plan/?is_migrated=true`,
    displayServerError: true,
    messages: {
      success: 'Migrated record created.',
      error: 'Could not create migrated record.',
    },
  })
  const createFonterraPlan = usePostAction<
    EmptyObject,
    CreateMigratedRecordResponse,
    AxiosError<{ message: string | undefined }>
  >({
    url: `/api/plan/?is_fonterra=true`,
    displayServerError: true,
    messages: {
      success: 'Fonterra record created.',
      error: 'Could not create Fonterra record.',
    },
  })

  const dashboardTransition = useCallback(
    (planId: number, applicationId: number, form: string) => {
      switch (form) {
        case 'eoi-decision':
          navigate(`/plans/edit/${planId}/eoi/decision`)
          break
        case 'plan-overview':
          navigate(`/plans/edit/${planId}/plan/overview`)
          break
        case 'review-review':
          navigate(`/plans/edit/${planId}/review/project/${applicationId}/review`)
          break
        case 'review-approval':
          navigate(`/plans/edit/${planId}/review/project/${applicationId}/approval`)
          break
        case 'supply-nursery':
          navigate(`/plans/edit/${planId}/supply/project/${applicationId}/nursery`)
          break
        case 'supply-contract':
          navigate(`/plans/edit/${planId}/supply/project/${applicationId}/contract`)
          break
        case 'progress-pre-checks':
          navigate(`/plans/edit/${planId}/progress/project/${applicationId}/pre-checks`)
          break
        case 'plan-pre-checks':
        default:
          navigate(`/plans/edit/${planId}/plan/pre-checks`)
          break
      }
    },
    [navigate],
  )

  return (
    <ContentContainer>
      {canCreateEoi && (
        <ButtonsContainer containerStyle='full-width'>
          <Button size='large' icon='new' iconAlignment='left' label='Register landowner interest' path='/plans/new' />
          {canCreateMigratedPlan && (
            <Button
              size='large'
              icon='new'
              iconAlignment='left'
              label='Enter migrated record'
              onClick={() =>
                createLegacyPlan.mutate(
                  {},
                  {
                    onSuccess: ({ data }) => {
                      navigate(`/plans/edit/${data.planId}/plan/pre-checks`)
                    },
                  },
                )
              }
            />
          )}
          {canCreateFonterraPlan && (
            <Button
              size='large'
              icon='new'
              iconAlignment='left'
              label='Enter Fonterra record'
              onClick={() =>
                createFonterraPlan.mutate(
                  {},
                  {
                    onSuccess: ({ data }) => {
                      navigate(`/plans/edit/${data.planId}/plan/pre-checks`)
                    },
                  },
                )
              }
            />
          )}
        </ButtonsContainer>
      )}
      <AdvancedSearch<'token'>
        searchTypes={[
          {
            key: 'dashboard',
            label: 'Assigned',
          },
        ]}
        heading='Assigned records'
        viewKey='assigned'
        requestOptions={{
          urlPrefix: `${SEARCH_API_URL}`,
          authStrategy: 'token',
          authToken: authStrategy.token,
          authTokenScheme: authStrategy.tokenIdentifier,
        }}
        defaultSortBy={{
          orderColumn: 'identifier',
          orderDirection: 'desc',
        }}
        resultNameSingular='record'
        resultNamePlural='records'
        noResultsMessage='No records available.'
        customActions={{
          dashboardTransition,
        }}
      />
    </ContentContainer>
  )
}
