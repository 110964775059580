import { Divider, MAP_NAVIGATION_BREAKPOINT, useMediaQuery } from '@msaf/core-react'
import { useContext, useEffect, useState } from 'react'
import { FeatureMapContext } from '../context'
import { AddFeature } from './components/add-feature'
import { SnapAction } from './components/snap-action'
import { FeatureFilterGroupPills } from './components/feature-filter-group'

export interface AddFeaturePanelProps {
  setIsMenuPanelOpen: (isOpen: boolean) => void
  isSkeleton?: boolean
}

export function AddFeaturePanel({ setIsMenuPanelOpen }: AddFeaturePanelProps) {
  const {
    drawFeature,
    drawOptions,
    setSnappable,
    selectedFeature,
    clearFeatureSelection,
    cancelDrawing,
    filterGroups,
    isLoading,
  } = useContext(FeatureMapContext)

  // Used to determine whether menu panel should close on feature button click or not
  const isDesktop = useMediaQuery(`(min-width: ${MAP_NAVIGATION_BREAKPOINT})`)

  const [selectedFeatureType, setSelectedFeatureType] = useState<string | null>(null)

  const selectFeature = (key: string) => {
    if (key === selectedFeatureType) {
      setSelectedFeatureType(null)
      cancelDrawing?.()
    } else {
      setSelectedFeatureType(key)
      clearFeatureSelection?.()
      cancelDrawing?.()

      // Defer 1 ms to allow map time to update
      setTimeout(() => {
        drawFeature?.(key)
      }, 1)

      if (!isDesktop) {
        setIsMenuPanelOpen(false)
      }
    }
  }

  useEffect(() => {
    if (selectedFeature) {
      setSelectedFeatureType(null)
    }
  }, [selectedFeature])

  const typeFilterGroup = filterGroups?.find((f) => f.key == 'type')

  return (
    <>
      {typeFilterGroup && <FeatureFilterGroupPills {...typeFilterGroup} isSkeleton={isLoading} />}
      {!typeFilterGroup?.filterObjects.every((f) => f.isSelected === false) && (
        <>
          <Divider verticalSpacingSize='small' />
          <AddFeature selectFeature={selectFeature} selectedFeatureType={selectedFeatureType} isDisabled={isLoading} />

          {!!selectedFeatureType && (
            <>
              <Divider verticalSpacingSize='small' />
              <SnapAction
                setSnappable={() => setSnappable?.(!drawOptions?.allowSnapping)}
                snappable={!!drawOptions?.allowSnapping}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
