import { PropsWithChildren } from 'react'
import { BaseApplicationWorkflow } from '.'
import { UserPermissions } from '../../types/permissions'
import { useViewRedirectGatekeeper } from '../gatekeeper'

interface WorkflowProps {
  recordId: string
}

export const SupplyWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  useViewRedirectGatekeeper(UserPermissions.EDIT_SUPPLY, UserPermissions.VIEW_SUPPLY)

  return (
    <BaseApplicationWorkflow recordId={recordId} workflow={'supply'} noResultsText={'Not contracted'}>
      {children}
    </BaseApplicationWorkflow>
  )
}
