import rg4js, { RaygunOptions, RaygunV2UserDetails } from 'raygun4js'

export enum ErrorType {
  // API returned non-ok response
  API_ERROR = 'API error - response returned not within 2xx range',
  RENDER_ERROR = 'Render error - the app fell to the error boundary as a result of an unexpected error',
  AUTH_ERROR = 'Auth error - the app failed to authenticate successfully with the server',
}

export const RAYGUN_TAG = 'KMR Client'

export const SENSITIVE_DATA_FILTERS = ['token']

export default class RaygunClient {
  private static instance: RaygunClient

  public static getInstance(): RaygunClient {
    if (!RaygunClient.instance) {
      RaygunClient.instance = new RaygunClient()
    }
    return RaygunClient.instance
  }

  init() {
    if (process.env.REACT_APP_RAYGUN_KEY) {
      rg4js('enableCrashReporting', true)
      rg4js('apiKey', process.env.REACT_APP_RAYGUN_KEY)
      rg4js('withTags', [RAYGUN_TAG])

      rg4js('options', {
        allowInsecureSubmissions: false, // Submissions over HTTPS only
        ignoreAjaxAbort: true, // User-aborted Ajax calls will not be sent as errors
        ignoreAjaxError: false, // Send Ajax errors to raygun
        debugMode: false, // Don't log to console when sending errors
      } as RaygunOptions)

      // Basic Options for filter and set scope for all key-value pairs
      rg4js('setFilterScope', 'all')
      rg4js('filterSensitiveData', SENSITIVE_DATA_FILTERS)
      rg4js('disableAutoBreadcrumbsClicks')
    }
  }

  startUserTracking(userData: Exclude<RaygunV2UserDetails, 'uuid'>) {
    rg4js('setUser', {
      ...userData,
      isAnonymous: false,
    })
  }

  endUserTracking() {
    rg4js('setUser', {
      identifier: 'Unauthenticated',
      isAnonymous: true,
      email: undefined,
      firstName: undefined,
      fullName: undefined,
    })
  }

  trackPageChange({ path }: { path: string }) {
    rg4js('trackEvent', {
      type: 'pageView',
      path,
    })
  }

  trackError(error: Error | string, data?: Record<string, unknown>) {
    if (typeof error === 'string') {
      rg4js('send', { error: new Error(error), customData: data })
    } else {
      rg4js('send', { error, customData: data })
    }
  }
}
