import { FormEvent, PropsWithChildren, useRef } from 'react'

interface BaseFormProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
  formRef?: React.MutableRefObject<HTMLFormElement | null | undefined>
}

export const BaseForm = ({
  onSubmit,
  formRef = useRef<HTMLFormElement | null>(),
  children,
}: PropsWithChildren<BaseFormProps>) => {
  return (
    <form
      ref={(ref) => {
        formRef.current = ref
      }}
      onSubmit={(e) => {
        if (formRef.current?.contains(e.currentTarget)) {
          onSubmit(e)
        }
      }}
    >
      {children}
    </form>
  )
}
