import { RenderContentComponent, RenderContentComponentProps } from '@msaf/maps-common'
import { Feature, Geometry } from '@turf/helpers'
import React from 'react'

export interface FeatureFormWrapperProps<TState extends Feature<Geometry>, TContext>
  extends RenderContentComponentProps<TState, TContext> {
  renderContent: RenderContentComponent<TState, TContext>
}

export function FeatureFormWrapper<TState extends Feature<Geometry> = Feature<Geometry>, TContext = {}>({
  renderContent: RenderContent,
  ...props
}: FeatureFormWrapperProps<TState, TContext>) {
  return (
    <div className='c-context-panel-form'>
      <RenderContent {...props} />
    </div>
  )
}
