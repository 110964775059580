import { FieldValues } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { GetFeatureFormConfigProps } from '..'
import { FeatureFormMode, FormConfig, GateTroughFormFields } from '../../types'
import { isFormMode, isFormModes, toDollarCurrencyFormat } from '../../utils'

export const getGateTroughFormConfig = <T extends FieldValues>({
  planId,
  formMode,
}: Pick<GetFeatureFormConfigProps<T>, 'planId' | 'formMode'>): FormConfig<GateTroughFormFields> => {
  const { isMapOrEditMode, isMapMode, isVerificationMode } = isFormModes(formMode)
  const displayStandardFeatureFields = isVerificationMode ? RouteMode.VIEW : RouteMode.EDIT

  const isNotVerificationMapMode = isFormMode(formMode, [
    FeatureFormMode.VERIFICATION_FORM,
    FeatureFormMode.EDIT_FORM,
    FeatureFormMode.MAIN_MAP,
  ])

  return [
    {
      renderOnlyWhen: isMapOrEditMode,
      type: 'field',
      element: {
        fieldId: 'application',
        type: 'lookup',
        lookupId: 'application',
        fieldFilters: { planId },
        isRequired: true,
        defaultToFirst: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'locationName',
        type: 'text',
        mode: displayStandardFeatureFields,
        isRequired: true,
      },
    },
    {
      type: 'field',
      renderOnlyWhen: isNotVerificationMapMode,
      element: {
        fieldId: 'anticipatedStart',
        type: 'date',
        isRequired: true,
        showMonthYearPicker: true,
        dateFormat: 'MMM yyyy',
        mode: displayStandardFeatureFields,
      },
    },
    {
      type: 'field',
      renderOnlyWhen: isNotVerificationMapMode,
      element: {
        fieldId: 'justification',
        formPrefix: 'action',
        type: 'text-area',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      type: 'field',
      renderOnlyWhen: isNotVerificationMapMode,
      element: {
        fieldId: 'intendedImplementer',
        type: 'lookup',
        isRequired: true,
        lookupId: 'work_implementor',
        mode: displayStandardFeatureFields,
      },
    },
    {
      type: 'field',
      renderOnlyWhen: isNotVerificationMapMode,
      element: {
        fieldId: 'unitCount',
        type: 'number',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isMapMode,
      type: 'field',
      element: {
        fieldId: 'materialPrice',
        formPrefix: 'actionGateTrough',
        type: 'read-only',
        conversion: toDollarCurrencyFormat,
      },
    },
  ]
}
