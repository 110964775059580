import * as L from 'leaflet'
import type _ from '@geoman-io/leaflet-geoman-free'
import { LineString, Polygon } from '@turf/helpers'
import { DrawOptions, EditOptions, SupportedDrawnLayers } from '../types'
import { deepOmitBy } from '@msaf/core-common'
import { isUndefined } from 'lodash'
import area from '@turf/area'
import length from '@turf/length'

/*
Calculate the length of a Polyline. Only LineStrings are supported, MutiLineString Polylines are not.
Distance returned is in meters.
*/
export const calculatePolylineLength = (layer: L.Polyline<LineString>) => {
  return length(layer.toGeoJSON(), { units: 'meters' })
}

/*
Calculate the area of a Polygon.
Area returned is in m2.
*/
export const calculatePolygonArea = (layer: L.Polygon<Polygon>) => {
  return area(layer.toGeoJSON().geometry)
}

export const mapDrawOptionsForGeoman = (options: Partial<DrawOptions>): Partial<L.PM.DrawModeOptions> => {
  const strippableResults: L.PM.DrawModeOptions = {
    snappable: options.allowSnapping,
    snapDistance: options.snapDistance,
    tooltips: options.showTooltip,
    mediaSuite: {
      measurement: options.measurement,
    },
  }

  /*
  Strip out any undefined values as they might be specified as part of the global options.

  pathOptions and markerStyle are excluded because we don't want to selectively apply their
  options and it breaks icons.
  */
  return {
    ...deepOmitBy(strippableResults, isUndefined),
    pathOptions: options.pathOptions,
    markerStyle: options.markerStyle,
  }
}

export const mapEditOptionsForGeoman = (
  options: Partial<EditOptions>,
  featureLayer: SupportedDrawnLayers,
): Partial<L.PM.EditModeOptions> => {
  return {
    preventMarkerRemoval:
      (featureLayer instanceof L.Marker && !options.allowMarkerRemoval) ||
      (!(featureLayer instanceof L.Marker) && !(options.allowLinePointRemoval ?? true)),
  }
}
