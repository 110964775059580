import { formatDate, INTERNAL_DATE_FORMAT } from '@msaf/core-common'
import { Button, createToastMessage, RecordContainer, ToastMessage, WorkflowNav } from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useUser } from '../../auth'
import { useGatekeeper } from '../../components/gatekeeper'
import { EoiForm, FormErrors, EoiFormFields } from '../../forms/eoi'
import { useBackLink } from '../../hooks/useBackLink'
import { usePageHeaderTabs } from '../../hooks/usePageHeaderTabs'
import { usePageHeading } from '../../hooks/usePageHeading'
import { useRequest } from '../../hooks/useRequest'
import { usePermission } from '../../services/permissions'
import { UserPermissions } from '../../types/permissions'
import { RouteMode } from '../../types/route'

export const NewPlanEoi = () => {
  const location = useLocation()
  const { client } = useRequest()
  const [user] = useUser()
  useGatekeeper(UserPermissions.CREATE_EOI)
  useBackLink({ label: 'Dashboard', path: '/' })
  usePageHeading('Landowner grant - New registration', ['Expression of Interest'])
  const canViewEoi = usePermission(UserPermissions.VIEW_EOI)

  const [isValid, setValid] = useState(false)
  const navigate = useNavigate()

  const initialState = useMemo(
    () => ({
      dateReceived: formatDate(new Date(), INTERNAL_DATE_FORMAT),
      registeredBy: `${user?.firstName} ${user?.lastName}`,
      addresses: [{ address: '' }],
      sectors: [{ label: '', value: '' }],
      notes: [],
      isRepresentingGroupOrCollective: false,
      activitiesInterestedIn: [{}],
    }),
    [],
  )
  const headerTabs = useMemo(
    () => [
      {
        path: location.pathname,
        label: 'Expression of Interest',
      },
    ],
    [],
  )
  usePageHeaderTabs(headerTabs)

  const onSuccess = useCallback(
    ({ data }: { data: EoiFormFields }) => {
      createToastMessage(
        <ToastMessage messageType='success' title='Success' message={'Expression of interest created.'} />,
      )
      // If not able to view created EOIs, navigate to dashboard
      if (!canViewEoi) {
        navigate('/')
        return
      }
      if (data.planId) {
        navigate(`/plans/edit/${data.planId}/eoi`)
      } else {
        console.warn("EOI creation didn't return a planId.")
      }
    },
    [navigate],
  )

  const onError = useCallback(() => {
    createToastMessage(
      <ToastMessage messageType='error' title='Error' message={'Could not create expression of interest.'} />,
    )
  }, [])

  const mutation = useMutation<AxiosResponse<EoiFormFields>, AxiosError<FormErrors>, EoiFormFields>(
    (data) => client.post('/api/plan/', data),
    {
      onSuccess,
      onError,
    },
  )

  return (
    <RecordContainer
      workflowNav={
        <WorkflowNav
          sections={[
            { heading: 'Expression of Interest', links: [{ label: 'New registration', path: location.pathname }] },
            { heading: 'Review', adviceText: 'Not yet started' },
          ]}
        />
      }
    >
      <EoiForm
        initialState={initialState}
        mode={RouteMode.EDIT}
        submitAction={async (data) => {
          await mutation.mutateAsync(data)
        }}
        onValidate={setValid}
        footerActions={
          <>
            <Button
              type='submit'
              buttonStyle='primary'
              label='Create'
              isLoading={mutation.isLoading}
              isDisabled={mutation.isLoading || !isValid}
            />
            <Button
              type='button'
              buttonStyle='text-action'
              label='Cancel'
              onClick={() => navigate('/')}
              isDisabled={mutation.isLoading}
            />
          </>
        }
      />
    </RecordContainer>
  )
}
