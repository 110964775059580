import { useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { ActionsMenuItemWithId, useAppContext } from '../contexts/app-context'
import { useMutationWithToast } from './use-mutation-with-toast'
import { usePlanSummary } from './usePlanSummary'
import { useRequest } from './useRequest'
import { usePermission } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { RouteMode, RouteParams } from '../types/route'

export function useRegisterMenuEoiActions() {
  const { id: planId, mode } = useParams<RouteParams>()
  const { addPageActions, removePageActions } = useAppContext()
  const { client } = useRequest()
  const { refetch: refetchPlanSummary } = usePlanSummary()
  const hasPermission = usePermission([UserPermissions.REOPEN_EOI])

  const navigate = useNavigate()

  const navigateToEdit = () => navigate(`/plans/${RouteMode.EDIT}/${planId}/plan/pre-checks`)
  const url = `/api/plan/${planId}/eoi/status`

  const { data: statusData, refetch: refetchStatus } = useQuery<{ canReopen: boolean }>(
    [url, mode],
    async () => (await client.get(url, {})).data,
  )

  const refetch = useCallback(() => {
    refetchStatus()
    refetchPlanSummary()
  }, [refetchPlanSummary, refetchStatus])

  const reopenMutation = useMutationWithToast({
    method: 'POST',
    url: `/api/plan/${planId}/eoi/reopen`,
    success: {
      action: () => {
        refetch()
        navigateToEdit()
      },
      toastMessage: 'Successfully reopened Expression of Interest',
    },
    error: { toastMessage: "Only declined and deferred Expression of Interest's can be reopened." },
  })

  useEffect(() => {
    const actions: ActionsMenuItemWithId[] = []
    if (hasPermission && statusData) {
      if (statusData.canReopen)
        actions.push({
          id: 'plan-reopen',
          label: 'Resume plan',
          action: () => reopenMutation.mutate({}),
        })

      addPageActions?.(actions)
    }
    return () => {
      removePageActions?.(actions.map((a) => a.id))
    }
  }, [hasPermission, statusData])

  return { refetch, statusData }
}
