import { Button, Label, Modal, Typeahead } from '@msaf/core-react'
import { MarkerIcon } from '@msaf/maps-react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { MAP_CONFIG } from '../../../config/map'
import { ACTIONS_CATEGORIES_MEASURED_IN_HECTARES } from '../../../constants'
import { sqmToHa } from '../../../utils/units'
import { FeatureMapContext } from '../context'
import { Feature, Polygon } from '@turf/helpers'
import { SuperFeature } from '../types'

const DUPLICATE_TARGET_OPTIONS = Object.entries(MAP_CONFIG.featureConfig)
  .filter(([type, config]) => config.geometryType == 'Polygon' && type != 'propertyBoundary')
  .map(([type, config]) => ({
    label: config.label,
    value: type,
  }))

const getHeadingPolygonSuffix = (selectedFeature: SuperFeature) => {
  const area = selectedFeature.feature.properties?.area

  if (area && !isNaN(area)) {
    const showInHa = ACTIONS_CATEGORIES_MEASURED_IN_HECTARES.includes(selectedFeature.config.categoryKey)
    const areaString = showInHa ? `${sqmToHa(area)}ha` : `${area.toFixed(2)}sqm`

    const numberOfPlants = selectedFeature.feature.properties?.numberOfPlants
    return numberOfPlants ? `${areaString} (${numberOfPlants} plants)` : areaString
  }
}

const getHeadingLineStringSuffix = (selectedFeature: SuperFeature) => {
  const length = selectedFeature.feature.properties?.length
  if (length && !isNaN(length)) {
    return `${length.toFixed(2)}m`
  }
}

const getHeadingSuffix = (selectedFeature: SuperFeature) => {
  if (selectedFeature.config.geometryType === 'Polygon') {
    return getHeadingPolygonSuffix(selectedFeature)
  } else if (selectedFeature.config.geometryType === 'LineString') {
    return getHeadingLineStringSuffix(selectedFeature)
  }
}

export const useContextPanelHeading = () => {
  const navigate = useNavigate()
  const [modalRef, setModalRef] = useState<HTMLDivElement | undefined>()

  const { selectedFeature, duplicateFeature, uiConfig, actionUrlTemplate, featureToggle } =
    useContext(FeatureMapContext)

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)
  const [duplicateFeatureType, setDuplicateFeatureType] = useState<string | undefined>()

  const duplicateFeatureAction = useCallback(() => {
    if (selectedFeature == null || duplicateFeatureType == null || duplicateFeature == null) return
    setDuplicateModalOpen(false)

    /* We don't need to check the type as incorrect values
     * just get ignored and stripped by the backend. */
    const newProperties = {
      locationName: selectedFeature.feature.properties?.locationName,
      area: selectedFeature.feature.properties?.area,
      numberOfPlantsRequiringSpraying: selectedFeature.feature.properties?.numberOfPlants,
    }

    duplicateFeature(selectedFeature.feature as Feature<Polygon>, duplicateFeatureType, newProperties)
  }, [duplicateFeature, duplicateFeatureType])

  const heading = useMemo(() => {
    if (!selectedFeature) {
      return ''
    }
    const label = selectedFeature.config.label
    const suffix = getHeadingSuffix(selectedFeature)
    return suffix ? `${label} - ${suffix}` : label
  }, [selectedFeature])

  const headingIcon = useMemo(() => {
    if (!selectedFeature || !uiConfig) {
      return null
    }
    return <MarkerIcon marker={selectedFeature.config} isLarge />
  }, [selectedFeature, uiConfig])

  const headingAction = useMemo(() => {
    const { id: actionId, properties } = selectedFeature?.feature ?? {}

    // Don't show form mode for forms without render content.
    if (!selectedFeature?.config.renderContent || !actionUrlTemplate) return

    const applicationId = properties?.['application']?.['value']

    const actionUrl = applicationId
      ? generatePath(actionUrlTemplate, {
          applicationId,
          actionId: String(actionId),
        })
      : null
    // Show 'Form mode' button if its a saved action/feature with a valid id
    const formButtonEnabled = applicationId && actionId && (actionId as number) > 0

    return (
      <>
        {duplicateFeature && selectedFeature.config.geometryType == 'Polygon' && (
          <>
            <Modal
              contentRef={(ref) => setModalRef(ref)}
              isOpen={duplicateModalOpen}
              onRequestClose={() => setDuplicateModalOpen(false)}
              heading='Duplicate action'
              body={
                <>
                  <Label labelId={'duplicateActionType'} labelText={'Select a type for the new action'} />
                  <Typeahead
                    menuPortalTarget={modalRef}
                    options={DUPLICATE_TARGET_OPTIONS}
                    labelledBy={'duplicateActionType'}
                    handleChange={(option) => setDuplicateFeatureType(option.value)}
                  />
                </>
              }
              buttons={
                <>
                  <Button
                    buttonStyle='primary'
                    type='button'
                    label='Create action'
                    isDisabled={duplicateFeatureType == null}
                    onClick={duplicateFeatureAction}
                  />
                  <Button
                    buttonStyle='text-action'
                    type='button'
                    label='Cancel'
                    onClick={() => setDuplicateModalOpen(false)}
                  />
                </>
              }
              contentLabel='Select duplicate type'
            />
            {featureToggle?.create && (
              <Button
                iconAriaLabel='clone'
                buttonStyle='secondary'
                icon='duplicate'
                iconAlignment='left'
                title='Duplicate action'
                onClick={() => setDuplicateModalOpen(true)}
              />
            )}
          </>
        )}
        {actionUrl && (
          <Button
            label='Form mode'
            buttonStyle='secondary'
            icon='file'
            iconAlignment='left'
            isDisabled={!formButtonEnabled}
            onClick={() => navigate(actionUrl)}
          />
        )}
      </>
    )
  }, [
    selectedFeature?.feature.id,
    selectedFeature?.feature.properties?.type,
    actionUrlTemplate,
    duplicateFeature,
    duplicateModalOpen,
    duplicateFeatureType,
    modalRef,
    duplicateFeatureAction,
  ])

  return {
    heading,
    headingIcon,
    headingAction,
  }
}
