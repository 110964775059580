import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { mobileMenuAnimations, desktopMenuAnimations, desktopOpacityOnlyMenuAnimations } from './animations'
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '../../../../hooks/media-query'
import { useClickOutside } from '../../../../hooks/click-outside'
import { NAVIGATION_BREAKPOINT } from '../../../../constants'
import { IconWithBadge, IconWithBadgeProps } from '../../../common/icon-with-badge'
import { Icon } from '../../../common/icon'

type ToolbarMenuCommonProps = {
  opacityOnlyAnimation?: boolean
  isSkeleton?: boolean
  closeMenu?: boolean
}
export interface StandardToolbarMenuProps extends IconWithBadgeProps, ToolbarMenuCommonProps {
  userName?: undefined
  userRole?: undefined
  userInitials?: undefined
}

export interface UserToolbarMenuProps extends ToolbarMenuCommonProps {
  userName?: undefined
  userRole?: undefined
  userInitials?: string
  icon?: undefined
  iconAriaLabel?: undefined
  badgeCount?: undefined
}

export interface UserDetailsMenuProps extends ToolbarMenuCommonProps {
  userName?: string
  userRole?: string
  userInitials?: undefined
  icon?: undefined
  iconAriaLabel?: undefined
  badgeCount?: undefined
}

export function ToolbarMenu({
  icon,
  iconAriaLabel,
  badgeCount,
  userInitials,
  userName,
  userRole,
  isSkeleton,
  children,
  closeMenu = false,
  opacityOnlyAnimation,
}: React.PropsWithChildren<StandardToolbarMenuProps | UserToolbarMenuProps | UserDetailsMenuProps>) {
  const [isOpen, setIsOpen] = useState(false)

  // Conditionally select animation behaviour based on viewport size
  const isDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT})`)

  let menuAnimations: any = isDesktop ? desktopMenuAnimations : mobileMenuAnimations
  if (isDesktop && opacityOnlyAnimation) {
    menuAnimations = desktopOpacityOnlyMenuAnimations
  }

  // Automatically close menu if user clicks outside component area
  const node = useClickOutside<HTMLDivElement>(() => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [closeMenu])

  if (isSkeleton) {
    return (
      <div className='c-toolbar-menu'>
        <div className='c-skeleton c-skeleton--circle-small'></div>
      </div>
    )
  }

  const menuClasses = classNames('c-toolbar-menu', {
    'c-toolbar-menu--open': isOpen,
  })

  return (
    <div ref={node} className={menuClasses}>
      {/* Simple initials inside circle toggle button for user menu */}
      {userInitials && (
        <button className='c-toolbar-btn c-toolbar-btn--avatar' onClick={() => setIsOpen(!isOpen)}>
          <span className='c-avatar'>
            <span className='c-avatar__initials' aria-hidden>
              {userInitials}
            </span>
            <Icon
              className='c-avatar__dropdown-icon'
              icon='chevron-down'
              ariaLabel={'Open user menu'}
              isIconRotated180={isOpen}
            />
          </span>
        </button>
      )}
      {/* User icon + name + role toggle button (used in MWS) */}
      {userName && (
        <button
          className='c-toolbar-btn c-toolbar-btn--user-details'
          onClick={() => setIsOpen(!isOpen)}
          aria-label='Show user options'
        >
          <span className='c-avatar'>
            <span className='c-avatar__initials' aria-hidden>
              <Icon icon='user' ariaHidden />
            </span>
          </span>
          <span className='c-toolbar-btn__details'>
            <span className='c-toolbar-btn__user-name'>
              <span className='c-toolbar-btn__user-name-text'>{userName}</span>
              <Icon className='c-toolbar-btn__chevron' icon='chevron-down' isIconRotated180={isOpen} ariaHidden />
            </span>
            <span className='c-toolbar-btn__user-role'>{userRole}</span>
          </span>
        </button>
      )}
      {/* Standard icon + badge to display number of items toggle button */}
      {icon && iconAriaLabel && (
        <button className='c-toolbar-btn c-toolbar-btn--standard' onClick={() => setIsOpen(!isOpen)}>
          <IconWithBadge icon={icon} iconAriaLabel={iconAriaLabel} badgeCount={badgeCount} />
        </button>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className='c-toolbar-menu__menu-container'
            initial={menuAnimations.initial}
            animate={menuAnimations.animate}
            exit={menuAnimations.initial}
          >
            <div className='c-toolbar-menu__content'>
              {/* Mobile only 'close' link (for full screen panel) */}
              <button
                className='c-back-link c-back-link--mobile-only c-back-link--right'
                onClick={() => setIsOpen(!isOpen)}
              >
                <Icon className='c-back-link__icon' icon='chevron-right' ariaHidden={true} />
                <span className='c-back-link__text'>Close</span>
              </button>

              {/* Output menu header, links etc here */}
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
