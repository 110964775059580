import { parseDate } from '@msaf/generic-search-common'
import { DeepPartial, EventType, Path, UnpackNestedValue, UseFormWatch } from 'react-hook-form'
import { FormWatchResultProps, UseFormWatchFunc } from '../forms/components/hooks/use-form-watch'

export type ValidationType = 'number' | 'date' | 'populated' | 'atLeastOne' | 'conditionallyPopulated'

export interface Validation<T> {
  name: keyof T
  validate: ValidationType[]
  key?: string
  dependencyCheck?: (data: T) => boolean
}

export interface ValidationConfig<T> {
  requiredFields: (keyof T)[]
  validations: []
}

export function validateNumber<T>({ value }: ValidatorProps<T>) {
  return !isNaN(+value)
}

export function validateDate<T>({ value }: ValidatorProps<T>) {
  if (value == undefined) return false
  if (typeof value !== 'string') {
    throw new Error('validateDate can only be called for strings')
  }
  return parseDate(value) !== undefined
}

export function validateAtLeastOne<T extends Record<string, string>>({ value, key }: ValidatorProps) {
  if (key == undefined) {
    throw new Error('Incorrectly configured field for validateAtLeastOne. No key provided')
  }
  return (value as T[])?.some((entry) => validatePopulated({ value: entry[key] })) ?? false
}

export function validatePopulated({ value }: ValidatorProps) {
  // Eliminate null and empty values
  return (
    (value != null && value !== '') || (value != null && typeof value === 'object' && Object.keys(value).length > 0)
  )
}

export function validateConditionallyPopulated({ value, dependencyMet }: ValidatorProps) {
  if (!dependencyMet) {
    return true
  }
  return validatePopulated({ value })
}

// Disable as this will be a dev issue only
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ValidatorProps<T = any> {
  value: T
  key?: string
  dependencyMet?: boolean
}

export const validations: Record<ValidationType, (validatorProps: ValidatorProps) => boolean> = {
  number: validateNumber,
  date: validateDate,
  atLeastOne: validateAtLeastOne,
  populated: validatePopulated,
  conditionallyPopulated: validateConditionallyPopulated,
}

const processValidationsForEvent = <T>(
  hardRequiredFields: Validation<T>[],
  data: UnpackNestedValue<DeepPartial<T>>,
  type?: EventType,
  name?: Path<T>,
  onValidate?: (state: boolean) => void,
) => {
  if (
    (type === 'change' && name == null) ||
    !hardRequiredFields.map((f) => f.name).some((field) => (name as string)?.includes(field as string))
  ) {
    return
  }

  onValidate?.(
    hardRequiredFields.every((f) =>
      f.validate.every((validation) =>
        validations[validation as ValidationType]({
          value: data?.[f.name as keyof NonNullable<UnpackNestedValue<DeepPartial<T>>>],
          key: f.key,
          dependencyMet: f.dependencyCheck?.(data as T),
        }),
      ),
    ),
  )
}

export const registerHardValidator = <T>(
  hardRequiredFields: Validation<T>[],
  watch: UseFormWatch<T>,
  onValidate?: (state: boolean) => void,
) => {
  watch((data, { name, type }) => {
    processValidationsForEvent(hardRequiredFields, data, type, name, onValidate)
  })
}

export const formWatchHardValidator = <T>(
  hardRequiredFields: Validation<T>[],
  onValidate?: (state: boolean) => void,
): UseFormWatchFunc<T> => {
  return (props: FormWatchResultProps<T>) => {
    const { info, data } = props
    const { type, name } = info
    processValidationsForEvent(hardRequiredFields, data, type, name, onValidate)
  }
}
