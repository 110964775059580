import { MapUIConfig, getConfigForFeature } from '@msaf/maps-common'
import { Feature, FeatureCollection, Geometry, Properties } from '@turf/helpers'

export const collectionFromFeature = (feature?: Feature<Geometry, Properties>) => {
  if (feature) {
    return {
      type: 'FeatureCollection',
      features: [feature],
    } as FeatureCollection<Geometry, Properties>
  }

  return undefined
}

export const safeGetConfigForFeature = (config: MapUIConfig, feature?: Feature | undefined) => {
  try {
    return getConfigForFeature(config, feature)
  } catch {
    return null
  }
}

/*
 Build a feature title as "Feature Type - Location Name" e.g. "Riparian Planting - River Bank"
 Falls back to just Feature Type if no location name is available.
 */
export const buildTitleForFeature = (config: MapUIConfig, feature?: Feature | undefined) => {
  // Type can either by a string of the type key or an object with a label and value.
  const type =
    typeof feature?.properties?.type != 'string'
      ? feature?.properties?.type.label
      : config?.featureConfig[feature?.properties?.type].label
  return [type, feature?.properties?.locationName].filter((s) => !!s).join(' - ')
}
