import { formatDate } from '@msaf/core-common'
import { Notes, NoteType } from '@msaf/core-react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { useUser } from '../../../auth'
import { ISO_DATETIME_MS_FORMAT } from '../../../constants'
import { canEditNotes } from '../../../services/permissions'
import { AppNoteType, NoteForm, NoteRecordType } from '../../../types/notes'
import { RouteMode } from '../../../types/route'
import { NotesFieldProps } from '../../types'
import { formatDisplayDate } from '../../../utils/dates'

export interface NewNoteType extends NoteType<NoteForm, NoteRecordType> {
  createdTimestamp?: string
}

export function NotesField<T extends FieldValues>(props: NotesFieldProps<T>) {
  const [user] = useUser()
  const { control } = useFormContext<T>()

  if (!user) {
    throw new Error('Unable to render notes without user')
  }

  return (
    <Controller
      control={control}
      name={props.fieldId}
      render={({ field }) => {
        const notes = field.value as AppNoteType[]
        notes.forEach((n) => {
          n.createdDate = formatDisplayDate(n.createdTimestamp)
        })
        return (
          <Notes<NoteForm, NoteRecordType>
            isEditMode={props.mode === RouteMode.EDIT}
            hasEditPermission={(authorId: number) => canEditNotes(user, authorId)}
            notes={notes}
            form={props.form}
            recordType={NoteRecordType.EOI}
            onChange={(notes: Array<NewNoteType>) => {
              // This is a bit of a hack to inject the created timestamp into the notes.
              // This should be fixed in future when notes is refactored to work as was
              // originally intended.
              notes.forEach((n) => {
                if (!n.createdTimestamp) n.createdTimestamp = formatDate(new Date(), ISO_DATETIME_MS_FORMAT)
              })
              field.onChange(notes)
            }}
            author={{
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
            }}
          />
        )
      }}
    />
  )
}
