import { SectionConfig, TabItemProps } from '@msaf/core-react'
import { EOI_STATUS_NAMES, PlanStatusNames } from '../constants'
import { WorkflowState } from '../validation/constants'
import { hasFormBeenCompleted } from '../validation/utils'

export type ApplicationsState = { id: string; applicationNo: string; isSubmitted: boolean }

export function usePlanWorkflowLinks(
  id: string | undefined,
  mode: 'edit' | 'view' | undefined,
  workflowState?: WorkflowState<unknown>,
  applications?: Array<ApplicationsState>,
): Array<SectionConfig> {
  if (!id || !mode) return []

  const planFormState = workflowState?.plan?.[id]?.state?.planCreation?.forms

  const links: SectionConfig[] = [
    {
      heading: 'Plan creation',
      links: [
        {
          path: `/plans/${mode}/${id}/plan/pre-checks`,
          label: 'Pre-checks',
          checked: hasFormBeenCompleted(planFormState?.preChecks),
        },
        {
          path: `/plans/${mode}/${id}/plan/applicant`,
          label: 'Applicant',
          checked: hasFormBeenCompleted(planFormState?.applicant),
        },
        {
          path: `/plans/${mode}/${id}/plan/catchment-context`,
          label: 'Catchment context',
          checked: hasFormBeenCompleted(planFormState?.catchmentContext),
        },
        {
          path: `/plans/${mode}/${id}/plan/property-systems-aspirations`,
          label: 'Property systems & aspirations',
          checked: hasFormBeenCompleted(planFormState?.propertySystemsAspirations),
        },
        {
          path: `/plans/${mode}/${id}/plan/system-aspirations`,
          label: 'Risks & opportunities',
          checked: hasFormBeenCompleted(planFormState?.systemAspirations),
        },
        {
          path: `/plans/${mode}/${id}/plan/overview`,
          label: 'Plan overview',
          checked: hasFormBeenCompleted(planFormState?.overview),
        },
      ],
    },
  ]

  if (Array.isArray(applications)) {
    return links.concat(
      applications.map(({ id: applicationId, applicationNo }) => ({
        heading: applicationNo,
        links: [
          { path: `/plans/${mode}/${id}/plan/project/${applicationId}/details`, label: 'Details & budget' },
          { path: `/plans/${mode}/${id}/plan/project/${applicationId}/submission`, label: 'Submission' },
        ],
      })),
    )
  }
  return links
}

type PlanWorkflowOptions = {
  id?: string
  statusName?: PlanStatusNames
  currentPath: string
  mode: string
  hasEoi: boolean
  hasLongTermMonitoring: boolean
}

export const WorkflowTabs = Object.freeze({
  EOI: 'Expression of interest',
  PLAN: 'Plan',
  MAP: 'Map',
  REVIEW: 'Review & Approval',
  SUPPLY: 'Supply',
  PROGRESS: 'Progress',
  PAYMENTS: 'Payments',
  LONG_TERM_MONITORING: 'Long term monitoring',
  HISTORY: 'History',
} as const)

export type WorkflowTabsKeys = keyof typeof WorkflowTabs

export type WorkflowTabConfig = {
  [key in WorkflowTabsKeys]: TabItemProps & { display: boolean }
}

export const getPlanWorkflowTabs = ({
  id,
  statusName,
  mode,
  currentPath,
  hasEoi,
  hasLongTermMonitoring,
}: PlanWorkflowOptions): Array<TabItemProps> => {
  if (id == null || statusName == null) {
    return [
      {
        label: WorkflowTabs.EOI,
        path: `/plans/new`,
      },
    ]
  }

  const showPlanTabs = !EOI_STATUS_NAMES.includes(statusName)

  const tabConfig: WorkflowTabConfig = {
    EOI: { label: WorkflowTabs.EOI, path: `/plans/${mode}/${id}/eoi`, display: hasEoi },
    PLAN: {
      label: WorkflowTabs.PLAN,
      path: `/plans/${mode}/${id}/plan`,
      display: showPlanTabs,
    },
    MAP: { label: WorkflowTabs.MAP, path: `/plans/${mode}/${id}/map?origin=${currentPath}`, display: true },
    REVIEW: { label: WorkflowTabs.REVIEW, path: `/plans/${mode}/${id}/review`, display: showPlanTabs },
    SUPPLY: {
      label: WorkflowTabs.SUPPLY,
      path: `/plans/${mode}/${id}/supply`,
      isNewSection: true,
      display: showPlanTabs,
    },
    PROGRESS: { label: WorkflowTabs.PROGRESS, path: `/plans/${mode}/${id}/progress`, display: showPlanTabs },
    PAYMENTS: { label: WorkflowTabs.PAYMENTS, path: `/plans/${mode}/${id}/payments`, display: showPlanTabs },
    LONG_TERM_MONITORING: {
      label: WorkflowTabs.LONG_TERM_MONITORING,
      path: `/plans/${mode}/${id}/long-term-monitoring`,
      isNewSection: true,
      display: hasLongTermMonitoring,
    },
    HISTORY: { label: WorkflowTabs.HISTORY, path: `/plans/${mode}/${id}/history`, isNewSection: true, display: true },
  }

  return Object.values(tabConfig).filter((tab) => tab.display)
}
