import { addMarkerUpdateEvents, SupportedDrawnLayers } from '@msaf/maps-common'
import { useCallback, useState } from 'react'

type LayerHandler = (l: L.LeafletEvent) => void

export function useFeatureDrawingHandlers(onLayerClick: LayerHandler, onLayerEdit: LayerHandler) {
  const [handlers, setHandlers] = useState<Record<number, boolean>>({})

  const addLayerHandlers = useCallback(
    (l: L.Layer, featureLayer?: L.GeoJSON) => {
      const layerId = featureLayer?.getLayerId(l)
      if (layerId && !handlers[layerId]) {
        // Add marker events here so we can control removing them.
        addMarkerUpdateEvents(l as SupportedDrawnLayers)

        l.on('click', onLayerClick)
        l.on('pm:edit', onLayerEdit)
        setHandlers((h) => ({ ...h, [layerId]: true }))
      }
    },
    [onLayerClick, onLayerEdit, handlers, setHandlers],
  )

  // Add handlers for individual layer
  const setupAllLayerHandlers = useCallback(
    (featureLayer: L.GeoJSON | undefined) => {
      if (featureLayer) {
        featureLayer.eachLayer((l) => addLayerHandlers(l, featureLayer))
      }
    },
    [addLayerHandlers],
  )

  // Remove handlers for individual layer
  const removeAllLayerHandlers = useCallback(
    (featureLayer?: L.GeoJSON) => {
      if (featureLayer) {
        featureLayer.eachLayer((l) => {
          const layerId = featureLayer.getLayerId(l)
          // remove all marker events for this layer.
          l.off('click')
          l.off('pm:edit')
          l.off('pm:markerdragstart')
          l.off('pm:markerdragend')

          setHandlers((h) => ({ ...h, [layerId]: false }))
        })
      }
    },
    [onLayerClick, onLayerEdit, handlers, setHandlers],
  )

  return {
    addLayerHandlers,
    setupAllLayerHandlers,
    removeAllLayerHandlers,
  }
}
