/**
 * NOTE: This is left as a JS file because we are modifying leaflet internals - which makes working with types tricky.
 */
import * as L from 'leaflet'

export function initialize(/* container, application */) {
  // Fix the browser touch flag so that we can get consistent point sizes.
  L.Browser.touch = false

  // Overriding the Style for leaflet to resolve the issue for leaflet.js 1.5.1
  // sometimes shape.options.editing===undefine , override L.Path.prototype.setStyle just check empty;
  // Reference: https://github.com/Leaflet/Leaflet.draw/issues/943

  L.Path.prototype.setStyle = function (style) {
    L.setOptions(this, style)
    if (this._renderer) {
      this._renderer._updateStyle(this)
      if (this.options.stroke && typeof style === 'object' && style.hasOwnProperty('weight')) {
        this._updateBounds()
      }
    }
    return this
  }
  L.Circle.prototype.setStyle = function (style) {
    L.setOptions(this, style)
    if (this._renderer) {
      this._renderer._updateStyle(this)
      if (this.options.stroke && typeof style === 'object' && style.hasOwnProperty('weight')) {
        this._updateBounds()
      }
    }
    return this
  }
  // ---------------------------------------------------------------------------------------
}

export default {
  name: 'leaflet-initializer',
  initialize: initialize,
}
