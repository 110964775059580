import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal, FormField, InputWidth, TextInput, Button, BaseModalProps } from '@msaf/core-react'
import { useSaveSearch } from '../../../../../src/hooks/use-api'
import { AuthStrategy, RequestOptions, SearchQuery } from '@msaf/generic-search-common'
import { useQueryClient } from 'react-query'

export type SaveSearchModal = <T extends AuthStrategy = 'token'>(props: SaveSearchModalProps<T>) => JSX.Element

export interface SaveSearchModalProps<T extends AuthStrategy = 'token'> extends BaseModalProps {
  searchQuery?: SearchQuery
  requestOptions?: RequestOptions<T>
  setSelectedOption: Dispatch<SetStateAction<{ value: any; label: string } | undefined>>
}

export function SaveSearchModal<T extends AuthStrategy = 'token'>({
  isOpen,
  onRequestClose,
  searchQuery,
  requestOptions,
  setSelectedOption,
}: SaveSearchModalProps<T>) {
  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const isNameValid = searchName !== undefined && searchName !== ''

  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, data } = useSaveSearch<T>(requestOptions)

  const canSubmit = isNameValid && !!searchQuery && !isLoading

  const close = () => {
    setSearchName(undefined)
    onRequestClose()
  }

  const save = () => {
    if (!canSubmit) return
    mutate({ searchName, searchQuery: searchQuery })
  }

  useEffect(() => {
    if (!isSuccess) return
    queryClient.invalidateQueries({ queryKey: ['lookupSavedSearch'] })
    setSelectedOption({ value: data.queryId, label: data.queryName })
    close()
  }, [isSuccess])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      heading='Save your search'
      body={
        <>
          <p>Your saved searches will appear at the top of your search controls on each tab.</p>
          <FormField label='Search Name' isRequired htmlFor='searchName'>
            <TextInput
              id='searchName'
              fieldSize={InputWidth.full}
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </FormField>
        </>
      }
      buttons={
        <>
          <Button
            type='submit'
            buttonStyle='primary'
            label='Save search'
            isDisabled={!canSubmit}
            size='default'
            onClick={save}
          />
          <Button type='button' buttonStyle='text-action' label='Cancel' size='default' onClick={close} />
        </>
      }
    />
  )
}
