import { InlineNotification, LoadingIndicator, RecordContent } from '@msaf/core-react'
import { useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { useWorkflowGatekeeper } from '../../../../forms/application/utils'
import { UserPermissions } from '../../../../types/permissions'
import { ApplicationRouteParams } from '../../../../types/route'
import { ApplicationWrapper } from './application-wrapper'

export const PaymentsWrapper = () => {
  const { id: planId, applicationId, mode } = useParams<ApplicationRouteParams>()

  useViewRedirectGatekeeper(UserPermissions.EDIT_GRANT_PAYMENT, UserPermissions.VIEW_GRANT_PAYMENT)

  const { isLoading, open } = useWorkflowGatekeeper(planId, applicationId, 'payment', mode)

  if (isLoading) return <LoadingIndicator />

  if (!open) {
    return (
      <RecordContent>
        <InlineNotification isDismissable={false}>Not submitted</InlineNotification>
      </RecordContent>
    )
  }

  return <ApplicationWrapper />
}
