import { DrawOptions, setDrawOptions as setGlobalDrawOptions } from '@msaf/maps-common'
import * as L from 'leaflet'
import { useCallback, useEffect, useState } from 'react'

export function useDrawOptions(map?: L.Map) {
  const defaultDrawOptions = useCallback(() => {
    const { snappable, snapDistance, tooltips, mediaSuite } = (map?.pm.getGlobalOptions() ?? {}) as L.PM.GlobalOptions

    return {
      allowSnapping: snappable,
      snapDistance,
      showTooltip: tooltips,
      measurement: mediaSuite?.measurement,
    }
  }, [map])

  const [options, setOptions] = useState<Partial<DrawOptions>>({})

  useEffect(() => {
    if (map) {
      setOptions(defaultDrawOptions())
    }
  }, [map])

  const setDrawOptions = useCallback(
    (newOptions: Partial<DrawOptions>) => {
      if (map) {
        setOptions((current) => ({ ...current, ...newOptions }))
        setGlobalDrawOptions(map, newOptions)
      }
    },
    [setOptions, map],
  )

  const setSnappable = useCallback(
    (canSnap: boolean) => {
      map?.pm.setGlobalOptions({ snappable: canSnap })
      setOptions({ ...options, allowSnapping: canSnap })
    },
    [map],
  )

  return { options, setDrawOptions, setSnappable }
}
