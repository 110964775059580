import { useEffect } from 'react'
import { DeepPartial, EventType, FieldValues, Path, UnpackNestedValue, UseFormReturn } from 'react-hook-form'

export interface FormWatchResultProps<T extends FieldValues> {
  data: UnpackNestedValue<DeepPartial<T>>
  info: {
    name?: Path<T> | undefined
    type?: EventType | undefined
    value?: unknown
  }
  methods: UseFormReturn<T>
}

export type UseFormWatchFunc<T extends FieldValues> = (props: FormWatchResultProps<T>) => void

export function useFormWatch<T extends FieldValues>(methods: UseFormReturn<T>, func?: UseFormWatchFunc<T>) {
  useEffect(() => {
    if (func) {
      const subscription = methods.watch((data, info) => {
        func({ data, info, methods })
      })

      return () => subscription.unsubscribe()
    }
  }, [methods.watch, func])
}
