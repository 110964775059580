import {
  ArrayPath,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import LookupSearch from '../../../../components/lookup-typeahead'
import { RouteMode } from '../../../../types/route'
import { RepeatingLibLookupFieldProps } from '../../../types'
import { useLabelProps } from '../../hooks/use-label-props'
import { Wrapper } from './wrapper'

export function RepeatingLookupField<T extends FieldValues>(props: RepeatingLibLookupFieldProps<T>) {
  const { control, getValues } = useFormContext<T>()
  const { fieldId, lookupId, mode, defaultValue, isDisabled, isSkeleton, fieldFilters, allowDuplicates } = props
  const fieldArray = useFieldArray({ name: fieldId as ArrayPath<T>, control })
  const labelProps = useLabelProps<T>(props)

  // Set the existing values to disable duplicates if allowDuplicates is false
  const existingValues =
    allowDuplicates === false
      ? getValues()[`${fieldId}`]?.map((f: UnpackNestedValue<PathValue<T, Path<T>>>) => f.value) ?? []
      : []

  return (
    <Wrapper
      {...props}
      addField={() => fieldArray.append(defaultValue)}
      removeField={(index) => {
        fieldArray.remove(index)
      }}
    >
      {fieldArray.fields.map((field, index) => (
        <Controller
          key={field.id}
          control={control}
          name={`${fieldId}.${index}` as Path<T>}
          render={({ field }) => (
            <LookupSearch
              id={`${fieldId}.${index}`}
              isDisabled={isDisabled || mode === RouteMode.VIEW}
              isSkeleton={isSkeleton}
              lookupId={lookupId}
              value={field.value}
              setValue={(v) => field.onChange(v)}
              labelledBy={labelProps.labelId ?? fieldId}
              fieldFilters={fieldFilters}
              excludeValues={existingValues}
            />
          )}
        />
      ))}
    </Wrapper>
  )
}
