import React, { useState } from 'react'
import { LegendOptions } from './legend-options'
import { Button } from '../../common'
import { LegendSectionProps } from './legend-options/legend-section'
import { useFloating, flip, offset, useDismiss, useInteractions, useTransitionStyles } from '@floating-ui/react'
import classNames from 'classnames'

export interface LegendMenuProps {
  buttonLabel?: string
  menuSections: Array<LegendSectionProps>
  isOpen?: boolean
  /**
   * By default, the menu is statically positioned. Passing in a positionMenu option
   * will automatically set it's positioning to absolute so make sure you've set
   * positioning attributes appropriately on parent element where this will be displayed.
   */
  positionMenu?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}

export function LegendMenu({ buttonLabel = 'Map layers', menuSections, isOpen, positionMenu }: LegendMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen)

  const { refs, floatingStyles, context } = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: 'top-end',
    middleware: [
      flip(),
      offset({
        mainAxis: 8,
      }),
    ],
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: 200,
    initial: ({ side }) => ({
      transform: side === 'bottom' ? 'translateY(-8px)' : 'translateY(8px)',
      opacity: 0,
    }),
    open: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  })

  const classes = classNames('c-legend-menu', {
    [`c-legend-menu--position-${positionMenu}`]: !!positionMenu,
  })

  return (
    <div className={classes}>
      <Button
        buttonStyle='tertiary'
        label={buttonLabel}
        /**
         * Chevron direction will not update if menu is automatically placed below button
         * (due to overlowing container if placed above). Will need to figure out a way to achieve
         * this from initial render if menu is aligned top of an area in future. Current blocker is
         * that placement value is not updated until first open.
         */
        icon={'chevron-up'}
        isIconRotated180={isMenuOpen}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        ref={refs.setReference}
        {...getReferenceProps()}
      />
      {isMenuOpen && (
        <nav
          className='c-legend-menu__nav'
          aria-label='Legend menu'
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {isMounted && (
            <div
              className='c-legend-menu__options-container'
              style={{
                ...styles,
              }}
            >
              <LegendOptions menuSections={menuSections} />
            </div>
          )}
        </nav>
      )}
    </div>
  )
}
