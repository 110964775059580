import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { useLocation } from '@msaf/router-react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Form } from '../../../../../../forms/components/form'
import { useRequest } from '../../../../../../hooks/useRequest'
import { RouteMode } from '../../../../../../types/route'
import { formatDisplayDate } from '../../../../../../utils/dates'

interface HistoryAtomFields {
  modifiedTimestamp: string
  user: string
  tableDisplayName: string
  columnDisplayName: string
  oldValue: string
  newValue: string
  changeTypeLabel: string
}

export const HistoryAtom = () => {
  const { id, mode, atomId } = useParams<{ id: string; mode: RouteMode; atomId: string }>()
  const { client } = useRequest()
  const { pathname } = useLocation()
  const { data, isLoading } = useQuery<HistoryAtomFields>(
    [id, atomId, 'history'],
    () => client.get(`/api/plan/${id}/history/${atomId}`).then((response) => response.data),
    {
      keepPreviousData: true,
    },
  )

  return (
    <RecordContainer workflowNav={<WorkflowNav sections={[{ links: [{ label: 'Details', path: pathname }] }]} />}>
      <Form<HistoryAtomFields>
        isSkeleton={isLoading}
        initialState={data}
        mode={RouteMode.VIEW}
        submitAction={() => Promise.resolve(null)}
        footerActions={null}
        config={[
          {
            type: 'action',
            element: {
              type: 'button',
              path: `/plans/${mode}/${id}/history`,
              label: 'Back',
              iconAlignment: 'left',
              icon: 'arrow-left',
              buttonStyle: 'text-action',
              isDisabled: false,
              enabledInViewMode: true,
            },
          },
          { type: 'atom', element: { type: 'heading', level: 2, content: 'History' } },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'modifiedTimestamp',
              conversion: (value: string) => formatDisplayDate(value),
              labelOverride: 'Date',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'user',
              labelOverride: 'User',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'tableDisplayName',
              labelOverride: 'Form',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'columnDisplayName',
              labelOverride: 'Field',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'oldValue',
              labelOverride: 'From',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'newValue',
              labelOverride: 'To',
            },
          },
          {
            type: 'field',
            element: {
              type: 'read-only',
              fieldId: 'changeTypeLabel',
              labelOverride: '+ / - / MOD',
            },
          },
        ]}
      />
    </RecordContainer>
  )
}
