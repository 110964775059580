import { RepeatableField } from '@msaf/core-react'
import {
  AuthStrategy,
  FilterInstance,
  FilterSet,
  RequestOptions,
  SearchTemplate,
  SupportedRepeatingFilterType,
} from '@msaf/generic-search-common'
import React, { useMemo } from 'react'
import { FilterProps } from '../../utils'
import { FilterDate } from './filter-date'
import { FilterDateRange } from './filter-date-range'
import { FilterNumericRange } from './filter-numeric-range'
import { FilterSelect } from './filter-select'
import { FilterText } from './filter-text'
import { FilterTypeahead } from './filter-typeahead'

export interface FilterInputProps<T extends AuthStrategy = 'token'> {
  searchTemplate: SearchTemplate
  filterKey: string
  query: (FilterSet | FilterInstance)[]
  search: () => void
  addValue: (filter: string) => void
  setValue: (filter: string, index: number, value: string | FilterSet) => void
  removeValue: (filter: string, index: number) => void
  requestOptions?: RequestOptions<T>
  searchTypeKey: string
  dateFormat: string
  onKeyDown: React.KeyboardEventHandler<Element>
}

export function FilterInput<T extends AuthStrategy = 'token'>({
  searchTemplate,
  filterKey,
  query,
  search,
  addValue,
  removeValue,
  setValue,
  requestOptions,
  searchTypeKey,
  dateFormat,
  onKeyDown,
}: FilterInputProps<T>) {
  const template = useMemo(() => {
    return searchTemplate.filters.find((f: any) => f.filterKey === filterKey)
  }, [filterKey, searchTemplate])

  // If there is no matching filter key in the search template, or
  // there are no filters in the filter query, render nothing
  if (template === undefined || query.length === 0) {
    return null
  }

  const elementMap: Record<SupportedRepeatingFilterType, React.ElementType<FilterProps<T>>> = {
    text: FilterText,
    date: FilterDate,
    'date-range': FilterDateRange,
    select: FilterSelect,
    typeahead: FilterTypeahead,
    'typeahead-selector': FilterTypeahead,
    'numeric-range': FilterNumericRange,
  }

  if (!(template.type in elementMap)) {
    throw new Error(`Filter type '${template.type}' is not supported.`)
  }
  const Filter = elementMap[template.type as SupportedRepeatingFilterType]

  const labelId = `search-filter-input-label-${filterKey}`

  return (
    <RepeatableField
      label={template?.label}
      labelId={labelId}
      addField={() => addValue(filterKey)}
      removeField={(index) => removeValue(filterKey, index)}
      allowRemoveLast
      components={query.map((filter: FilterSet | FilterInstance, index: number) => (
        <Filter
          key={`${filterKey}-${index}`}
          template={template}
          filterKey={filterKey}
          search={search}
          labelledBy={labelId}
          setValue={(value: string | FilterSet) => setValue(filterKey, index, value)}
          value={'filterKey' in filter ? filter.filterValue ?? '' : filter}
          requestOptions={requestOptions}
          searchTypeKey={searchTypeKey}
          dateFormat={dateFormat}
          onKeyDown={onKeyDown}
        />
      ))}
    />
  )
}
