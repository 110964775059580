import { Button } from '@msaf/core-react'
import { useNavigate } from '@msaf/router-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { useViewRedirectGatekeeper } from '../../../../components/gatekeeper'
import { EoiWorkflow } from '../../../../components/workflow-containers/eoi'
import { SAVE_AND_CONTINUE_ID } from '../../../../forms/components/atom/footer'
import { DecisionForm, DecisionFormValues } from '../../../../forms/decision'
import { useFormState } from '../../../../hooks/use-form-state'
import { useMutationWithToast } from '../../../../hooks/use-mutation-with-toast'
import { usePermission } from '../../../../services/permissions'
import { UserPermissions } from '../../../../types/permissions'
import { RouteMode, RouteParams } from '../../../../types/route'

export const EoiDecision = () => {
  const { id, mode } = useParams<RouteParams>()
  // If edit mode, check if the user has permission to edit / process the EOI
  // If view mode, check that the user has permission to view the EOI
  // TODO: Scoped permissions to be done when assign FA story done
  useViewRedirectGatekeeper(UserPermissions.PROCESS_EOI, [UserPermissions.VIEW_EOI], `/plans/view/${id}/eoi`)
  const navigate = useNavigate()

  const hasEditPermission = usePermission(UserPermissions.PROCESS_EOI)

  const { isLoading, data, errors, refetchErrors } = useFormState<DecisionFormValues>(
    ([id]) => `/api/plan/${id}/decision`,
    {
      workflowId: 'expressionOfInterest',
      sectionId: 'review',
      formId: 'decision',
      instanceId: `${id}`,
    },
  )

  const mutation = useMutationWithToast<
    AxiosResponse<DecisionFormValues>,
    AxiosError<Map<keyof DecisionFormValues, Array<string>>>,
    DecisionFormValues,
    void
  >({
    method: 'PUT',
    url: `/api/plan/${id}/decision`,
    success: {
      action: () => {
        // Refetch on success for any new validation errors
        refetchErrors?.()
      },
      toastMessage: 'Expression of interest saved.',
    },
    error: { toastMessage: 'Could not save expression of interest.' },
  })

  const processMutation = useMutationWithToast<AxiosResponse<{ outcome: 'approved' | 'declined' }>, void, void, void>({
    method: 'POST',
    url: `/api/plan/${id}/process`,
    success: {
      toastMessage: 'Expression of interest successfully processed.',
    },
    error: {
      toastMessage: 'Could not process expression of interest.',
    },
  })

  const actionsDisabled = mutation.isLoading || processMutation.isLoading || isLoading

  return (
    <EoiWorkflow recordId={id}>
      <DecisionForm
        data={data}
        errors={errors}
        mode={mode}
        canEdit={hasEditPermission}
        footerActions={
          mode === RouteMode.EDIT ? (
            <>
              <Button id={SAVE_AND_CONTINUE_ID} type='submit' label='Process' isDisabled={actionsDisabled} />
              <Button type='submit' label='Save' isDisabled={actionsDisabled} />
              <Button buttonStyle='text-action' label='Cancel' isDisabled={actionsDisabled} />
            </>
          ) : undefined
        }
        submitAction={async (data, submitter) => {
          const process = submitter?.id === SAVE_AND_CONTINUE_ID
          await mutation.mutateAsync(data, {
            onSuccess: async () => {
              if (process) {
                const processResponse = await processMutation.mutateAsync()
                if (processResponse.status === 200) {
                  if (processResponse.data.outcome === 'approved') {
                    navigate(`/plans/${mode}/${id}/plan`)
                  } else {
                    navigate(`/plans/${mode}/${id}/eoi`)
                  }
                }
              }
            },
          })
        }}
      />
    </EoiWorkflow>
  )
}
