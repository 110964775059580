import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import LookupSearch from '../../../components/lookup-typeahead'
import { RouteMode } from '../../../types/route'
import { LibLookupFieldProps, RepeatingLibLookupFieldProps } from '../../types'
import { useLabelProps } from '../hooks/use-label-props'
import { RepeatingLookupField } from './repeating/lookup-field'
import { Wrapper } from './wrapper'

export function LookupField<T extends FieldValues>(props: LibLookupFieldProps<T> | RepeatingLibLookupFieldProps<T>) {
  const { fieldId, lookupId, mode, isDisabled, defaultToFirst, isSkeleton, fieldFilters, isClearable } = props
  const { control } = useFormContext<T>()
  const labelProps = useLabelProps<T>(props)

  if ('repeating' in props && props.repeating) {
    return <RepeatingLookupField {...props} />
  }

  if (lookupId === undefined) {
    throw new Error('LookupField cannot be used with an undefined lookupId.')
  }

  return (
    <Wrapper {...props}>
      <Controller
        control={control}
        name={fieldId}
        render={({ field }) => (
          <LookupSearch
            id={fieldId}
            isDisabled={isDisabled || mode === RouteMode.VIEW}
            isSkeleton={isSkeleton}
            lookupId={lookupId}
            value={field.value}
            defaultToFirst={defaultToFirst}
            setValue={(v) => {
              // If the `value` is undefined, set to null
              // This prevents us from sending an empty object to the API, instead explicitly sending
              // an object that looks like `{ value: null }`
              field.onChange(v ?? null)
            }}
            labelledBy={labelProps.labelId ?? fieldId}
            fieldFilters={fieldFilters}
            isClearable={isClearable}
          />
        )}
      />
    </Wrapper>
  )
}
