import { cancelEditing, drawFeature, editFeature, MapUIConfig, SupportedDrawnLayers } from '@msaf/maps-common'
import * as L from 'leaflet'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseFeatureLayerReturn } from '../feature-management'
import { useFeatureDrawingHandlers } from './handlers'
import { useDrawingInit } from './initialise'
import { useDrawOptions } from './options'

export interface UseFeatureDrawingProps {
  map?: L.Map
  featureLayerMethods: UseFeatureLayerReturn
  onGeometryChange: (feature: SupportedDrawnLayers) => void
  clearFeatureSelection: () => void
  config: MapUIConfig
  readOnly?: boolean
}

/**
 * Handles management of drawing activities
 * @returns
 */
export function useFeatureDrawing({
  map,
  featureLayerMethods,
  onGeometryChange,
  config,
  readOnly,
}: UseFeatureDrawingProps) {
  const [editing, setEditing] = useState<boolean>(false)
  const [count, setCount] = useState(-1)
  const { options: drawOptions, setDrawOptions, setSnappable } = useDrawOptions(map)

  const selectedLayerId = useMemo(() => {
    if (featureLayerMethods.state?.selectedFeature?.layer) {
      return featureLayerMethods.state?.layer?.getLayerId(featureLayerMethods.state?.selectedFeature?.layer)
    }
    return undefined
  }, [featureLayerMethods.state?.layer, featureLayerMethods.state?.selectedFeature])

  // Layer edit handler
  // Triggered when pm:edit and pm:update events fire
  const onLayerEdit = useCallback(
    (e: L.LeafletEvent) => {
      const layer = e.target as SupportedDrawnLayers
      onGeometryChange(layer)
      featureLayerMethods.editFeature(layer)
    },
    [onGeometryChange, featureLayerMethods.editFeature],
  )

  // Layer click handler
  // Triggers when click event fires
  const onLayerClick = useCallback(
    (e: L.LeafletEvent) => {
      const layer = e.target as SupportedDrawnLayers
      onGeometryChange(layer)
      featureLayerMethods.editFeature(layer)
    },
    [onGeometryChange, featureLayerMethods.editFeature],
  )

  const { addLayerHandlers, setupAllLayerHandlers, removeAllLayerHandlers } = useFeatureDrawingHandlers(
    onLayerClick,
    onLayerEdit,
  )

  // Make layer editable
  useDrawingInit(config, (layer) => setupAllLayerHandlers(layer), map, featureLayerMethods.state.layer, readOnly)

  // Handle Layer selection
  useEffect(() => {
    if (map) {
      if (selectedLayerId == null) {
        setupAllLayerHandlers(featureLayerMethods.state.layer)
      } else {
        // Should never get to this point.
        if (!featureLayerMethods.state.selectedFeature?.layer) throw new Error('Something went wrong.')

        addLayerHandlers(featureLayerMethods.state.selectedFeature.layer, featureLayerMethods.state.layer)
        if (!readOnly && !featureLayerMethods.state.selectedFeature.feature.properties?.readOnly) {
          // If the action's properties allow changes, then...
          try {
            editFeature(featureLayerMethods.state.config, map, featureLayerMethods.state.selectedFeature.layer)
          } catch (e) {
            const errorMessage = e instanceof Error ? e.message : e

            if (typeof errorMessage === 'string' && errorMessage.includes('MAP-7')) {
              console.warn(errorMessage)
              setEditing(false)
              // Draw cancelled
              return undefined
            }
            throw e
          }

          return () => {
            cancelEditing(map, featureLayerMethods.state.selectedFeature?.layer)
          }
        }
      }
    }
  }, [featureLayerMethods.state.selectedFeature?.layer, selectedLayerId, readOnly])

  const newFeature = useCallback(
    async (featureType: string) => {
      if (!map) {
        throw new Error('Cannot enable feature drawing without a map')
      }

      setEditing(true)
      try {
        removeAllLayerHandlers(featureLayerMethods.state.layer)

        const layer = await drawFeature(config, map, featureType, {
          drawOptions: { ...drawOptions, showTooltip: true },
        })

        setEditing(false)

        if (!layer.feature) {
          throw new Error('Expected an initial feature creation')
        }

        layer.feature.id = count
        setCount((count) => count - 1)

        // Trigger layer create in global state
        onGeometryChange(layer)

        // Return layer
        return layer
      } catch (e) {
        const errorMessage = e instanceof Error ? e.message : e

        if (typeof errorMessage === 'string' && errorMessage.includes('MAP-8')) {
          console.warn(errorMessage)
          setEditing(false)
          // Draw cancelled
          return null
        }
        throw e
      }
    },
    [map, featureLayerMethods.state.layer, removeAllLayerHandlers, setEditing, config, count, setCount],
  )

  const cancelDrawing = useCallback(() => {
    if (map) cancelEditing(map)
  }, [map])

  return {
    newFeature,
    editing,
    setDrawOptions,
    drawOptions,
    setSnappable,
    cancelDrawing,
  }
}
