import { useEffect } from 'react'
import { ErrorOption, FieldPath, FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form'

interface TranslateErrorsProps<
  TFieldValues extends FieldValues,
  TErrorValues = string,
  TMessageTranslator = undefined,
> {
  errors: Record<keyof TFieldValues, TErrorValues[]> | undefined | null
  setError: UseFormSetError<TFieldValues>
  clearErrors: UseFormClearErrors<TFieldValues>
  toMessage?: TMessageTranslator
  submitCount?: number
}

export function translateErrors<TFieldValues extends FieldValues, TErrorValues>({
  errors,
  setError,
  clearErrors,
  toMessage,
}:
  | TranslateErrorsProps<TFieldValues, string, undefined>
  | TranslateErrorsProps<TFieldValues, TErrorValues, (e: TErrorValues) => ErrorOption>) {
  // Clear all existing errors first
  clearErrors()
  if (errors == null) {
    return
  }
  setTimeout(() => {
    Object.entries(errors).forEach(([key, errorList]) => {
      ;(errorList as TErrorValues[]).forEach((e) => {
        // When supporting future error types, `type` should be checked to adjust based
        // on whether or not the type from the server is a required error
        const error = typeof e === 'string' ? { type: 'custom', message: e } : toMessage?.(e)
        if (!error) throw new Error('toMessage is required if TErrorValues is not of type string')

        setError(key as FieldPath<TFieldValues>, error)
      })
    })
  }, 1)
}

export const useTranslateErrors = <TFieldValues extends FieldValues, TErrorValues>(
  props:
    | TranslateErrorsProps<TFieldValues, string, undefined>
    | TranslateErrorsProps<TFieldValues, TErrorValues, (e: TErrorValues) => ErrorOption>,
) => {
  const { errors, setError, clearErrors, submitCount } = props
  useEffect(() => {
    translateErrors(props)
  }, [errors, setError, clearErrors, submitCount])

  return () => translateErrors(props)
}
