import { ConfirmTransition } from '@msaf/core-react'
import { FieldValues, useFormContext } from 'react-hook-form'
import labels from '../../../constants/labels'
import { useCallback, useMemo } from 'react'
import { FormElementConfig } from '../../types'

type TransitionProps<T extends FieldValues> = {
  formConfig: FormElementConfig<T>[]
  onSave: () => Promise<void>
}

export function Transition<T extends FieldValues>({ formConfig, onSave }: TransitionProps<T>) {
  const { formState } = useFormContext<T>()

  const { isDirty, dirtyFields } = formState

  const getFieldConfig = useCallback(
    (field: string) => {
      return formConfig.find(
        (fieldConfig) =>
          fieldConfig?.type === 'field' &&
          'element' in fieldConfig &&
          'fieldId' in fieldConfig.element &&
          fieldConfig.element.type !== 'read-only' &&
          fieldConfig.element.fieldId === field,
      )
    },
    [formConfig],
  )

  const dirtyFieldLabels = useMemo(
    () =>
      Object.keys(dirtyFields)
        .map((fieldId) => {
          const fieldConfig = getFieldConfig(fieldId)

          // Skip fields we haven't matched a config on.
          if (!fieldConfig) {
            console.warn(`No fieldConfig found for dirty field '${fieldId}'`)
            return null
          }

          const formPrefix = 'formPrefix' in fieldConfig.element ? fieldConfig.element?.formPrefix : null
          const labelKey = formPrefix ? `${formPrefix}.${fieldId}` : fieldId
          const label = labels[labelKey]
          if (label == null) {
            console.warn(`No form label found for dirty field '${labelKey}'`)
          }
          return label
        })
        .filter((label): label is string => label != null),
    [isDirty, Object.keys(dirtyFields)],
  )

  return (
    <ConfirmTransition
      when={isDirty}
      contentLabel={'Unsaved changes confirmation dialog'}
      modifiedFields={dirtyFieldLabels}
      actionConfig={[
        {
          type: 'confirm',
          key: 'saveContinue',
          buttonStyle: 'primary',
          label: 'Save and continue',
          onClick: onSave,
        },
        {
          type: 'confirm',
          key: 'discard',
          buttonStyle: 'text-action',
          label: 'Discard changes',
        },
        {
          type: 'cancel',
          key: 'continue',
          buttonStyle: 'text-action',
          label: 'Continue editing',
        },
      ]}
    />
  )
}
