import * as L from 'leaflet'
import 'leaflet.markercluster'
import { useCallback, useEffect, useState } from 'react'
import {
  RequestOptions,
  SearchResult,
  SearchTemplate,
  SearchTableClickHandler,
  SearchTableClickActionArg,
  AuthStrategy,
  MapPointComponents,
  SearchResultRow,
} from '@msaf/generic-search-common'
import { MAP_DEFAULTS, convertToLatLng, validateCoordinates, DEFAULT_MARKER_ICON } from '@msaf/maps-common'
import { DataMarker } from '../components/data-marker'
import { useDetailedMapResult } from '../../../generic-search-react/src'
import { MAP_BOUNDS_CACHE_KEY, SIDEBAR_WIDTH } from '../config/constants'
import { ClientCache } from '@msaf/core-common'
type PopupItem = {
  options: {
    data: Record<string, string | number | boolean>
  }
}

export type MapBoundsCacheType = {
  northEast: L.LatLng
  southWest: L.LatLng
}

type UseMapSearchProps<T extends AuthStrategy = 'token'> = {
  searchTypeKey: string
  defaultIcon: L.Icon<L.IconOptions>
  activeSearchTemplate?: SearchTemplate
  searchResults?: SearchResult
  searchRequestOptions?: RequestOptions<T>
  map: L.Map | undefined
  markerIcon?: L.Icon
  cachedMapBounds?: MapBoundsCacheType
}

export default function useMapSearch<T extends AuthStrategy = 'token'>({
  searchTypeKey,
  defaultIcon,
  activeSearchTemplate,
  searchResults,
  searchRequestOptions,
  map,
  markerIcon = DEFAULT_MARKER_ICON,
  cachedMapBounds,
}: UseMapSearchProps<T>) {
  const [popupItems, setPopupItems] = useState<PopupItem[]>([])
  const [popupItemsIndex, setPopupItemsIndex] = useState<number>(0)
  const [popupCurrentId, setPopupCurrentId] = useState<string | number | boolean | null>(null)
  const [mapDetailUniqueIdColKey, setMapDetailUniqueIdColKey] = useState<string | undefined>()
  const [showNextButton, setShowNextButton] = useState(false)
  const [popupData, setPopupData] = useState<{ [key: string]: string }>({})
  const [isMenuPanelOpen, setIsMenuPanelOpen] = useState<boolean>(true)
  const [isContextPanelOpen, setIsContextPanelOpen] = useState<boolean>(false)
  const [markerClusterGroup, setMarkerClusterGroup] = useState<L.MarkerClusterGroup | undefined>()
  // Maintaining a local copy of the cached map bounds to be able to control it locally
  // The cached map bounds should only be applied when the map first mounts
  const [localCachedMapBounds, setLocalCachedMapBounds] = useState(cachedMapBounds)
  const [activeMarker, setActiveMarker] = useState<DataMarker<SearchResultRow> | null>(null)

  const { isSuccess, data } = useDetailedMapResult<T>(
    searchTypeKey,
    popupCurrentId,
    searchRequestOptions,
    !!popupCurrentId,
  )

  // Set up popup/map marker data
  useEffect(() => {
    if (isSuccess && data) {
      setPopupData(data)
    }
  }, [isSuccess, data])

  // Set the key that uniquely identifies a map detail/popup
  useEffect(() => {
    if (activeSearchTemplate) {
      const mapView = activeSearchTemplate?.views.find(
        ({ viewKey }) => viewKey === activeSearchTemplate?.mapConfig?.key,
      )
      const mapDetailUniqueIdCol = mapView?.table.columns.find(
        ({ isMapDetailUniqueIdentifier }) => isMapDetailUniqueIdentifier,
      )
      setMapDetailUniqueIdColKey(mapDetailUniqueIdCol?.elementKey)
    }
  }, [activeSearchTemplate])

  // Set current/active popup id
  useEffect(() => {
    if (!popupItems.length || typeof popupItemsIndex !== 'number') {
      setPopupCurrentId(null)
    } else {
      const popupItem: PopupItem = popupItems[popupItemsIndex]
      popupItem && mapDetailUniqueIdColKey && setPopupCurrentId(popupItem?.options.data[mapDetailUniqueIdColKey])
    }
    setShowNextButton(popupItems.length > 1)
  }, [popupItems, popupItemsIndex, mapDetailUniqueIdColKey])

  // Set the marker cluster group, add layer to map and extend the zoom controls
  useEffect(() => {
    if (map) {
      const markerClusterGroup: L.MarkerClusterGroup = L.markerClusterGroup({
        // eslint-disable-line new-cap
        maxClusterRadius: (zoom: number) => {
          return zoom <= MAP_DEFAULTS.MAX_CLUSTERING_ZOOM_LEVEL ? 50 : 0
        },
        iconCreateFunction: function (cluster: L.MarkerCluster) {
          const childMarkers = cluster.getAllChildMarkers()
          const count = Array.isArray(childMarkers) ? childMarkers.length : 0

          // Check if all the markers in the cluster are in the same location.
          let isSameLocation = false
          if (count) {
            const firstMarkerLatLng = childMarkers[0].getLatLng()
            isSameLocation = childMarkers.every((marker) => firstMarkerLatLng.equals(marker.getLatLng()))
          }

          if (isSameLocation) {
            return L.divIcon({
              className: 'c-cluster__pin',
              html: `${defaultIcon.createIcon().outerHTML}<span class="c-cluster__pin-counter">${count}</span>`,
              iconSize: new L.Point(15, 18),
            })
          }

          const containerClasses = ['c-cluster-icon']
          const countClasses = ['c-cluster-icon__count']
          let size
          // If the number is bigger than 4 digits, add a styling class.
          if (count < 10) {
            containerClasses.push('c-cluster-icon--small')
            countClasses.push('c-cluster-icon__count--small')
            size = 24
          } else if (count < 100) {
            containerClasses.push('c-cluster-icon--medium')
            countClasses.push('c-cluster-icon__count--medium')
            size = 28
          } else if (count < 1000) {
            containerClasses.push('c-cluster-icon--large')
            countClasses.push('c-cluster-icon__count--large')
            size = 32
          } else if (count < 10000) {
            containerClasses.push('c-cluster-icon--xlarge')
            countClasses.push('c-cluster-icon__count--xlarge')
            size = 36
          } else {
            containerClasses.push('c-cluster-icon--xxlarge')
            countClasses.push('c-cluster-icon__count--xxlarge')
            size = 40
          }
          return L.divIcon({
            className: containerClasses.join(' '),
            html: `<span class="${countClasses.join(' ')}">${count}</span>`,
            iconSize: new L.Point(size, size),
          })
        },
        spiderLegPolylineOptions: {
          weight: 2,
          color: '#fff',
          opacity: 0.8,
        },
      })

      markerClusterGroup.on('clusterclick', (event: L.LeafletEvent) => {
        // All children in the cluster.
        const children = event.propagatedFrom.getAllChildMarkers()

        // See if all the points are in the same location or if they differ.
        const first = children[0].getLatLng()
        const sameLocation = !children.some((cm: any) => !first.equals(cm.getLatLng()))

        // See if this cluster already spidered.
        const currentSpidered = event.target._spiderfied === event.propagatedFrom

        // If they are all in the same location display the popup to cycle
        // between them in the middle of the cluster icon, the location test
        // will show they're different if it's already spidered, so check for that.
        if (sameLocation || (!sameLocation && currentSpidered)) {
          setPopupItems(children)
          setPopupItemsIndex(0)
          setIsContextPanelOpen(true)
        }
      })

      map.addLayer(markerClusterGroup)
      setMarkerClusterGroup(markerClusterGroup)
      extendZoomControls(map)
    }
    // Excluded markerPanelState from dependencies to ensure
    // map is not re-initialised everytime marker panel state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, defaultIcon])

  // Setup map markers and popups for the search results
  useEffect(() => {
    const processResults = (searchResults: SearchResult) => {
      markerClusterGroup?.clearLayers()

      if (searchResults.rows) {
        const markers: L.Marker[] = []
        const mapConfig = activeSearchTemplate?.mapConfig

        let crs: string | undefined
        let pointComponents: MapPointComponents | undefined
        if (mapConfig) {
          // Set default coordinate system to NZTM and default point components to easting/northing
          crs = mapConfig.crs != null ? mapConfig.crs : 'EPSG2193'
          pointComponents = mapConfig.pointComponents != null ? mapConfig.pointComponents : ['easting', 'northing']

          searchResults.rows.forEach((searchResult) => {
            if (pointComponents && pointComponents.length === 2) {
              if (
                !validateCoordinates(
                  crs,
                  searchResult[pointComponents[0]] as string,
                  searchResult[pointComponents[1]] as string,
                )
              ) {
                return
              }

              const latLng = convertToLatLng(crs, [
                searchResult[pointComponents[0]] as number,
                searchResult[pointComponents[1]] as number,
              ])

              const marker = new DataMarker<SearchResultRow>(latLng, {
                icon: defaultIcon,
                data: searchResult,
              })

              marker.on('click', function ({ target }: { target: DataMarker<SearchResultRow> }) {
                setPopupItems([{ options: { data: { ...target.data } } }])
                setPopupItemsIndex(0)
                setIsContextPanelOpen(true)
                markers.forEach((mark) => mark.setIcon(defaultIcon))
                marker.setIcon(markerIcon)
                // Store the active marker to later reset the icon
                setActiveMarker(marker)
              })
              markers.push(marker)
            }
          })

          // Would like to chunk loading as per the markercluster docs, however
          // couldn't get the fit bounds to work.
          markerClusterGroup?.addLayers(markers)

          if (markers.length) {
            // Set the map bounds to the cached bounds, if present
            if (localCachedMapBounds?.northEast && localCachedMapBounds.southWest) {
              // Construct a valid LatLngBounds from the cached LatLng values
              // Leaflet needs the SouthWest and NorthEast coordinates to be able to construct LatLngBounds
              const northEastLatLng = new L.LatLng(
                localCachedMapBounds?.northEast.lat,
                localCachedMapBounds.northEast.lng,
              )
              const southWestLatLng = new L.LatLng(
                localCachedMapBounds?.southWest.lat,
                localCachedMapBounds.southWest.lng,
              )
              const cachedBounds = new L.LatLngBounds(southWestLatLng, northEastLatLng)
              resetBounds(cachedBounds)
              // Clear the local cache as its job is now done
              // From here on, the map will reset its bounds depending on the search results
              setLocalCachedMapBounds(undefined)
            } else {
              resetBounds()
            }
          }
          // Close the context panel on search
          toggleContextPanelState(false)
        }
      }
    }

    // Only process the results once MarkerClusterGroup is setup
    markerClusterGroup && searchResults && processResults(searchResults)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerClusterGroup, searchResults])

  const resetBounds = useCallback(
    (bounds?: L.LatLngBounds) => {
      if (map && markerClusterGroup) {
        const padding = 20
        map.fitBounds(bounds ?? markerClusterGroup.getBounds(), {
          paddingTopLeft: [SIDEBAR_WIDTH + padding, padding],
          paddingBottomRight: [padding, padding],
        })
      }
    },
    [map, markerClusterGroup],
  )

  const extendZoomControls = (map: L.Map) => {
    // See: https://github.com/alanshaw/leaflet-zoom-min
    const ZoomControlClass = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: () => {
        const controlElementTag = 'div'
        const controlElementClass = 'c-map-screen__zoom-controls'
        const container = L.DomUtil.create(controlElementTag, controlElementClass)
        return container
      },
      onRemove() {},
    })

    if (map.zoomControl) {
      map.zoomControl.remove()
    }

    const zoomControl = new ZoomControlClass({ position: 'topright' })
    zoomControl.addTo(map)
  }

  const nextAction = () => {
    const nextIndex = (popupItemsIndex + 1) % popupItems.length
    setPopupItemsIndex(nextIndex)
  }

  const previousAction = () => {
    let nextIndex
    if (popupItemsIndex === 0) {
      nextIndex = popupItems.length - 1
    } else {
      nextIndex = (popupItemsIndex - 1) % popupItems.length
    }
    setPopupItemsIndex(nextIndex)
  }

  const viewAction = (
    actionConfig: SearchTableClickHandler,
    customActions?: { [key: string]: (...args: any[]) => any },
  ) => {
    if (actionConfig === undefined || actionConfig === null) {
      // no action configured
      return
    }
    const args = actionConfig.args.map((arg: SearchTableClickActionArg) => {
      if ('constant' in arg && arg.constant) {
        return arg.constant
      }
      if ('elementKey' in arg && arg.elementKey) {
        return popupData[arg.elementKey]
      }
      return null
    })

    const type = actionConfig.type

    if (typeof type === 'string' && type === 'transitionAction') {
      return `/${args.join('/')}`
    } else if (customActions && typeof type === 'string' && typeof customActions[type] === 'function') {
      return customActions[type](...args)
    } else {
      throw new Error(
        'Action is not a transitionAction or a customActions function. This error was likely caused by Generic Search misconfiguration.',
      )
    }
  }

  function onZoomEnd() {
    map && onZoomLevelChange && onZoomLevelChange(map.getBounds())
  }

  const onZoomLevelChange = (bounds: L.LatLngBounds) => {
    const cacheClient = ClientCache.getInstance()
    // Cache map bounds
    cacheClient.create(MAP_BOUNDS_CACHE_KEY, { northEast: bounds.getNorthEast(), southWest: bounds.getSouthWest() })
  }

  const toggleContextPanelState = (state: boolean) => {
    setIsContextPanelOpen(state)
    // Reset marker icon to default to indicate that the marker is no longer selected
    activeMarker?.setIcon(defaultIcon)
    // Reset the active marker state once icon is set to default
    setActiveMarker(null)
  }

  return {
    viewAction,
    nextAction,
    previousAction,
    isMenuPanelOpen,
    setIsMenuPanelOpen,
    isContextPanelOpen,
    toggleContextPanelState,
    resetBounds,
    onZoomLevelChange,
    onZoomEnd,
    showNextButton,
    popupData,
  }
}
