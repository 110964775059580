import { FieldValues } from 'react-hook-form'
import {
  BuiltFormError,
  FieldError,
  FormErrors,
  FormState,
  SectionState,
  WORKFLOWS_CONFIG,
  Workflow,
  WorkflowFormConfig,
  WorkflowFormsConfig,
  WorkflowState,
} from '../validation/constants'
import { useParams } from 'react-router-dom'
import { ApplicationRouteParams, RouteMode } from '../types/route'
import { errorsAsFormConfig, getFieldLabel } from '../forms/utils'

export const useBuildWorkflowErrors = <T extends FieldValues>(
  state: WorkflowState<FormErrors<T>> | undefined,
  workflowId: Workflow,
  sectionId: string,
): BuiltFormError[] => {
  const params = useParams<ApplicationRouteParams>()
  if (!state) return []

  // Gross special sauce to handle plans
  const modelId = workflowId == 'plan' ? params.id : params.applicationId
  if (!modelId) throw new Error(`Can only be called in a plan route.`)

  const modelState = state?.[workflowId]?.[modelId]
  if (!modelState) throw new Error(`ModelId (${modelId}) not in [${Object.keys(state?.[workflowId]).join(', ')}].`)

  const sectionState = modelState.state[sectionId]
  if (!sectionState) throw new Error(`SectionId (${sectionId}) not in [${Object.keys(state).join(', ')}].`)

  const workflowConfig = WORKFLOWS_CONFIG[workflowId]

  const sectionConfigs = Object.values(workflowConfig)
  if (sectionConfigs.length === 0) throw new Error(`No sections configured for workflow (${workflowId}).`)

  return sectionConfigs.flatMap((section) => {
    return buildFormErrorsForWorkflowTree(section.forms, sectionState, params).filter((fd) => fd.errors.length != 0)
  })
}

export const useBuildWorkflowErrorsAsFormNotice = <T extends FieldValues>(
  state: WorkflowState<FormErrors<T>> | undefined,
  workflowId: Workflow,
  sectionId: string,
) => {
  const errors = useBuildWorkflowErrors(state, workflowId, sectionId)
  return errorsAsFormConfig<T>(errors)
}

const buildFormErrorsForWorkflowTree = <T,>(
  forms: WorkflowFormsConfig | undefined,
  workflowState: SectionState<T>,
  params: Partial<ApplicationRouteParams>,
): BuiltFormError[] => {
  return Object.entries(forms ?? {}).flatMap(([formId, formConfig]) => {
    const subforms = buildFormErrorsForWorkflowTree(formConfig.subforms, workflowState, params)
    const formData = workflowState.forms?.[formId]
    const formDataArray = Array.isArray(formData) ? formData : [formData]
    return [...formDataArray.map((fd) => buildWorkflowErrorsForForm(formId, fd, formConfig, params)), ...subforms]
  })
}

const buildWorkflowErrorsForForm = <T,>(
  formId: string,
  { state, errors, objectId }: FormState<T>,
  formConfig: WorkflowFormConfig,
  params: Partial<ApplicationRouteParams>,
) => {
  const formErrors =
    state === 'unstarted' && !formConfig.isRequired == false
      ? [formConfig.unstartedFormError ?? 'This form has not been started']
      : []
  return {
    id: `${formId}.${objectId}`,
    formId,
    formLabel: formConfig.label,
    objectId,
    url: formConfig.pathFunc?.([params.id, params.applicationId, objectId], params?.mode ?? RouteMode.VIEW),
    errors: [...formErrors, ...errors.map((e) => getErrorsLabel(formId, e))],
  }
}

const getErrorsLabel = <T,>(formId: string, error: FieldError<T>) => {
  const fieldId = error.field
  if (typeof fieldId != 'string') throw new Error('Fields should always have a string name')

  return getFieldLabel(fieldId, formId, true)
}
