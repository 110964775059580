import React from 'react'
import { ColumnHeader, TableAction, Table, TableSortByProps } from '@msaf/core-react'
import { SearchQuery, SearchResult, SearchView } from '@msaf/generic-search-common'

// All props for a ResultView are optional, since we can't
// predict what the view implementation will need or want.
// The props are based on what the TableView requires. Most
// other views will probably just use a subset of these.
export interface ResultViewProps {
  showResultsInCard?: boolean
  currentUserPermissions?: Array<string>
  customActions?: { [key: string]: (...args: any[]) => void }
  defaultSortBy?: TableSortByProps
  resultNameSingular?: string
  resultNamePlural?: string
  showResultsCount?: boolean
  noResultsMessage?: string
  pageSize?: number
  isSkeleton?: boolean
  currentView?: SearchView
  searchQuery?: SearchQuery
  searchResults?: SearchResult
  setCurrentPage?: (page: number) => void
  setSortColumn?: (orderColumn: string, orderDirection: 'asc' | 'desc') => void
  dateFormat?: string
}

// A ResultView is just a component that conforms to this interface
export type ResultView = (props: ResultViewProps) => JSX.Element | null

// The default result view, a basic Table
export function TableView(props: ResultViewProps): JSX.Element | null {
  if (!props) return null

  // Destructure the props we need. The Table component requires the
  // setSortColumn and setCurrentPage props to be defined, so there
  // are empty implementations here as defaults.
  // They should never actually be undefined though, since the AdvancedSearch
  // component will get these functions from the useSearch hook, and
  // pass them down to the TableView.
  const {
    searchResults,
    isSkeleton,
    currentView,
    defaultSortBy,
    customActions,
    searchQuery,
    currentUserPermissions,
    pageSize,
    resultNamePlural,
    resultNameSingular,
    showResultsCount,
    noResultsMessage,
    dateFormat,
    setSortColumn = (_c: string, __d: 'asc' | 'desc') => {},
    setCurrentPage = (_p: number) => {},
  } = props

  return (
    <Table
      isSkeleton={isSkeleton}
      columnHeaders={currentView?.table.columns as Array<ColumnHeader>}
      tableData={searchResults?.rows || []}
      onRowClick={currentView?.table.onRowClick as Omit<TableAction, 'handler' | 'actionPermissions'>}
      setSortColumn={setSortColumn}
      defaultSortBy={defaultSortBy}
      customActions={customActions}
      currentUserPermissions={currentUserPermissions}
      currentPage={searchQuery?.requestedPage ?? 1}
      setPage={setCurrentPage}
      totalResults={searchResults?.totalRows ?? 0}
      totalPages={searchResults?.totalRows ? Math.ceil(searchResults.totalRows / (pageSize ?? 10)) : 0}
      resultNameSingular={resultNameSingular}
      resultNamePlural={resultNamePlural}
      showResultsCount={showResultsCount}
      noResultsMessage={noResultsMessage}
      pageSize={pageSize}
      dateFormat={dateFormat}
    />
  )
}
