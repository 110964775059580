import { PropsWithChildren } from 'react'
import { BaseApplicationWorkflow } from '.'
import { UserPermissions } from '../../types/permissions'
import { useViewRedirectGatekeeper } from '../gatekeeper'

interface WorkflowProps {
  recordId: string
}

export const LongTermMonitoringWorkflow = ({ children, recordId }: PropsWithChildren<WorkflowProps>) => {
  useViewRedirectGatekeeper(
    [
      UserPermissions.EDIT_LONG_TERM_MONITORING,
      UserPermissions.EDIT_LONG_TERM_MONITORING_ASSIGNED,
      UserPermissions.EDIT_FONTERRA_LONG_TERM_MONITORING_ASSIGNED,
    ],
    UserPermissions.VIEW_LONG_TERM_MONITORING,
  )

  return (
    <BaseApplicationWorkflow
      recordId={recordId}
      workflow={'longTermMonitoring'}
      noResultsText={'Long term monitoring not enabled'}
    >
      {children}
    </BaseApplicationWorkflow>
  )
}
