import { createToastMessage, ToastMessage } from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { useRequest } from './useRequest'

type Messages = {
  success?: string
  error?: string
}

interface PostActionProps {
  url: string
  messages?: Messages
  displayServerError?: boolean
}

export function usePostAction<TVars, TData, TError extends { message: string | undefined }>(props: PostActionProps) {
  const { client } = useRequest()
  return useMutation<AxiosResponse<TData>, AxiosError<TError>, TVars>((data) => client.post(props.url, data), {
    onSuccess: () => {
      createToastMessage(
        <ToastMessage messageType='success' title='Success' message={props.messages?.success ?? 'Success'} />,
      )
    },
    onError: (e) => {
      let message = props.messages?.error ?? 'An error occurred.'
      if (e.response?.data?.message && props.displayServerError) {
        message = `${message}: ${e.response?.data?.message}`
      }
      createToastMessage(<ToastMessage messageType='error' title='Error' message={message} />)
    },
  })
}
