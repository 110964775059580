import React, { ReactNode } from 'react'

export interface LegendOptionsLabelProps {
  label: string
  indicator: ReactNode
}

export function LegendOptionLabel({ label, indicator }: LegendOptionsLabelProps) {
  return (
    <div className='c-legend-menu__option-label'>
      {indicator}
      {label}
    </div>
  )
}
