import { CheckableField, createToastMessage, FormField, TextInput, ToastMessage } from '@msaf/core-react'
import { FormEvent, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { validationErrorForField } from '../../../forms/utils'
import LookupAddress from '../../lookup-address'
import { ReadOnlyField } from '../../read-only-field'
import { CrudErrors } from '../crud'
import { Person, PersonFields } from './type'
import { useTranslateErrors } from '../../../hooks/use-translate-errors'

interface EditFormProps {
  submitAction: (data: PersonFields) => void
  fields: Partial<PersonFields>
  validationDisabled?: boolean
  isManualPostalAddressEntry?: boolean
  errors?: CrudErrors<Person>
}

interface FieldConfig<T> {
  fieldId: keyof T
  label: string
  type: 'text' | 'email' | 'address' | 'checkbox'
  isRequired: boolean
  validationDisabled?: boolean
  isManualPostalAddressEntry?: boolean
}

const FORM_CONFIG: FieldConfig<PersonFields>[] = [
  { label: 'First name', fieldId: 'firstName', type: 'text', isRequired: true },
  { label: 'Last name', fieldId: 'lastName', type: 'text', isRequired: true },
  { label: 'Phone', fieldId: 'phone', type: 'text', isRequired: true },
  { label: 'Email', fieldId: 'email', type: 'email', isRequired: true },
  { label: 'Postal address', fieldId: 'postalAddress', type: 'address', isRequired: false },
  {
    label: 'Enter address manually',
    fieldId: 'isManualPostalAddressEntry',
    type: 'checkbox',
    isRequired: false,
  },
]

export const EditForm = ({
  submitAction,
  children,
  fields,
  errors,
  validationDisabled,
  isManualPostalAddressEntry,
}: PropsWithChildren<EditFormProps>) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors: formErrors, submitCount },
    control,
    setError,
    clearErrors,
  } = useForm<PersonFields>({ defaultValues: { ...fields }, reValidateMode: 'onBlur', mode: 'all' })
  const watchIsManualPostalAddressEntry = watch('isManualPostalAddressEntry', false)
  const { pathname } = useLocation()
  const fieldConfig: FieldConfig<PersonFields>[] = useMemo(
    () =>
      FORM_CONFIG.map((config) => {
        // emails are required in all instances except when a new EOI is created
        const isNewEoiRoute = pathname.includes('plans/new')
        const isRequired = isNewEoiRoute && config.type == 'email' ? false : config.isRequired && !validationDisabled
        return { ...config, isRequired }
      }),
    [validationDisabled, isManualPostalAddressEntry],
  )

  useTranslateErrors<PersonFields, string>({ errors, setError, clearErrors, submitCount })

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      handleSubmit(
        (data) => {
          submitAction({ ...data })
        },
        () => {
          createToastMessage(
            <ToastMessage
              messageType='error'
              message='Please fill in all required fields before submitting'
              title='Unable to submit'
            />,
          )
        },
      )(e)
      e.stopPropagation()
    },
    [handleSubmit, submitAction],
  )

  return (
    <form onSubmit={onSubmit} data-testid='person-edit-form'>
      {fieldConfig.map(({ label, fieldId, type, isRequired }) => {
        const validationErrors = validationErrorForField(formErrors, fieldId)

        if (type === 'address') {
          return (
            <FormField labelId={`${fieldId}Label`} label='Address'>
              {!watchIsManualPostalAddressEntry ? (
                <Controller
                  control={control}
                  name={fieldId}
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <LookupAddress
                      labelledBy={`${fieldId}Label`}
                      address={(field.value as string) ?? ''}
                      menuPortalTarget={null}
                      setAddressFields={(address) => {
                        field.onChange(address)
                      }}
                    />
                  )}
                />
              ) : (
                <TextInput
                  id={fieldId}
                  type={'text'}
                  isInvalid={!!formErrors[fieldId]}
                  {...register(fieldId, { required: isRequired })}
                />
              )}
            </FormField>
          )
        }

        if (type === 'checkbox') {
          return <CheckableField id={fieldId} label={label} type='checkbox' {...register(fieldId)} />
        }
        return (
          <FormField
            key={fieldId}
            label={label}
            htmlFor={fieldId}
            isRequired={isRequired}
            validationMessages={validationErrors}
          >
            <TextInput
              id={fieldId}
              type={'text'}
              isInvalid={!!formErrors[fieldId]}
              {...register(fieldId, { required: isRequired })}
            />
          </FormField>
        )
      })}
      {children}
    </form>
  )
}

export const ViewForm = ({ fields }: { fields: PersonFields }) => {
  const { control, reset } = useForm<PersonFields>({ defaultValues: { ...fields } })
  const fieldConfig: FieldConfig<PersonFields>[] = useMemo(() => FORM_CONFIG, [])

  useEffect(() => {
    // Form is only for viewing so always reset it to new values.
    reset(fields)
  }, [fields])

  return (
    <div data-testid='person-view-form'>
      {fieldConfig.map(({ label, fieldId }) => (
        <FormField key={fieldId} label={label} labelId={`${fieldId}Label`}>
          <ReadOnlyField<PersonFields> control={control} fieldName={fieldId} labelledBy={`${fieldId}Label`} />
        </FormField>
      ))}
    </div>
  )
}
