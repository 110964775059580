import { StaticInlineFeatureMap } from '../../feature-map/static-inline'
import { PdfPage } from '../pdf-page'
import { Heading } from '@msaf/core-react'
import { MarkerIcon } from '@msaf/maps-react'
import { FeatureType, MAP_CONFIG } from '../../../config/map'
import { FeatureAction } from '../types'

interface PdfMapLandscapeProps {
  pageTitle: string
  features: FeatureAction[]
}

export const PdfMapLandscape = ({ pageTitle, features }: PdfMapLandscapeProps) => {
  const uniqueFeatures = features.reduce(
    (uniqueFeatures: { names: FeatureType[]; features: FeatureAction[] }, feature: FeatureAction) => {
      const featureType = feature.properties?.type
      return !uniqueFeatures.names.includes(featureType)
        ? {
            names: [...uniqueFeatures.names, featureType],
            features: [...uniqueFeatures.features, feature],
          }
        : uniqueFeatures
    },
    {
      names: [],
      features: [],
    },
  )

  if (features.length === 0) return null

  return (
    <PdfPage>
      <div className='l-pdf-container--landscape'>
        <Heading level={2}>{pageTitle}</Heading>
        <StaticInlineFeatureMap
          features={{
            type: 'FeatureCollection',
            features,
          }}
          isEditable={false}
        />

        <div className='c-pdf-container__map-legend'>
          {uniqueFeatures.names.map((featureName) => {
            const markerConfig = MAP_CONFIG.featureConfig[featureName]
            return (
              <div className='c-pdf-container__map-legend-item' key={featureName}>
                <MarkerIcon marker={markerConfig} isLarge={true} />
                {markerConfig.label}
              </div>
            )
          })}
        </div>
      </div>
    </PdfPage>
  )
}
