import React from 'react'
import { SearchTableClickHandler } from '@msaf/generic-search-common'
import { Button } from '@msaf/core-react'

export type SearchPopupActionsProps = {
  actionConfig?: SearchTableClickHandler
  viewAction: (
    actionConfig: SearchTableClickHandler,
    customActions?: { [key: string]: (...args: any[]) => any },
  ) => string | void
  showNextButton: boolean
  previousAction: () => void
  nextAction: () => void
  customActions?: { [key: string]: (...args: any[]) => any }
}

export default function SearchPopupActions({
  actionConfig,
  viewAction,
  showNextButton,
  previousAction,
  nextAction,
  customActions,
}: SearchPopupActionsProps) {
  return (
    <div className='c-marker-panel__actions'>
      {actionConfig && typeof actionConfig['type'] === 'string' && (
        <Button
          label={actionConfig.label}
          buttonStyle='text-action'
          iconAriaLabel={actionConfig.label}
          path={viewAction(actionConfig, customActions) ?? ''}
        />
      )}
      {showNextButton && (
        <>
          <Button label='Previous' onClick={previousAction} buttonStyle='text-action' />
          <Button label='Next' onClick={nextAction} buttonStyle='text-action' />
        </>
      )}
    </div>
  )
}
