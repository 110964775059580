import { useContext } from 'react'
import { DisplayContext } from '../../components/gatekeeper'
import { ErrorCode } from '../../constants'
import { RouteMode } from '../../types/route'
import { Workflow } from '../../validation/constants'
import { useWorkflowState } from '../../validation/hooks'

export const useWorkflowGatekeeper = (
  planId: string | undefined,
  applicationId: string | undefined,
  workflowId: Workflow,
  mode?: RouteMode,
) => {
  const { setError } = useContext(DisplayContext)

  const { isLoading, data } = useWorkflowState(planId, workflowId, mode)

  if (isLoading) return { isLoading, open: undefined }

  if (planId === undefined || applicationId === undefined) {
    setError(ErrorCode.NOT_FOUND_404)
    return { isLoading, open: false }
  }

  const metadata = data?.[workflowId][applicationId].meta

  if (metadata === undefined) {
    setError(ErrorCode.NOT_FOUND_404)
    return { isLoading, open: false }
  }

  return { isLoading, open: metadata['showWorkflow'] ?? false, adviceText: metadata['adviceText'] }
}
