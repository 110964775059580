import { FieldValues } from 'react-hook-form'
import { RouteMode } from '../../../types/route'
import { GetFeatureFormConfigProps } from '..'
import { FeatureFormMode, FormElementConfig, SiteMaintenanceFormFields } from '../../types'
import { isFormModes, toDollarCurrencyFormatter } from '../../utils'

type SiteMaintenanceGetFeatureFormConfigProps<T extends FieldValues> = Pick<
  GetFeatureFormConfigProps<T>,
  'planId' | 'formMode' | 'data'
> & {
  actionType: 'sitePreparation' | 'maintenanceSpraying' | 'maintenanceSpotSpraying' | 'sitePreparationSpotSpraying'
}

export const MAINTENANCE_LOOKUP_ID_MAP = {
  sitePreparation: 'sitePreparationType',
  sitePreparationSpotSpraying: 'sitePreparationSpotSprayingType',
  maintenanceSpraying: 'maintenanceSprayingType',
  maintenanceSpotSpraying: 'maintenanceSpotSprayingType',
}

export const getSiteMaintenanceFormConfig = <T extends FieldValues>({
  planId,
  formMode,
  actionType: type,
  data,
}: SiteMaintenanceGetFeatureFormConfigProps<T>): FormElementConfig<SiteMaintenanceFormFields>[] => {
  const { isMapMode, isMapOrEditMode, isNotVerificationMapMode, isVerificationMode } = isFormModes(formMode)
  const displayStandardFeatureFields = isVerificationMode ? RouteMode.VIEW : RouteMode.EDIT
  const isSpotSprayingAction = ['sitePreparationSpotSpraying', 'maintenanceSpotSpraying'].includes(type)

  return [
    {
      renderOnlyWhen: formMode != FeatureFormMode.MAIN_MAP,
      type: 'field',
      element: {
        // Hack override type to cope with 'area' or 'proposed' field. We don't have verification form types here.
        fieldId: isVerificationMode ? ('proposedArea' as keyof SiteMaintenanceFormFields) : 'area',
        type: 'read-only',
      },
    },
    {
      renderOnlyWhen: isMapOrEditMode,
      type: 'field',
      element: {
        fieldId: 'application',
        type: 'lookup',
        lookupId: 'application',
        fieldFilters: { planId },
        isRequired: true,
        defaultToFirst: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'locationName',
        type: 'text',
        mode: displayStandardFeatureFields,
        isRequired: true,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'anticipatedStart',
        type: 'date',
        isRequired: true,
        showMonthYearPicker: true,
        dateFormat: 'MMM yyyy',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        formPrefix: 'action',
        fieldId: 'justification',
        type: 'text-area',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode,
      type: 'field',
      element: {
        fieldId: 'intendedImplementer',
        type: 'lookup',
        isRequired: true,
        lookupId: 'work_implementor',
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isSpotSprayingAction && isNotVerificationMapMode,
      type: 'field',
      element: {
        labelOverride: 'Difficulty',
        fieldId: 'terrainDifficulty',
        type: 'lookup',
        isRequired: true,
        lookupId: 'terrain_difficulty',
        fieldFilters: { name__in: ['moderate', 'difficult'] },
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode && ['maintenanceSpraying', 'sitePreparation'].includes(type),
      type: 'field',
      element: {
        fieldId: 'maintenanceType',
        labelOverride: ['sitePreparation', 'sitePreparationSpotSpraying'].includes(type)
          ? 'Preparation type'
          : 'Maintenance type',
        type: 'lookup',
        lookupId: MAINTENANCE_LOOKUP_ID_MAP[type],
        isRequired: true,
        mode: displayStandardFeatureFields,
        fieldFilters: type === 'maintenanceSpraying' ? { applicationId: data?.application?.value } : undefined,
        watch:
          type === 'maintenanceSpraying'
            ? ({ info, data, methods, initialConfig }) => {
                if (info.type === 'change' && info.name === 'application') {
                  methods.setValue('maintenanceType', {})
                  if (data?.application?.value)
                    return {
                      ...initialConfig,
                      fieldFilters: { applicationId: data?.application?.value },
                      isDisabled: false,
                    }
                  return { ...initialConfig, fieldFilters: undefined, isDisabled: true }
                }
                return { ...initialConfig, isDisabled: !data?.application?.value }
              }
            : undefined,
      },
    },
    {
      renderOnlyWhen:
        isNotVerificationMapMode && ['sitePreparationSpotSpraying', 'maintenanceSpotSpraying'].includes(type),
      type: 'field',
      element: {
        fieldId: 'numberOfPlantsRequiringSpraying',
        type: 'number',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: isNotVerificationMapMode && ['maintenanceSpraying', 'maintenanceSpotSpraying'].includes(type),
      type: 'field',
      element: {
        fieldId: 'sprayCount',
        type: 'number',
        isRequired: true,
        mode: displayStandardFeatureFields,
      },
    },
    {
      renderOnlyWhen: !isMapMode,
      type: 'field',
      element: {
        fieldId: 'materialPrice',
        labelOverride: ['sitePreparation', 'maintenanceSpraying'].includes(type) ? '$ per m2' : '$ per plant',
        type: 'read-only',
        conversion: toDollarCurrencyFormatter(['sitePreparation', 'maintenanceSpraying'].includes(type) ? 3 : 2),
      },
    },
  ]
}
