import { createContext, PropsWithChildren, useContext } from 'react'

interface ApplicationState {
  isMigrated?: boolean
}

const ApplicationContext = createContext<ApplicationState | undefined>(undefined)

export function ApplicationStateProvider({
  children,
  applicationState,
}: PropsWithChildren<{ applicationState: ApplicationState }>) {
  return <ApplicationContext.Provider value={applicationState}>{children}</ApplicationContext.Provider>
}

export function useApplicationState() {
  const context = useContext(ApplicationContext)
  if (context === undefined) {
    throw new Error('useApplicationState must be used within a ApplicationStateProvider')
  }
  return context
}
