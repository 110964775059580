import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FeatureMap } from '../../../../components/feature-map'
import { LoadingPage } from '../../../../components/loading-page'
import NotFound from '../../../../components/not-found'
import { usePlanPermissions } from '../../../../hooks/use-plan-permissions'
import { PlanSummaryData } from '../../../../hooks/usePlanSummary'
import { useRequest } from '../../../../hooks/useRequest'
import { RouteMode, RouteParams } from '../../../../types/route'

export type MapRouteParams = Pick<RouteParams, 'id'> & { mode: RouteMode.EDIT | RouteMode.VIEW | 'base' }

export const PlanMap = () => {
  const { id, mode } = useParams<MapRouteParams>()
  const { client } = useRequest()

  usePlanPermissions()

  const {
    data: planSummary,
    isSuccess,
    isLoading,
  } = useQuery<PlanSummaryData>([id], () => client.get(`/api/plan/${id}/summary`).then((res) => res.data))

  const isEditMode = useMemo(() => mode === RouteMode.EDIT, [mode])
  const isBase = useMemo(() => mode === 'base', [mode])

  if (!id) return null

  if (isLoading) return <LoadingPage header='Initialising Map' />

  if (!isSuccess) {
    return <NotFound />
  }

  return (
    <FeatureMap
      featureToggle={{
        create: isEditMode,
        edit: isEditMode,
        delete: isEditMode,
        view: !isBase,
      }}
      featuresUrl={`/api/plan/${id}/features`}
      center={planSummary.location ?? undefined}
      zoom={planSummary.location ? 16 : undefined}
      centerMode={'toFeatures'}
      actionUrlTemplate={`/plans/${mode}/${id}/plan/project/:applicationId/action/:actionId`}
      defaultMenuIsOpen={!isBase}
    />
  )
}
