import React from 'react'
import { CommonMarkerPanelProps } from '@msaf/maps-react'
import { SearchViewColumn } from '@msaf/generic-search-common'
import { GroupContainer, KeyValuePair, Tag } from '@msaf/core-react'
import SearchPopupActions, { SearchPopupActionsProps } from '../search-popup-actions'

export interface SearchMarkerPanelProps extends CommonMarkerPanelProps<SearchViewColumn>, SearchPopupActionsProps {
  mapDetailHeadingCol?: SearchViewColumn | undefined
}

export function SearchMarkerPanel({
  config,
  data,
  mapDetailHeadingCol,
  ...searchPopupActionsProps
}: SearchMarkerPanelProps) {
  return (
    <>
      {Array.isArray(data?.tags) && (
        <GroupContainer verticalSpacing='x-small'>
          {data?.tags.map((tag) => (
            <Tag key={tag} label={tag} />
          ))}
        </GroupContainer>
      )}

      {config?.map((col: SearchViewColumn) => (
        <KeyValuePair key={col.elementKey} label={col.columnHeading} value={data?.[col.elementKey] ?? '-'} />
      ))}

      <SearchPopupActions {...searchPopupActionsProps} />
    </>
  )
}
