import { Id } from '@turf/helpers'
import { useContext } from 'react'
import { FeatureMapContext } from '../context'
import { FeatureSelectionPanel } from '../feature-selection-panel'

export interface ViewFeaturesPanelProps {
  setIsMenuPanelOpen: (isOpen: boolean) => void
}

export function ViewFeaturesPanel({ setIsMenuPanelOpen }: ViewFeaturesPanelProps) {
  const { selectFeature } = useContext(FeatureMapContext)

  return (
    <FeatureSelectionPanel
      label='Features available for view'
      handleClick={(featureId: Id) => selectFeature?.(featureId)}
      setIsMenuPanelOpen={setIsMenuPanelOpen}
    />
  )
}
