import { AuthStrategy } from '@msaf/generic-search-common'
import { format } from 'date-fns'
import React, { useCallback } from 'react'
import { usePassedState } from '@msaf/core-react'
import { Date } from '../../../../../../date'
import { FilterProps } from '../../../utils'

export function FilterDate<T extends AuthStrategy = 'token'>({
  value,
  labelledBy,
  dateFormat,
  onKeyDown,
}: FilterProps<T>) {
  if (typeof value !== 'string') throw new Error('Invalid value type for `date`')
  const { state: internalState, setState: setInternalState } = usePassedState<string>(value)

  const setState = useCallback(
    (date: Date) => {
      const dateString = format(date, dateFormat)
      setInternalState(dateString)
    },
    [dateFormat, setInternalState],
  )

  return (
    <Date
      // Accessibility
      ariaLabelledBy={labelledBy}
      // Value props
      onChange={setState}
      value={internalState}
      dateFormat={dateFormat}
      // Styles
      onKeyDown={onKeyDown}
    />
  )
}
