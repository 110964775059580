// Framer Motion animations (see: https://www.framer.com/api/motion/animation/#variants)
export const mobileMenuAnimations = {
  initial: {
    x: '100%',
    transition: {
      duration: 0.25,
    },
  },
  animate: {
    x: 0,
    transition: {
      duration: 0.25,
    },
  },
}

export const desktopMenuAnimations = {
  initial: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.15,
    },
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.15,
    },
  },
}

export const desktopOpacityOnlyMenuAnimations = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.15,
    },
  },
}
