import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useAppContext } from '../contexts/app-context'
import { ApplicationStatusFields } from '../routes/plans/[mode]/[id]/application/[applicationId]/submission'
import { ApplicationRouteParams } from '../types/route'
import { useFeatureFlags } from './use-feature-flags'
import { useGenerateExport } from './use-generate-export'

export const useAddGenerateExportMenu = (data: ApplicationStatusFields | undefined) => {
  const { applicationId } = useParams<ApplicationRouteParams>()
  const { addPageActions: addPageAction, removePageActions } = useAppContext()

  const { hasFlag } = useFeatureFlags()

  const { mutate: generateExportAction } = useGenerateExport()

  useEffect(() => {
    if (hasFlag('pdf_export') && !!applicationId && data?.isMigrated == false) {
      addPageAction?.([
        {
          id: 'generate-pdf',
          label: 'Generate PDF',
          action: generateExportAction,
        },
      ])
    }

    return () => {
      removePageActions?.('generate-pdf')
    }
  }, [hasFlag, applicationId, data?.isMigrated])
}
