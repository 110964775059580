import { Button, Modal, usePassedState } from '@msaf/core-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useMutationWithToast } from '../../../../hooks/use-mutation-with-toast'

export type ConfirmActionProps<T> = {
  requestUrl: string
  isOpen?: boolean
  contentLabel?: string
  heading?: string
  body?: string
  successMessage?: string
  errorMessage?: string
  primaryActionLabel: string
  postActionFunc?: (data: T) => void
  onRequestClose?: VoidFunction
}

export default function ConfirmAction<T>({
  requestUrl,
  contentLabel = 'Action confirmation dialog',
  heading,
  body,
  successMessage = 'Success',
  errorMessage = 'Could not complete the action. Please try again.',
  primaryActionLabel,
  postActionFunc,
  onRequestClose,
  ...props
}: ConfirmActionProps<T>) {
  const { state: isOpen, setState: setIsOpen } = usePassedState(props.isOpen ?? false)
  const mutation = useMutationWithToast<AxiosResponse<T>, AxiosError<Map<keyof T, Array<string>>>, T, void>({
    method: 'DELETE',
    url: requestUrl,
    success: {
      toastMessage: successMessage,
    },
    error: {
      toastMessage: errorMessage,
    },
  })

  const handleAction = () => {
    mutation.mutate({} as T, {
      onSuccess: ({ data }) => {
        setIsOpen(false)
        postActionFunc?.(data)
      },
    })
  }

  const onCancel = () => {
    setIsOpen(false)
    onRequestClose?.()
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={onCancel}
      heading={heading}
      body={body}
      buttons={
        <>
          <Button buttonStyle='primary' label={primaryActionLabel} onClick={handleAction} />
          <Button buttonStyle='text-action' label='Cancel' onClick={onCancel} />
        </>
      }
    />
  )
}
