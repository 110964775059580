import React, { DragEventHandler, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { dropAreaDefaultVariants, dropAreaHoverVariants } from './animations'
import {
  FileErrorResponse,
  FileSaveActionCallback,
  FileState,
  FileUploadedCallback,
  FileUploadFailedCallback,
  FileUploadingCallback,
  NewFile,
  UploadedFile,
} from '../types'
import { DEFAULT_VALIDATORS, DEFAULT_ALLOWED_FILE_TYPES, FileValidationResult, FileValidator } from './validators'
import { useMediaQuery } from '../../../../hooks'
import { getRandomID } from '@msaf/core-common'
import { Button, createToastMessage, Icon, ToastMessage } from '../../../common'
import { MOBILE_BREAKPOINT } from '../../../../constants'

export * from './validators'

export interface FileUploadProps {
  fileRequirementsText?: string
  isSkeleton?: boolean
  fileSaveAction: FileSaveActionCallback
  onFileUploading?: FileUploadingCallback
  onFileUploaded?: FileUploadedCallback
  onFileUploadFailed?: FileUploadFailedCallback
  fileValidators?: FileValidator[]
  allowedFileTypes?: string[]
  allowMultiple?: boolean
}

export function FileUpload({
  fileRequirementsText,
  fileSaveAction,
  isSkeleton = false,
  onFileUploading,
  onFileUploaded,
  onFileUploadFailed,
  fileValidators = DEFAULT_VALIDATORS,
  allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES,
  allowMultiple = false,
}: FileUploadProps) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT})`)
  const [failedFiles, setFailed] = useState<Array<NewFile>>([])

  const fileInputRef = useRef<HTMLInputElement>(null!)
  const [isDragging, setIsDragging] = useState(false)

  const addFileMutation = useMutation<Omit<UploadedFile, 'state'>, FileErrorResponse, NewFile>(fileSaveAction, {
    onMutate: (newFile: NewFile) => {
      onFileUploading?.(newFile)
    },
    onSuccess: (result: Omit<UploadedFile, 'state'>, sent: NewFile) => {
      onFileUploaded?.(
        {
          ...result,
          state: FileState.Uploaded,
        },
        sent.id,
      )
    },
    onError: (error: any, newFile: NewFile) => {
      if (error?.name === 'AbortError') {
        // We don't care if the upload was aborted.
        return
      }
      if (error.file) {
        newFile.error = error.file[0]
      } else {
        newFile.error = error
      }
      setFailed((previous) => [...previous, newFile])
      onFileUploadFailed?.(newFile, error)
    },
  })

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true)
    }
  }

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleFileDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setIsDragging(false)
    handleFiles(e.dataTransfer.files)
  }

  const handleSelectedFiles = () => {
    handleFiles(fileInputRef.current.files)
  }

  const handleBrowseFiles = () => {
    fileInputRef.current.click()
  }

  const validateFile = (file: File): FileValidationResult => {
    for (let i = 0; i < fileValidators.length; i++) {
      let result = fileValidators[i](file)
      if (!result.isValid) {
        return result
      }
    }

    return {
      isValid: true,
    }
  }

  const handleFiles = async (files: FileList | null) => {
    Array.from(files ?? []).forEach((file: File) => {
      const { isValid, error } = validateFile(file)
      const newFile: NewFile = {
        id: getRandomID(),
        originalName: file.name,
        size: file.size,
        file: file,
        createdTimestamp: new Date().toISOString(),
        state: FileState.Uploading,
        abortController: new AbortController(),
      }

      if (isValid && allowMultiple) {
        addFileMutation.mutate(newFile)
      } else if (isValid && files!.length === 1) {
        addFileMutation.mutate(newFile)
      } else {
        newFile.error = error
        setFailed((previous) => [...previous, newFile])
      }
    })
  }

  const containerStyles = classNames('c-file-upload', {
    'c-file-upload--active': isDragging,
  })

  useEffect(() => {
    setFailed((failedFiles) => {
      while (failedFiles.length !== 0) {
        const file = failedFiles.pop()
        createToastMessage(
          <ToastMessage
            messageType='error'
            title='Operation failed'
            message={`File upload failed for ${file?.originalName}: ${file?.error}`}
          />,
        )
      }
      return failedFiles
    })
  }, [failedFiles])

  if (isSkeleton) return <></>

  return (
    <div
      className={containerStyles}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleFileDrop}
    >
      <input
        ref={fileInputRef}
        type='file'
        accept={allowedFileTypes.join(',')}
        multiple={allowMultiple}
        hidden
        aria-label='File upload'
        onChange={handleSelectedFiles}
      />

      {/* Default state of file drop area: */}
      <motion.div
        className='c-file-upload__instructions c-file-upload__instructions--default'
        variants={dropAreaDefaultVariants}
        initial='default'
        animate={isDragging ? 'hover' : 'default'}
      >
        <Icon className='c-file-upload__icon' icon='new' ariaHidden />

        <div className='c-file-upload__instructions-text'>Drag and drop files here to upload</div>

        {isMobile ? (
          <Button
            isSkeleton={isSkeleton}
            className='c-file-upload__upload-btn'
            onClick={handleBrowseFiles}
            buttonStyle='secondary'
            label='Add file'
            icon='add'
            iconAlignment='left'
            type='button'
          />
        ) : (
          <Button
            isSkeleton={isSkeleton}
            className='c-file-upload__upload-btn'
            onClick={handleBrowseFiles}
            isDisabled={isDragging}
            buttonStyle='primary'
            label='Browse for files'
            type='button'
          />
        )}
        <div className='c-file-upload__requirements'>{fileRequirementsText}</div>
      </motion.div>

      {/* Alternate state when user has dragged file over top of drop area. Hidden by default */}
      <motion.div
        className='c-file-upload__instructions c-file-upload__instructions--hover'
        variants={dropAreaHoverVariants}
        initial='default'
        animate={isDragging ? 'hover' : 'default'}
      >
        <Icon className='c-file-upload__hover-icon' icon='upload' ariaHidden />
        <div className='c-file-upload__instructions-text'>Release to begin upload</div>
      </motion.div>
    </div>
  )
}
